import React from 'react';
import '../TopHeader/TopHeader.css';
import ProfileSetting from '../ProfileSetting/ProfileSetting';
import Notifications from '../Dashboard/Notifications/Notifications';
import WelcomeName from './WelcomeName/WelcomeName';
import fabIcon from '../images/thinksys-favicon.png';
import { useState } from 'react';
import NavbarMobile from '../Navbar/NavbarMobile';

const TopHeader = (props) => {
  const [active, setActive] = useState(false);
  const handleMobileMenu = () => {
    setActive(true);
  };
  return (
    <>
      <NavbarMobile setActive={setActive} active={active} />
      <div className="topHeader">
        <div className="topHeaderleft">
          <i className="fa-light fa-bars-sort mobileMenuIcon" onClick={handleMobileMenu}></i>
          <WelcomeName className="mobileMenuWelcomeText" />
        </div>
        <div className="topHeaderCenter"><img src={fabIcon} alt="FavIcon" className="img-fluid" /></div>
        <div className="topHeaderRight">
          <Notifications {...props} />
          <ProfileSetting />
        </div>
      </div>
    </>
  );
};
export default TopHeader;
