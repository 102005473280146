// import CarouselEmployee from "./CarouselEmployee";
import React, { useEffect, useState } from "react";
import ImageSlider from "./ImageSlider";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
//import { SliderData } from "./Slider";
import {
  fetchEmployeeConsent,
  fetchHandbookSlides,
  markHandbookConsent,
  markPageWiseConsent,
} from "../../store/slices/handbookSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchConfigData } from "../../store/slices/configData";
import { NavLink } from "react-router-dom";
import { componentDisplayAccess } from "../../utils/userPermission";
import Loader from "../Loader/Loader";

export const EmployeeHandbook = () => {
  const HandbookAccess = componentDisplayAccess({
    component: "Handbook",
  });
  const dispatch = useDispatch();
  const slides = useSelector((state) => state?.handbook?.handbookData);
  useEffect(() => {
    dispatch(fetchHandbookSlides());
    dispatch(fetchConfigData());
    dispatch(fetchEmployeeConsent());
  }, []);

  const configData = useSelector((state) => state?.config?.configData);
  const policyVersion = configData?.filter(
    (i) => i?.name === "PolicyVersion"
  )[0];
  const companyName = configData?.filter((i) => i?.name === "companyName")[0];

  const consentData = useSelector((state) => state?.handbook?.employeeConsent);
  const employeeConsent = consentData?.data?.handbookConsent;
  const slideConsentData = consentData?.data?.slideConsent;

  const [enable, setEnable] = useState(true);
  const [loader, setLoader] = useState(false);
  const [handbookConsent, setHandbookConsent] = useState(false);
  const [pageConsent, setPageConsent] = useState(false);
  const [slideConsent, setSlideConsent] = useState(slideConsentData);
  const [id, setId] = useState(null);
  const [current, setCurrent] = useState(0);

  const handleHandbookClick = async () => {
    setEnable(true);
    setLoader(true);
    await dispatch(markHandbookConsent());
    await dispatch(fetchEmployeeConsent());
    setLoader(false);
    setHandbookConsent(false);
  };

  const handlePageClick = async () => {
    setLoader(true);
    await dispatch(
      markPageWiseConsent({
        handbookId: id,
      })
    );
    await dispatch(fetchEmployeeConsent());
    setLoader(false);
    setPageConsent(false);
  };
  return (
    <div>
      <div
        className="flex flex-col gap-3 position-absolute top-20 right-10"
        style={{ zIndex: "1" }}
      >
        {HandbookAccess?.updateAccess ? (
          <NavLink
            to="/handbook-dashboard"
            className="editHandBook border-1 border-gray-200 rounded-md py-2 px-3 text-sm cursor-pointer bg-white text-center"
          >
            <div>Edit HandBook</div>
          </NavLink>
        ) : (
          <></>
        )}
        {/* {!employeeConsent ? (
          <button
            type="button"
            className="border-1 border-gray-200 rounded-md py-2 px-3 text-sm bg-white cursor-pointer hover:text-blue-600 focus:outline-none disabled:opacity-25"
            disabled={enable}
            onClick={() => setHandbookConsent(true)}
          >
            Mark Handbook Consent
          </button>
        ) : (
          <></>
        )}
        {employeeConsent ? (
          <button
            type="button"
            className="border-1 border-gray-200 rounded-md py-2 px-3 text-sm bg-green-400 text-white focus:outline-none disabled:opacity-50"
            disabled={true}
          >
           Consent Marked
          </button>
        ) : (
          <></>
        )}
        {slideConsent ? (
          <button
            type="button"
            className="border-1 border-gray-200 rounded-md py-2 px-3 text-sm bg-white cursor-pointer hover:text-blue-600 focus:outline-none disabled:opacity-25"
            // disabled={enable}
            onClick={() => setPageConsent(true)}
          >
            Mark Slide Consent
          </button>
        ) : (
          <></>
        )} */}
      </div>

      {/* {handbookConsent ? (
        <Dialog
          open={handbookConsent}
          //   TransitionComponent={Transition}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
          className="employee_form_dialog"
        >
          <DialogTitle className="add_employee_main_heading text-red-600">
            <span>Mark consent for Handbook </span>
          </DialogTitle>
          <DialogContent className="card-content-employee-form">
            <div className="">
              Are you sure you have read all slides of Handbook?
            </div>
          </DialogContent>
          <DialogActions className="employee_form_button_div">
            <button
              type="button"
              onClick={() => setHandbookConsent(false)}
              className="border-1 border-gray-300 rounded-lg py-2 px-4 hover:bg-gray-50 text-sm mr-3"
            >
              No
            </button>
            <button
              onClick={() => handleHandbookClick()}
              className="border-1 border-blue-500 rounded-lg py-2 px-4 bg-blue-600 text-white hover:bg-blue-700 hover:text-white text-sm"
            >
              Yes
            </button>
          </DialogActions>
        </Dialog>
      ) : null} */}

      {/* {pageConsent ? (
        <Dialog
          open={pageConsent}
          //   TransitionComponent={Transition}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
          className="employee_form_dialog"
        >
          <DialogTitle className="add_employee_main_heading text-red-600">
            <span>Mark consent for Slide </span>
          </DialogTitle>
          <DialogContent className="card-content-employee-form">
            <div className="">Are you sure you have read this slide?</div>
          </DialogContent>
          <DialogActions className="employee_form_button_div">
            <button
              type="button"
              onClick={() => setPageConsent(false)}
              className="border-1 border-gray-300 rounded-lg py-2 px-4 hover:bg-gray-50 text-sm mr-3"
            >
              No
            </button>
            <button
              onClick={() => handlePageClick()}
              className="border-1 border-blue-500 rounded-lg py-2 px-4 bg-blue-600 text-white hover:bg-blue-700 hover:text-white text-sm"
            >
              Yes
            </button>
          </DialogActions>
        </Dialog>
      ) : null} */}

      {loader ? (
        <Loader />
      ) : (
        <ImageSlider
          slides={slides}
          policyVersion={policyVersion}
          companyName={companyName}
          setEnable={setEnable}
          setSlideConsent={setSlideConsent}
          setId={setId}
          current={current}
          setCurrent={setCurrent}
        />
      )}
    </div>
  );
};
