import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import END_POINTS from "../../constants/endpoints";
import http from "../../utils/http";
import { toast } from "react-toastify";

export const fetchHandbookSlides = createAsyncThunk(
  END_POINTS.GET_ALL_HANDBOOK_SLIDES,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.GET_ALL_HANDBOOK_SLIDES);
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchEmployeeConsent = createAsyncThunk(
  END_POINTS.GET_EMPLOYEE_CONSENT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.GET_EMPLOYEE_CONSENT}/${params?.title ? params?.title : "All"}`);
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchHandbookSlideById = createAsyncThunk(
  END_POINTS.GET_HANDBOOK_SLIDE_BY_ID,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.GET_HANDBOOK_SLIDE_BY_ID}${params?.Id}`);
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createHandbookSlides = createAsyncThunk(
  END_POINTS.CREATE_HANDBOOK_SLIDE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(`${END_POINTS.CREATE_HANDBOOK_SLIDE}`, {
        title: params?.title,
        titleName: params?.titleName,
        handbookHtml: params?.handbookHtml,
      });
      toast.success(data.message);
      return data;
    } catch (error) {
      toast.error(error.response.data.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const markHandbookConsent = createAsyncThunk(
  END_POINTS.MARK_HANDBOOK_CONSENT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(`${END_POINTS.MARK_HANDBOOK_CONSENT}`);
      toast.success(data.message);
      return data;
    } catch (error) {
      console.log('error:', error)
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
// 

export const markPageWiseConsent = createAsyncThunk(
  END_POINTS.MARK_SLIDE_CONSENT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(`${END_POINTS.MARK_SLIDE_CONSENT}`, {
        handbookId: params?.handbookId,
      });
      toast.success(data.message);
      return data;
    } catch (error) {
      console.log('error:', error)
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const notifyEmployeesForConsent = createAsyncThunk(
  END_POINTS.NOTIFY_EMPLOYEES_FOR_CONSENT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(`${END_POINTS.NOTIFY_EMPLOYEES_FOR_CONSENT}`, {
        title: params?.title,
      });
      toast.success(data.message);
      return data;
    } catch (error) {
      console.log('error:', error)
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateHandbookSlides = createAsyncThunk(
  END_POINTS.UPDATE_HANDBOOK_SLIDE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(`${END_POINTS.UPDATE_HANDBOOK_SLIDE}/${params?.Id}`, {
        title: params?.title,
        titleName: params?.titleName,
        handbookHtml: params?.handbookHtml,
        is_consent_required: params?.isConsentRequired,
      });
      toast.success(data.message);
      return data;
    } catch (error) {
      toast.error(error.response.data.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const deleteHandbookSlide = createAsyncThunk(
  END_POINTS.DELETE_HANDBOOK_SLIDE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(`${END_POINTS.DELETE_HANDBOOK_SLIDE}/${params?.Id}`);
      toast.success(data.message);
      return data;
    } catch (error) {
      console.log('error:', error)
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateManageHandBookOrder = createAsyncThunk(
  END_POINTS.MANAGE_HANDBOOK_ORDER,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(`${END_POINTS.MANAGE_HANDBOOK_ORDER}`, params);
      toast.success(data.message);
      return data;
    } catch (error) {
      toast.error(error.response.data.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

const initialState = {
  handbookData: [],
  handbookDataById: [],
  employeeConsent: {},
};

export const handbookSlice = createSlice({
  name: "handbook",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHandbookSlides.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.isFetching = true;
      })
      .addCase(fetchHandbookSlides.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isFetching = false;
        state.success = true;
        state.handbookData = payload.data;
        state.error = null;
      })
      .addCase(fetchHandbookSlides.rejected, (state, { payload }) => {
        state.loading = false;
        state.isFetching = false;
        state.error = payload;
        state.handbookData = [];
        state.success = false;
      })
      // 
      .addCase(fetchEmployeeConsent.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.isFetching = true;
      })
      .addCase(fetchEmployeeConsent.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isFetching = false;
        state.success = true;
        state.employeeConsent = payload;
        state.error = null;
      })
      .addCase(fetchEmployeeConsent.rejected, (state, { payload }) => {
        state.loading = false;
        state.isFetching = false;
        state.error = payload;
        state.employeeConsent = [];
        state.success = false;
      })

      .addCase(fetchHandbookSlideById.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.isFetching = true;
      })
      .addCase(fetchHandbookSlideById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isFetching = false;
        state.success = true;
        state.handbookDataById = payload.data;
        state.error = null;
      })
      .addCase(fetchHandbookSlideById.rejected, (state, { payload }) => {
        state.loading = false;
        state.isFetching = false;
        state.error = payload;
        state.handbookDataById = [];
        state.success = false;
      })

      .addCase(createHandbookSlides.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.isFetching = true;
      })
      .addCase(createHandbookSlides.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isFetching = false;
        state.success = true;
        state.handbookData = payload.data;
        state.error = null;
      })
      .addCase(createHandbookSlides.rejected, (state, { payload }) => {
        state.loading = false;
        state.isFetching = false;
        state.error = payload;
        state.handbookData = [];
        state.success = false;
      })
      .addCase(updateHandbookSlides.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.isFetching = true;
      })
      .addCase(updateHandbookSlides.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isFetching = false;
        state.success = true;
        state.handbookData = payload.data;
        state.error = null;
      })
      .addCase(updateHandbookSlides.rejected, (state, { payload }) => {
        state.loading = false;
        state.isFetching = false;
        state.error = payload;
        state.handbookData = [];
        state.success = false;
      })

  },
});
export default handbookSlice.reducer;
