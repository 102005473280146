import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "../../utils/http";
import END_POINTS from "../../constants/endpoints";
import { toast } from "react-toastify";

export const fetchTeamTodayAttendanceStatus = createAsyncThunk(
  END_POINTS.TEAM_TODAY_ATTENDANCE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.TEAM_TODAY_ATTENDANCE}`, {
        params: { 
          page: params.page, 
          limit: params.limit,
          search: params.search,
          leaveType: params.leaveType,
          projectType: params.projectType,
          attendanceStatus: params.attendanceStatus,
          reporting: params.reporting,
        },
      });
      return { teamTodayStatus: data?.data };
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchTeamStatus = createAsyncThunk(
  END_POINTS.TEAM_ATTENDANCE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.TEAM_ATTENDANCE}`, {
        params: { startDate: params.startDate, endDate: params.endDate },
      });
      return { attendanceData: data?.data?.attendanceData };
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
// , {params : {startDate: params.startDate, endDate: params.endDate}}
export const fetchDepartmentResources = createAsyncThunk(
  END_POINTS.DEPARTMENT_RESOURCES,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.DEPARTMENT_RESOURCES}`);
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchTeamAttendanceData = createAsyncThunk(
  END_POINTS.TEAM_COMPLETE_ATTENDANCE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.TEAM_COMPLETE_ATTENDANCE}`,
        {
          params: params,
        }
      );

      return { attendanceData: data?.data };
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

const initialState = {
  attendanceData: {},
  teamTodayStatus: {
    teamTodayStatus: [],
    isFetching: false,
  },
  teamAttendanceData: {},
  isFetching: false,
  success: false,
  fetchDepartmentResources: {
    data: [],
    loading: false,
    error: null,
    success: null,
  },
};

export const TeamSlice = createSlice({
  name: "TeamSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeamStatus.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchTeamStatus.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.success = true;
        state.attendanceData = payload.attendanceData;
      })
      .addCase(fetchTeamStatus.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.attendanceData = [];
        state.success = false;
      })
      .addCase(fetchTeamTodayAttendanceStatus.pending, (state) => {
        state.teamTodayStatus.isFetching = true;
      })
      .addCase(
        fetchTeamTodayAttendanceStatus.fulfilled,
        (state, { payload }) => {
          state.teamTodayStatus.isFetching = false;
          state.success = true;
          state.teamTodayStatus.teamTodayStatus = payload?.teamTodayStatus;
        }
      )
      .addCase(
        fetchTeamTodayAttendanceStatus.rejected,
        (state, { payload }) => {
          state.teamTodayStatus.isFetching = false;
          state.teamTodayStatus.teamTodayStatus = {};
          state.success = false;
        }
      )

      .addCase(fetchDepartmentResources.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchDepartmentResources.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.success = true;
        state.data = payload.departmentData;
      })
      .addCase(fetchDepartmentResources.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.data = {};
        state.success = false;
      })
      .addCase(fetchTeamAttendanceData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchTeamAttendanceData.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.success = true;
        state.teamAttendanceData = payload;
      })
      .addCase(fetchTeamAttendanceData.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.teamAttendanceData = {};
        state.success = false;
      });
  },
});

export default TeamSlice.reducer;
