import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  errormsg: {
    fontSize: "12px",
    color: "#f00",
    marginTop: "8px",
    marginBottom: "15px",
  },
}));
const AutoCompleteComponent = ({
  name,
  //   defaultValue,
  options,
  control,
  errors,
  onChangeHandler,
  multiple = false,
  isFirstValueDefault = false,
  disableClearable = false,
  disabled = false,
  limitTags = 2,
  required = true,
  disableInput = false,
  filterSelectedOptions = false,
  placeholder = "",
}) => {
  const classes = useStyles();
  const getErrorMessage = (fieldName) => {
    let nestedErrors = errors;
    const fieldNames = fieldName.split(".");
    for (const field of fieldNames) {
      nestedErrors = nestedErrors?.[field];
      if (!nestedErrors) break;
    }
    return nestedErrors?.message;
  };
  const error = getErrorMessage(name);

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{
          required: required,
        }}
        render={({ field: { onChange, name, value } }) => {
          // Ensure value is always an array for multiple selection
          const selectedValue = Array.isArray(value) ? value : [];

          return (
            <Autocomplete
              popupIcon={<KeyboardArrowDownIcon />}
              disabled={disabled}
              options={options}
              onChange={(e, value) => {
                onChange(value);
                onChangeHandler && onChangeHandler(value);
              }}
              // disableInput={disableInput}
              limitTags={multiple ? limitTags : 1}
              disableClearable={disableClearable}
              filterSelectedOptions={filterSelectedOptions}
              multiple={multiple}
              filterSelectedOptions={filterSelectedOptions}
              isOptionEqualToValue={(option, value) =>
                option?.value === value?.value
              }
              getOptionLabel={(option) => option?.label || ""}
              value={
                multiple
                  ? selectedValue
                  : selectedValue.length > 0
                  ? selectedValue[0]
                  : value ||
                    (isFirstValueDefault && options[0] ? options[0] : null)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  className="autoCompleteImput"
                  disabled={disableInput}
                  placeholder={placeholder}
                />
              )}
            />
          );
        }}
      />

      {error ? <p className={classes.errormsg}>{error}</p> : null}
    </>
  );
};

export default AutoCompleteComponent;
