import React from "react";
import { Controller } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import "./MasterComponent.css";
import { TextField } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  errormsg: {
    fontSize: "12px",
    color: "#f00",
    marginTop: "5px",
    marginBottom: "15px",
  },
}));

const TextFieldNumberComponent = ({
  name,
  control,
  errors,
  type,
  disabled,
  defaultValue,
  InputProps,
  onChangeHandler,
}) => {
  const classes = useStyles();
  const getErrorMessage = (fieldName) => {
    let nestedErrors = errors;
    const fieldNames = fieldName.split(".");
    for (const field of fieldNames) {
      nestedErrors = nestedErrors?.[field];
      if (!nestedErrors) break;
    }
    return nestedErrors?.message;
  };
  const error = getErrorMessage(name);

  return (
    <div>
      <Controller
        name={name}
        control={control}
        errors={errors}
        render={({ field: { onChange, value, name } }) => (
          <TextField
            type={type}
            disabled={disabled}
            defaultValue={defaultValue}
            onChange={(event) => {
              const inputValue = String(event.target.value); // Remove leading and trailing whitespaces
              if (!inputValue?.includes(".")) {
                event.target.value =
                  event.target.value < 0
                    ? null
                    : parseFloat(event.target.value);
                const value = event.target.value;
                onChange(value);
                onChangeHandler && onChangeHandler(value);
              } else {
                onChange(event?.target?.value); // Update form value without parsing
                onChangeHandler && onChangeHandler(event?.target?.value); // Call onChangeHandler if provided
              }
            }}
            InputProps={{ ...InputProps }}
            className="form-control"
          />
        )}
      />
      {error ? <p className={classes?.errormsg}>{error}</p> : null}
    </div>
  );
};

export default TextFieldNumberComponent;
