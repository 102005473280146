import React, { useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import DatePickerComponent from "../../../MasterComponent/DatePickerComponent";
import dayjs from "dayjs";
import TextFieldComponent from "../../../MasterComponent/TextFieldComponent";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  updateAttendance,
} from "../../../../store/slices/masterSlice";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const UpdateAttendanceSchema = yup.object().shape({
  employee: yup?.string()?.label("Employee Name")?.required(),
  att_date: yup?.string()?.label("Attendance Date")?.required(),
  InTime: yup?.date()?.label("In Time")?.required(),
  OutTime: yup?.date()?.label("Out Time")?.required(),
  is_regularized: yup?.boolean()?.label("Is Regularized")?.optional(),
});

const UpdateAttendance = ({
  openRightDrawer,
  setOpenRightDrawer,
  employeeName,
  attendanceData,
  setRenderComp,
}) => {
  const defaultValue = {
    employee: employeeName ?? null,
    att_date: attendanceData?.attendanceDate ?? null,
    InTime: attendanceData?.InTime ?? null,
    OutTime: attendanceData?.OutTime ?? null,
    is_regularized: attendanceData?.is_regularized == 1 ? true : false,
  };

  const methods = useForm({
    defaultValues: defaultValue,
    resolver: yupResolver(UpdateAttendanceSchema),
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
    watch,
  } = methods;

  useEffect(() => {
    reset(defaultValue);
  }, [employeeName, attendanceData]);

  const submitHandler = async (data) => {
    const submitData = {
      fromDateTime: data?.InTime,
      toDateTime: data?.OutTime,
      isRegularized: data?.is_regularized,
    };
    try {
      const result = await dispatch(
        updateAttendance({
          attendanceId: attendanceData?.attendanceId,
          data: submitData,
        })
      );
      if (result?.meta?.requestStatus === "fulfilled") {
        setRenderComp(true);
      }
      setOpenRightDrawer(false);
      reset();
    } catch (err) {
      console.log("err:", err);
    }
  };

  const dispatch = useDispatch();

  let maxDate = dayjs().format("YYYY-MM-DD");
  return (
    <>
      <div
        className={
          openRightDrawer ? "drawerContainer active" : "drawerContainer"
        }
      >
        {/* <div className="drawerHeader">
          <div>Update Attendance</div>
          <div
            className="headerClose"
            onClick={() => setOpenRightDrawer(false)}
          >
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </div> */}
        <div className="formContent relative">
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(submitHandler)}>
              <div className="px-3 mt-3">
                <div>
                  <label
                    htmlFor="employee"
                    className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Employee Name:
                  </label>
                  <TextFieldComponent
                    name="employee"
                    control={control}
                    errors={errors}
                    disabled={true}
                  />
                </div>
                <div className="">
                  <label className="create_form_labels block">
                    Attendance Date:
                  </label>
                  <DatePickerComponent
                    name="att_date"
                    errors={errors}
                    control={control}
                    disableMaskedInput={true}
                    backSpaceChange={false}
                    maxDate={maxDate}
                    disabled={true}
                  />
                </div>
                <div className="">
                  <label className="create_form_labels block">
                    First In:
                    {/* <span className="create_form_asterisk">*</span>: */}
                  </label>
                  <Controller
                    name="InTime"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          className="form-control"
                          name="InTime"
                          value={value}
                          onChange={(e) => onChange(dayjs(e)?.toISOString())}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "",
                              }}
                              onKeyDown={(e) => e?.preventDefault()}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </div>
                <div className="">
                  <label className="create_form_labels block">
                    Last Out:
                    {/* <span className="create_form_asterisk">*</span>: */}
                  </label>
                  <Controller
                    name="OutTime"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          className="form-control"
                          name="OutTime"
                          value={value}
                          onChange={(e) => onChange(dayjs(e)?.toISOString())}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "",
                              }}
                              onKeyDown={(e) => e?.preventDefault()}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </div>
                <div className="mt-2">
                  <Controller
                    name="is_regularized"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e, val) => onChange(val)}
                              checked={value || false}
                            />
                          }
                          label="Is Regularized"
                        />
                      </FormGroup>
                    )}
                  />
                </div>
                <div className="mt-4 text-right">
                  <button
                    type="button"
                    onClick={() => setOpenRightDrawer(false)}
                    className="border-1 border-gray-300 rounded-lg py-2 px-4 hover:bg-gray-50 text-sm mr-4"
                  >
                    Cancel
                  </button>
                  {isDirty ? (
                    <button
                      type="submit"
                      className="border-1 border-blue-500 rounded-lg py-2 px-4 bg-blue-600 text-white hover:bg-blue-700 hover:text-white text-sm"
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      type="submit"
                      id="newDisable"
                      className="border-1 border-blue-500 rounded-lg py-2 px-4 bg-blue-600 text-white hover:bg-blue-700 hover:text-white text-sm"
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
      <div
        className={openRightDrawer ? "drawerOverlay active" : "drawerOverlay"}
        onClick={() => setOpenRightDrawer(false)}
      ></div>
    </>
  );
};

export default UpdateAttendance;
