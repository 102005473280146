
import EmployeePresenceDashboard from "./Attendance/AttendanceStatus/EmployeePresenceDashboard";
import EmployeePresentAbsentStatus from "./Attendance/MonthlyAttendance/MonthlyAttendance";
import TodaysCheckInCheckOut from "./Attendance/DailyCheckInCheckOut/TodaysCheckInCheckOut";
import EarlyLateCheckinCheckout from "./Attendance/EarlyLateCheckinCheckout/EarlyLateCheckinCheckout";
import ProjectBandwidth from "./TeamInfo/ProjectBandwidth";
import ShiftBoard from "./ShiftBoard/ShiftBoard";

const TeamRoutes = [
  // {
  //   path: "project",
  //   element: "",
  //   title: "PROJECT",
  //   index: true,
  //   access: true,
  //   subList: [
  //     {
  //       path: "project-list",
  //       element: <ProjectList />,
  //       title: "Project List",
  //       index: true,
  //       access: true,
  //     },
  //     // {
  //     //   path: "task-complete",
  //     //   element: "",
  //     //   title: "Task Complete",
  //     //   index: true,
  //     //   access: true,
  //     // },
  //     // {
  //     //   path: "project-health",
  //     //   element: "",
  //     //   title: "Project Health",
  //     //   index: true,
  //     //   access: true,
  //     // },
  //   ],
  // },
  {
    path: "attendance",
    element: "",
    title: "ATTENDANCE",
    index: true,
    access: true,
    subList: [
      {
        path: "attendance-status",
        element: <EmployeePresenceDashboard />,
        title: "Attendance Status",
        index: true,
        access: true,
        parentPath: "team",
      },
      {
        path: "daily-attendance",
        element: <EarlyLateCheckinCheckout />,
        title: "Early / late check-in & check-out",
        index: true,
        access: true,
      },
      {
        path: "monthly-attendance",
        element: <EmployeePresentAbsentStatus />,
        title: "Monthly Attendance",
        index: true,
        access: true,
      },
      {
        path: "todays-checkin",
        element: <TodaysCheckInCheckOut />,
        title: "Today's check-in",
        index: true,
        access: true,
      },
    ],
  },
  // {
  //   path: "employee-shift",
  //   element: "",
  //   title: "EMPLOYEE SHIFT",
  //   index: true,
  //   access: true,
  //   subList: [
  //     {
  //       path: "shift-board",
  //       element: <ShiftBoard />,
  //       title: "Shift Board",
  //       index: true,
  //       access: true,
  //     },
  //   ],
  // },
  // {
  //   path: "team-info",
  //   element: "",
  //   title: "TEAM INFO",
  //   index: true,
  //   access: true,
  //   subList: [
  //     {
  //       path: "project-bandwidth",
  //       element: <ProjectBandwidth />,
  //       title: "Project Bandwidth",
  //       index: true,
  //       access: true,
  //     },
  //   ],
  // },
  // {
  //   path: "all-members",
  //   element: "",
  //   title: "ALL MEMBERS",
  //   index: true,
  //   access: true,
  //   subList: [
  //     {
  //       path: "all-members",
  //       element: <AllMembers />,
  //       title: "All Members",
  //       index: true,
  //       access: true,
  //     },
  //   ],
  // },
  // {
  //   path: "pms",
  //   element: "",
  //   title: "PMS",
  //   index: true,
  //   access: true,
  //   subList: [
  //     {
  //       path: "pms",
  //       element: "",
  //       title: "PMS",
  //       index: true,
  //       access: true,
  //     },
  //   ],
  // },
  // {
  //   path: "work-hift",
  //   element: "",
  //   title: "WORK SHIFT",
  //   index: true,
  //   access: true,
  //   subList: [
  //     {
  //       path: "work-shift",
  //       element: "",
  //       title: "Work Shift",
  //       index: true,
  //       access: true,
  //     },
  //   ],
  // },
  // {
  //   path: "resignation",
  //   element: "",
  //   title: "RESIGNATION",
  //   index: true,
  //   access: true,
  //   subList: [
  //     {
  //       path: "resignation",
  //       element: "",
  //       title: "Resignation",
  //       index: true,
  //       access: true,
  //     },
  //   ],
  // },
  // {
  //   path: "timesheet",
  //   element: "",
  //   title: "TIMESHEET",
  //   index: true,
  //   access: true,
  //   subList: [
  //     {
  //       path: "timesheet",
  //       element: "",
  //       title: "Timesheet",
  //       index: true,
  //       access: true,
  //     },
  //   ],
  // },
  // {
  //   path: "leaves",
  //   element: "",
  //   title: "LEAVE",
  //   index: true,
  //   access: true,
  //   subList: [
  //     {
  //       path: "leaves",
  //       element: "",
  //       title: "Leaves",
  //       index: true,
  //       access: true,
  //     },
  //   ],
  // },
];

export default TeamRoutes;
