import React, { useEffect } from "react";
import "../ClientDashboard.css";
import TextFieldComponent from "../../MasterComponent/TextFieldComponent";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import DatePickerComponent from "../../MasterComponent/DatePickerComponent";
import TextAreaComponent from "../../MasterComponent/TextAreaComponent";
import { Checkbox } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  getAllClientsDataById,
  updateClientSlice,
} from "../../../store/slices/masterSlice";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import pdf from "./images/pdf-icon.png";
import doc from "./images/doc-icon.png";
import AutoCompleteComponent from "../../MasterComponent/AutoCompleteComponent";
const updateClientSchema = yup?.object({
  clientName: yup?.string()?.label("Client Name")?.required().trim(),
  salesPerson: yup
    ?.string()
    ?.label("Sales Person")
    ?.optional()
    .nullable()
    .trim(),
  clientContact: yup
    .array()
    .label("client Contact")
    .of(
      yup?.object({
        contactPerson: yup.string().label("Contact Person Name").required(),
        contactPersonEmail: yup
          .string()
          .label("Contact Person Email")
          .required()
          ?.matches(
            /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
            "Please Enter a Valid User Id"
          ),
        contactPersonNumber: yup
          .string()
          .label("Contact Number")
          .optional()
          .nullable(),
        contactPersonDepartment: yup
          .string()
          .label("Department")
          ?.optional()
          .nullable(),
      })
    ),
  ndaDocumentOld: yup?.mixed()?.label("NDA Document").optional().nullable(),
  domain: yup.object().label("Domain").optional().nullable(),
  otherDomain: yup.string().label("Other Domain").optional().nullable(),
  ndaDocumentDeleted: yup
    ?.mixed()
    ?.label("NDA Document Deleted")
    .optional()
    .nullable(),
  clientDeleted: yup?.mixed()?.label("Client Contact").optional().nullable(),
  ndaDocument: yup
    .mixed()
    .label("NDA Signed Attachment")
    .when("ndaDocumentOld", {
      is: (val) => val?.length > 0,
      then: (updateClientSchema) => updateClientSchema.optional().nullable(),
      otherwise: (updateClientSchema) => updateClientSchema.required(),
    }),
  ndaDate: yup
    .string()
    .label("NDA Signed Attachment & Signed Date")
    ?.trim()
    ?.optional()
    .nullable(),
  ndaCheckbox: yup?.boolean()?.label("NDA checkbox")?.optional().nullable(),
  clientAddress: yup
    ?.string()
    ?.label("Address")
    ?.optional()
    ?.nullable()
    ?.trim(),
});

const UpdateClient = ({ openEdit, setOpenEdit, clientData }) => {
  const dispatch = useDispatch();
  const methods = useForm({
    defaultValues: {
      clientName: clientData ? clientData?.client_name : null,
      clientContact: clientData?.contactPerson
        ? clientData?.contactPerson?.map((data) => {
            return {
              contactPerson: data?.contact_person ?? null,
              contactPersonEmail: data?.contact_email ?? null,
              contactPersonNumber: data?.contact_number ?? null,
              contactPersonDepartment: data?.department ?? null,
            };
          })
        : [],
      salesPerson: clientData ? clientData?.salesPerson : null,
      otherDomain: clientData ? clientData?.otherDomain : null,
      ndaDocument: null,
      domain: clientData
        ? { label: clientData?.domain, value: clientData?.domain }
        : null,
      ndaDocumentDeleted: [],
      clientDeleted: [],
      ndaDocumentOld: clientData
        ? clientData?.kickDetails?.nda_Document?.map((data, index) => ({
            ...data,
            id: index + 1,
          }))
        : null,
      ndaDate: clientData ? clientData?.kickDetails?.nda_date : null,
      ndaCheckbox: clientData
        ? Boolean(clientData?.kickDetails?.is_visible_nda_details)
        : false,
      clientAddress: clientData ? clientData?.address : null,
    },
    resolver: yupResolver(updateClientSchema),
  });
  let domainList = [
    { label: "Insurance", value: "Insurance" },
    { label: "Healthcare", value: "Healthcare" },
    { label: "Sports", value: "Sports" },
    { label: "Internal Product", value: "Internal Product" },
    { label: "Finance", value: "Finance" },
    { label: "Technology", value: "Technology" },
    { label: "Education", value: "Education" },
    { label: "Retail", value: "Retail" },
    { label: "Hospitality", value: "Hospitality" },
    { label: "Real Estate", value: "Real Estate" },
    { label: "Automotive", value: "Automotive" },
    { label: "Fashion", value: "Fashion" },
    { label: "Food & Beverage", value: "Food & Beverage" },
    { label: "Entertainment", value: "Entertainment" },
    { label: "Travel & Tourism", value: "Travel & Tourism" },
    { label: "Legal", value: "Legal" },
    { label: "Marketing & Advertising", value: "Marketing & Advertising" },
    { label: "Construction", value: "Construction" },
    { label: "Non-profit/Charity", value: "Non-profit/Charity" },
    { label: "Others", value: "Others" },
  ];
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
    trigger,
    setValue,
    getValues,
  } = methods;
  const {
    fields: clientContactFields,
    append: clientContactAppend,
    remove: clientContactRemove,
  } = useFieldArray({
    name: `clientContact`,
    control,
  });
  useEffect(() => {
    // Watch for changes in userData and reset form values when available
    if (clientData) {
      const { client_name, address, sale_person, domain,other_domain} = clientData;
      const { nda_Document, nda_date, is_visible_nda_details } =
        clientData?.kickDetails;
      const clientContact = clientData?.contactDetails;
      const updatedValues = {
        clientName: client_name,
        salesPerson: sale_person,
        clientContact: clientContact?.map((data) => {
          return {
            contactPerson: data?.contact_person_name,
            contactPersonId: data?.contact_person_id,
            contactPersonEmail: data?.contact_email,
            contactPersonNumber: data?.contact_number,
            contactPersonDepartment: data?.department,
          };
        }),
        ndaDate: nda_date,
        otherDomain: other_domain,
        ndaDocumentOld: nda_Document?.map((data, index) => ({
          ...data,
          id: index + 1,
        })),
        ndaCheckbox: is_visible_nda_details,
        clientAddress: address,
        ndaDocumentDeleted: [],
        clientDeleted: [],
        domain: { label: domain, value: domain },
      };
      methods.reset(updatedValues);
    }
  }, [clientData, methods, setValue]);
  const submitHandler = async (data) => {
    data.hod = data?.hod?.map((data) => data?.value);
    const submitData = {
      client_name: data?.clientName,
      client_contact_person: JSON.stringify(
        data?.clientContact?.map((data) => {
          return {
            contact_person_name: data?.contactPerson,
            contact_person_id: data?.contactPersonId,
            contact_email: data?.contactPersonEmail,
            contact_number: data?.contactPersonNumber,
            department: data?.contactPersonDepartment,
          };
        })
      ),
      sale_person: data?.salesPerson,
      domain: data?.domain?.value,
      other_domain: data?.otherDomain,
      nda_date: data?.ndaDate,
      is_visible_nda_details: data?.ndaCheckbox,
      address: data?.clientAddress,
      nda_document_deleted: JSON.stringify(data?.ndaDocumentDeleted),
      client_deleted: JSON.stringify(data?.clientDeleted),
    };
    const formData = new FormData();
    if (data?.ndaDocument && data?.ndaDocument?.length > 0) {
      for (let i = 0; i < data?.ndaDocument?.length; i++) {
        formData.append(`nda_Document`, data.ndaDocument[i]);
      }
    }
    for (let key in submitData) {
      formData.append(key, submitData[key]);
    }
    await dispatch(
      updateClientSlice({ id: clientData?.client_id, body: formData })
    );
    reset();
    setOpenEdit(false);
    setValue("ndaDocument", null);
    dispatch(getAllClientsDataById(clientData?.client_id));
  };
  const handleDeleteImage = (item) => {
    const filesUpload = getValues("ndaDocumentOld");
    let filesDeleted = getValues("ndaDocumentDeleted");
    const updatedFilesUploaded = filesUpload?.filter(
      (file) => file?.id !== item?.id
    );
    filesDeleted?.push(item);
    setValue("ndaDocumentDeleted", filesDeleted);
    setValue("ndaDocumentOld", updatedFilesUploaded);
    // setFilesUploaded(updatedFilesUploaded);
  };
  const handleClientDelete = (item) => {
    let filesDeleted = getValues("clientDeleted");
    let modifiedData = {
      contact_person_name: item?.contactPerson,
      contact_person_id: item?.contactPersonId,
      contact_email: item?.contactPersonEmail,
      contact_number: item?.contactPersonNumber,
      department: item?.contactPersonDepartment,
    };
    filesDeleted?.push(modifiedData);
    setValue("clientDeleted", filesDeleted);
  };
  const inputRefNdaDocument = React.useRef(null);
  return (
    <>
      <div
        className={
          openEdit ? "createProjectDrawer active" : "createProjectDrawer"
        }
      >
        <div className="createProjectDrawerHeader">
          <div className="w-full whitespace-nowrap">
            <div className="text-lg mb-2 font-semibold flex items-center justify-between">
              <div>Update Client</div>
              <div
                className="headerClose"
                onClick={() => {
                  setOpenEdit(false);
                  reset();
                }}
              >
                <i className="fa-light fa-xmark fa-lg"></i>
              </div>
            </div>
          </div>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submitHandler)}>
            <div className={openEdit ? "active" : ""}>
              <div className="addClientForm bg-white rounded-md border-1 border-gray-200 mt-2">
                <div className="font-semibold flex items-center justify-between text-lg px-3">
                  Client Details:
                </div>
                <div className="formContainer whitespace-nowrap pt-4">
                  {/* Client form details */}
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="clientName"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Client name <span className="text-red-500">*</span>
                      </label>
                      <TextFieldComponent
                        name="clientName"
                        onChangeHandler={() => trigger("clientName")}
                        control={control}
                        errors={errors}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="salesPerson"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Sales Person
                      </label>
                      <TextFieldComponent
                        name="salesPerson"
                        id="salesPerson"
                        onChangeHandler={() => trigger("salesPerson")}
                        control={control}
                        errors={errors}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="ndaDocument"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        NDA Signed Attachment & Signed Date
                        <span className="text-red-500">*</span>
                        <label
                          htmlFor="nda-file-upload"
                          className="cursor-pointer inline-flex items-center rounded-md text-sm font-medium ml-1 text-black"
                        >
                          <i className="fa-light fa-paperclip mr-2 justify-center align-middle"></i>
                        </label>
                      </label>

                      <div className="flex flex-column items-center">
                        <div className="col-md-12">
                          <Controller
                            name="ndaDocument"
                            control={control}
                            errors={errors}
                            render={({ field: { value, onChange, name } }) => (
                              <>
                                <div className="flex items-center gap-3">
                                  <div>
                                    <input
                                      ref={inputRefNdaDocument}
                                      name={name}
                                      className="w-0 h-0 opacity-0 overflow-hidden absolute z-0"
                                      accept="image/*, .pdf, .doc, .docx"
                                      type="file"
                                      id="nda-file-upload"
                                      multiple={true}
                                      onChange={(e) => {
                                        e.preventDefault();
                                        onChange(e?.target?.files);
                                        trigger("ndaDate");
                                      }}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          />
                        </div>
                        <div className="col-md-12">
                          <DatePickerComponent
                            name="ndaDate"
                            errors={errors}
                            control={control}
                            backSpaceChange={false}
                            onHandleChange={() => trigger("ndaDate")}
                            disableMaskedInput={true}
                          />
                        </div>
                        {watch("ndaDocument")?.length ? (
                          <div className="col-md-12 text-sm ml-0 mt-1">
                            {watch("ndaDocument")?.length === 1
                              ? `${
                                  watch("ndaDocument")?.length
                                } file Uploaded Successfully !`
                              : `${
                                  watch("ndaDocument")?.length
                                } file(s) Uploaded Successfully !`}
                          </div>
                        ) : null}
                        {!watch("ndaDocument")?.length && errors.ndaDocument ? (
                          <div className="custom-error w-100 text-left mt-1">
                            {errors?.ndaDocument?.message}
                          </div>
                        ) : null}
                      </div>
                      <div className="mt-3">
                        <label
                          htmlFor="domain"
                          className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Domain
                        </label>
                        <div className="create_form_input_div autoSelect">
                          <AutoCompleteComponent
                            // size="small"
                            name="domain"
                            control={control}
                            errors={errors}
                            onChangeHandler={() => trigger("domain")}
                            options={domainList?.length ? domainList : []}
                          />
                        </div>
                      </div>
                      {watch("domain")?.value === "Others" && (
                        <div>
                          <label
                            htmlFor="otherDomain"
                            className="block mb-1 text-sm font-medium text-gray-900 dark:text-white mt-3"
                          >
                            Other Domain
                          </label>
                          <div className="create_form_input_div autoSelect">
                            <TextFieldComponent
                              name="otherDomain"
                              id="otherDomain"
                              onChangeHandler={() => trigger("otherDomain")}
                              control={control}
                              errors={errors}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="clientAddress"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Address
                      </label>
                      <TextAreaComponent
                        name="clientAddress"
                        placeholder="Write your address here..."
                        control={control}
                        handleChange={() => trigger("clientAddress")}
                        errors={errors}
                        rows={watch("domain")?.value === "Others" ? 7 : 4}
                      />
                    </div>
                    <div className="flex items-center flex-wrap mt-3 gap-3">
                      {watch("ndaDocumentOld")?.map((item, key) => {
                        let itemExtension = item?.name?.split(".");
                        let imageExtension = [
                          "jpg",
                          "jpeg",
                          "png",
                          "gif",
                          "bmp",
                          "tiff",
                          "tif",
                          "webp",
                          "svg",
                        ];
                        let documentExtension = ["doc", "docx"];
                        itemExtension =
                          itemExtension[itemExtension?.length - 1];
                        return (
                          <div
                            key={key}
                            id={key}
                            className="updateFormImg mb-3"
                          >
                            {imageExtension?.includes(itemExtension) ? (
                              <div className="relative">
                                <i className="fa-light fa-xmark updateImgDeleteIcon" onClick={() => handleDeleteImage(item)}></i>
                                <img
                                  src={item?.url}
                                  alt={item?.name}
                                  onClick={() => {
                                    const link = document.createElement("a");
                                    link.href = item?.url;
                                    link.download = item?.name;
                                    link.click();
                                  }}
                                  className="uploadImgIcon img-fluid"
                                />
                                <div className="updateImgName">
                                  {item?.name}
                                </div>
                              </div>
                            ) : documentExtension?.includes(itemExtension) ? (
                              <div className="relative">
                                <i className="fa-light fa-xmark updateImgDeleteIcon" onClick={() => handleDeleteImage(item)}></i>
                                <img
                                  src={doc}
                                  alt={item?.name}
                                  onClick={() => {
                                    const link = document.createElement("a");
                                    link.href = item?.url;
                                    link.download = item?.name;
                                    link.click();
                                  }}
                                  className="uploadImgIcon img-fluid"
                                />
                                <div className="updateImgName">
                                  {item?.name}
                                </div>
                              </div>
                            ) : (
                              <div className="relative">
                                <i className="fa-light fa-xmark updateImgDeleteIcon" onClick={() => handleDeleteImage(item)}></i>
                                <img
                                  src={pdf}
                                  alt={item?.name}
                                  onClick={() => {
                                    const link = document.createElement("a");
                                    link.href = item?.url;
                                    link.download = item?.name;
                                    link.click();
                                  }}
                                  className="uploadImgIcon img-fluid"
                                />
                                <div className="updateImgName">
                                  {item?.name}
                                </div>
                              </div>
                            )}
                            {/* <p className="text-sm mt-1">{item?.name}</p> */}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8 mb-3">
                      <label
                        htmlFor="ndaCheckbox"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        <Controller
                          name="ndaCheckbox"
                          control={control}
                          errors={errors}
                          value={false}
                          render={({ field: { onChange, value } }) => (
                            <>
                              <Checkbox
                                color="primary"
                                onChange={(e, val) => {
                                  onChange(val);
                                }}
                                checked={value || false}
                              />
                            </>
                          )}
                        />
                        Show NDA Details to HOD.
                      </label>
                    </div>
                    <div
                      className="col-md-4 mb-3"
                      onClick={() => {
                        clientContactAppend({
                          contactPerson: "",
                          contactPersonEmail: "",
                          contactPersonNumber: null,
                          contactPersonDepartment: "",
                        });
                      }}
                    >
                      <div className="border-1 border-blue-600 rounded-md cursor-pointer text-blue-600 hover:text-gray-50 hover:bg-blue-500 hover:border-blue-500  d-flex justify-content-center align-items-center gap-1 px-3 text-sm py-0.5">
                        <i className="fa-light fa-plus primary"></i>
                        Add Client Contact
                      </div>
                    </div>
                  </div>
                  <hr className="border-1 border-gray-300" />
                  {clientContactFields?.map((item, index) => {
                    return (
                      <>
                        <div
                          className="flex-col py-2 mb-2 justify-content-center "
                          key={item?.id}
                        >
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <label
                                htmlFor={`clientContact.${index}.contactPerson`}
                                className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                              >
                                Client Contact Person Name
                                <span className="text-red-500">*</span>
                              </label>
                              <TextFieldComponent
                                name={`clientContact.${index}.contactPerson`}
                                id={`clientContact.${index}.contactPerson`}
                                control={control}
                                errors={errors}
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <label
                                htmlFor="contactPersonEmail"
                                className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                              >
                                Client Contact Person Email
                                <span className="text-red-500">*</span>
                              </label>
                              <TextFieldComponent
                                name={`clientContact.${index}.contactPersonEmail`}
                                id={`clientContact.${index}.contactPersonEmail`}
                                control={control}
                                errors={errors}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <label
                                htmlFor="contactPersonNumber"
                                className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                              >
                                Client Contact Person No.
                              </label>
                              <TextFieldComponent
                                name={`clientContact.${index}.contactPersonNumber`}
                                id={`clientContact.${index}.contactPersonNumber`}
                                type="number"
                                control={control}
                                errors={errors}
                              />
                            </div>
                            <div className="col-md-6 d-flex gap-4">
                              <div className="col-md-9">
                                <label
                                  htmlFor="contactPersonDepartment"
                                  className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                  Client Contact Person Department
                                </label>
                                <TextFieldComponent
                                  name={`clientContact.${index}.contactPersonDepartment`}
                                  id={`clientContact.${index}.contactPersonDepartment`}
                                  control={control}
                                  errors={errors}
                                />
                              </div>
                              <div
                                className="col-md-2 mt-4 relative border-1 h-fit border-gray-400 d-flex justify-content-center px-0 py-2 rounded cursor-pointer"
                                onClick={() => {
                                  clientContactRemove(index);
                                  handleClientDelete(item);
                                }}
                              >
                                <i className="fa-solid fa-trash-can danger"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="border-1 border-gray-300" />
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="text-end p-3  whitespace-nowrap">
              <button
                onClick={() => {
                  setOpenEdit(false);
                  reset();
                }}
                type="button"
                className="inline-flex font-sm text-sm border-1 border-gray-600 rounded-md cursor-pointer hover:text-gray-50 hover:bg-gray-500 hover:border-gray-500 py-2 px-4"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="ml-3 inline-flex font-sm text-sm border-1 border-blue-600 rounded-md cursor-pointer bg-blue-600 text-white hover:text-gray-50 hover:bg-blue-500 hover:border-blue-500 py-2 px-4"
              >
                Submit
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
      <div
        className={
          openEdit ? "createProjectOverlay active" : "createProjectOverlay"
        }
        onClick={() => setOpenEdit(false)}
      ></div>
    </>
  );
};

export default UpdateClient;
