import { ClickAwayListener, Pagination, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Loader/Loader";
import { fetchTodayAttendanceReportData } from "../../../../store/slices/ReportsSlice";
import fileDownload from "js-file-download";
import http from "../../../../utils/http";

const TodaysCheckInCheckOut = () => {
  const [toggle, setToggle] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [togglePageLimit, setTogglePageLimit] = useState("");
  const [sortField, setsortField] = useState();
  const [sortOrder, setSortOrder] = useState("ASC");
  const [search, setSearch] = useState("");
  const [count, setCount] = useState("");
  const filterStatus = ["Office", "WFH", "Absent", "On Leave"];
  const [status, setStatus] = useState(filterStatus[0]);
  const [pageCount, setPageCount] = useState(0);
  const [downloading, setDownloading] = useState(false);

  const dailyAttendanceData = useSelector(
    (state) => state?.reports?.getTodayAttendanceData?.response?.data
  );
  const isFetching = useSelector(
    (state) => state?.reports?.getTodayAttendanceData?.isFetching
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      fetchTodayAttendanceReportData({
        status: status,
        page: page,
        limit: limit,
        sortField: sortField,
        sortOrder: sortOrder,
        search: search,
        status:
          status === "On Leave"
            ? "OnLeave"
            : status === "WFH"
              ? "Home"
              : status,
      })
    );
  }, [status, page, limit, sortField, sortOrder, search]);

  useEffect(() => {
    if (dailyAttendanceData) {
      setPageCount(dailyAttendanceData?.totalPages);
      setCount(dailyAttendanceData?.totalItems);
    }
  }, [dailyAttendanceData]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const ref = useRef(null);
  const handleSearchChange = (event) => {
    const delayInput = setTimeout(() => {
      setSearch(event?.target?.value);
    }, 800);
    setPage(1);
    return () => clearTimeout(delayInput);
  };

  const handleAttendanceDownload = async () => {
    try {
      setDownloading(true);
      const response = await http({
        url: `team/v1/download-in-out-attendance`,
        method: "GET",
        responseType: "blob",
        params: {
          status:
            status === "On Leave"
              ? "OnLeave"
              : status === "WFH"
                ? "Home"
                : status,
        },
      });
      fileDownload(response.data, `Daily_Check_In_Out_Report.xlsx`);
      setDownloading(false);
    } catch (error) {
      setDownloading(false);
    }
  };

  return (
    <>
      <div className="dailyAttendanceStatus relative">
        {(isFetching || downloading) && <Loader />}

        <div className="border-1 rounded-md mt-3">
          <div className="flex items-center justify-between bg-white py-2 px-3 border-b border-gray-200">
            {/* <div className="flex items-center text-sm">
                <FontAwesomeIcon
                  className="text-gray-700 text-sm px-2 bg-gray-50 rounded-md"
                  icon={faClipboardUser}
                />
                <div className="pl-2 pr-3">Daily CheckIn-CheckOut</div>
              </div> */}
            <div className="text-gray-500 relative">
              <span
                className="text-gray-500 cursor-text"
                onClick={() => ref.current.focus()}
              >
                <i className="fa-light fa-magnifying-glass"></i>
              </span>
              <input
                ref={ref}
                onChange={handleSearchChange}
                type="text"
                name="search input"
                placeholder="Search"
                className="text-md border-none pl-2"
              />
            </div>
            <div className="flex items-center gap-3">
              <ClickAwayListener onClickAway={() => setToggle(false)}>
                <div className="relative ml-3">
                  <div
                    onClick={() => setToggle((prev) => !prev)}
                    className="flex items-center justify-between gap-3 w-52 text-gray-500 text-md py-1 px-3 bg-white border-1 border-gray-400 rounded-md cursor-pointer"
                  >
                    <div>{status}</div>
                    <i
                      className={
                        toggle
                          ? "fa-light fa-chevron-up"
                          : "fa-light fa-chevron-down"
                      }
                    ></i>
                  </div>
                  {toggle && (
                    <ul className="bg-white w-full border-1 border-gray-200 rounded-md absolute top-9 right-0 z-10">
                      {filterStatus?.map((item) => (
                        <li
                          onClick={(e) => {
                            setToggle(false);
                            setStatus(e?.target?.innerText);
                            setPage(1);
                          }}
                          className="border-b border-gray-200 px-3 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                        >
                          {item}
                        </li>
                      ))}
                      {/* <li
                          onClick={(e) => {
                            setToggle(false);
                            setStatus(e?.target?.innerText);
                          }}
                          className="border-b border-gray-200 px-3 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                        >
                          Office
                        </li>
                        <li
                          onClick={(e) => {
                            setToggle(false);
                            setStatus(e?.target?.innerText);
                          }}
                          className="border-b border-gray-200 px-3 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                        >
                          WFH
                        </li>
                        <li
                          onClick={(e) => {
                            setToggle(false);
                            setStatus(e?.target?.innerText);
                          }}
                          className="border-b border-gray-200 px-3 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                        >
                          Absent
                        </li>
                        <li
                          onClick={(e) => {
                            setToggle(false);
                            setStatus(e?.target?.innerText);
                          }}
                          className="border-b border-gray-200 px-3 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                        >
                          On Leave
                        </li> */}
                    </ul>
                  )}
                </div>
              </ClickAwayListener>
              <div
                onClick={() => {
                  handleAttendanceDownload();
                }}
                className="tooltipWrapper relative text-blue-600 text-md py-1 px-3 bg-inherit border-1 border-blue-400 rounded-md cursor-pointer hover:bg-blue-600 hover:text-white"
              >
                <i className="fa-light fa-download"></i>
                <div className="tooltipHover edit hidden border-1 border-solid border-blue-400 bg-blue-50 text-blue-500 py-1 px-3 rounded-md text-xs font-semibold">
                  Download in .xlsx
                </div>
              </div>
            </div>
          </div>
          <div className="attendanceTable dailyCheckInCheckOut relative bg-white w-full rounded-lg">
            <table className="w-full text-sm dark:text-gray-400 whitespace-nowrap">
              <thead className="bg-white text-gray-700">
                <tr>
                  {dailyAttendanceData &&
                    dailyAttendanceData?.headersColumns?.map((item) => (
                      <th
                        scope="col"
                        className="px-6 py-2 border-b border-gray-200"
                      >
                        {item?.header}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {dailyAttendanceData?.item?.length ? (
                  dailyAttendanceData?.item?.map((list, key) => {
                    return (
                      <tr key={key} className="bg-white border-b">
                        <td className="py-2 px-3">
                          <span className="">#{list?.EmployeeId ? list?.EmployeeId : "-"}</span>
                        </td>
                        <td className="py-2 px-3">
                          {list?.EmployeeName ? list?.EmployeeName : "-"}
                        </td>
                        <td className="py-2 px-3">{list?.manager && list?.manager !== "null" ? list?.manager : "-"}</td>
                        <td className="py-2 px-3">{list?.department ? list?.department : "-"}</td>
                        <td className="py-2 px-3">
                          {list?.checkin && list?.checkin !== "-"
                            ? dayjs(list?.checkin)?.format("hh:mm:ss A")
                            : "-"}
                        </td>
                        <td className="py-2 px-3">
                          {list?.checkout && list?.checkout !== "-"
                            ? dayjs(list?.checkout)?.format("hh:mm:ss A")
                            : "-"}

                        </td>
                        {/* <td className="py-2 px-3">{list?.totalHours ? list?.totalHours : "-"}</td> */}
                        <td className="py-2 px-3">
                          {list?.attendanceType ?? "-"}
                        </td>
                        <td className="py-2 px-3">{list?.location ? list?.location : "-"}</td>
                        <td className="py-2 px-3">
                          {list?.EmployeeMachineCode ? list?.EmployeeMachineCode : "-"}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <div className="absolute inset-y-1/2 inset-x-1/2">
                    <div className="flex items-center flex-col gap-2">
                      <i className="fa-light fa-clipboard-user fa-4x text-gray-300"></i>

                      <div className="text-md">No data Found</div>
                    </div>
                  </div>
                )}
              </tbody>
            </table>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center text-sm">
              <div className="flex items-center gap-1 px-2">
                <div className="mr-1 w-1 h-5 bg-purple-500"></div>
                <div className="flex items-center gap-2">
                  <p>Total Employees :</p>
                  <p className="font-semibold">
                    {dailyAttendanceData
                      ? dailyAttendanceData?.employeeLength
                      : "-"}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-1 px-2">
                <div className="mr-1 w-1 h-5 bg-blue-600"></div>
                <div className="flex items-center gap-2">
                  <p>Office :</p>
                  <p className="font-semibold">
                    {dailyAttendanceData
                      ? dailyAttendanceData?.workModeData?.Office
                      : "-"}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-1 px-2">
                <div className="mr-1 w-1 h-5 bg-green-600"></div>
                <div className="flex items-center gap-2">
                  <p>WFH :</p>
                  <p className="font-semibold">
                    {dailyAttendanceData
                      ? dailyAttendanceData?.workModeData?.Wfh
                      : "-"}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-1 px-2">
                <div className="mr-1 w-1 h-5 bg-red-600"></div>
                <div className="flex items-center gap-2">
                  <p>Absent :</p>
                  <p className="font-semibold">
                    {dailyAttendanceData
                      ? dailyAttendanceData?.absentCount
                      : "-"}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-1 px-2">
                <div className="mr-1 w-1 h-5 bg-orange-400"></div>
                <div className="flex items-center gap-2">
                  <p>On Leave :</p>
                  <p className="font-semibold">
                    {dailyAttendanceData
                      ? dailyAttendanceData?.employeesOnLeave
                      : "-"}
                  </p>
                </div>
              </div>
            </div>
            <div className="px-3 border-t">
              <div className="timeSheetPagination bg-transparent">
                <Stack spacing={2}>
                  <Pagination
                    count={pageCount}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                    className="paginationCount"
                  />
                </Stack>
                <div className="totalNoOfPages ms-3">
                  On Page:
                  <span className="totalCount">
                    {page} of {pageCount}
                  </span>
                </div>
                <label className="PaginationLabelWhere">|</label>
                <div className="totalNoOfPages">
                  No. of Rows: <span className="totalCount">{count}</span>
                </div>
                <label className="PaginationLabelWhere">|</label>
                <div className="text-sm font-medium text-gray-600">
                  Limit :{" "}
                </div>
                <ClickAwayListener
                  onClickAway={() => setTogglePageLimit(false)}
                >
                  <div
                    className="paginationLimitBox"
                    onClick={() => setTogglePageLimit((prev) => !prev)}
                  >
                    {limit}
                    {togglePageLimit ? (
                      <i className={"fa-light fa-chevron-up ms-2"}></i>
                    ) : (
                      <i className={"fa-light fa-chevron-down ms-2"}></i>
                    )}
                    {togglePageLimit && (
                      <ul className="paginationLimitBoxDropdown">
                        {[25, 50, 75, 100]?.map((list, index) => {
                          return (
                            <li
                              key={index}
                              value={list}
                              onClick={(e) => (
                                setLimit(e?.target?.innerText), setPage(1)
                              )}
                            >
                              {list}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                </ClickAwayListener>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TodaysCheckInCheckOut;
