import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userLogout } from "../../store/slices/authSlice";
import { getLoggedUserData } from "../../utils/helper";
import "./ProfileSetting.css";
import stringAvatar from "../PMS/AvatarFunction";
import Avatar from "@mui/material/Avatar";
import { fetchEmployeeCard } from "../../store/slices/employeeSlice";
import { useSelector } from "react-redux";
import { ClickAwayListener } from "@mui/material";

const ProfileSetting = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const success = useSelector((state) => state?.auth?.success);
  // const history = useNavigate();
  const loggedEmployee = getLoggedUserData();
  const EmployeeId = loggedEmployee?.employeeId ?? null;
  useEffect(() => {
    if (EmployeeId || success) {
      dispatch(fetchEmployeeCard(EmployeeId));
    }
  }, [EmployeeId, dispatch, success]);
  const employeeCardData = useSelector(
    (state) => state?.employee?.employeeCardData?.employeeCard
  );
  const onSignoutSuccess = () => {
    // setTimeout(() => history("/login"), 0.1)
    dispatch(userLogout());
  };
  const handleClick = () => {
    setOpen(!open);
  };
  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div
          id="userProfile"
          role="button"
          className="userSetting"
          onClick={handleClick}
        >
          {employeeCardData?.profileImage ? (
            <Avatar
              src={employeeCardData?.profileImage}
              className="img-fluid"
            />
          ) : (
            employeeCardData?.firstName && (
              <Avatar
                {...stringAvatar(employeeCardData?.firstName)}
                className="img-fluid"
              />
            )
          )}
          {open ? (
            <div className="logout">
              <ul>
                <div className="userDetails">
                  <div className="userProfileIcon">
                    {employeeCardData?.profileImage ? (
                      <Avatar
                        src={employeeCardData?.profileImage}
                        className="newJoineeProfile"
                      />
                    ) : (
                      employeeCardData?.firstName && (
                        <Avatar
                          {...stringAvatar(employeeCardData?.firstName)}
                          className="newJoineeProfile"
                        />
                      )
                    )}
                  </div>
                  <div className="userProfileText">
                    <div className="userProfileName">
                      {`${employeeCardData?.firstName} ${employeeCardData?.middleName ?? ""
                        } ${employeeCardData?.lastName ?? ""}`}
                    </div>
                    <div className="userProfileDesig">
                      {employeeCardData?.designation}
                    </div>
                  </div>
                </div>
                <li>
                  <Link to={`/EmployeeDetails/${EmployeeId}`}>
                    <div className="userSettingIcons"> <i className="fa-light fa-user-tie"></i></div>
                    <span> My Profile </span>
                  </Link>
                </li>
                <li>
                  <Link to={`/ChangePassword`}>
                    <div className="uSettingPassIcon">
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                    </div>
                    <span>Change Password </span>
                  </Link>
                </li>
                {/* <li>
                  <Link to={`/MySettingsForm/${loggedEmployee?.id}`}>
                    <span>
                      <i className="fa-light fa-gear userSettingIcons"
                      ></i>
                      My Settings{" "}
                    </span>
                  </Link>
                </li> */}
                {/* <li>
                  <Link to="Helpdesk">
                    <span>
                      <i className="fa-light fa-user-headset userSettingIcons"></i>
                      Helpdesk
                    </span>
                  </Link>
                </li> */}
                <li>
                  <Link to="#" onClick={onSignoutSuccess}>
                    <div className="userSettingIcons">
                      <i className="fa-light fa-right-from-bracket"></i>
                    </div>
                    <span> Log Out</span>
                  </Link>
                </li>
              </ul>
            </div>
          ) : null}
        </div>
      </ClickAwayListener>
    </>
  );
};

export default ProfileSetting;
