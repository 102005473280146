// import React, { useContext } from 'react';
import { useCallback, useState } from "react";

import {
  fetchLeaveApplicationV1,
  fetchLeaveApprovers,
  fetchLeaveType,
  leaveUpdateStatusV1,
} from "../../../store/slices/leaveSlice";
import ApplyLeaveDialog from "./ApplyLeaveDialog";
import LeaveCommentsDialog from "./LeaveCommentsDialog";
import ReviewAllocation from "./ReviewAllocation";
import {
  fetchEmployeeNameList,
  fetchEmployeesUserIds,
} from "../../../store/slices/employeeSlice";
import UpdateLeaveDialog from "./UpdateLeaveDialog";
import FilterLeaveComponent from "./FilterLeaveComponent";
import { getLoggedUserData } from "../../../utils/helper";
import Pagination from "@mui/material/Pagination";
import { ClickAwayListener } from "@mui/base";
import Loader from "../../Loader/Loader";
import LeaveConfirmationDialog from "./LeaveConfirmationDialog";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useContext } from "react";
import { PopupContext } from "../../GlobalPopup/GlobalPopup";
import {
  componentDisplayAccess,
  getEmployeesAccess,
} from "../../../utils/userPermission";

const Leaves = () => {
  const { displayPopup } = useContext(PopupContext);
  const statusFilter = JSON.parse(localStorage?.getItem("leaveFilter"))?.status
    ?.value;
  const [openEdit, setOpenEdit] = useState(false);
  const [verifyLeaveAllocation, setVerifyLeaveAllocation] = useState(false);
  const [open, setOpen] = useState(false);
  const [commentDialog, setCommentDialog] = useState(false);
  const [selectedEmpData, setSelectedEmpData] = useState([]);
  const [updateData, setUpdateData] = useState({});
  const [comment, setComment] = useState();
  const [applyFilter, setApplyFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [toggle, setToggle] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(statusFilter ?? null);
  const [action, setAction] = useState();
  const [filterValues, setFilterValues] = useState(
    localStorage.getItem("leaveFilter")
      ? JSON.parse(localStorage.getItem("leaveFilter"))
      : null
  );
  const [sorting, setSorting] = useState("posting_date");
  const [order, setOrder] = useState("DESC");
  const dispatch = useDispatch();
  const leaveApplicationAccess = componentDisplayAccess({
    component: "Leave_Application_V1",
  });
  const leaveAllocationDisplayAccess = componentDisplayAccess({
    component: "Review_Apply_Allocation",
  });
  const loggedEmployee = getLoggedUserData();
  const EmployeeId = loggedEmployee?.employeeId ?? null;
  const getData = useCallback(
    (params) => {
      let response = JSON.parse(localStorage.getItem("leaveFilter"));
      dispatch(
        fetchLeaveApplicationV1({
          filterValue: response?.leave_type?.value,
          search: response?.employee?.value,
          status: response?.status?.value,
          fromDate: response?.date?.from_date,
          toDate: response?.date?.to_date,
          limit,
          page,
          sort: sorting,
          order,
        })
      );
      setSelectedStatus(statusFilter ?? null);
      setSelectedEmpData([]);
    },
    [dispatch, limit, page, statusFilter, sorting, order]
  );

  const handleUpdate = async (status) => {
    const response = await dispatch(
      leaveUpdateStatusV1({
        status: status,
        leavelist: selectedEmpData,
      })
    );
    displayPopup(
      response.payload.message,
      response.payload.status ? "alert-success" : "alert-danger"
    );
    setSelectedEmpData([]);
    setSelectedStatus(statusFilter ?? null);
    getData();
    setConfirmStatus(false);
  };

  useEffect(() => {
    if (EmployeeId) {
      dispatch(fetchLeaveApprovers({ EmployeeId }));
    }
    dispatch(fetchEmployeesUserIds());
    dispatch(fetchLeaveType());
  }, [EmployeeId, dispatch]);

  useEffect(() => {
    if (localStorage?.getItem("leaveFilter")) {
      const initialData = JSON.parse(localStorage?.getItem("leaveFilter"));
      setFilterValues(initialData);
      // }
    } else {
      localStorage?.setItem(
        "leaveFilter",
        JSON.stringify({
          status: { label: "Open", value: "Open" },
        })
      );
      const initialData = JSON.parse(localStorage?.getItem("leaveFilter"));
      setFilterValues(initialData);
    }
  }, []);
  useEffect(() => {
    dispatch(fetchEmployeeNameList({ module: "Leave_Module_V1" }));
    getData();
  }, [limit, page, getData, filterValues, dispatch]);
  const employeeList = useSelector(
    (state) => state?.employee?.employeeNameList
  );
  const employeeArrayList = employeeList?.map((data) => data?.EmployeeId);
  const leaveApplicationAccessById = getEmployeesAccess({
    employees: employeeArrayList,
    component: "Leave_Application_V1",
  });
  const LeaveType = useSelector((state) => state?.leave?.leaveType?.types);
  const leaveApplicationData = useSelector(
    (state) => state?.leave?.leaveApplicationDataV1?.leaveData?.data
  );
  const currFiscalYear = leaveApplicationData?.data[0]?.fiscal_year;
  const isFetching = useSelector(
    (state) => state?.leave?.leaveApplicationDataV1?.isFetching
  );
  const handleCheckboxInput = (data, e) => {
    if (
      selectedEmpData.includes(data.leaveApplicationId) &&
      !e.target.checked
    ) {
      setSelectedEmpData(
        selectedEmpData.filter((i) => i !== data.leaveApplicationId)
      );
      if (selectedEmpData.length === 1) {
        setSelectedStatus(statusFilter ?? null);
      }
    } else {
      setSelectedEmpData([...selectedEmpData, data.leaveApplicationId]);
      setSelectedStatus(data.status);
    }
  };
  const leaveStatus = ["Approved", "Dismissed", "Open", "Rejected"];
  const handleFilterClose = (e, dataId) => {
    const data = filterValues;
    data[dataId] = null;
    localStorage.setItem("leaveFilter", JSON.stringify(data));
    const result = JSON.parse(localStorage.getItem("leaveFilter"));
    setFilterValues(result);
    setPage(1);
  };
  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleChangeLimit = () => {
    setToggle(!toggle);
  };
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const selectAllData = [];
      leaveApplicationData?.data?.forEach((data) => {
        if (
          data.status === selectedStatus &&
          leaveApplicationAccessById?.[data?.masterleave?.EmployeeId]
            ?.updateAccess
        )
          selectAllData.push(data.leaveApplicationId);
      });
      setSelectedEmpData(selectAllData);
    } else {
      setSelectedEmpData([]);
      setSelectedStatus(statusFilter ?? null);
    }
  };

  let headers = [
    { label: "Employee ID", name: "EmployeeId" },
    { label: "Employee Name", name: "employee_name" },
    { label: "Leave Type", name: "leave_type" },
    { label: "Posting Date", name: "posting_date" },
    { label: "From Date", name: "from_date" },
    { label: "To Date", name: "to_date" },
    { label: "status", name: "status" },
    { label: "Reporting Manager", name: "reporting_manager" },
    { label: "Action Taken By", name: "action_taken_by" },
    { label: "Total Leave Days", name: "total_leave_days" },
    { label: "Comments" },
    { label: "Action" },
  ];

  return (
    <>
      {isFetching ? (
        <Loader />
      ) : (
        <div className="p-3 pb-0 bg-indigo-50">
          <div className="flex items-center justify-end flex-wrap gap-3 pb-3 pt-0">
            {selectedEmpData?.length && selectedStatus !== "Dismissed" ? (
              <>
                {selectedStatus !== "Approved" && (
                  <button
                    className="border-solid border-1 border-green-600 rounded-md text-green-700 bg-green-0 hover:text-green-50 hover:bg-green-700 py-1 px-4 "
                    id="3"
                    type="button"
                    onClick={() => {
                      setConfirmStatus(true);
                      setAction("Approved");
                    }}
                  >
                    Approve
                  </button>
                )}
                {selectedStatus !== "Rejected" ? (
                  <button
                    className="border-solid border-1 border-red-600 rounded-md text-red-700 bg-red-0 hover:text-red-50 hover:bg-red-700 py-1 px-4 "
                    id="3"
                    type="button"
                    onClick={() => {
                      setConfirmStatus(true);
                      setAction("Rejected");
                    }}
                  >
                    Reject
                  </button>
                ) : null}
              </>
            ) : null}
            {leaveAllocationDisplayAccess?.readAccess ? (
              <button
                onClick={() => setVerifyLeaveAllocation(true)}
                className="border-solid border-1 border-blue-700 rounded-md bg-blue-0 text-blue-700 hover:bg-blue-700 hover:text-blue-50 py-1 px-4"
                id="3"
                type="button"
              >
                <i className="fa-light fa-sitemap me-1"></i> Review Allocation
              </button>
            ) : null}
            {leaveApplicationAccess?.createAccess ? (
              <button
                onClick={() => setOpen(true)}
                className="border-solid border-1 border-blue-700 rounded-md bg-blue-0 text-blue-700 hover:bg-blue-700 hover:text-blue-50 py-1 px-4 "
                id="3"
                type="button"
              >
                <i className="fa-light fa-person-to-door me-1"></i> Apply Leave
              </button>
            ) : null}
            <button
              className="border-solid border-1 border-blue-700 rounded-md bg-blue-0 text-blue-700 hover:bg-blue-700 hover:text-blue-50 py-1 px-4 "
              id="3"
              type="button"
              onClick={() => setApplyFilter(true)}
            >
              <i className="fa-light fa-filter me-1"></i> Filter
            </button>
          </div>
          <div className="bg-white px-3 pb-0 flex items-center gap-3 rounded-t-lg border-b border-b-gray-300 shadow-md">
            <div className="flex items-center gap-3 py-3">
              <div className="flex items-center gap-2 font-semibold after:content-['|']  after:text-gray-700">
                {leaveApplicationData?.totalItems} <span>Records</span>{" "}
                <span className="border-r border-r-indigo-200"></span>
              </div>
              {filterValues?.employee && (
                <div className="border-solid border-1 border-blue-700 rounded-md bg-blue-600 text-sm text-white py-1 pl-3">
                  {filterValues?.employee?.label}
                  <i
                    className="fa-light fa-xmark pl-2 pr-3 cursor-pointer"
                    id="employee"
                    onClick={(e) => handleFilterClose(e, "employee")}
                  ></i>
                </div>
              )}

              {filterValues?.leave_type && (
                <div className="border-solid border-1 border-blue-700 rounded-md bg-blue-600 text-sm text-white py-1 pl-3">
                  {filterValues?.leave_type?.label}
                  <i
                    className="fa-light fa-xmark pl-2 pr-3 cursor-pointer"
                    id="leave_type"
                    onClick={(e) => handleFilterClose(e, "leave_type")}
                  ></i>
                </div>
              )}

              {filterValues?.status && (
                <div className="border-solid border-1 border-blue-700 rounded-md bg-blue-600 text-sm text-white py-1 pl-3">
                  {filterValues?.status?.label}
                  <i
                    className="fa-light fa-xmark pl-2 pr-3 cursor-pointer"
                    id="status"
                    onClick={(e) => handleFilterClose(e, "status")}
                  ></i>
                </div>
              )}

              {filterValues?.date?.from_date && (
                <>
                  <div className="border-solid border-1 border-blue-700 rounded-md bg-blue-600 text-sm text-white py-1 pl-3">
                    {`${dayjs(filterValues?.date?.from_date)?.format(
                      "DD-MMM-YYYY"
                    )} - ${dayjs(filterValues?.date?.to_date)?.format(
                      "DD-MMM-YYYY"
                    )}`}
                    <i
                      className="fa-light fa-xmark pl-2 pr-3 cursor-pointer"
                      id="date"
                      onClick={(e) => handleFilterClose(e, "date")}
                    ></i>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="leaveAppTableContainer relative overflow-x-auto shadow-sm">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400  rounded-b-lg whitespace-nowrap">
              <thead className="text-xs bg-white text-gray-700 uppercase">
                <tr>
                  <th scope="col" className="p-4">
                    <div className="flex items-center">
                      <input
                        id="checkbox-all-search"
                        type="checkbox"
                        disabled={
                          !selectedStatus || selectedStatus === "Dismissed"
                        }
                        onChange={handleSelectAll}
                        className="w-4 h-4 cursor-pointer disabled:cursor-not-allowed text-blue-600 indeterminate:bg-gray-300 enabled:border-gray-400 disabled:opacity-45  border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label htmlFor="checkbox-all-search" className="sr-only">
                        checkbox
                      </label>
                    </div>
                  </th>
                  {headers?.map((i) => {
                    return (
                      <th key={i} scope="col" className="px-6 py-3">
                        <div className="flex">
                          {i.label}
                          {i?.name && (
                            <>
                              {sorting === i.name ? (
                                <span
                                  className="pl-1"
                                  onClick={() => {
                                    setSorting(i?.name);
                                    order === "DESC"
                                      ? setOrder("ASC")
                                      : setOrder("DESC");
                                  }}
                                >
                                  <i
                                    className={`${
                                      order === "DESC"
                                        ? "fa-arrow-down"
                                        : "fa-arrow-down"
                                    } fa-light`}
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="pl-1 opacity-50"
                                  onClick={() => {
                                    setSorting(i?.name);
                                    setOrder("ASC");
                                  }}
                                >
                                  <i className="fa-light fa-arrow-down"></i>
                                </span>
                              )}
                            </>
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {leaveApplicationData?.data?.map((data) => {
                  return (
                    <tr
                      key={data?.leaveApplicationId}
                      className={
                        data?.leave_type === "Leave Without Pay"
                          ? "bg-red-300 text-black border-t dark:bg-gray-800 dark:border-gray-700 hover:bg-red-300 dark:hover:bg-red-300"
                          : "bg-white border-t dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                      }
                    >
                      <td className="w-4 px-4 py-2">
                        {leaveApplicationAccessById?.[
                          data?.masterleave?.EmployeeId
                        ]?.updateAccess ? (
                          <div className="flex items-center">
                            <input
                              onChange={(e) => handleCheckboxInput(data, e)}
                              id="checkbox-table-search-1"
                              type="checkbox"
                              disabled={
                                (!(data?.status === selectedStatus) &&
                                  !(selectedStatus == null)) ||
                                data?.status === "Dismissed"
                              }
                              checked={
                                selectedEmpData.includes(
                                  data.leaveApplicationId
                                )
                                  ? true
                                  : false
                              }
                              className="w-4 h-4 cursor-pointer disabled:cursor-not-allowed text-blue-600 indeterminate:bg-gray-300 enabled:border-gray-400 disabled:opacity-45  border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                              htmlFor="checkbox-table-search-1"
                              className="sr-only"
                            >
                              checkbox
                            </label>
                          </div>
                        ) : (
                          <div className="flex items-center">
                            <input
                              id="checkbox-table-search-1"
                              type="checkbox"
                              disabled={true}
                              checked={
                                selectedEmpData.includes(
                                  data.leaveApplicationId
                                )
                                  ? true
                                  : false
                              }
                              className="w-4 h-4 cursor-pointer disabled:cursor-not-allowed text-blue-600 indeterminate:bg-gray-300 enabled:border-gray-400 disabled:opacity-45  border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                              htmlFor="checkbox-table-search-1"
                              className="sr-only"
                            >
                              checkbox
                            </label>
                          </div>
                        )}
                      </td>
                      {/* <td
                        scope="row"
                        className="flex items-center px-3 py- text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        <ListItemAvatar className="img-fluid">
                          {data?.profileImage ? (
                            <Avatar
                              className="img-fluid allEmpProfileImg w-10 h-10 rounded-full object-cover"
                              src={data?.profileImage}
                              alt={data?.masterleave?.employee_name}
                            />
                          ) : (
                            <Avatar
                              className="img-fluid empCardAvatar w-10 h-10 rounded-full object-cover"
                              {...stringAvatar(
                                data?.masterleave?.employee_name
                              )}
                              alt={data?.masterleave?.employee_name}
                            />
                          )}
                        </ListItemAvatar>
                        <div className="pl-3">
                          <div className="text-base font-semibold">
                            {data?.masterleave?.employee_name}
                          </div>
                          <div className="font-normal text-gray-500">
                            #{data?.masterleave?.EmployeeId}
                          </div>
                        </div>
                      </td> */}
                      <td className="px-3 py-2">
                        #{data?.masterleave?.EmployeeId}
                      </td>
                      <td className="px-3 py-2">
                        {data?.masterleave?.employee_name}
                      </td>
                      <td className="px-3 py-2">{data?.leave_type}</td>
                      <td className="px-3 py-2">
                        {dayjs(data?.posting_date)?.format("DD-MMM-YYYY")}
                      </td>
                      <td className="px-3 py-2">
                        {dayjs(data.from_date)?.format("DD-MMM-YYYY")}
                      </td>
                      <td className="px-3 py-2">
                        {dayjs(data.to_date)?.format("DD-MMM-YYYY")}
                      </td>
                      <td className="px-3 py-2">
                        <div
                          className={`flex items-center leaveTableStatus ${data.status} rounded-sm px-2`}
                        >
                          <div
                            className={`h-2.5 w-2.5 rounded-full mr-2 ${data.status}`}
                          ></div>
                          {data.status}
                        </div>
                      </td>
                      <td className="px-3 py-2">
                        {data?.masterleave?.manager?.employee_name}
                      </td>
                      <td className="px-3 py-2">
                        {data?.status === "Approved" ||
                        data?.status === "Rejected"
                          ? `${data?.approver?.firstName ?? "-"} ${
                              data?.approver?.middleName ?? ""
                            } ${data?.approver?.lastName ?? ""}`
                          : "-"}
                      </td>
                      <td className="px-3 py-2">
                        {Number(data?.total_leave_days)?.toFixed(2)}
                      </td>
                      <td className="px-3 py-2">
                        {leaveApplicationAccessById?.[
                          data?.masterleave?.EmployeeId
                        ]?.updateAccess ? (
                          <div className="empLeaveComments relative inline-block">
                            <div
                              className={
                                data?.status === "Dismissed"
                                  ? "tooltipWrapper relative statusDismissed"
                                  : "tooltipWrapper relative"
                              }
                            >
                              <i
                                className="fa-light fa-comment-lines text-blue-600 font-semibold cursor-pointer"
                                onClick={() => {
                                  setCommentDialog(true);
                                  setComment(data);
                                }}
                              ></i>
                              <div className="tooltipHover comment hidden border-1 border-solid border-blue-400 bg-blue-50 text-blue-500 py-1 px-3 rounded-md text-xs font-semibold whitespace-nowrap">
                                View Comments
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="empLeaveComments relative inline-block"
                            id="newDisable"
                          >
                            <div
                              className={
                                data?.status === "Dismissed"
                                  ? "tooltipWrapper relative statusDismissed"
                                  : "tooltipWrapper relative"
                              }
                            >
                              <i
                                className="fa-light fa-comment-lines text-blue-600 font-semibold cursor-pointer"
                                id="newDisable"
                              ></i>
                              <div className="tooltipHover comment hidden border-1 border-solid border-blue-400 bg-blue-50 text-blue-500 py-1 px-3 rounded-md text-xs font-semibold whitespace-nowrap">
                                View Comments
                              </div>
                            </div>
                          </div>
                        )}
                      </td>
                      <td className="px-3 py-2">
                        <div className="flex items-center gap-3">
                          {leaveApplicationAccessById?.[
                            data?.masterleave?.EmployeeId
                          ]?.updateAccess ? (
                            <div
                              className={
                                data?.status === "Dismissed"
                                  ? "tooltipWrapper relative statusDismissed"
                                  : "tooltipWrapper relative"
                              }
                            >
                              <i
                                className="fa-light fa-pen cursor-pointer text-blue-600"
                                onClick={() => {
                                  setOpenEdit(true);
                                  setUpdateData(data);
                                }}
                              ></i>
                              <div className="tooltipHover edit hidden border-1 border-solid border-blue-400 bg-blue-50 text-blue-500 py-1 px-3 rounded-md text-xs font-semibold">
                                Edit
                              </div>
                            </div>
                          ) : (
                            <div
                              className={
                                data?.status === "Dismissed"
                                  ? "tooltipWrapper relative statusDismissed"
                                  : "tooltipWrapper relative"
                              }
                              id="newDisable"
                            >
                              <i className="fa-light fa-pen cursor-pointer text-blue-600"></i>
                              <div className="tooltipHover edit hidden border-1 border-solid border-blue-400 bg-blue-50 text-blue-500 py-1 px-3 rounded-md text-xs font-semibold">
                                Edit
                              </div>
                            </div>
                          )}
                          {leaveApplicationAccessById?.[
                            data?.masterleave?.EmployeeId
                          ]?.updateAccess ? (
                            <div
                              className={
                                data?.status === "Approved"
                                  ? "tooltipWrapper relative statusApproved"
                                  : "tooltipWrapper relative" &&
                                    data?.status === "Dismissed"
                                  ? "tooltipWrapper relative statusDismissed"
                                  : "tooltipWrapper relative"
                              }
                            >
                              <i
                                className="fa-light fa-circle-check cursor-pointer text-green-500"
                                onClick={() => {
                                  setConfirmStatus(true);
                                  setAction("Approved");
                                  setSelectedStatus(data.status);
                                  setSelectedEmpData([data.leaveApplicationId]);
                                }}
                              ></i>
                              <div className="tooltipHover approve hidden border-1 border-solid border-green-400 bg-green-50 text-green-500 py-1 px-3 rounded-md text-xs font-semibold">
                                Approve
                              </div>
                            </div>
                          ) : (
                            <div
                              className={
                                data?.status === "Approved"
                                  ? "tooltipWrapper relative statusApproved"
                                  : "tooltipWrapper relative" &&
                                    data?.status === "Dismissed"
                                  ? "tooltipWrapper relative statusDismissed"
                                  : "tooltipWrapper relative"
                              }
                              id="newDisable"
                            >
                              <i className="fa-light fa-circle-check cursor-pointer text-green-500"></i>
                              <div className="tooltipHover approve hidden border-1 border-solid border-green-400 bg-green-50 text-green-500 py-1 px-3 rounded-md text-xs font-semibold">
                                Approve
                              </div>
                            </div>
                          )}
                          {leaveApplicationAccessById?.[
                            data?.masterleave?.EmployeeId
                          ]?.updateAccess ? (
                            <div
                              className={
                                data?.status === "Rejected"
                                  ? "tooltipWrapper relative statusRejected"
                                  : "tooltipWrapper relative" &&
                                    data?.status === "Dismissed"
                                  ? "tooltipWrapper relative statusDismissed"
                                  : "tooltipWrapper relative"
                              }
                            >
                              <i
                                className="fa-light fa-circle-xmark cursor-pointer text-red-700"
                                onClick={() => {
                                  setConfirmStatus(true);
                                  setAction("Rejected");
                                  setSelectedStatus(data.status);
                                  setSelectedEmpData([data.leaveApplicationId]);
                                }}
                              ></i>
                              <div className="tooltipHover reject hidden border-1 border-solid border-red-400 bg-red-50 text-red-500 py-1 px-3 rounded-md text-xs font-semibold">
                                Reject
                              </div>
                            </div>
                          ) : (
                            <div
                              className={
                                data?.status === "Rejected"
                                  ? "tooltipWrapper relative statusRejected"
                                  : "tooltipWrapper relative" &&
                                    data?.status === "Dismissed"
                                  ? "tooltipWrapper relative statusDismissed"
                                  : "tooltipWrapper relative"
                              }
                              id="newDisable"
                            >
                              <i className="fa-light fa-circle-xmark cursor-pointer text-red-700"></i>
                              <div className="tooltipHover reject hidden border-1 border-solid border-red-400 bg-red-50 text-red-500 py-1 px-3 rounded-md text-xs font-semibold">
                                Reject
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="w-100 flex items-center justify-end py-3">
            <Pagination
              count={Math.ceil(leaveApplicationData?.totalItems / limit)}
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
            <div className="totalNoOfPages ms-3 after:content-[''] after:ml-3 after:text-gray-500 after:border-r after:border-r-gray-500">
              On Page:{" "}
              <span className="totalCount">
                {leaveApplicationData?.totalItems ? page : 0} of{" "}
                {Math.ceil(leaveApplicationData?.totalItems / limit)}
              </span>
            </div>
            {/* <label className="pl-3">|</label> */}
            <div className="flex items-center ml-3">
              <div className="totalNoOfPages flex items-center">
                Rows per page: <span className="totalCount"></span>
              </div>
              <ClickAwayListener onClickAway={() => setToggle(false)}>
                <div className="paginationLimitBox" onClick={handleChangeLimit}>
                  {limit}
                  {toggle ? (
                    <i className="fa-light fa-chevron-up ms-2"></i>
                  ) : (
                    <i className="fa-light fa-chevron-down ms-2"></i>
                  )}
                  {toggle && (
                    <ul className="paginationLimitBoxDropdown">
                      {[25, 50, 75, 100]?.map((list) => {
                        return (
                          <li
                            value={list}
                            onClick={(e) => {
                              setLimit(e?.target?.value);
                              setPage(1);
                            }}
                          >
                            {list}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </ClickAwayListener>
            </div>
          </div>
        </div>
      )}

      {verifyLeaveAllocation && (
        <ReviewAllocation
          setVerifyLeaveAllocation={setVerifyLeaveAllocation}
          employeeList={employeeList}
          leaveType={LeaveType}
          status={leaveStatus}
        />
      )}

      {applyFilter && (
        <FilterLeaveComponent
          applyFilter={applyFilter}
          setApplyFilter={setApplyFilter}
          employeeList={employeeList}
          LeaveType={LeaveType.filter(
            (v) => v.leave_type_name !== "Work From Home"
          )}
          status={leaveStatus}
          getData={getData}
          setPage={setPage}
          setFilterValues={setFilterValues}
          filterValues={filterValues}
        />
      )}

      {openEdit && (
        <UpdateLeaveDialog
          updateData={updateData}
          openEdit={openEdit}
          setOpenEdit={setOpenEdit}
          getData={getData}
          filterValues={filterValues}
          currFiscalYear={currFiscalYear}
        />
      )}

      {open && (
        <ApplyLeaveDialog
          setOpen={setOpen}
          open={open}
          getData={getData}
          currFiscalYear={currFiscalYear}
        />
      )}

      {commentDialog && (
        <LeaveCommentsDialog
          setCommentDialog={setCommentDialog}
          commentDialog={commentDialog}
          userComment={comment?.description}
          managementComment={comment?.management_comment}
        />
      )}
      {confirmStatus && (
        <LeaveConfirmationDialog
          confirmStatus={confirmStatus}
          setConfirmStatus={setConfirmStatus}
          filterValues={filterValues}
          handleUpdate={handleUpdate}
          status={action}
          setSelectedEmp={setSelectedEmpData}
          setSelectedStatus={setSelectedStatus}
        />
      )}
    </>
  );
};

export default Leaves;
