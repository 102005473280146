import React, { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import DatePickerComponent from "../../MasterComponent/DatePickerComponent";
import dayjs from "dayjs";
import TextFieldComponent from "../../MasterComponent/TextFieldComponent";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import http from "../../../utils/http";
import SelectComponent from "../../MasterComponent/SelectComponent";
import { useDispatch, useSelector } from "react-redux";
import { fetchEmployeeNameList, getEmployeeShiftDetails } from "../../../store/slices/employeeSlice";
import { createWorkShiftStore } from "../../../store/slices/employeeNewSlice";
import Loader from "../../Loader/Loader";

const employeeShiftSchema = yup.object().shape({
  employee: yup?.string()?.label("Employee Name"),
  shift_date: yup?.date()?.label("Shift Date"),
});

const AddEmployeeShiftForm = ({
  openRightDrawer,
  setOpenRightDrawer,
  width,
  shiftData,
}) => {
  const [loading, setLoading] = useState(false);
  // let Id = shiftData?.empId;
  // const offdayData = shiftData?.offDay[shiftData?.empId];
  // const result = Object?.values(offdayData);
  
  const defaultValue = {
    employee: shiftData?.empName ?? null,
    shift_date: shiftData?.date ?? null,
    shift_name: "",
    start_time: null,
    end_time: null,
  };

  const methods = useForm({
    defaultValues: defaultValue,
    resolver: yupResolver(employeeShiftSchema),
  });

  useEffect(() => {
    reset(defaultValue);
  }, [shiftData]);

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
    watch,
    trigger,
    setValue,
  } = methods;

  const submitHandler = async (res) => {
    const data = {
      EmployeeId: shiftData?.empId,
      start_date: dayjs(res?.shift_date)?.format("YYYY-MM-DD"),
      end_date: dayjs(res?.shift_date)?.format("YYYY-MM-DD"),
      workShiftId: res?.shift_name,
      start_time: res?.start_time,
      end_time: res?.end_time,
      weekly_off: ["Sat", "Sun"],
    };
    try {
     const result = await dispatch(createWorkShiftStore(data));
      if (result?.meta?.requestStatus === "fulfilled") {
        setLoading(true);
        dispatch(getEmployeeShiftDetails());
        setLoading(false);
        setOpenRightDrawer(false);
        reset();
      }
    } catch (err) {
      console.log("err:", err);
    }
  };

  const [types, setTypes] = useState([]);
  useEffect(() => {
    http.get(`shift/list`).then((res) => {
      setTypes(res?.data?.data?.shiftListData);
    });
  }, []);
  const handleOnChange = (e) => {
    const shift = types.find((v) => Number(v.workShiftId) === Number(e));
    setValue("start_time", shift.start_time);
    setValue("end_time", shift.end_time);
    trigger("start_time");
    trigger("end_time");
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchEmployeeNameList({ module: "EMPLOYEE_MODULE" }));
  }, []);

  return (
    <>
      <div
        className={
          openRightDrawer ? "drawerContainer active" : "drawerContainer"
        }
        style={
          openRightDrawer ? { width: width, transform: "translateX(0px)" } : {}
        }
      >
        {/* <div className="drawerHeader">
            <div>Add Employee Shift</div>
            <div
              className="headerClose"
              onClick={() => setOpenRightDrawer(false)}
            >
              <FontAwesomeIcon icon={faXmark} />
            </div>
          </div> */}
        <div className="formContent relative">
          {
          loading && <Loader/>}
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(submitHandler)}>
              <div className="px-3">
                <div className="row">
                  <div className="col-md-12">
                    <div>
                      <label
                        htmlFor="employee"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Employee Name:
                      </label>
                      <TextFieldComponent
                        name="employee"
                        value={shiftData?.empId}
                        control={control}
                        errors={errors}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="">
                      <label className="create_form_labels block">Date:</label>
                      <DatePickerComponent
                        name="shift_date"
                        errors={errors}
                        control={control}
                        disableMaskedInput={true}
                        backSpaceChange={false}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mt-3">
                      <label
                        htmlFor="shift_name"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Shift Name:
                      </label>
                      <SelectComponent
                        name="shift_name"
                        id="shift_name"
                        control={control}
                        errors={errors}
                        options={types?.map((v) => ({
                          label: v?.shift_name,
                          value: v?.workShiftId,
                          startTime: v?.start_time,
                          endTime: v?.end_time,
                        }))}
                        handleChange={handleOnChange}
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="mt-3">
                      <label
                        htmlFor="shift_type"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Shift Type:
                      </label>
                      <SelectComponent
                        name="shift_type"
                        id="shift_type"
                        req
                        control={control}
                        errors={errors}
                        // handleChange={() => trigger("shiftType")}
                        options={shiftTypeList?.map((v) => ({
                          label: v,
                          value: v,
                        }))}
                      />
                    </div>
                  </div> */}
                  <div className="col-md-12">
                    <div className="">
                      <label className="create_form_labels block">
                        Start Time:
                        {/* <span className="create_form_asterisk">*</span>: */}
                      </label>
                      <Controller
                        name="start_time"
                        control={control}
                        render={({ field: { onChange, value, name } }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              className="form-control"
                              name={name}
                              onChange={onChange}
                              value={
                                value
                                  ? dayjs(value, "h:mm").toISOString()
                                  : null
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "",
                                  }}
                                  onKeyDown={(e) => e?.preventDefault()}
                                />
                              )}
                              disabled={true}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="">
                      <label className="create_form_labels block">
                        End Time:
                        {/* <span className="create_form_asterisk">*</span>: */}
                      </label>

                      <Controller
                        name="end_time"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              className="form-control"
                              name="end_time"
                              value={
                                value
                                  ? dayjs(value, "h:mm").toISOString()
                                  : null
                              }
                              onChange={(e) =>
                                onChange(dayjs(e)?.toISOString())
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "",
                                  }}
                                  onKeyDown={(e) => e?.preventDefault()}
                                />
                              )}
                              disabled={true}
                            />
                          </LocalizationProvider>
                          // <LocalizationProvider dateAdapter={AdapterDayjs}>
                          //   <TimePicker
                          //     className="form-control"
                          //     name="end_time"
                          //     onChange={onChange}
                          //     value={dayjs(value).format('h:mm A')}
                          //     renderInput={(params) => (
                          //       <TextField
                          //         {...params}
                          //         inputProps={{
                          //           ...params.inputProps,
                          //           placeholder: "",
                          //         }}
                          //         onKeyDown={(e) => e?.preventDefault()}
                          //       />
                          //     )}
                          //   />
                          // </LocalizationProvider>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mt-4 text-right">
                      <button
                        type="button"
                        onClick={() => setOpenRightDrawer(false)}
                        className="border-1 border-gray-300 rounded-lg py-2 px-4 hover:bg-gray-50 text-sm mr-4"
                      >
                        Cancel
                      </button>
                      {isDirty ? (
                        <button
                          type="submit"
                          className="border-1 border-blue-500 rounded-lg py-2 px-4 bg-blue-600 text-white hover:bg-blue-700 hover:text-white text-sm"
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          type="submit"
                          id="newDisable"
                          className="border-1 border-blue-500 rounded-lg py-2 px-4 bg-blue-600 text-white hover:bg-blue-700 hover:text-white text-sm"
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
      <div
        className={openRightDrawer ? "drawerOverlay active" : "drawerOverlay"}
        onClick={() => setOpenRightDrawer(false)}
      ></div>
    </>
  );
};

export default AddEmployeeShiftForm;
