import React, { useEffect, useState } from "react";
import ProjectListTeam from "./ProjectListTeam";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { getAllProjectByID } from "../../../store/slices/ProjectSlice";
import ProjectInformation from "./ProjectInformation";

const ProjectListDetails = ({
  projectData,
  setProjectData,
  setshowProjectDetail,
  allProjectData,
}) => {
  const [activeProjectTab, setActiveProjectTab] = useState(1);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllProjectByID(projectData));
  }, [projectData, dispatch]);

  let getProjectsById = useSelector(
    (state) => state?.Project?.getProjectDataById?.projectData
  );
  let isFetching = useSelector(
    (state) => state?.Project?.getProjectDataById?.isFetching
  );
  const projectListTab = [
    {
      id: 1,
      tabname: "Team",
    },
    // {
    //   id: 2,
    //   tabname: "Task",
    // },
    {
      id: 3,
      tabname: "Information",
    },
    // {
    //   id: 4,
    //   tabname: "Settings",
    // },
  ];

  return (
    <>
      <div className="projectListDetailWrapper p-4">
        <div
          onClick={() => setshowProjectDetail(false)}
          className="flex items-center gap-2 mb-2 text-blue-600 cursor-pointer text-md"
        >
          <i className="fa-light fa-arrow-left"></i>{" "}
          <span className="text-sm">Back to Projects</span>
        </div>
        <div className="rounded-md bg-white border-1 border-gray-100">
          <div className="flex items-center justify-between py-3 px-3 border-b border-gray-200">
            <div className="w-full flex items-center gap-3">
              <div className="flex items-center">
                <div className="border-r border-gray-300 pr-3">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/5828/5828115.png"
                    alt={getProjectsById?.data?.Client?.client_name}
                    className="img-fluid w-12 h-12"
                  />
                </div>
                <div className="pl-3">
                  <div className="text-gray-950">
                    {getProjectsById?.data?.project_name}
                  </div>
                  <div className="text-xs text-gray-500">
                    {getProjectsById?.data?.project_area}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flex items-center justify-end gap-5 text-sm">
              <div className="">
                <div className="text-gray-500 mb-2 font-semibold">
                  Client name
                </div>
                <div className="text-gray-500 cursor-pointer">
                  {getProjectsById?.data?.Client?.client_name}
                </div>
              </div>
              <div className="">
                <div className="text-gray-500 mb-2 font-semibold">
                  Project Manager
                </div>
                <div className="text-blue-500 cursor-pointer">
                  {getProjectsById?.data?.ProjectApprovers?.find(
                    (item) =>
                      item?.ApprovalLevel?.approver_post === "project_manager"
                  )?.ProjectApproverNames?.employee_name ?? "-"}
                </div>
              </div>
              <div className="">
                <div className="text-gray-500 mb-2 font-semibold">Status</div>
                <div className="text-gray-700">
                  {getProjectsById?.data?.status ?? "-"}
                </div>
              </div>
              <div className="">
                <div className="text-gray-500 mb-2 font-semibold">
                  Start Date
                </div>
                <div className="text-gray-700">
                  {getProjectsById?.data?.start_date
                    ? dayjs(getProjectsById?.data?.start_date)?.format(
                        "DD-MMM-YYYY"
                      )
                    : "-"}
                </div>
              </div>
              <div className="">
                <div className="text-gray-500 mb-2 font-semibold">End Date</div>
                <div className="text-gray-700">
                  {getProjectsById?.data?.end_date
                    ? dayjs(getProjectsById?.data?.end_date)?.format(
                        "DD-MMM-YYYY"
                      )
                    : "-"}
                </div>
              </div>
            </div>
          </div>
          <div className="projectListDetailsTab">
            <ul className="flex items-center text-sm">
              {projectListTab &&
                projectListTab?.map((list) => (
                  <li
                    id={list?.id}
                    onClick={() => setActiveProjectTab(list?.id)}
                    className={`${
                      activeProjectTab === list?.id && "active"
                    } py-2 px-3 cursor-pointer border-b border-transparent hover:text-blue-600 active:border-b`}
                  >
                    {list?.tabname}
                  </li>
                ))}
            </ul>
          </div>
        </div>
        {activeProjectTab === 1 && (
          <ProjectListTeam
            getProjectsById={getProjectsById}
            isFetching={isFetching}
            allProjectData={allProjectData}
          />
        )}
        {/* {activeProjectTab === 2 && (
          <ProjectListTask
            getProjectsById={getProjectsById?.data?.team}
            isFetching={isFetching}
          />
        )} */}
        {activeProjectTab === 3 && (
          <ProjectInformation
            getProjectsById={getProjectsById?.data}
            isFetching={isFetching}
            projectData={projectData}
            setProjectData={setProjectData}
            allProjectData={allProjectData}
          />
        )}
      </div>
    </>
  );
};

export default ProjectListDetails;
