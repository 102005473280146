import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import dayjs from "dayjs";
import { Avatar, ListItemAvatar } from "@mui/material";
import stringAvatar from "../../PMS/AvatarFunction";
import { useDispatch, useSelector } from "react-redux";
import {
  availableLeavesByEmployeeId,
  fetchLeaveAllocationDataV1,
  leaveUpdateStatusV1,
  resetAppliedLeave,
  resetLeaveTypeBalance,
} from "../../../store/slices/leaveSlice";
import Loader from "../../Loader/Loader";
import { useContext } from "react";
import { PopupContext } from "../../GlobalPopup/GlobalPopup";
import AutoCompleteComponent from "../../MasterComponent/AutoCompleteComponent";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import {
  getCurrentQuarterStartMidEndDates,
  getFiscalYear,
} from "../../../utils/helper";
import CONSTANTS from "../../../constants/constants";
import moment from "moment";
import DatePickerComponent from "../../MasterComponent/DatePickerComponent";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRef } from "react";
const leaveUpdateSchema = yup.object().shape({
  leave_type: yup.object().label("Leave Type").required(),
  from_date: yup.string().label("From Date").required(),
  to_date: yup.string().label("To Date").required(),
});
const UpdateLeaveDialog = ({
  openEdit,
  setOpenEdit,
  updateData,
  getData,
  filterValues,
  currFiscalYear,
}) => {
  const formRef = useRef(null);
  let currentDate = moment(new Date()).format("YYYY-MM-DD");
  const employeeId = updateData.EmployeeId;
  const startOfMonth = moment(currentDate)
    .startOf("month")
    .format("YYYY-MM-DD");

  const minLeaveAllowDate = moment(startOfMonth)
    .subtract(1, "month")
    .format("YYYY-MM-DD");
  const [managementComment, setManagementComment] = useState(
    updateData?.management_comment ?? ""
  );
  const { displayPopup } = useContext(PopupContext);
  const [maxToDate, setmaxToDate] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      fetchLeaveAllocationDataV1({
        employeeId: updateData?.EmployeeId,
        filterValue: updateData?.leave_type,
        fiscal_year: getFiscalYear(),
      })
    );
  }, [dispatch, updateData]);
  const totalSelectedLeave = useSelector(
    (state) => state?.leave?.appliedLeave?.actualDaysApplied
  );
  // const validateLeave = async () => {
  //   if (watch("from_date") && watch("to_date") && watch("leave_type")) {
  //     const result = await dispatch(
  //       fetchTotalAppliedLeave({
  //         from_date: dayjs(watch("from_date")).format("YYYY-MM-DD"),
  //         to_date: dayjs(watch("to_date")).format("YYYY-MM-DD"),
  //         employeeId: employeeId,
  //         leaveType: watch("leave_type")?.value,
  //       })
  //     );

  //     if (result?.error?.message === "Rejected") {
  //       displayPopup(result.payload.message, "alert-danger");
  //       setValue("from_date", null);
  //       setValue("to_date", null);
  //     }
  //   }
  // };
  const LeaveType = useSelector((state) => state?.leave?.leaveType?.types);
  let leave_type = LeaveType?.filter(
    (v) => v.leave_type_name === updateData?.leave_type
  )[0];
  const methods = useForm({
    defaultValues: {
      leave_type: {
        label: leave_type?.leave_type_name,
        value: leave_type?.leave_type_name,
        maxAllowed: leave_type?.max_days_allowed,
      },
      half_day_info: "First Half",
      half_day_check: 0,
      from_date: updateData?.from_date,
      to_date: updateData?.to_date,
      management_comment: managementComment,
    },
    resolver: yupResolver(leaveUpdateSchema),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    watch,
    trigger,
    setValue,
  } = methods;

  const fromDateChangeHandler = (date) => {
    setValue("from_date", dayjs(date).format("YYYY-MM-DD"));
    setValue("to_date", null);
    trigger("from_date");
    trigger("to_date");
    // validateLeave();
  };
  const toDateChangeHandler = (date) => {
    // validateLeave();
    setValue("to_date", dayjs(date).format("YYYY-MM-DD"));
    setValue("total_leave_days", totalSelectedLeave);
    trigger("to_date");
  };
  const LeaveTypeChangeHandler = async (newValue) => {
    try {
      trigger("leave_type");
      dispatch(resetLeaveTypeBalance());
      dispatch(resetAppliedLeave());
      newValue &&
        (await dispatch(
          availableLeavesByEmployeeId({
            EmployeeId: employeeId,
            leaveType: newValue?.value,
          })
        ));
      let mxDate;
      if (newValue.value === "Restricted Holiday") {
        const { quarterEnd } = getCurrentQuarterStartMidEndDates();
        mxDate = quarterEnd;
      } else {
        const leaveTypeKey = newValue.value.split(" ").join("_");
        const daysToAdd = CONSTANTS.LEAVE_MAX_TO_DATE_MONTHS[leaveTypeKey];
        mxDate =
          newValue.value === "Sick Leave"
            ? dayjs(currentDate).add(2, "days").format("YYYY-MM-DD")
            : dayjs(startOfMonth).add(daysToAdd, "month").format("YYYY-MM-DD");
      }
      setmaxToDate(mxDate);
    } catch (e) {
      displayPopup(e?.response?.data?.message, "alert-danger");
    }
  };
  const submitHandler = (e) => {
    console.log("e:", e);
  };

  const allocationData = useSelector(
    (state) => state?.leave?.leaveAllocationDataV1?.allocationData
  );
  const isFetching = useSelector(
    (state) => state?.leave?.leaveAllocationDataV1?.isFetching
  );
  const statusIsFetching = useSelector(
    (state) => state?.leave?.leaveUpdateStatusDataV1?.isFetching
  );
  const handleApprove = async (data) => {
    const isValid = await trigger();
    if (isValid) {
      if (formRef.current) {
        formRef.current.dispatchEvent(
          new Event("submit", { cancelable: true })
        );
      }
      let leaveTypeChange =
        updateData?.leave_type === getValues("leave_type")?.value
          ? null
          : getValues("leave_type")?.value;
      let fromDate =
        updateData?.from_date === getValues("from_date")
          ? null
          : getValues("from_date");
      let toDate =
        updateData?.to_date === getValues("to_date")
          ? null
          : getValues("to_date");
      let res = await dispatch(
        leaveUpdateStatusV1({
          status: "Approved",
          leavelist: [updateData?.leaveApplicationId],
          management_comment: managementComment,
          fromDate: toDate ? fromDate : null,
          toDate: toDate,
          leaveType: leaveTypeChange,
        })
      );
      await getData({
        filterValue: filterValues?.leave_type?.value,
        search: filterValues?.employee?.value,
        status: filterValues?.status?.value,
        fromDate: filterValues?.date?.from_date,
        toDate: filterValues?.date?.to_date,
      });
      setOpenEdit(false);
      if (res?.payload?.status)
        displayPopup(res?.payload?.message, "alert-success");
      else displayPopup(res?.payload?.message, "alert-danger");
    }
  };
  return (
    <Dialog
      open={openEdit}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      className="employee_form_dialog leaveUpdateDialog"
    >
      {statusIsFetching && <Loader />}
      <DialogTitle className="flex justify-between add_employee_main_heading">
        <span>Update Leave</span>
        <i
          className="fa-light fa-xmark cursor-pointer text-gray-600 text-xl"
          onClick={() => setOpenEdit(false)}
        ></i>
      </DialogTitle>
      <DialogContent className="leaveApplication">
        <div className="row">
          {isFetching && <Loader />}
          <div className="border-b border-gray-200 pb-3 w-full flex flex-wrap gap-3 justify-between mb-3">
            <div className="flex items-center text-gray-900 whitespace-nowrap dark:text-white">
              <ListItemAvatar className="img-fluid">
                {updateData?.profileImage ? (
                  <Avatar
                    className="img-fluid updateLeaveProfileImg rounded-full object-cover"
                    src={updateData?.profileImage}
                    alt={updateData?.masterleave?.employee_name}
                  />
                ) : (
                  <Avatar
                    className="img-fluid updateLeaveProfileImg  rounded-full object-cover"
                    {...stringAvatar(updateData?.masterleave?.employee_name)}
                    alt={updateData?.masterleave?.employee_name}
                  />
                )}
              </ListItemAvatar>
              <div className="pl-3">
                <div className="text-lg font-bold ">
                  {updateData?.masterleave?.employee_name}
                </div>
                <div className=" text-xs font-normal text-gray-600 ">
                  {updateData?.masterleave?.designation}
                </div>
                <div className="inline-flex  text-sm font-normal text-gray-600 items-center">
                  <label className="text-xs">Reports To : </label>
                  {updateData?.masterleave?.manager?.employee_name}
                </div>
              </div>
            </div>
            <div className="leaveAppFiscalYearData">
              <div className="font-semibold text-black">
                Fiscal Year: {currFiscalYear}
              </div>
              <div className="flex justify-between gap-4 bg-white font-semibold border border-solid border-gray-300 rounded-md p-3">
                <div className="">
                  <div className="flex items-center justify-between whitespace-nowrap">
                    <p className="text-sm mr-2">Total Leaves Allocated : </p>
                    <p className="font-bold text-base">
                      {allocationData?.allocationData?.total_leaves_allocated
                        ? Number(
                            allocationData?.allocationData
                              ?.total_leaves_allocated
                          ).toFixed(2)
                        : "0.00"}
                    </p>
                  </div>
                  <div className="flex items-center justify-between mt-3 whitespace-nowrap">
                    <p className="text-sm mr-2">Current Leave Balance : </p>
                    <p className="font-bold text-base text-green-500">
                      {allocationData?.allocationData?.current_leave_balance
                        ? Number(
                            allocationData?.allocationData
                              ?.current_leave_balance
                          ).toFixed(2)
                        : "0.00"}
                    </p>
                  </div>
                </div>
                <div>
                  <div className="flex items-center justify-between whitespace-nowrap">
                    <p className="text-sm mr-2">Taken Leaves : </p>
                    <p className="font-bold text-base text-red-500">
                      {allocationData?.approvedLeaveDays
                        ? Number(allocationData?.approvedLeaveDays).toFixed(2)
                        : "0.00"}
                    </p>
                  </div>
                  <div className="flex items-center justify-between mt-3">
                    <p className="text-sm mr-2">Open Leave : </p>
                    <p className="font-bold text-base text-blue-500">
                      {allocationData?.openLeaveDays
                        ? Number(allocationData?.openLeaveDays).toFixed(2)
                        : "0.00"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8 border border-solid border-gray-300 rounded px-2 py-3">
            <div className="text-lg font-bold">
              {updateData?.leave_type} :{" "}
              <label className="text-lg text-blue-800 font-bold bg-blue-50 rounded px-2">
                {Number(updateData?.total_leave_days)?.toFixed(2)}{" "}
                {Number(updateData?.total_leave_days)?.toFixed(2) > 1 ? (
                  <label className="text-xs">Days</label>
                ) : (
                  <label className="text-xs">Day</label>
                )}
              </label>
              {updateData?.half_day ? (
                <label className="text-xs bg-orange-100 text-yellow-600 py-1 px-2 rounded float-right	">
                  {updateData?.half_day_info}
                </label>
              ) : null}
            </div>
            <div className="flex justify-between my-3 items-center">
              <div className="flex flex-col border border-solid border-gray-300 rounded p-2 gap-1 w-fit">
                <label className="text-sm">
                  {dayjs(updateData?.from_date)?.format("dddd")}
                </label>
                <div className="flex items-center">
                  <label className="font-bold">
                    {dayjs(updateData?.from_date)?.format("MMMM DD")}
                  </label>
                  <label className="text-sm ml-2">
                    {dayjs(updateData?.from_date)?.format("YYYY")}
                  </label>
                </div>
              </div>
              <i className="fa-light fa-arrow-right"></i>
              <div className="flex flex-col border border-solid border-gray-300 rounded p-2 gap-1 w-fit">
                <label className="text-sm">
                  {dayjs(updateData?.to_date)?.format("dddd")}
                </label>
                <div className="flex items-center">
                  <label className="font-bold">
                    {dayjs(updateData?.to_date)?.format("MMMM DD")}
                  </label>
                  <label className="text-sm ml-2">
                    {dayjs(updateData?.to_date)?.format("YYYY")}
                  </label>
                </div>
              </div>
            </div>
            {updateData?.status !== "Approved" ? (
              <FormProvider {...methods}>
                <form ref={formRef} onSubmit={handleSubmit(submitHandler)}>
                  <div className="col-md-12 mb-3">
                    <label className="text-sm">
                      Leave Type <span className="create_form_asterisk">*</span>
                      :
                    </label>
                    <AutoCompleteComponent
                      name="leave_type"
                      control={control}
                      errors={errors}
                      // disabled={true}
                      options={LeaveType.filter(
                        (data) => data?.leave_type_name !== "Work From Home"
                      ).map((v) => ({
                        label: v.leave_type_name,
                        value: v.leave_type_name,
                        maxAllowed: v.max_days_allowed,
                      }))}
                      onChangeHandler={LeaveTypeChangeHandler}
                    />
                  </div>
                  <div className="d-flex justify-content-between mx-1">
                    <div className="col-md-6 mb-3 mr-1">
                      <label className="text-sm">
                        From Date{" "}
                        <span className="create_form_asterisk">*</span>:
                      </label>
                      <DatePickerComponent
                        errors={errors}
                        control={control}
                        name="from_date"
                        id="from_date"
                        disabled={!watch("leave_type")}
                        placeHolder="YYYY-MM-DD"
                        backSpaceChange={false}
                        minDate={minLeaveAllowDate}
                        maxDate={maxToDate}
                        onHandleChange={fromDateChangeHandler}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="text-sm">
                        To Date <span className="create_form_asterisk">*</span>:
                      </label>
                      <DatePickerComponent
                        errors={errors}
                        control={control}
                        name="to_date"
                        id="to_date"
                        placeHolder="YYYY-MM-DD"
                        disabled={
                          !watch("leave_type") || watch("half_day_check")
                        }
                        backSpaceChange={false}
                        // minDate={watch("from_date")}
                        minDate={
                          watch("fromValue")
                            ? watch("fromValue")
                            : minLeaveAllowDate
                        }
                        maxDate={maxToDate}
                        // maxDate={currentDate}
                        onHandleChange={toDateChangeHandler}
                      />
                    </div>
                  </div>
                </form>
              </FormProvider>
            ) : null}
            <label className="text-sm	font-semibold	mb-1">Employee Comment</label>
            <div className="border border-solid border-gray-300 rounded p-2 bg-slate-50 text-sm mb-3">
              {updateData?.description}
            </div>
            <label className="text-sm	font-semibold	mb-1">
              Approver's Comment
            </label>
            <textarea
              id="message"
              rows="2"
              value={managementComment}
              className="text-sm text-gray-900 bg-slate-50 rounded border border-gray-300 focus:ring-slate-500 focus:border-slate-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
              onChange={(e) => setManagementComment(e?.target?.value)}
            ></textarea>
          </div>
          <div className="col-md-4">
            {/* <div
              className={`px-2 item-center w-fit mb-3 rounded ${
                updateData?.status === "Open"
                  ? "bg-blue-50 border-1 border-solid border-blue-200"
                  : updateData?.status === "Approved"
                  ? "bg-green-50 border-1 border-solid border-green-200"
                  : updateData?.status === "Rejected"
                  ? "bg-red-50 border-1 border-solid border-red-200"
                  : "bg-yellow-50 border-1 border-solid border-yellow-200"
              }`}
            >
              <i  className={`fa-light fa-circle text-xs mr-2 ${
                  updateData?.status === "Open"
                    ? "text-blue-500"
                    : updateData?.status === "Approved"
                    ? "text-green-500"
                    : updateData?.status === "Rejected"
                    ? "text-red-600"
                    : "text-yellow-500"
                }`}
              ></i>
              <label
                className={`${
                  updateData?.status === "Open"
                    ? "text-blue-500"
                    : updateData?.status === "Approved"
                    ? "text-green-500"
                    : updateData?.status === "Rejected"
                    ? "text-red-600"
                    : "text-yellow-500"
                }`}
              >
                {updateData?.status}
              </label>
            </div> */}
            <label className="mb-2 text-lg font-bold text-gray-950">
              History
            </label>
            {updateData?.status !== "Open" ? (
              <>
                <div>
                  <i
                    className={`${
                      updateData?.status === "Approved"
                        ? "text-green-500"
                        : updateData?.status === "Rejected"
                        ? "text-red-600"
                        : "text-yellow-400"
                    } text-xs fa-light fa-circle`}
                  ></i>
                  <label className="font-semibold text-sm">
                    Request {updateData?.status}
                  </label>
                  <label className="text-xs ml-5">
                    {dayjs(updateData?.modified)?.format("MMMM DD, YYYY")}
                  </label>
                </div>
                {updateData?.leaveHistory?.map((item) => {
                  return item?.status !== "Open" ? (
                    <div>
                      <i
                        className={`${
                          item?.status === "Approved"
                            ? "text-green-500"
                            : item?.status === "Rejected"
                            ? "text-red-600"
                            : "text-yellow-400"
                        } text-xs fa-light fa-circle`}
                      ></i>
                      <label className="font-semibold text-sm">
                        Request {item.status}
                      </label>
                      <label className="text-xs ml-5">
                        {dayjs(item?.modified)?.format("MMMM DD, YYYY")}
                      </label>
                    </div>
                  ) : null;
                })}

                {/* {updateData?.status === "Approved" ? (
                  <div>
                    <i className="fa-light fa-circle text-green-500 text-xs"></i>
                    <label className="font-semibold text-sm">
                      Request Approved
                    </label>
                    <label className="text-xs ml-5">
                      {dayjs(updateData?.modified)?.format("MMMM DD, YYYY")}
                    </label>
                  </div>
                ) : updateData?.status === "Rejected" ? (
                  <div>
                    <i className="fa-light fa-circle text-red-600 text-xs"></i>
                    <label className="font-semibold text-sm">
                      Request Rejected
                    </label>
                    <label className="text-xs ml-5">
                      {dayjs(updateData?.modified)?.format("MMMM DD, YYYY")}
                    </label>
                  </div>
                ) : (
                  <div>
                    <i className="fa-light fa-circle text-yellow-500 text-xs"></i>
                    <label className="font-semibold text-sm">
                      Request Dismissed
                    </label>
                    <label className="text-xs ml-5">
                      {dayjs(updateData?.modified)?.format("MMMM DD, YYYY")}
                    </label>
                  </div>
                )} */}
              </>
            ) : null}
            <div className="mb-2">
              <i className="fa-light fa-circle text-yellow-200 text-xs"></i>
              <label className="font-semibold text-sm">Request Submitted</label>
              <label className="text-xs ml-5">
                {dayjs(updateData?.posting_date)?.format("MMMM DD, YYYY")}
              </label>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="employee_form_button_div">
        <button
          onClick={async () => {
            let res = await dispatch(
              leaveUpdateStatusV1({
                status: "Rejected",
                leavelist: [updateData?.leaveApplicationId],
                management_comment: managementComment,
              })
            );
            await getData({
              filterValue: filterValues?.leave_type?.value,
              search: filterValues?.employee?.value,
              status: filterValues?.status?.value,
              fromDate: filterValues?.date?.from_date,
              toDate: filterValues?.date?.to_date,
            });
            setOpenEdit(false);
            if (res?.payload?.status)
              displayPopup(res?.payload?.message, "alert-success");
            else displayPopup(res?.payload?.message, "alert-danger");
          }}
          className={`rounded border-1 border-solid border-red-500 px-3 py-1 text-red-500 bg-red-0 ${
            updateData?.status === "Rejected" ||
            updateData?.status === "Dismissed"
              ? "text-red-300 border border-solid border-red-300"
              : "hover:bg-red-500 hover:text-red-50"
          }`}
          id={
            watch("leave_type") === null ||
            watch("from_date") === null ||
            watch("to_date") === null
              ? "newDisable"
              : ""
          }
          disabled={
            updateData?.status === "Rejected" ||
            updateData?.status === "Dismissed" ||
            watch("leave_type") === null ||
            watch("from_date") === null ||
            watch("to_date") === null
          }
        >
          Reject Request
        </button>
        <button
          onClick={handleApprove}
          className={`rounded text-white px-3 py-1 ${
            updateData?.status !== "Approved"
              ? "bg-green-500 hover:bg-green-700"
              : "bg-green-300"
          } `}
          id={
            watch("leave_type") === null ||
            watch("from_date") === null ||
            watch("to_date") === null
              ? "newDisable"
              : ""
          }
          disabled={
            updateData?.status === "Approved" ||
            watch("leave_type") === null ||
            watch("from_date") === null ||
            watch("to_date") === null
          }
        >
          Approve Request
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateLeaveDialog;
