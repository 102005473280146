import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClickAwayListener, Pagination } from "@mui/material";
import Loader from "../../Loader/Loader";
import AddClient from "./AddClient";
import {
  fetchAllClientsName,
  getAllClientsData,
} from "../../../store/slices/masterSlice";
import { Stack } from "@mui/system";
import ClientDetails from "./ClientDetails";
import { componentDisplayAccess } from "../../../utils/userPermission";
// import '../../Team/Team.css';

const Client = () => {
  const [addClient, setAddClient] = useState(false);
  const [load, setLoad] = useState(false);
  const [showClientDetail, setShowClientDetail] = useState(false);
  const [clientData, setClientData] = useState({});
  const [toggle, setToggle] = useState(false);
  const [toggleClientFilter, setToggleClientFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [filterValue, setFilterValue] = useState({
    label: "All Clients",
    value: "",
  });

  const dispatch = useDispatch();
  const ref = useRef(null);

  useEffect(() => {
    dispatch(
      getAllClientsData({ search, clientName: filterValue?.value, page, limit })
    );
    dispatch(fetchAllClientsName());
  }, [search, filterValue, dispatch, page, limit]);

  const getClientList = useSelector(
    (state) => state?.masterList?.getClientData?.clientDataList?.data
  );
  const getClientsName = useSelector(
    (state) => state?.masterList?.clientList?.clientData?.data
  );

  useEffect(() => {
    if (getClientList) {
      setPageCount(getClientList?.totalPages);
      setCount(getClientList?.totalItems);
    }
  }, [getClientList]);

  const isFetchingClientList = useSelector(
    (state) => state?.masterList?.getClientData?.isFetching
  );

  const handleSearchChange = (event) => {
    const delayInput = setTimeout(() => {
      setSearch(event?.target?.value);
    }, 800);
    return () => clearTimeout(delayInput);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleShowProjects = (event, data) => {
    setClientData(data);
    if (Number(event) === Number(data?.client_id)) {
      setShowClientDetail(true);
    }
  };
  const clientAccess = componentDisplayAccess({
    component: "Client_Details",
  });
  return (
    <>
      {showClientDetail ? (
        <ClientDetails
          clientData={clientData}
          setShowClientDetail={setShowClientDetail}
        />
      ) : (
        <div className="bg-indigo-50">
          {load ? (
            <Loader />
          ) : (
            <div className="container-fluid">
              <div className="flex items-center justify-between py-3">
                <div className="">
                  <div className="text-2xl">Client List</div>
                  <div className="text-sm text-gray-500">
                    Client List Description
                  </div>
                </div>
                {clientAccess?.createAccess ? (
                  <div
                    className="font-sm text-sm border-1 border-blue-600 rounded-md cursor-pointer text-blue-600 hover:text-gray-50 hover:bg-blue-500 hover:border-blue-500 py-2 px-4"
                    onClick={() => setAddClient(true)}
                  >
                    <i className="fa-light fa-plus pr-1"></i> Add
                    Client
                  </div>
                ) : null}
              </div>
              <div className="clientListContainer border-1 relative overflow-x-auto rounded-md shadow-sm-light">
                <div className="projectListFilter bg-white flex items-center border-b border-gray-300 h-14">
                  {/* <ClickAwayListener
                    onClickAway={() => setToggleClientFilter(false)}
                  >
                    <div className="projectList relative cursor-pointer ">
                      <div
                        onClick={() =>
                          setToggleClientFilter(!toggleClientFilter)
                        }
                        className="inline-flex items-center justify-between gap-4 border-r border-gray-300 py-3 px-3 w-52"
                      >
                        <div className="text-sm text-gray-700">
                          {filterValue?.label}
                        </div>
                        <div className="text-sm text-gray-500">
                          {toggleClientFilter ? (
                            <i className="fa-light fa-chevron-up"></i>
                          ) : (
                            <i className="fa-light fa-chevron-down"></i>
                          )}
                        </div>
                      </div>
                      {toggleClientFilter && (
                        <ul className="border border-gray-500 rounded-md mt-1 absolute bg-white w-52">
                          <li
                            onClick={() => {
                              setFilterValue({
                                label: "All Clients",
                                value: "",
                              });
                              setToggleClientFilter(false);
                            }}
                            className="text-sm border-b border-gray-200 py-2 px-3"
                          >
                            All Clients
                          </li>
                          {getClientsName &&
                            getClientsName?.map((item, key) => (
                              <li
                                key={key}
                                onClick={() => {
                                  setFilterValue({
                                    label: item?.client_name,
                                    value: item?.client_name,
                                  });
                                  setToggleClientFilter(false);
                                }}
                                className="text-sm border-b border-gray-200 py-2 px-3"
                              >
                                {item?.client_name}
                              </li>
                            ))}
                        </ul>
                      )}
                    </div>
                  </ClickAwayListener> */}
                  <div className="px-3">
                    <div className="text-gray-500 relative">
                      <span
                        className="text-gray-500 cursor-text"
                        onClick={() => ref.current.focus()}
                      >
                        <i className="fa-light fa-magnifying-glass"></i>
                      </span>
                      <input
                        ref={ref}
                        onChange={handleSearchChange}
                        type="text"
                        name="search input"
                        placeholder="Search By Client"
                        className="text-sm border-none pl-1"
                      />
                    </div>
                  </div>
                </div>
                <div className="clientListTable bg-white">
                  <table className=" w-full text-sm text-left text-gray-500 dark:text-gray-400 whitespace-nowrap">
                    <thead className="text-xs bg-gray-50 text-gray-700 uppercase border-b border-gray-200">
                      <tr>
                        <th scope="col" className="px-6 py-2">
                          CLIENT Name
                        </th>
                        <th scope="col" className="px-6 py-2">
                          SALES PERSON
                        </th>
                        <th scope="col" className="px-6 py-2">
                          DOMAIN
                        </th>
                        <th scope="col" className="px-6 py-2">
                          CONTACT PERSON
                        </th>
                        <th scope="col" className="px-6 py-2">
                          CONTACT PERSON NUMBER
                        </th>
                        <th scope="col" className="px-6 py-2">
                          CONTACT PERSON EMAIL
                        </th>
                        <th scope="col" className="px-6 py-2">
                          STATUS
                        </th>
                      </tr>
                    </thead>
                    {isFetchingClientList && <Loader />}
                    <tbody>
                      {getClientList &&
                        getClientList?.item?.map((items, key) => {
                          return (
                            <tr
                              key={items?.client_id}
                              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                            >
                              <td className="p-3">
                                <span
                                  id={items?.client_id}
                                  onClick={(e) =>
                                    handleShowProjects(e?.target?.id, items)
                                  }
                                  className="text-blue-600 cursor-pointer capitalize"
                                >
                                  {items?.client_name ?? "-"}
                                </span>
                              </td>
                              <td className="p-3">
                                <span className="capitalize">
                                  {items?.sale_person ?? "-"}
                                </span>
                              </td>
                              <td className="p-3">
                                <span className="capitalize">
                                  {items?.domain === "Others"
                                    ? items?.other_domain
                                    : items?.domain ?? "-"}
                                </span>
                              </td>
                              <td className="p-3">
                                <span className="capitalize">
                                  {items?.contactDetails?.[0]
                                    ?.contact_person_name ?? "-"}
                                </span>
                              </td>
                              <td className="p-3">
                                {items?.contactDetails?.[0]?.contact_number ??
                                  "-"}
                              </td>
                              <td className="p-3">
                                {items?.contactDetails?.[0]?.contact_email ??
                                  "-"}
                              </td>
                              <td className="p-3">
                                {items?.is_active ? "Active" : "Left"}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="timeSheetPagination bg-transparent">
                <Stack spacing={2}>
                  <Pagination
                    count={pageCount}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                    className="paginationCount"
                  />
                </Stack>
                <div className="totalNoOfPages ms-3">
                  On Page:
                  <span className="totalCount">
                    {page} of {pageCount}
                  </span>
                </div>
                <label className="PaginationLabelWhere px-2">|</label>
                <div className="totalNoOfPages">
                  Total Count: <span className="totalCount">{count}</span>
                </div>
                <ClickAwayListener onClickAway={() => setToggle(false)}>
                  <div
                    className="paginationLimitBox"
                    onClick={() => setToggle(!toggle)}
                  >
                    {limit}
                    {toggle ? (
                      <i className="fa-light fa-chevron-up ms-2"></i>
                    ) : (
                      <i className="fa-light fa-chevron-down ms-2"></i>
                    )}
                    {toggle && (
                      <ul className="paginationLimitBoxDropdown">
                        {[10, 15, 25, 50, 75, 100]?.map((list) => {
                          return (
                            <li
                              value={list}
                              onClick={(e) => {
                                setLimit(e?.target?.value);
                                setPage(1);
                              }}
                            >
                              {list}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                </ClickAwayListener>
              </div>
            </div>
          )}
        </div>
      )}
      {addClient && (
        <AddClient
          setAddClient={setAddClient}
          addClient={addClient}
          setLoad={setLoad}
        />
      )}
    </>
  );
};

export default Client;
