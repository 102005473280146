import React, { useEffect, useRef, useState } from "react";
import "./Handbook.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { NavLink } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchHandbookSlides,
  notifyEmployeesForConsent,
} from "../../store/slices/handbookSlice";
import Loader from "../Loader/Loader";
import { componentDisplayAccess } from "../../utils/userPermission";
import { fetchConfigData } from "../../store/slices/configData";
import DragDropPopup from "./HandbookEdit/DraggableItem";

import { useLocation } from "react-router";
import ConfirmDeleteDialog from "./HandbookEdit/ConfirmDeleteDialog";

const HandbookDashboard = () => {
  const HandbookAccess = componentDisplayAccess({
    component: "Handbook",
  });
  const [activeSlide, setActiveSlide] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [reArrangePopup, setReArrangePopup] = useState(false);
  const [deletePage, setDeletePage] = useState(false);
  const [mail, setMail] = useState(false);
  const location = useLocation();
  let pageAfterUpdate = location?.state?.currentSlide;
  let createNewData = location?.state?.createNew;
  const orderAfterUpdate = location?.state?.currentOrder;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchHandbookSlides());
    dispatch(fetchConfigData());
  }, [dispatch]);

  const handbookList = useSelector((state) => state?.handbook);
  const handbookSlides = handbookList?.handbookData;
  const configData = useSelector((state) => state.config.configData);
  const policyVersion = configData?.filter(
    (i) => i?.name === "PolicyVersion"
  )[0];
  const companyName = configData?.filter((i) => i?.name === "CompanyName")[0];

  const [Id, setId] = useState(handbookSlides[0]?.handbookId);
  const [order, setOrder] = useState(handbookSlides[0]?.s_no);

  let currentTitle = handbookSlides?.filter((i) => i?.handbookId === Id)[0];
  let consentAccess = currentTitle?.is_consent_required;

  currentTitle = currentTitle?.title;

  useEffect(() => {
    if (handbookSlides?.length) {
      setIsLoading(true);
    }
  }, []);
  useEffect(() => {
    if (pageAfterUpdate) {
      createNewData = null;
      setActiveSlide(pageAfterUpdate);
      let updateId = handbookSlides?.filter(
        (i) => i?.s_no === orderAfterUpdate
      );
      setId(updateId[0]?.handbookId);
      setOrder(updateId[0]?.s_no);
    }
    if (createNewData) {
      pageAfterUpdate = null;
      setId(handbookSlides[handbookSlides?.length - 1]?.handbookId);
      setActiveSlide(handbookSlides?.length - 1);
    }
  }, [pageAfterUpdate, createNewData]);

  const handleSlideChange = (index, item) => {
    setId(item?.handbookId);
    setOrder(item?.s_no);
    setActiveSlide(index);
    setDeletePage(false);
    setIsLoading(true);
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index);
    }
  };

  const swiperRef = useRef(null);
  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleConsent = async () => {
    setIsLoading(true);
    await dispatch(
      notifyEmployeesForConsent({
        title: currentTitle,
      })
    );
    setIsLoading(false);
    setMail(false);
  };

  return (
    <>
      <div className="handbookWrapper">
        {HandbookAccess?.updateAccess ? (
          <div className="handbookLeftAsside">
            <div className="p-3 font-semibold border-b border-gray-300">
              Handbook Page List
            </div>
            <ul className="handbookPageList">
              {handbookSlides?.map((item, index) => (
                <li
                  id={index}
                  key={index}
                  onClick={() =>
                    activeSlide !== index && handleSlideChange(index, item)
                  }
                  className={
                    activeSlide === index
                      ? "bg-gray-100 text-sm font-medium p-3 border-b border-gray-200 hover:bg-gray-100 cursor-pointer"
                      : "text-sm font-medium p-3 border-b border-gray-200 hover:bg-gray-100 cursor-pointer"
                  }
                >
                  {item?.title}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          ""
        )}

        <div className=" handbookRightAsside relative">
          <div className="flex text-center flex-col z-10 gap-3 absolute top-3 right-3">
            {HandbookAccess?.createAccess ? (
              <NavLink to="/handbook-dashboard/handbook-create-new">
                <div className="border-1 border-gray-200 rounded-md py-2 px-3 text-sm cursor-pointer bg-white">
                  New Slide
                </div>
              </NavLink>
            ) : (
              ""
            )}
            {HandbookAccess?.updateAccess && handbookSlides?.length ? (
              <NavLink
                to="/handbook-dashboard/handbook-page-editor"
                state={{ Id, activeSlide, order }}
              >
                <div className="border-1 border-gray-200 rounded-md py-2 px-3 text-sm cursor-pointer bg-white">
                  Edit Slide
                </div>
              </NavLink>
            ) : (
              ""
            )}
            {HandbookAccess?.updateAccess && handbookSlides?.length > 1 ? (
              <div
                className="border-1 border-gray-200 rounded-md py-2 px-3 text-sm cursor-pointer bg-white hover:text-blue-500"
                onClick={() => {
                  setReArrangePopup(true);
                }}
              >
                Manage Order
              </div>
            ) : (
              ""
            )}
            {HandbookAccess?.updateAccess && handbookSlides?.length ? (
              <div
                className="border-1 border-gray-200 rounded-md py-2 px-3 text-sm cursor-pointer bg-white hover:text-blue-500"
                onClick={() => setDeletePage(true)}
              >
                Delete Slide
              </div>
            ) : (
              ""
            )}
            {/* {HandbookAccess?.updateAccess && consentAccess ? (
              <div
                className="border-1 border-gray-200 rounded-md py-2 px-3 text-sm cursor-pointer bg-white hover:text-blue-500"
                // onClick={() => handleConsent()}
                onClick={() => setMail(true)}
              >
                Send Consent Mail
              </div>
            ) : (
              ""
            )} */}
          </div>

          {mail ? (
            <Dialog
              open={mail}
              //   TransitionComponent={Transition}
              keepMounted
              aria-describedby="alert-dialog-slide-description"
              className="employee_form_dialog"
            >
              <DialogTitle className="add_employee_main_heading text-red-600">
                <span>Mail Confirmation</span>
              </DialogTitle>
              <DialogContent className="card-content-employee-form">
                <div className="">
                  Are you sure you want to send mail for this slide? All
                  previous employee consents for this slide (if exists) will be
                  unmarked.
                </div>
              </DialogContent>
              <DialogActions className="employee_form_button_div">
                <button
                  type="button"
                  onClick={() => setMail(false)}
                  className="border-1 border-gray-300 rounded-lg py-2 px-4 hover:bg-gray-50 text-sm mr-3"
                >
                  No
                </button>
                <button
                  onClick={() => handleConsent()}
                  className="border-1 border-blue-500 rounded-lg py-2 px-4 bg-blue-600 text-white hover:bg-blue-700 hover:text-white text-sm"
                >
                  Yes
                </button>
              </DialogActions>
            </Dialog>
          ) : (
            <></>
          )}
          <ConfirmDeleteDialog
            deletePage={deletePage}
            setDeletePage={setDeletePage}
            Id={Id}
            setIsLoading={setIsLoading}
            setId={setId}
            setActiveSlide={setActiveSlide}
            handbookSlides={handbookSlides}
          />
          {handbookSlides?.length ? (
            <div className="handbookSwiperContainer relative">
              <div className="handbookSlideContainer">
                <div className="handbookSlideHeader bg-white flex items-center justify-between py-2 px-3 font-semibold border-b border-gray-300">
                  <div>Employee Handbook</div>
                  <div>{companyName?.value}</div>
                </div>

                {isLoading && <Loader />}
                {handbookSlides?.length ? (
                  handbookSlides?.map(
                    (list, index) =>
                      activeSlide === index && (
                        <>
                          <div className="handbookImageSlides">
                            <img
                              id={index}
                              src={list?.handbookImage}
                              onLoad={handleImageLoad}
                              alt="Handbook Img"
                              className="img-fluid"
                            />
                          </div>
                          <div className="handbookSlideBottom bg-red-700 text-white flex items-center justify-between text-xs py-1 px-3">
                            <div>Policy Version {policyVersion?.value}</div>
                            <div>
                              Page {index + 1}/{handbookSlides?.length}{" "}
                            </div>
                          </div>
                        </>
                      )
                  )
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <div className="flex items-center flex-column justify-center h-100 ">
              <i className="fa-light fa-book-open-reader fa-3x text-blue-500"></i>
              <div className="mt-2 text-gray-500">
                Handbook is Empty! Kindly Add New Slides.
              </div>
            </div>
          )}
        </div>
        {reArrangePopup && (
          <DragDropPopup
            open={reArrangePopup}
            setOpen={setReArrangePopup}
            handbookSlides={handbookSlides}
            setActiveSlide={setActiveSlide}
            setId={setId}
          />
        )}
      </div>
    </>
  );
};
export default HandbookDashboard;
