import React, { useEffect } from "react";
import "../ClientDashboard.css";
import TextFieldComponent from "../../MasterComponent/TextFieldComponent";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import DatePickerComponent from "../../MasterComponent/DatePickerComponent";
import { Checkbox } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllClientsName,
  fetchAllReportingManagers,
  fetchDepartmentTypeSliceDataStore,
  updateClientProject,
} from "../../../store/slices/masterSlice";
import AutoCompleteComponent from "../../MasterComponent/AutoCompleteComponent";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import pdf from "../Client/images/pdf-icon.png";
import doc from "../Client/images/doc-icon.png";
import {
  fetchAllProjects,
  fetchProjectType,
  getAllProjectByID,
} from "../../../store/slices/ProjectSlice";
import Loader from "../../Loader/Loader";
import TextFieldNumberComponent from "../../MasterComponent/TextFieldNumberComponent";

let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;
const commonStringValidatorOptional = yup
  .number()
  .test("is-decimal", "Please enter upto two decimal only", (val) => {
    if (val !== undefined) {
      return patternTwoDigisAfterComma.test(val);
    }
    return true;
  })
  .label("Cost")
  .optional()
  .nullable()
  .typeError("Please Enter a Valid Cost");
const commonStringValidatorRequired = yup
  .number()
  .test("is-decimal", "Please enter upto two decimal only", (val) => {
    if (val !== undefined) {
      return patternTwoDigisAfterComma.test(val);
    }
    return true;
  })
  .label("Cost")
  .required()
  .typeError("Please Enter a Valid Cost");

const updateProjectSchema = yup?.object({
  clientName: yup.object()?.label("Client Name")?.required(),
  projectStatus: yup.object()?.label("Project Status")?.required(),
  projectName: yup?.string()?.label("Project name")?.required()?.trim(),
  department: yup?.object()?.label("Department")?.optional().nullable(),
  startDate: yup?.string()?.label("Start Date")?.required(),
  endDate: yup?.string()?.label("End Date")?.optional().nullable(),
  projectType: yup?.object()?.label("Project Type")?.optional().nullable(),
  cost: commonStringValidatorRequired,
  projectBilling: yup
    ?.object()
    ?.label("Project Billing")
    ?.optional()
    .nullable(),
  sowDocument: yup
    .mixed()
    .label("SOW Signed Attachment")
    .when("sowDocumentOld", {
      is: (val) => val?.length > 0,
      then: (updateProjectSchema) => updateProjectSchema.optional().nullable(),
      otherwise: (updateProjectSchema) => updateProjectSchema.required(),
    }),
  sowDate: yup
    .string()
    .label("SOW Signed Attachment & Signed Date")
    ?.optional()
    .nullable(),
  sowCheckbox: yup?.boolean()?.label("SOW checkbox")?.optional()?.nullable(),
  billingTerms: yup.string().label("Billing Terms")?.required().trim(),
  billingStartDate: yup.string().label("Billing Start Date")?.required().trim(),
  followUp: yup.string().label("Follow Up")?.required().trim(),
  projectDeleted: yup?.mixed()?.label("Project Contact").optional().nullable(),
  billingTermsDataDeleted: yup
    ?.mixed()
    ?.label("Billing Terms")
    .optional()
    .nullable(),
  projectContact: yup
    .array()
    .label("Project Contact")
    .of(
      yup?.object({
        contactPerson: yup.string().label("Name").required(),
        contactPersonEmail: yup
          .string()
          .label("Contact Person Email")
          .required()
          ?.matches(
            /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
            "Please Enter a Valid User Id"
          ),
        contactPersonNumber: yup
          .string()
          .label("Contact Number")
          .optional()
          .nullable(),
      })
    ),
  sowDocumentOld: yup?.mixed()?.label("SOW Document").optional().nullable(),
  sowDocumentDeleted: yup
    ?.mixed()
    ?.label("SOW Document Deleted")
    .optional()
    .nullable(),
  billingTermsData: yup
    .array()
    .label("Billing Terms Data")
    .of(
      yup?.object({
        cost: commonStringValidatorOptional,
        billingDueDate: yup
          .string()
          .label("Billing Due Date")
          .optional()
          .nullable(),
        paidDate: yup.string().label("Paid On").optional().nullable(),
      })
    ),
});

const UpdateProject = ({
  editProject,
  setEditProject,
  getProjectsById,
  projectData,
  allProjectData,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllReportingManagers());
    dispatch(fetchDepartmentTypeSliceDataStore());
    dispatch(fetchProjectType());
    dispatch(fetchAllClientsName());
  }, [dispatch]);

  // useEffect(() => {
  //   dispatch(getAllProjectByID(editItemId));
  // }, [editItemId, dispatch]);

  // let getProjectsById = useSelector(
  //   (state) => state?.Project?.getProjectDataById?.projectData?.data
  // );

  let getClientsName = useSelector(
    (state) => state?.masterList?.clientList?.clientData?.data
  );

  getClientsName = getClientsName?.map((v) => ({
    label: `${v?.client_name ?? ""}`,
    value: v?.client_id,
  }));

  let getDepartmentType = useSelector(
    (state) => state?.masterList?.departmentTypeData?.departmentTypeList
  );
  getDepartmentType = getDepartmentType?.map((v) => ({
    label: `${v?.department_name ?? ""}`,
    value: v?.department_id,
  }));
  const ProjectState = useSelector((state) => state?.Project);
  const isFetching = useSelector(
    (state) => state?.masterList?.updateClientProject?.isFetching
  );
  let projectTypeNames = ProjectState?.projectType?.rows?.map((i) => {
    return {
      label: i?.name,
      value: i?.project_type_id,
    };
  });
  let projectBilling = ProjectState?.projectType?.rows;

  const methods = useForm({
    defaultValues: {
      clientName:
        getClientsName?.find(
          (data) => data?.value === getProjectsById?.client_id
        ) ?? null,
      department:
        getDepartmentType?.find(
          (data) => data?.value === getProjectsById?.department
        ) ?? null,
      projectName: getProjectsById?.project_name ?? null,
      projectStatus:
        {
          label: getProjectsById?.status,
          value: getProjectsById?.status,
        } ?? null,
      startDate: getProjectsById?.start_date ?? null,
      endDate: getProjectsById?.end_date ?? null,
      projectContact: getProjectsById?.contactDetails
        ? getProjectsById?.contactDetails?.map((data) => {
            return {
              contactPerson: data?.contact_person_name ?? "",
              contactPersonEmail: data?.contact_email ?? "",
              contactPersonNumber: data?.contact_number ?? null,
              contactPersonId: data?.contact_person_id ?? null,
            };
          })
        : [],
      projectDeleted: [],
      billingTermsDataDeleted: [],
      projectType:
        projectTypeNames?.find(
          (data) =>
            data?.value ===
            Number(getProjectsById?.ProjectBilling?.project_type)
        ) ?? null,
      cost: Number(getProjectsById?.ProjectBilling?.cost) ?? 0,
      projectBilling:
        {
          label: getProjectsById?.ProjectBilling?.project_billing?.name,
          value:
            getProjectsById?.ProjectBilling?.project_billing
              ?.project_billing_id,
        } ?? null,
      sowCheckbox:
        getProjectsById?.ProjectBilling?.is_visible_billing_type ?? false,
      sowDocument: null,
      sowDocumentOld: getProjectsById
        ? getProjectsById?.ProjectBilling?.sow_signed?.map((data, index) => ({
            ...data,
            id: index + 1,
          }))
        : null,
      sowDocumentDeleted: [],
      followUp: getProjectsById?.ProjectBilling?.follow_up_terms ?? null,
      sowDate: getProjectsById?.ProjectBilling?.sow_date ?? null,
      billingTerms: getProjectsById?.ProjectBilling?.billing_terms ?? null,
      billingStartDate:
        getProjectsById?.ProjectBilling?.billing_start_date ?? null,
      billingTermsData: getProjectsById?.ProjectCost
        ? getProjectsById?.ProjectCost?.map((data) => {
            return {
              billingDueDate: data?.billing_due_date ?? null,
              cost: data?.cost ?? 0,
              billingTermId: data?.id ?? null,
              paidDate: data?.paid_date,
              isEdited: 0,
            };
          })
        : [],
    },
    resolver: yupResolver(updateProjectSchema),
  });
  const projectStatus = ["Cancelled", "Completed", "Initiate", "Inprogress"];

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    watch,
    reset,
    trigger,
    setValue,
    getValues,
  } = methods;
  projectBilling = ProjectState?.projectType?.rows?.filter(
    (i) => i?.project_type_id === watch("projectType")?.value
  )[0]?.projectTypeBilling;
  let projectBillingTypes = projectBilling?.map((i) => {
    return {
      label: i?.name,
      value: i?.project_billing_id,
    };
  });
  useEffect(() => {
    const data = {
      clientName: getClientsName?.find(
        (data) => data?.value === getProjectsById?.client_id
      ),
      department:
        getDepartmentType?.find(
          (data) => data?.value === getProjectsById?.department
        ) ?? null,
      projectName: getProjectsById?.project_name ?? null,
      projectStatus:
        {
          label: getProjectsById?.status,
          value: getProjectsById?.status,
        } ?? null,
      startDate: getProjectsById?.start_date ?? null,
      endDate: getProjectsById?.end_date ?? null,
      projectContact: getProjectsById?.contactDetails
        ? getProjectsById?.contactDetails?.map((data) => {
            return {
              contactPerson: data?.contact_person_name ?? "",
              contactPersonEmail: data?.contact_email ?? "",
              contactPersonNumber: data?.contact_number ?? null,
              contactPersonId: data?.contact_person_id ?? null,
            };
          })
        : [],
      projectBilling:
        {
          label: getProjectsById?.ProjectBilling?.project_billing?.name,
          value:
            getProjectsById?.ProjectBilling?.project_billing
              ?.project_billing_id,
        } ?? null,
      sowCheckbox:
        getProjectsById?.ProjectBilling?.is_visible_billing_type ?? false,
      cost: getProjectsById?.ProjectBilling?.cost ?? 0,
      billingTermsData: getProjectsById?.ProjectCost
        ? getProjectsById?.ProjectCost?.map((data) => {
            return {
              billingDueDate: data?.billing_due_date ?? null,
              cost: data?.cost ?? 0,
              billingTermId: data?.id ?? null,
              isEdited: 0,
              paidDate: data?.paid_date,
            };
          })
        : [],
      billingStartDate:
        getProjectsById?.ProjectBilling?.billing_start_date ?? null,
      projectDeleted: [],
      sowDocumentDeleted: [],
      sowDocumentOld: getProjectsById?.ProjectBilling?.sow_signed?.map(
        (data, index) => ({
          ...data,
          id: index + 1,
        })
      ),
      followUp: getProjectsById?.ProjectBilling?.follow_up_terms ?? null,
      projectType:
        projectTypeNames?.find(
          (data) =>
            data?.value ===
            Number(getProjectsById?.ProjectBilling?.project_type)
        ) ?? null,
      billingTermsDataDeleted: [],
      sowDate: getProjectsById?.ProjectBilling?.sow_date ?? null,
      billingTerms: getProjectsById?.ProjectBilling?.billing_terms ?? null,
    };
    methods.reset(data);
  }, [methods, getProjectsById]);

  const submitHandler = async (data) => {
    const submitData = {
      client_id: data?.clientName?.value,
      department: data?.department?.value,
      status: data?.projectStatus?.value,
      start_date: data?.startDate,
      end_date: data?.endDate,
      project_name: data?.projectName,
      project_deleted: JSON.stringify(data?.projectDeleted),
      project_type: data?.projectType?.value,
      billing_terms_deleted: JSON.stringify(data?.billingTermsDataDeleted),
      project_billing: data?.projectBilling?.value,
      sow_date: data?.sowDate,
      is_visible_billing_type: data?.sowCheckbox,
      cost: data?.cost ? data?.cost : 0,
      billing_terms: data?.billingTerms,
      billing_start_date: data?.billingStartDate,
      follow_up: data?.followUp,
      billing_due_date: JSON.stringify(
        data?.billingTermsData?.map((data) => {
          return {
            cost: String(data?.cost),
            billing_due_date: data?.billingDueDate ?? null,
            id: data?.billingTermId,
            paid_date: data?.paidDate ? data?.paidDate : null,
            isEdited: data?.isEdited,
          };
        })
      ),

      sow_document_deleted: JSON.stringify(data?.sowDocumentDeleted),
      project_contact_person: JSON.stringify(
        data?.projectContact?.map((data) => {
          return {
            contact_person_name: data?.contactPerson,
            contact_email: data?.contactPersonEmail,
            contact_number: data?.contactPersonNumber,
            contact_person_id: data?.contactPersonId,
          };
        })
      ),
    };
    const formData = new FormData();
    if (data?.sowDocument && data?.sowDocument?.length > 0) {
      for (let i = 0; i < data?.sowDocument?.length; i++) {
        formData.append(`sow_signed`, data.sowDocument[i]);
      }
    }
    for (let key in submitData) {
      formData.append(key, submitData[key]);
    }
    await dispatch(
      updateClientProject({ id: getProjectsById?.project_id, body: formData })
    );
    reset();
    setEditProject(false);
    setValue("sowDocument", null);
    await dispatch(fetchAllProjects(allProjectData));
    await dispatch(getAllProjectByID(projectData));

    // let result = await dispatch(fetchAllProjects(allProjectData));
    // result = result?.payload?.data?.item?.find(
    //   (item) => Number(item?.client_id) === Number(data?.clientName?.value)
    // );
    // console.log("result:", result);
    // setProjectData(result);
  };
  const inputRefSowDocument = React.useRef(null);

  const {
    fields: projectContactFields,
    append: projectContactAppend,
    remove: projectContactRemove,
  } = useFieldArray({
    name: `projectContact`,
    control,
  });
  const {
    fields: billingTermsDataFields,
    append: billingTermsDataAppend,
    remove: billingTermsDataRemove,
  } = useFieldArray({
    name: `billingTermsData`,
    control,
  });
  const handleProjectDelete = (item) => {
    let filesDeleted = getValues("projectDeleted");
    let modifiedData = {
      contact_person_name: item?.contactPerson,
      contact_person_id: item?.contactPersonId,
      contact_email: item?.contactPersonEmail,
      contact_number: item?.contactPersonNumber,
    };
    filesDeleted?.push(modifiedData);
    setValue("projectDeleted", filesDeleted);
  };
  const handleBillingTermsDelete = (item) => {
    let filesDeleted = getValues("billingTermsDataDeleted");
    let modifiedData = {
      billing_due_date: item?.billingDueDate,
      cost: item?.cost,
      id: item?.billingTermId,
      paid_date: item?.paidDate,
    };
    filesDeleted?.push(modifiedData);
    setValue("billingTermsDataDeleted", filesDeleted);
  };
  const handleDeleteImage = (item) => {
    const filesUpload = getValues("sowDocumentOld");
    let filesDeleted = getValues("sowDocumentDeleted");
    const updatedFilesUploaded = filesUpload?.filter(
      (file) => file?.id !== item?.id
    );
    filesDeleted?.push(item);
    setValue("sowDocumentDeleted", filesDeleted);
    setValue("sowDocumentOld", updatedFilesUploaded);
    // setFilesUploaded(updatedFilesUploaded);
  };
  return (
    <>
      <div
        className={
          editProject ? "createProjectDrawer active" : "createProjectDrawer"
        }
      >
        <div className="createProjectDrawerHeader">
          <div className="w-full whitespace-nowrap">
            <div className="text-lg mb-2 font-semibold flex items-center justify-between">
              <div>Update Project</div>
              <div
                className="headerClose"
                onClick={() => {
                  setEditProject(false);
                  reset();
                }}
              >
                <i className="fa-light fa-xmark fa-lg"></i>
              </div>
            </div>
            <div className="text-sm text-blue-100">
              Get started by filling the information below to update a Project.
            </div>
          </div>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submitHandler)}>
            {isFetching && <Loader />}
            <div className={editProject ? "active" : ""}>
              <div className="">
                <div className="formContainer whitespace-nowrap addClientForm bg-white mt-2">
                  {/*  Project Detail form */}
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <div className="font-semibold flex items-center justify-between py-2 text-lg">
                        Project Details:
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label
                        htmlFor="clientName"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Client Name<span className="text-red-500">*</span>
                      </label>
                      <div className="create_form_input_div autoSelect mb-3">
                        <AutoCompleteComponent
                          name="clientName"
                          control={control}
                          errors={errors}
                          disabled={true}
                          onChangeHandler={() => trigger("clientName")}
                          options={getClientsName?.length ? getClientsName : []}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label
                        htmlFor="projectStatus"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Project Status<span className="text-red-500">*</span>
                      </label>
                      <div className="create_form_input_div autoSelect mb-3">
                        <AutoCompleteComponent
                          name="projectStatus"
                          control={control}
                          errors={errors}
                          onChangeHandler={(e, value) => {
                            trigger("projectStatus");
                            if (e?.value === "Completed") {
                              setValue("endDate", new Date());
                            } else {
                              setValue("endDate", null);
                            }
                          }}
                          options={
                            projectStatus
                              ? projectStatus?.map((data) => {
                                  return {
                                    label: data,
                                    value: data,
                                  };
                                })
                              : []
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="startDate"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Start Date<span className="text-red-500">*</span>
                      </label>
                      <DatePickerComponent
                        name="startDate"
                        control={control}
                        errors={errors}
                        backSpaceChange={false}
                        onHandleChange={() => trigger("startDate")}
                        disableMaskedInput={true}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="endDate"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        End Date
                      </label>
                      <DatePickerComponent
                        name="endDate"
                        control={control}
                        errors={errors}
                        backSpaceChange={false}
                        onHandleChange={(date) => {
                          trigger("endDate");
                          const currentDate = new Date();
                          if (date <= currentDate) {
                            setValue("projectStatus", {
                              label: "Completed",
                              value: "Completed",
                            });
                          }
                        }}
                        disableMaskedInput={true}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="projectName"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Project Name <span className="text-red-500">*</span>
                      </label>
                      <TextFieldComponent
                        name="projectName"
                        onChangeHandler={() => trigger("projectName")}
                        control={control}
                        errors={errors}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="department"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Department
                      </label>
                      <div className="create_form_input_div autoSelect">
                        <AutoCompleteComponent
                          // size="small"
                          name="department"
                          control={control}
                          errors={errors}
                          onChangeHandler={() => trigger("department")}
                          options={
                            getDepartmentType?.length ? getDepartmentType : []
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="projectType"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Project Type
                      </label>
                      <div className="create_form_input_div autoSelect">
                        <AutoCompleteComponent
                          size="small"
                          name="projectType"
                          control={control}
                          errors={errors}
                          onChangeHandler={() =>
                            setValue("projectBilling", null)
                          }
                          options={
                            projectTypeNames?.length ? projectTypeNames : []
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="projectBilling"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Project Billing
                      </label>
                      <div className="create_form_input_div autoSelect">
                        <AutoCompleteComponent
                          name="projectBilling"
                          control={control}
                          errors={errors}
                          disabled={!watch("projectType")}
                          options={
                            projectBillingTypes?.length
                              ? projectBillingTypes
                              : []
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="sowDocument"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        SOW Signed Attachment & Signed Date
                        <span className="text-red-500">*</span>
                        <label
                          htmlFor="sow-file-upload-update"
                          className="cursor-pointer inline-flex items-center rounded-md text-sm font-medium ml-1 text-black"
                        >
                          <i className="fa-light fa-paperclip mr-2 justify-center align-middle"
                          ></i>
                          {/* Upload file */}
                        </label>
                      </label>
                      <div className="flex flex-column items-center">
                        <div className="col-md-12">
                          <Controller
                            name="sowDocument"
                            control={control}
                            errors={errors}
                            render={({ field: { value, onChange, name } }) => (
                              <div className="flex items-center gap-3">
                                <div>
                                  <input
                                    ref={inputRefSowDocument}
                                    name={name}
                                    className="w-0 h-0 opacity-0 overflow-hidden absolute z-0"
                                    accept="image/*, .pdf, .doc, .docx"
                                    type="file"
                                    id="sow-file-upload-update"
                                    multiple={true}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      onChange(e?.target?.files);
                                      trigger("sowDate");
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          />
                        </div>
                        <div className="col-md-12">
                          <DatePickerComponent
                            name="sowDate"
                            control={control}
                            errors={errors}
                            backSpaceChange={false}
                            onHandleChange={() => trigger("sowDate")}
                            disableMaskedInput={true}
                          />
                        </div>
                        {watch("sowDocument")?.length ? (
                          <div className="col-md-12 text-sm ml-0 mt-1">
                            {watch("sowDocument")?.length === 1
                              ? `${
                                  watch("sowDocument")?.length
                                } file Uploaded Successfully !`
                              : `${
                                  watch("sowDocument")?.length
                                } file(s) Uploaded Successfully !`}
                          </div>
                        ) : null}
                        {!watch("sowDocument")?.length && errors.sowDocument ? (
                          <div className="custom-error w-100 text-left mt-1">
                            {errors?.sowDocument?.message}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="cost"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Cost/ Rate <span className="text-red-500">*</span>
                      </label>
                      <TextFieldNumberComponent
                        name="cost"
                        control={control}
                        errors={errors}
                        type={"number"}
                        disabled={false}
                        defaultValue={watch("cost")}
                        InputProps={{
                          inputProps: { min: 0, step: "any" },
                        }}
                        onChangeHandler={() => trigger("cost")}
                      />

                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="flex items-center flex-wrap gap-3">
                      {watch("sowDocumentOld")?.map((item, key) => {
                        let itemExtension = item?.name?.split(".");
                        let imageExtension = [
                          "jpg",
                          "jpeg",
                          "png",
                          "gif",
                          "bmp",
                          "tiff",
                          "tif",
                          "webp",
                          "svg",
                        ];
                        let documentExtension = ["doc", "docx"];
                        itemExtension =
                          itemExtension[itemExtension?.length - 1];
                        return (
                          <div key={key} id={key} className="updateFormImg">
                            {imageExtension?.includes(itemExtension) ? (
                              <div className="relative">
                                <i className="fa-light fa-xmark updateImgDeleteIcon" onClick={() => handleDeleteImage(item)}></i>
                                <img
                                  src={item?.url}
                                  alt={item?.name}
                                  onClick={() => {
                                    const link = document.createElement("a");
                                    link.href = item?.url;
                                    link.download = item?.name;
                                    link.click();
                                  }}
                                  className="uploadImgIcon img-fluid"
                                />
                                <div className="updateImgName">
                                  {item?.name}
                                </div>
                              </div>
                            ) : documentExtension?.includes(itemExtension) ? (
                              <div className="relative">
                                <i className="fa-light fa-xmark updateImgDeleteIcon" onClick={() => handleDeleteImage(item)}></i>
                                <img
                                  src={doc}
                                  alt={item?.name}
                                  onClick={() => {
                                    const link = document.createElement("a");
                                    link.href = item?.url;
                                    link.download = item?.name;
                                    link.click();
                                  }}
                                  className="uploadImgIcon img-fluid"
                                />
                                <div className="updateImgName">
                                  {item?.name}
                                </div>
                              </div>
                            ) : (
                              <div className="relative">
                                <i className="fa-light fa-xmark updateImgDeleteIcon" onClick={() => handleDeleteImage(item)}></i>
                                <img
                                  src={pdf}
                                  alt={item?.name}
                                  onClick={() => {
                                    const link = document.createElement("a");
                                    link.href = item?.url;
                                    link.download = item?.name;
                                    link.click();
                                  }}
                                  className="uploadImgIcon img-fluid"
                                />
                                <div className="updateImgName">
                                  {item?.name}
                                </div>
                              </div>
                            )}
                            {/* <p className="text-sm mt-1">{item?.name}</p> */}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8 mb-3">
                      <label
                        htmlFor="sowCheckbox"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white mt-3"
                      >
                        <Controller
                          name="sowCheckbox"
                          control={control}
                          errors={errors}
                          value={false}
                          render={({ field: { onChange, value } }) => (
                            <>
                              <Checkbox
                                color="primary"
                                onChange={(e, val) => {
                                  onChange(val);
                                }}
                                checked={value || false}
                              />
                            </>
                          )}
                        />
                        Show Billing And Project Type Of HOD
                      </label>
                    </div>
                    <div
                      className="col-md-4 mb-3 relative"
                      onClick={() => {
                        projectContactAppend({
                          contactPerson: "",
                          contactPersonEmail: "",
                          contactPersonNumber: null,
                        });
                      }}
                    >
                      <div className="border-1 border-blue-600 rounded-md cursor-pointer text-blue-600 hover:text-gray-50 hover:bg-blue-500 hover:border-blue-500  d-flex justify-content-center align-items-center gap-1 px-3 py-0.5 text-sm absolute right-3 bottom-1">
                        <i className="fa-light fa-plus primary"></i>
                        Add Project Contact
                      </div>
                    </div>
                  </div>
                  <hr className="border-1 border-gray-300" />
                  {projectContactFields?.map((item, index) => {
                    return (
                      <>
                        <div
                          className="d-flex justify-content-center"
                          key={item?.id}
                        >
                          <div className="row py-2">
                            <div className="col-md-3 mb-2 mr-2">
                              <label
                                htmlFor={`projectContact.${index}.contactPerson`}
                                className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                              >
                                Contact Person Name
                                <span className="text-red-500">*</span>
                              </label>
                              <TextFieldComponent
                                name={`projectContact.${index}.contactPerson`}
                                id={`projectContact.${index}.contactPerson`}
                                control={control}
                                errors={errors}
                              />
                            </div>
                            <div className="col-md-4 mb-2 mr-2">
                              <label
                                htmlFor="contactPersonEmail"
                                className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                              >
                                Contact Person Email
                                <span className="text-red-500">*</span>
                              </label>
                              <TextFieldComponent
                                name={`projectContact.${index}.contactPersonEmail`}
                                id={`projectContact.${index}.contactPersonEmail`}
                                control={control}
                                errors={errors}
                              />
                            </div>
                            <div className="col-md-3 mb-2 mr-2">
                              <label
                                htmlFor="contactPersonNumber"
                                className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                              >
                                Contact Person No.
                              </label>
                              <TextFieldComponent
                                name={`projectContact.${index}.contactPersonNumber`}
                                id={`projectContact.${index}.contactPersonNumber`}
                                type="number"
                                control={control}
                                errors={errors}
                              />
                            </div>
                            <div
                              className="col-md-1 mt-4 ml-3 mb-2 h-fit relative border-1 border-gray-400 d-flex justify-content-center px-0 py-2 rounded cursor-pointer"
                              onClick={() => {
                                projectContactRemove(index);
                                handleProjectDelete(item);
                              }}
                            >
                              <i className="fa-solid fa-trash-can danger "></i>
                            </div>
                          </div>
                        </div>
                        <hr className="border-1 border-gray-300" />
                      </>
                    );
                  })}
                  {/*  Follow UP And Billing Terms Form */}
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <div className="font-semibold flex items-center justify-between py-2 text-lg">
                        Follow Up & Billing Terms:
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="billingTerms"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Billing Terms
                        <span className="text-red-500">*</span>
                      </label>
                      <TextFieldComponent
                        name="billingTerms"
                        onChangeHandler={() => trigger("billingTerms")}
                        control={control}
                        errors={errors}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="followUp"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Follow Up <span className="text-red-500">*</span>
                      </label>
                      <DatePickerComponent
                        name="followUp"
                        control={control}
                        errors={errors}
                        backSpaceChange={false}
                        onHandleChange={() => trigger("billingEndDate")}
                        disableMaskedInput={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="billingStartDate"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Billing Start Date
                        <span className="text-red-500">*</span>
                      </label>
                      <DatePickerComponent
                        name="billingStartDate"
                        control={control}
                        errors={errors}
                        backSpaceChange={false}
                        onHandleChange={() => trigger("billingStartDate")}
                        disableMaskedInput={true}
                      />
                    </div>
                    <div
                      className="col-md-6 mb-3 relative"
                      onClick={() => {
                        billingTermsDataAppend({
                          cost: 0,
                          billingDueDate: null,
                          paidDate: null,
                        });
                      }}
                    >
                      <div className="border-1 border-blue-600 rounded-md cursor-pointer text-blue-600 hover:text-gray-50 hover:bg-blue-500 hover:border-blue-500  d-flex justify-content-center align-items-center gap-1 px-3 py-0.5 text-sm absolute right-3 bottom-1">
                        <i className="fa-light fa-plus primary"></i>
                        Add Cost
                      </div>
                    </div>
                  </div>
                  <hr className="border-1 border-gray-300" />
                  {billingTermsDataFields?.map((item, index) => {
                    return (
                      <>
                        <div
                          className="d-flex justify-content-center"
                          key={item?.id}
                        >
                          <div className="row py-2">
                            <div className="col-md-3 mr-2">
                              <label
                                htmlFor={`billingTermsData.${index}.cost`}
                                className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                              >
                                Cost / Rate
                              </label>
                              <TextFieldNumberComponent
                                name={`billingTermsData.${index}.cost`}
                                control={control}
                                errors={errors}
                                type={"number"}
                                disabled={false}
                                defaultValue={watch(
                                  `billingTermsData.${index}.cost`
                                )}
                                InputProps={{
                                  inputProps: { min: 0, step: "any" },
                                }}
                                onChangeHandler={() =>
                                  trigger(`billingTermsData.${index}.cost`)
                                }
                              />

                            </div>
                            <div className="col-md-4 mr-2">
                              <label
                                htmlFor={`billingTermsData.${index}.billingDueDate`}
                                className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                              >
                                Billing Due Date
                              </label>
                              <DatePickerComponent
                                name={`billingTermsData.${index}.billingDueDate`}
                                control={control}
                                errors={errors}
                                onHandleChange={() => {
                                  setValue(
                                    `billingTermsData.${index}.isEdited`,
                                    1
                                  );
                                }}
                                backSpaceChange={false}
                                disableMaskedInput={true}
                              />
                            </div>
                            <div className="col-md-3 mr-5">
                              <label
                                htmlFor={`billingTermsData.${index}.paidDate`}
                                className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                              >
                                Paid Date
                              </label>
                              <DatePickerComponent
                                name={`billingTermsData.${index}.paidDate`}
                                control={control}
                                errors={errors}
                                onHandleChange={() => {
                                  setValue(
                                    `billingTermsData.${index}.isEdited`,
                                    1
                                  );
                                }}
                                backSpaceChange={false}
                                disableMaskedInput={true}
                              />
                            </div>
                            <div
                              onClick={() => {
                                billingTermsDataRemove(index);
                                handleBillingTermsDelete(item);
                              }}
                              className="col-md-1 mt-4 border-1 h-fit border-gray-400 d-flex justify-content-center px-3 py-2 rounded cursor-pointer"
                            >
                              <i className="fa-solid fa-trash-can danger "></i>
                            </div>
                          </div>
                        </div>
                        <hr className="border-1 border-gray-300" />
                      </>
                    );
                  })}
                </div>
              </div>

              {/* {renderButton()} */}
            </div>
            <div className="text-end p-3  whitespace-nowrap">
              <button
                onClick={() => {
                  setEditProject(false);
                  reset();
                }}
                type="button"
                className="inline-flex font-sm text-sm border-1 border-gray-600 rounded-md cursor-pointer hover:text-gray-50 hover:bg-gray-500 hover:border-gray-500 py-2 px-4"
              >
                Cancel
              </button>
              <button
                type="submit"
                // disabled={!isDirty}
                // id={!isDirty ? "newDisable" : ""}
                className="ml-3 inline-flex font-sm text-sm border-1 border-blue-600 rounded-md cursor-pointer bg-blue-600 text-white hover:text-gray-50 hover:bg-blue-500 hover:border-blue-500 py-2 px-4"
              >
                Submit
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
      <div
        className={
          editProject ? "createProjectOverlay active" : "createProjectOverlay"
        }
        onClick={() => setEditProject(false)}
      ></div>
    </>
  );
};

export default UpdateProject;
