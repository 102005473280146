import React, { useCallback, useContext } from "react";
import { useState } from "react";

import "../../../index.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  fetchAllLeaveAllocationV1,
  fetchLeaveApprovers,
  fetchLeaveType,
} from "../../../store/slices/leaveSlice";
import { fetchEmployeeNameList } from "../../../store/slices/employeeSlice";
import { getFiscalYear, getLoggedUserData } from "../../../utils/helper";
import Pagination from "@mui/material/Pagination";
import { ClickAwayListener } from "@mui/base";
import Loader from "../../Loader/Loader";
import FilterAllocation from "./FilterAllocation";
import dayjs from "dayjs";
import {
  allocationStatusUpdate,
  getstatusModifiedBy,
} from "../../HR/Leave Allocation/allocationHelper";
import ViewAllocationDialog from "./ViewAllocationDialog";
import ApplyAllocationDialog from "./applyAllocation";
import AllocationConfirmDialog from "./AllocationConfirmDialog";
import { PopupContext } from "../../GlobalPopup/GlobalPopup";
import {
  componentDisplayAccess,
  getEmployeesAccess,
} from "../../../utils/userPermission";
import moment from "moment";

const Allocation = () => {
  const { displayPopup } = useContext(PopupContext);

  const [allocationView, setAllocationView] = useState(false);
  const [updateIsFetching, setUpdateIsFetching] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedEmpData, setSelectedEmpData] = useState([]);
  const [updateData, setUpdateData] = useState({});
  const [applyFilter, setApplyFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [toggle, setToggle] = useState(false);
  const [checked, setChecked] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState();
  const [statusModifiedBy, setstatusModifiedBy] = useState([]);
  const [filterValues, setFilterValues] = useState(
    localStorage.getItem("allocationFilter")
      ? JSON.parse(localStorage.getItem("allocationFilter"))
      : null
  );
  const [sorting, setSorting] = useState("creation");
  const [order, setOrder] = useState("DESC");

  const dispatch = useDispatch();
  const loggedEmployee = getLoggedUserData();
  const EmployeeId = loggedEmployee?.employeeId ?? null;
  const leaveAllocationDisplayAccess = componentDisplayAccess({
    component: "Review_Apply_Allocation",
  });
  useEffect(() => {
    if (EmployeeId) {
      dispatch(fetchLeaveApprovers({ EmployeeId }));
    }
    dispatch(fetchEmployeeNameList({ module: "Leave_Module_V1" }));
    dispatch(fetchLeaveType());
  }, [EmployeeId, dispatch]);
  const employeeList = useSelector(
    (state) => state?.employee?.employeeNameList
  );
  const employeeArrayList = employeeList?.map((data) => data?.EmployeeId);
  const leaveAllocationAccessById = getEmployeesAccess({
    employees: employeeArrayList,
    component: "Leave_Allocation_V1",
  });
  const LeaveType = useSelector((state) => state?.leave?.leaveType?.types);
  const leaveApplicationData = useSelector(
    (state) => state?.leave?.leaveAllocationRequest?.allocationData
  );
  const fiscalYear = getFiscalYear();
  const fiscalFirst = fiscalYear?.split("-")[0];
  const fiscalLast = fiscalYear?.split("-")[1];
  const isFetching = useSelector(
    (state) => state?.leave?.leaveAllocationRequest?.isFetching
  );
  const getData = useCallback(() => {
    let response = JSON.parse(localStorage.getItem("allocationFilter"));
    dispatch(
      fetchAllLeaveAllocationV1({
        search: response?.employee?.value,
        limit,
        page,
        filterValue: response?.leave_type?.value,
        status: response?.status?.value,
        fromDate: response?.date?.from_date,
        toDate: response?.date?.to_date,
        order,
        sort: sorting,
      })
    );
  }, [dispatch, limit, page, order, sorting]);

  useEffect(() => {
    getData();
  }, [dispatch, limit, page, getData, filterValues, order, sorting]);
  useEffect(() => {
    const userAuthRoles = getstatusModifiedBy();
    setstatusModifiedBy(userAuthRoles);
    if (localStorage?.getItem("allocationFilter")) {
      const initialData = JSON.parse(localStorage?.getItem("allocationFilter"));
      setFilterValues(initialData);
    } else {
      localStorage?.setItem(
        "allocationFilter",
        JSON.stringify({
          search: null,
          status: { label: "Open", value: "Open" },
          date: {
            from_date: `04-01-${fiscalFirst}`,
            to_date: `03-31-${fiscalLast}`,
          },
        })
      );

      const initialData = JSON.parse(localStorage?.getItem("allocationFilter"));
      setFilterValues(initialData);
    }
  }, [fiscalFirst, fiscalLast]);

  const handleCheckboxInput = (data, e) => {
    if (
      selectedEmpData?.includes(data.leave_allocation_id) &&
      !e.target.checked
    ) {
      setSelectedEmpData(
        selectedEmpData?.filter((i) => i !== data.leave_allocation_id)
      );
    } else {
      setSelectedEmpData([...selectedEmpData, data.leave_allocation_id]);
    }
  };
  const leaveStatus = ["Open", "Dismissed", "Approved", "Rejected"];
  const handleFilterClose = (e, dataId) => {
    const data = filterValues;
    data[dataId] = null;
    localStorage.setItem("allocationFilter", JSON.stringify(data));
    const result = JSON.parse(localStorage.getItem("allocationFilter"));
    setFilterValues(result);
    setPage(1);
  };
  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleChangeLimit = () => {
    setToggle(!toggle);
  };
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const selectAllData = [];
      leaveApplicationData?.items?.forEach((data) => {
        if (
          data.status === "Open" &&
          leaveAllocationAccessById?.[data?.employee_id]?.updateAccess
        )
          selectAllData.push(data.leave_allocation_id);
      });
      setSelectedEmpData(selectAllData);
    } else setSelectedEmpData([]);
    setChecked(!checked);
  };
  const handleUpdateStatus = async (status, data) => {
    setUpdateIsFetching(true);
    let selected = data || selectedEmpData;
    const res = await allocationStatusUpdate({
      statusModifiedBy: statusModifiedBy,
      allocationIds: selected,
      status: status,
    });
    setUpdateIsFetching(false);
    setConfirmStatus(false);
    setSelectedEmpData([]);

    if (res?.status) displayPopup(res?.message, "alert-success");
    else displayPopup(res?.response?.data?.message, "alert-danger");
    getData();
  };

  let headers = [
    { label: "Employee ID", name: "employee_id" },
    { label: "Employee Name", name: "employee_name" },
    { label: "Leave Type", name: "leave_type" },
    { label: "Requested Days", name: "days_requested" },
    { label: "Posting Date", name: "creation" },
    { label: "From Date", name: "from_date" },
    { label: "To Date", name: "to_date" },
    { label: "Fiscal Year", name: "fiscal_year" },
    { label: "RM Approval" },
    { label: "HOD Approval" },
    { label: "HR Approval" },
    { label: "Action" },
  ];

  return (
    <>
      {isFetching ? (
        <Loader />
      ) : (
        <div className="p-3 pb-0 bg-indigo-50">
          {/* {alert && <AlertsMessage alertClass={alertClass} />} */}
          <div className="flex items-center justify-end flex-wrap gap-3 pb-3 pt-0">
            {selectedEmpData?.length ? (
              <>
                <button
                  className="border-solid border-1 border-green-600 rounded-md text-green-700 bg-green-0 hover:text-green-50 hover:bg-green-700 py-1 px-4 "
                  id="3"
                  type="button"
                  onClick={() => {
                    setConfirmStatus(true);
                    setSelectedStatus("Approved");
                  }}
                >
                  Approve
                </button>
                <button
                  className="border-solid border-1 border-red-600 rounded-md text-red-700 bg-red-0 hover:text-red-50 hover:bg-red-700 py-1 px-4 "
                  id="3"
                  type="button"
                  onClick={() => {
                    setConfirmStatus(true);
                    setSelectedStatus("Rejected");
                  }}
                >
                  Reject
                </button>
              </>
            ) : null}
            {leaveAllocationDisplayAccess?.createAccess ? (
              <button
                onClick={() => setOpen(true)}
                className="border-solid border-1 border-blue-700 rounded-md bg-blue-0 text-blue-700 hover:bg-blue-700 hover:text-blue-50 py-1 px-4 "
                id="3"
                type="button"
              >
                <i className="fa-light fa-person-to-door"></i> Apply Allocation
              </button>
            ) : null}
            <button
              className="border-solid border-1 border-blue-700 rounded-md bg-blue-0 text-blue-700 hover:bg-blue-700 hover:text-blue-50 py-1 px-4 "
              id="3"
              type="button"
              onClick={() => setApplyFilter(true)}
            >
              <i className="fa-light fa-filter me-1"></i> Filter
            </button>
          </div>
          <div className="bg-white px-3 pb-0 flex items-center gap-3 rounded-t-lg border-b border-b-gray-300 shadow-md">
            <div className="flex items-center gap-3 py-3">
              <div className="flex items-center gap-2 font-semibold after:content-['|']  after:text-gray-700">
                {leaveApplicationData?.totalItems} <span>Records</span>{" "}
                <span className="border-r border-r-indigo-200"></span>
              </div>
              {filterValues?.employee && (
                <div className="border-solid border-1 border-blue-700 rounded-md bg-blue-600 text-sm text-white py-1 pl-3">
                  {filterValues?.employee?.label}
                  <i
                    className="fa-light fa-xmark pl-2 pr-3 cursor-pointer"
                    id="employee"
                    onClick={(e) => handleFilterClose(e, "employee")}
                  ></i>
                </div>
              )}

              {filterValues?.leave_type && (
                <div className="border-solid border-1 border-blue-700 rounded-md bg-blue-600 text-sm text-white py-1 pl-3">
                  {filterValues?.leave_type?.label}
                  <i
                    className="fa-light fa-xmark pl-2 pr-3 cursor-pointer"
                    id="leave_type"
                    onClick={(e) => handleFilterClose(e, "leave_type")}
                  ></i>
                </div>
              )}

              {filterValues?.status && (
                <div className="border-solid border-1 border-blue-700 rounded-md bg-blue-600 text-sm text-white py-1 pl-3">
                  {filterValues?.status?.value}
                  <i
                    className="fa-light fa-xmark pl-2 pr-3 cursor-pointer"
                    id="status"
                    onClick={(e) => handleFilterClose(e, "status")}
                  ></i>
                </div>
              )}

              {filterValues?.date?.from_date && (
                <>
                  <div className="border-solid border-1 border-blue-700 rounded-md bg-blue-600 text-sm text-white py-1 pl-3">
                    {`${
                      filterValues?.date?.from_date &&
                      moment(
                        filterValues?.date?.from_date,
                        "MM-DD-YYYY"
                      ).format("DD-MMM-YYYY")
                    } - ${
                      filterValues?.date?.to_date &&
                      moment(filterValues?.date?.to_date, "MM-DD-YYYY").format(
                        "DD-MMM-YYYY"
                      )
                    }`}
                    <i
                      className="fa-light fa-xmark pl-2 pr-3 cursor-pointer"
                      id="date"
                      onClick={(e) => handleFilterClose(e, "date")}
                    ></i>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="leaveAppTableContainer relative overflow-x-auto shadow-sm">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400  rounded-b-lg">
              <thead className="text-xs bg-white text-gray-700 uppercase ">
                <tr>
                  <th scope="col" className="p-4">
                    <div className="flex items-center">
                      <input
                        id="checkbox-all-search"
                        type="checkbox"
                        onClick={handleSelectAll}
                        disabled={filterValues?.status?.label === "Dismissed"}
                        className="w-4 h-4 cursor-pointer disabled:cursor-not-allowed text-blue-600 indeterminate:bg-gray-300 enabled:border-gray-400 disabled:opacity-45  border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label htmlFor="checkbox-all-search" className="sr-only">
                        checkbox
                      </label>
                    </div>
                  </th>
                  {headers?.map((i) => {
                    return (
                      <th key={i} scope="col" className="px-6 py-3">
                        <div className="flex">
                          {i.label}
                          {i?.name && (
                            <>
                              {sorting === i.name ? (
                                <span
                                  className="pl-1"
                                  onClick={() => {
                                    setSorting(i?.name);
                                    order === "DESC"
                                      ? setOrder("ASC")
                                      : setOrder("DESC");
                                  }}
                                >
                                  <i
                                    className={`${
                                      order === "DESC"
                                        ? "fa-arrow-down"
                                        : "fa-arrow-up"
                                    } fa-light`}
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="pl-1 opacity-50"
                                  onClick={() => {
                                    setSorting(i?.name);
                                    setOrder("ASC");
                                  }}
                                >
                                  <i className="fa-light fa-arrow-down"></i>
                                </span>
                              )}
                            </>
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {leaveApplicationData?.items?.map((data) => {
                  return (
                    <tr
                      key={data?.leave_allocation_id}
                      className="bg-white border-t dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                      <td className="w-4 px-4 py-2">
                        {leaveAllocationAccessById?.[data?.employee_id]
                          ?.updateAccess ? (
                          <div className="flex items-center">
                            <input
                              onChange={(e) => handleCheckboxInput(data, e)}
                              id="checkbox-table-search-1"
                              type="checkbox"
                              checked={selectedEmpData?.includes(
                                data.leave_allocation_id
                              )}
                              disabled={
                                !(data.status === "Open") ||
                                data.status === "Dismissed"
                              }
                              className="w-4 h-4 cursor-pointer disabled:cursor-not-allowed text-blue-600 indeterminate:bg-gray-300 enabled:border-gray-400 disabled:opacity-45  border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                              htmlFor="checkbox-table-search-1"
                              className="sr-only"
                            >
                              checkbox
                            </label>
                          </div>
                        ) : (
                          <div className="flex items-center">
                            <input
                              id="checkbox-table-search-1"
                              type="checkbox"
                              checked={selectedEmpData?.includes(
                                data.leave_allocation_id
                              )}
                              disabled={true}
                              className="w-4 h-4 cursor-pointer disabled:cursor-not-allowed text-blue-600 indeterminate:bg-gray-300 enabled:border-gray-400 disabled:opacity-45  border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                              htmlFor="checkbox-table-search-1"
                              className="sr-only"
                            >
                              checkbox
                            </label>
                          </div>
                        )}
                      </td>
                      {/* <td
                        scope="row"
                        className="flex items-center px-3 py- text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        <ListItemAvatar className="img-fluid">
                          {data?.allocationRequestEmp?.profileImage ? (
                            <Avatar
                              className="img-fluid allEmpProfileImg w-10 h-10 rounded-full object-cover"
                              src={data?.allocationRequestEmp?.profileImage}
                              alt={data?.allocationRequestEmp?.firstName}
                            />
                          ) : (
                            <Avatar
                              className="img-fluid empCardAvatar w-10 h-10 rounded-full object-cover"
                              {...stringAvatar(
                                data?.allocationRequestEmp?.firstName
                              )}
                              alt={data?.allocationRequestEmp?.firstName}
                            />
                          )}
                        </ListItemAvatar>
                        <div className="pl-3">
                          <div className="text-base font-semibold">
                            {`${data?.allocationRequestEmp?.firstName} ${
                              data?.allocationRequestEmp?.middleName ?? ""
                            } ${data?.allocationRequestEmp?.lastName ?? ""}`}
                          </div>
                          <div className="font-normal text-gray-500">
                            #{data?.employee_id}
                          </div>
                        </div>
                      </td> */}
                      <td className="px-3 py-2">#{data?.employee_id}</td>
                      <td className="px-3 py-2">
                        {`${data?.allocationRequestEmp?.firstName} ${
                          data?.allocationRequestEmp?.middleName ?? ""
                        } ${data?.allocationRequestEmp?.lastName ?? ""}`}
                      </td>
                      <td className="px-3 py-2">{data?.leave_type}</td>
                      <td className="px-3 py-2">{data?.days_requested}</td>
                      <td className="px-3 py-2">
                        {dayjs(data?.creation).format("DD-MMM-YYYY")}
                      </td>
                      <td className="px-3 py-2">
                        {data?.from_date
                          ? dayjs(data?.from_date).format("DD-MMM-YYYY")
                          : "-"}
                      </td>
                      <td className="px-3 py-2">
                        {data?.to_date
                          ? dayjs(data?.to_date).format("DD-MMM-YYYY")
                          : "-"}
                      </td>
                      <td className="px-3 py-2">{data?.fiscal_year}</td>

                      {["RM", "HOD", "HR"].map((item, i) => (
                        <td className="px-3 py-2">
                          <div
                            className={`flex items-center leaveTableStatus ${
                              data?.allocationApproveStatus[i]
                                ? data?.allocationApproveStatus[i].status
                                : data?.status === "Open"
                                ? "Pending"
                                : data?.status === "Dismissed"
                                ? "Dismissed"
                                : "-"
                            } rounded-sm px-2`}
                          >
                            <div
                              className={`h-2.5 w-2.5 rounded-full mr-2 ${
                                data?.allocationApproveStatus[i]
                                  ? data?.allocationApproveStatus[i].status
                                  : data?.status === "Open"
                                  ? "Pending"
                                  : data?.status === "Dismissed"
                                  ? "Dismissed"
                                  : "-"
                              }`}
                            ></div>
                            {data?.allocationApproveStatus[i]
                              ? data?.allocationApproveStatus[i].status
                              : data?.status === "Open"
                              ? "Pending"
                              : data?.status === "Dismissed"
                              ? "Dismissed"
                              : "-"}
                          </div>
                        </td>
                      ))}
                      {/* <td className="px-3 py-2">
                        <div className="empLeaveComments relative inline-block">
                          <div className="tooltipWrapper relative">
                            <i className="fa-light fa-comment-lines text-blue-600 font-semibold cursor-pointer"
                              onClick={() => (
                                setCommentDialog(true), setComment(data)
                              )}
                            ></i>
                            <div className="tooltipHover comment hidden border-1 border-solid border-blue-400 bg-blue-50 text-blue-500 py-1 px-3 rounded-md text-xs font-semibold whitespace-nowrap">
                              View Comments
                            </div>
                          </div>
                        </div>
                      </td> */}
                      <td className="px-3 py-2">
                        <div className="flex items-center gap-3">
                          <div className="tooltipWrapper relative ">
                            <i
                              className="fa-light fa-eye cursor-pointer text-blue-600"
                              onClick={() => {
                                setAllocationView(true);
                                setUpdateData(data);
                              }}
                            ></i>
                            <div className="tooltipHover edit hidden border-1 border-solid border-blue-400 bg-blue-50 text-blue-500 py-1 px-3 rounded-md text-xs font-semibold">
                              View
                            </div>
                          </div>

                          {leaveAllocationAccessById?.[data?.employee_id]
                            ?.updateAccess ? (
                            <div
                              className={
                                data?.status !== "Open"
                                  ? "tooltipWrapper relative statusApproved"
                                  : "tooltipWrapper relative" &&
                                    data?.status === "Dismissed"
                                  ? "tooltipWrapper relative statusDismissed"
                                  : "tooltipWrapper relative"
                              }
                            >
                              <i
                                className="fa-light fa-circle-check cursor-pointer text-green-500"
                                onClick={() => {
                                  setConfirmStatus(true);
                                  setSelectedEmpData([
                                    data.leave_allocation_id,
                                  ]);
                                  setSelectedStatus("Approved");
                                }}
                              ></i>
                              <div className="tooltipHover approve hidden border-1 border-solid border-green-400 bg-green-50 text-green-500 py-1 px-3 rounded-md text-xs font-semibold">
                                Approve
                              </div>
                            </div>
                          ) : (
                            <div
                              className={
                                data?.status !== "Open"
                                  ? "tooltipWrapper relative statusApproved"
                                  : "tooltipWrapper relative" &&
                                    data?.status === "Dismissed"
                                  ? "tooltipWrapper relative statusDismissed"
                                  : "tooltipWrapper relative"
                              }
                              id="newDisable"
                            >
                              <i className="fa-light fa-circle-check cursor-pointer text-green-500"></i>
                              <div className="tooltipHover approve hidden border-1 border-solid border-green-400 bg-green-50 text-green-500 py-1 px-3 rounded-md text-xs font-semibold">
                                Approve
                              </div>
                            </div>
                          )}
                          {leaveAllocationAccessById?.[data?.employee_id]
                            ?.updateAccess ? (
                            <div
                              className={
                                data?.status !== "Open"
                                  ? "tooltipWrapper relative statusRejected"
                                  : "tooltipWrapper relative" &&
                                    data?.status === "Dismissed"
                                  ? "tooltipWrapper relative statusDismissed"
                                  : "tooltipWrapper relative"
                              }
                            >
                              <i
                                className="fa-light fa-circle-xmark cursor-pointer text-red-700"
                                onClick={() => {
                                  setConfirmStatus(true);
                                  setSelectedEmpData([
                                    data.leave_allocation_id,
                                  ]);
                                  setSelectedStatus("Rejected");
                                }}
                              ></i>
                              <div className="tooltipHover reject hidden border-1 border-solid border-red-400 bg-red-50 text-red-500 py-1 px-3 rounded-md text-xs font-semibold">
                                Reject
                              </div>
                            </div>
                          ) : (
                            <div
                              className={
                                data?.status !== "Open"
                                  ? "tooltipWrapper relative statusRejected"
                                  : "tooltipWrapper relative" &&
                                    data?.status === "Dismissed"
                                  ? "tooltipWrapper relative statusDismissed"
                                  : "tooltipWrapper relative"
                              }
                              id="newDisable"
                            >
                              <i className="fa-light fa-circle-xmark cursor-pointer text-red-700"></i>
                              <div className="tooltipHover reject hidden border-1 border-solid border-red-400 bg-red-50 text-red-500 py-1 px-3 rounded-md text-xs font-semibold">
                                Reject
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="w-100 flex items-center justify-end py-3">
            <Pagination
              count={Math.ceil(leaveApplicationData?.totalItems / limit)}
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
            <div className="totalNoOfPages ms-3 after:content-[''] after:ml-3 after:text-gray-500 after:border-r after:border-r-gray-500">
              On Page:{" "}
              <span className="totalCount">
                {leaveApplicationData?.totalItems ? page : 0} of{" "}
                {Math.ceil(leaveApplicationData?.totalItems / limit)}
              </span>
            </div>
            {/* <label className="pl-3">|</label> */}
            <div className="flex items-center ml-3">
              <div className="totalNoOfPages flex items-center">
                Rows per page: <span className="totalCount"></span>
              </div>
              <ClickAwayListener onClickAway={() => setToggle(false)}>
                <div className="paginationLimitBox" onClick={handleChangeLimit}>
                  {limit}
                  {toggle ? (
                    <i className="fa-light fa-chevron-up ms-2"></i>
                  ) : (
                    <i className="fa-light fa-chevron-down ms-2"></i>
                  )}
                  {toggle && (
                    <ul className="paginationLimitBoxDropdown">
                      {[25, 50, 75, 100]?.map((list) => {
                        return (
                          <li
                            value={list}
                            onClick={(e) => {
                              setLimit(e?.target?.value);
                              setPage(1);
                            }}
                          >
                            {list}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </ClickAwayListener>
            </div>
          </div>
        </div>
      )}
      {open && (
        <ApplyAllocationDialog
          setOpen={setOpen}
          open={open}
          getData={getData}
        />
      )}
      {confirmStatus && (
        <AllocationConfirmDialog
          confirmStatus={confirmStatus}
          setConfirmStatus={setConfirmStatus}
          handleUpdateStatus={handleUpdateStatus}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          setSelectedEmpData={setSelectedEmpData}
          updateIsFetching={updateIsFetching}
        />
      )}
      {applyFilter && (
        <FilterAllocation
          applyFilter={applyFilter}
          setApplyFilter={setApplyFilter}
          employeeList={employeeList}
          leaveType={LeaveType}
          status={leaveStatus}
          setPage={setPage}
          setFilterValues={setFilterValues}
          filterValues={filterValues}
        />
      )}
      {allocationView && (
        <ViewAllocationDialog
          allocationView={allocationView}
          setAllocationView={setAllocationView}
          updateData={updateData}
          setUpdateData={setUpdateData}
        />
      )}
    </>
  );
};

export default Allocation;
