import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Avatar,
  ClickAwayListener,
  Pagination,
  TextField,
} from "@mui/material";
import stringAvatar from "../../../PMS/AvatarFunction";
import { useDispatch, useSelector } from "react-redux";
import "../../../MyTeam/MyTeam.css";
import dayjs from "dayjs";
import { Stack } from "@mui/system";
import Loader from "../../../Loader/Loader";
import { fetchTeamAttendanceData } from "../../../../store/slices/TeamSlice";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import http from "../../../../utils/http";
import fileDownload from "js-file-download";
import Tooltip from "@mui/material/Tooltip";

const EmployeePresentAbsentStatus = () => {
  const dispatch = useDispatch();
  const [reportees, setReportees] = useState(false);
  const [search, setSearch] = useState("");
  const [toggleVal, setToggleVal] = useState("Direct Reporting");
  const [status, setStatus] = useState(false);
  const [statusVal, setStatusVal] = useState("Active");
  const [pageLimit, setPageLimit] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState("firstName");
  const [order, setOrder] = useState("ASC");
  const [isFetching, setIsFetching] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [openTooltip, setOpenTooltip] = useState({});
  const [leaveLabel, setLeaveLabel] = useState(false);
  const [monthCompare, setMonthCompare] = useState(dayjs());
  const [fromDate, setFromDate] = useState(
    dayjs()?.startOf("month")?.format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(
    dayjs()?.endOf("month")?.format("YYYY-MM-DD")
  );

  const [currDate, setCurrDate] = useState(dayjs()?.format("DD-MMM-YYYY"));

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const fetchAttData = useCallback(async () => {
    const params = {
      startDate: dayjs(fromDate)?.format("YYYY-MM-DD"),
      endDate: dayjs(toDate)?.format("YYYY-MM-DD"),
      reportType: toggleVal,
      limit: limit,
      page: page,
      sortBy: sortBy,
      order: order,
      search: search,
      attendanceStatus: statusVal,
    };
    setIsFetching(true);
    const result = await dispatch(fetchTeamAttendanceData(params));
    if (result?.meta?.requestStatus === "fulfilled") {
      setIsFetching(false);
    } else {
      setIsFetching(false);
    }
  }, [
    dispatch,
    limit,
    order,
    page,
    search,
    sortBy,
    fromDate,
    toDate,
    toggleVal,
    statusVal
  ]);

  useEffect(() => {
    toDate && fetchAttData();
  }, [
    fromDate,
    sortBy,
    order,
    page,
    search,
    limit,
    toDate,
    fetchAttData,
    toggleVal,
    statusVal
  ]);

  const empAPStatus = useSelector(
    (state) => state?.teamData?.teamAttendanceData?.attendanceData
  );

  useEffect(() => {
    if (empAPStatus) {
      setPageCount(empAPStatus?.totalRecords);
      setCount(empAPStatus?.totalPages);
    }
  }, [empAPStatus]);

  const empAttLabel = [
    {
      label: "A - Absent",
      labelColor: "#EA0000",
    },
    {
      label: "P - Present",
      labelColor: "#008104",
    },
    {
      label: "H - Half Day",
      labelColor: "#C39000",
    },
    {
      label: "OT - Overtime",
      labelColor: "#008104",
    },
    {
      label: "W - Weekly Off",
      labelColor: "#FFBC00",
    },
    {
      label: "NA - Not Applicable",
      labelColor: "#555555",
    },
  ];
  const empAttLeaveLabelData = [
    {
      label: "SL - Sick Leave",
      labelColor: "#EA0000",
    },
    {
      label: "ML - Maternity Leave",
      labelColor: "#EA0000",
    },
    {
      label: "PL - Paternity Leave",
      labelColor: "#EA0000",
    },
    {
      label: "BL - Bereavement Leave",
      labelColor: "#EA0000",
    },
    {
      label: "RH - Restricted Holiday",
      labelColor: "#EA0000",
    },
    {
      label: "EL - Earned Leave",
      labelColor: "#EA0000",
    },
    {
      label: "CO - Compensatory Off",
      labelColor: "#EA0000",
    },
    {
      label: "LWP - Leave Without Pay",
      labelColor: "#EA0000",
    },
  ];

  const attendanceStatus = () => {
    const data =
      empAttLabel &&
      empAttLabel?.map((item, index) => (
        <div key={index} className="text-sm inline-flex">
          <span
            style={{ background: item?.labelColor }}
            className="mr-1 w-1 h-5"
          ></span>
          {item?.label}
        </div>
      ));
    return data;
  };

  const empAttLabelStatus = () => {
    const data = leaveLabel && (
      <ul className="bg-white w-60 border-1 border-gray-200 rounded-md absolute bottom-8 left-0 z-10">
        {empAttLeaveLabelData?.map((list, index) => (
          <li
            key={index}
            className="flex items-center gap-2 border-b border-gray-200 px-3 py-2 text-sm"
          >
            <div
              style={{ background: list?.labelColor }}
              className="mr-1 w-1 h-5"
            ></div>{" "}
            {list?.label}
          </li>
        ))}
      </ul>
    );
    return data;
  };

  const handleAttendanceDownload = async () => {
    try {
      setDownloading(true);
      const response = await http({
        url: `team/v1/team-attendance-excel`,
        method: "GET",
        responseType: "blob",
        params: {
          startDate: dayjs(fromDate)?.format("YYYY-MM-DD"),
          endDate: dayjs(toDate)?.format("YYYY-MM-DD"),
          reportType: toggleVal,
          sortBy: sortBy,
          order: order,
          search: search,
          attendanceStatus: statusVal
        },
      });
      fileDownload(
        response.data,
        `Attendance_${dayjs(fromDate).format("MMM-YYYY")}.xlsx`
      );
      setDownloading(false);
    } catch (error) {
      setDownloading(false);
    }
  };
  const ref = useRef(null);
  const handleSearchChange = (event) => {
    const delayInput = setTimeout(() => {
      setSearch(event?.target?.value);
    }, 800);
    setPage(1);
    return () => clearTimeout(delayInput);
  };

  return (
    <>
      <div className="border-1 rounded-md mt-3 relative">
        <div className="bg-white flex items-center justify-between py-2 px-3 border-b border-gray-200">
          <div className="text-gray-500 relative">
            <span
              className="text-gray-500 cursor-text"
              onClick={() => ref.current.focus()}
            >
              <i className="fa-light fa-magnifying-glass"></i>
            </span>
            <input
              ref={ref}
              onChange={handleSearchChange}
              type="text"
              name="search input"
              placeholder="Search By Employee"
              className="text-md border-none pl-2"
            />
          </div>
          <div className="flex items-center gap-3">
            <ClickAwayListener onClickAway={() => setReportees(false)}>
              <div className="relative ml-3">
                <div
                  onClick={() => setReportees((prev) => !prev)}
                  className="flex items-center justify-between gap-3 w-52 text-gray-500 text-md py-1 px-3 bg-white border-1 border-gray-400 rounded-md cursor-pointer"
                >
                  <div>
                    {toggleVal == "Direct Reporting" ? "DIRECT" : "INDIRECT"}
                  </div>
                  <i
                    className={
                      reportees
                        ? "fa-light fa-chevron-up"
                        : "fa-light fa-chevron-down"
                    }
                  ></i>
                </div>
                {reportees && (
                  <ul className="bg-white w-full border-1 border-gray-200 rounded-md absolute top-9 right-0 z-10">
                    <li
                      onClick={(e) => {
                        setReportees(false);
                        setToggleVal("Direct Reporting");
                        setPage(1);
                      }}
                      className="border-b border-gray-200 px-3 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                    >
                      DIRECT
                    </li>
                    <li
                      onClick={(e) => {
                        setReportees(false);
                        setToggleVal("Indirect Reporting");
                        setPage(1);
                      }}
                      className="border-b border-gray-200 px-3 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                    >
                      INDIRECT
                    </li>
                  </ul>
                )}
              </div>
            </ClickAwayListener>
            <ClickAwayListener onClickAway={() => setStatus(false)}>
              <div className="relative ml-3">
                <div
                  onClick={() => setStatus((prev) => !prev)}
                  className="flex items-center justify-between gap-3 w-52 text-gray-500 text-md py-1 px-3 bg-white border-1 border-gray-400 rounded-md cursor-pointer"
                >
                  <div>
                    {statusVal == "Active" ? "Active" : "Left"}
                  </div>
                  <i
                    className={
                      status
                        ? "fa-light fa-chevron-up"
                        : "fa-light fa-chevron-down"
                    }
                  ></i>
                </div>
                {status && (
                  <ul className="bg-white w-full border-1 border-gray-200 rounded-md absolute top-9 right-0 z-10">
                    <li
                      onClick={(e) => {
                        setStatus(false);
                        setStatusVal("Active");
                      }}
                      className="border-b border-gray-200 px-3 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                    >
                      Active
                    </li>
                    <li
                      onClick={(e) => {
                        setStatus(false);
                        setStatusVal("Left");
                      }}
                      className="border-b border-gray-200 px-3 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                    >
                      Left
                    </li>
                  </ul>
                )}
              </div>
            </ClickAwayListener>
            <div className="fromToDate">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={fromDate}
                  label="From Date"
                  maxDate={currDate}
                  onChange={(newValue) => {
                    setFromDate(dayjs(newValue)?.format("DD-MMM-YYYY"));
                    setMonthCompare(newValue);
                    (dayjs(newValue) < dayjs(monthCompare)?.startOf("month") ||
                      dayjs(newValue) > dayjs(toDate)) &&
                      setToDate(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  )}
                  inputFormat={"DD-MMM-YYYY"}
                  disableMaskedInput
                />
              </LocalizationProvider>
            </div>
            <div className="fromToDate relative">
              {toDate == null && (
                <div className="text-xs text-red-600 absolute bottom-9">
                  To date is mandatory field!
                </div>
              )}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  slotProps={{ size: "small" }}
                  label="To Date"
                  value={toDate}
                  minDate={fromDate}
                  className={toDate == null ? "inputLabelCenterAlign" : ""}
                  maxDate={dayjs(fromDate)?.add(30, "day")}
                  onChange={(newValue) => {
                    setToDate(dayjs(newValue)?.format("DD-MMM-YYYY"));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  )}
                  inputFormat={"DD-MMM-YYYY"}
                  disableMaskedInput
                />
              </LocalizationProvider>
            </div>
            <div
              onClick={() => {
                handleAttendanceDownload();
              }}
              className="tooltipWrapper relative text-blue-600 text-md py-1 px-3 bg-inherit border-1 border-blue-400 rounded-md cursor-pointer hover:bg-blue-600 hover:text-white"
            >
              <i className="fa-light fa-download"></i>
              <div className="tooltipHover edit hidden border-1 border-solid border-blue-400 bg-blue-50 text-blue-500 py-1 px-3 rounded-md text-xs font-semibold">
                Download in .xlsx
              </div>
            </div>
            {/* <ClickAwayListener onClickAway={() => setToggleMenu(false)}>
            <div className="relative">
              <div
                onClick={() => setToggleMenu((prev) => !prev)}
                className="bg-gray-50 text-gray-600 cursor-pointer border-1 border-gray-300 w-8 h-8 flex items-center justify-center rounded-md"
              >
                <FontAwesomeIcon
                  icon={faEllipsisStrokeVertical}
                  className="text-lg"
                />
              </div>
              {toggleMenu && (
                <ul className="bg-white w-44 border-1 border-gray-200 rounded-md absolute top-9 right-0 z-10">
                  <li
                    onClick={() => {
                      handleAttendanceDownload();
                      setToggleMenu(false);
                    }}
                    className="border-b border-gray-200 px-3 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                  >
                    <FontAwesomeIcon
                      icon={faFileSpreadsheet}
                      className="pr-1 text-gray-500 cursor-pointer"
                    />
                    Export in XLS
                  </li>
                  <li
                    onClick={(e) => {
                      setToggleMenu(false);
                    }}
                    className="border-b border-gray-200 px-3 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                  >
                    <FontAwesomeIcon
                      icon={faFileCsv}
                      className="pr-1 text-gray-500 cursor-pointer"
                    />
                    Export in CSV
                  </li>
                </ul>
              )}
            </div>
          </ClickAwayListener> */}
          </div>
        </div>
        <div className="monthlyAttendanceData empAbsentPresentContainer">
          {(isFetching || downloading) && <Loader />}
          <table className="table m-0">
            <thead>
              <tr>
                <th scope="col" className="fixedRow theadFirstItem">
                  <div
                    onClick={() => setOrder(order == "ASC" ? "DESC" : "ASC")}
                    className="flex items-center justify-between cursor-pointer"
                  >
                    <p>Employee</p>
                    <i
                      className={
                        order === "ASC"
                          ? "fa-light fa-arrow-down-a-z text-md"
                          : "fa-light fa-arrow-up-z-a text-md"
                      }
                    />
                  </div>
                </th>
                {empAPStatus &&
                  empAPStatus?.datesInRange?.map((v, index) => (
                    <th key={index} scope="col">
                      <div className="flex items-center justify-center flex-column leading-5">
                        <div className="">{dayjs(v)?.format("DD MMM")}</div>
                        <div className="text-gray-400 text-xs">
                          {dayjs(v)?.format("ddd")}
                        </div>
                      </div>
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {empAPStatus && empAPStatus ? (
                empAPStatus?.attendanceData?.map((val, index) => {
                  return (
                    <tr key={index}>
                      <td scope="row" className="fixedRow">
                        <div className="mnthAttWrapper">
                          <div className="mnthAttEmpIcon">
                            <Avatar
                              className="my-2"
                              {...stringAvatar(`${val?.employee_name}`)}
                              style={{
                                width: "30px",
                                height: "30px",
                                fontSize: "14px",
                              }}
                              alt="Employee Image"
                            />
                          </div>
                          <div className="text-left">
                            <p className="font-semibold">
                              {val?.employee_name}
                            </p>
                            <p className="text-gray-500 text-sm">
                              #{val?.EmployeeId}
                            </p>
                          </div>
                        </div>
                      </td>
                      {Object?.entries(val?.monthlyAttendanceData)?.map(
                        ([key, value], index) => (
                          <td key={key} className={value?.offDay && "weekOff"}>
                            <div
                              onClick={(e) =>
                                setOpenTooltip({
                                  id: e?.target?.id,
                                  empId: val?.EmployeeId,
                                })
                              }
                              id={index}
                              className={`${value?.attendanceStatus == "Absent"
                                ? "absent cursor-pointer"
                                : value?.attendanceStatus == "Present"
                                  ? "present cursor-pointer"
                                  : value?.attendanceStatus !== "Absent" &&
                                    value?.offDay == true
                                    ? "weeklyOff"
                                    : value?.attendanceStatus == "Not Applicable"
                                      ? "notApplicable cursor-pointer"
                                      : value?.attendanceStatus == "Half Day"
                                        ? "halfDay cursor-pointer"
                                        : value?.leaveData[0]?.leaveType?.match(
                                          /\b(\w)/g
                                        )
                                          ? "leave cursor-pointer"
                                          : null
                                }`}
                            >
                              {value?.attendanceStatus == "Absent"
                                ? "A"
                                : value?.attendanceStatus == "Present"
                                  ? "P"
                                  : value?.attendanceStatus == "Half Day"
                                    ? "H"
                                    : value?.attendanceStatus !== "Absent" &&
                                      value?.offDay == true
                                      ? "W"
                                      : value?.attendanceStatus == "Not Applicable"
                                        ? "NA"
                                        : value?.attendanceStatus !== "Absent" ||
                                          value?.attendanceStatus !== "Present"
                                          ? value?.leaveData[0]?.leaveType?.match(
                                            /\b(\w)/g
                                          )
                                          : ""}

                              {openTooltip?.id == index &&
                                openTooltip?.empId == val?.EmployeeId &&
                                (value?.attendanceStatus == "Present" ||
                                  value?.attendanceStatus == "Half Day" ||
                                  value?.attendanceStatus == "Absent") ? (
                                <ClickAwayListener
                                  onClickAway={() => setOpenTooltip(false)}
                                >
                                  <Tooltip
                                    PopperProps={{
                                      disablePortal: true,
                                    }}
                                    onClose={() => setOpenTooltip(false)}
                                    open={openTooltip}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={
                                      <div className="tooltipContainer">
                                        <div className="tooltipContent">
                                          <label className="text-small">
                                            Attendance Status:
                                          </label>
                                          <div className="font-medium">
                                            {value?.attendanceStatus ??
                                              "-- : --"}
                                          </div>
                                        </div>
                                        <div className="tooltipContent">
                                          <label className="text-small">
                                            In Time:
                                          </label>
                                          <div className="font-medium">
                                            {value?.InTime
                                              ? dayjs(value?.InTime, "YYYY-MM-DD hh:mm:ss")?.format(
                                                "hh:mm A"
                                              )
                                              : "-- : --"}
                                          </div>
                                        </div>
                                        <div className="tooltipContent">
                                          <label className="text-small">
                                            Out Time:
                                          </label>
                                          <div className="font-medium">
                                            {value?.OutTime
                                              ? dayjs(value?.OutTime, "YYYY-MM-DD hh:mm:ss")?.format(
                                                "hh:mm A"
                                              )
                                              : "-- : --"}
                                          </div>
                                        </div>
                                        <div className="tooltipContent">
                                          <label className="text-small">
                                            Total Hours:
                                          </label>
                                          <div className="font-medium">
                                            {value?.TotalHours
                                              ? `${value?.TotalHours} hrs`
                                              : "-- : --"}
                                          </div>
                                        </div>
                                      </div>
                                    }
                                  ></Tooltip>
                                </ClickAwayListener>
                              ) : openTooltip?.id == index &&
                                openTooltip?.empId == val?.EmployeeId &&
                                value?.leaveData[0]?.leaveType?.match(
                                  /\b(\w)/g
                                ) ? (
                                <ClickAwayListener
                                  onClickAway={() => setOpenTooltip(false)}
                                >
                                  <Tooltip
                                    PopperProps={{
                                      disablePortal: true,
                                    }}
                                    onClose={() => setOpenTooltip(false)}
                                    open={openTooltip}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={
                                      <div className="tooltipContainer">
                                        <div className="tooltipContent">
                                          <label className="text-small">
                                            Attendance Status:
                                          </label>
                                          <div className="font-medium">
                                            {value?.attendanceStatus ??
                                              "-- : --"}
                                          </div>
                                        </div>
                                        <div className="tooltipContent">
                                          <label className="text-small">
                                            Leave Type:
                                          </label>
                                          <div className="font-medium">
                                            {value?.leaveData[0]?.leaveType ??
                                              "-- : --"}
                                          </div>
                                        </div>
                                        <div className="tooltipContent">
                                          <label className="text-small">
                                            Half Day Info:
                                          </label>
                                          <div className="font-medium">
                                            {value?.halfDayInfo ?? "-- : --"}
                                          </div>
                                        </div>
                                        <div className="tooltipContent">
                                          <label className="text-small">
                                            Status:
                                          </label>
                                          <div className="font-medium">
                                            {value?.leaveData[0]?.leaveStatus ??
                                              "-- : --"}
                                          </div>
                                        </div>
                                        <div className="tooltipContent">
                                          <label className="text-small">
                                            In Time:
                                          </label>
                                          <div className="font-medium">
                                            {value?.InTime
                                              ? dayjs(
                                                value?.InTime,
                                                "YYYY-MM-DD hh:mm:ss"
                                              )?.format("hh:mm A")
                                              : "-- : --"}
                                          </div>
                                        </div>
                                        <div className="tooltipContent">
                                          <label className="text-small">
                                            Out Time:
                                          </label>
                                          <div className="font-medium">
                                            {value?.OutTime
                                              ? dayjs(
                                                value?.OutTime,
                                                "YYYY-MM-DD hh:mm:ss"
                                              )?.format("hh:mm A")
                                              : "-- : --"}
                                          </div>
                                        </div>
                                        <div className="tooltipContent">
                                          <label className="text-small">
                                            Total Hours:
                                          </label>
                                          <div className="font-medium">
                                            {value?.TotalHours
                                              ? `${value?.TotalHours} hrs`
                                              : "-- : --"}
                                          </div>
                                        </div>
                                      </div>
                                    }
                                  ></Tooltip>
                                </ClickAwayListener>
                              ) : null}
                            </div>
                          </td>
                        )
                      )}
                    </tr>
                  );
                })
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3 p-3">
            {attendanceStatus()}
            <ClickAwayListener onClickAway={() => setLeaveLabel(false)}>
              <div className="relative">
                <i
                  className="fa-light fa-ellipsis-vertical px-3 cursor-pointer"
                  onClick={() => setLeaveLabel((prev) => !prev)}
                ></i>
                {empAttLabelStatus()}
              </div>
            </ClickAwayListener>
          </div>
          <div className="px-3 bg-white border-t">
            <div className="timeSheetPagination bg-transparent">
              <Stack spacing={2}>
                <Pagination
                  count={empAPStatus?.totalPages}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                  className="paginationCount"
                />
              </Stack>
              <div className="totalNoOfPages ms-3">
                On Page:
                <span className="totalCount">
                  {page} of {count}
                </span>
              </div>
              <label className="PaginationLabelWhere">|</label>
              <div className="totalNoOfPages">
                Total Count: <span className="totalCount">{pageCount}</span>
              </div>
              <label className="PaginationLabelWhere">|</label>
              <div className="totalNoOfPages">
                Limit: <span className="totalCount"></span>
              </div>
              <ClickAwayListener onClickAway={() => setPageLimit(false)}>
                <div
                  className="paginationLimitBox"
                  onClick={() => setPageLimit(!pageLimit)}
                >
                  {limit}
                  {pageLimit ? (
                    <i className={"fa-light fa-chevron-up ms-2"}></i>
                  ) : (
                    <i className={"fa-light fa-chevron-down ms-2"}></i>
                  )}
                  {pageLimit && (
                    <ul className="paginationLimitBoxDropdown">
                      {[25, 50, 75, 100]?.map((list, index) => {
                        return (
                          <li
                            key={index}
                            value={list}
                            onClick={(e) => (
                              setLimit(e?.target?.value), setPage(1)
                            )}
                          >
                            {list}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </ClickAwayListener>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeePresentAbsentStatus;
