import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "../../utils/http";
import END_POINTS from "../../constants/endpoints";
import { toast } from "react-toastify";

export const fetchMyTeamClientList = createAsyncThunk(
  END_POINTS.GET_MY_TEAM_CLIENT_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.GET_MY_TEAM_CLIENT_LIST}`);
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchMyTeamProjectList = createAsyncThunk(
  END_POINTS.GET_MY_TEAM_PROJECT_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_MY_TEAM_PROJECT_LIST}`,
        {
          params: {
            isActive: params?.isActive ?? "active",
            clientId: params?.clientId ?? "All",
            search: params?.search ?? "",
            sort: params?.sort ?? "ASC",
            limit: params?.limit ?? 25,
            page: params?.page ?? 1,
          },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchMyTeamProjectInfo = createAsyncThunk(
  END_POINTS.GET_MY_TEAM_PROJECT_INFO,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_MY_TEAM_PROJECT_INFO}/${params}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchMyTeamEmployeeProjectList = createAsyncThunk(
  END_POINTS.GET_MY_TEAM_EMPLOYEE_PROJECT_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_MY_TEAM_EMPLOYEE_PROJECT_LIST}/${params}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchMyTeamEmployeeTimeSheetList = createAsyncThunk(
  END_POINTS.GET_MY_TEAM_EMPLOYEE_TIMESHEET_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_MY_TEAM_EMPLOYEE_TIMESHEET_LIST}/${params?.employee_id}/${params?.project_id}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchAttendanceCheckInCheckOutData = createAsyncThunk(
  END_POINTS.ATTENDANCE_BY_DATE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.ATTENDANCE_BY_DATE}`, {
        params: {
          attendanceDate: params?.attendanceDate,
          page: params?.page,
          limit: params?.limit,
          type: params?.type,
          sortBy: params?.sortBy,
          order: params?.order,
          search: params?.search,
          attendanceStatus: params?.attendanceStatus
        },
      });
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

const initialState = {
  clientList: {
    clientListData: [],
    isFetching: false,
  },
  projectList: {
    projectListData: [],
    isFetching: false,
  },
  projectInfo: {
    projectInfoData: [],
    isFetching: false,
  },
  employeeProjectList: {
    employeeProjectListData: [],
    isFetching: false,
  },
  employeeTimeSheetList: {
    employeeTimeSheetListData: [],
    isFetching: false,
  },
  attendanceCheckInCheckOutList: {
    attendanceCheckInCheckOutData: [],
    isFetching: false,
  },
  loading: false,
  error: null,
  success: false,
};

export const MyTeamSlice = createSlice({
  name: "MyTeamSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyTeamClientList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.clientList = {
          isFetching: true,
          clientListData: null,
        };
      })
      .addCase(fetchMyTeamClientList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.clientList = {
          isFetching: false,
          clientListData: payload,
        };
        state.error = null;
      })
      .addCase(fetchMyTeamClientList.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.clientList = {
          isFetching: false,
          clientListData: null,
        };
        state.success = false;
      })
      .addCase(fetchMyTeamProjectList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.projectList = {
          isFetching: true,
          projectListData: null,
        };
      })
      .addCase(fetchMyTeamProjectList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.projectList = {
          isFetching: false,
          projectListData: payload,
        };
        state.error = null;
      })
      .addCase(fetchMyTeamProjectList.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.projectList = {
          isFetching: false,
          projectListData: null,
        };
        state.success = false;
      })
      .addCase(fetchMyTeamProjectInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.projectInfo = {
          isFetching: true,
          projectInfoData: null,
        };
      })
      .addCase(fetchMyTeamProjectInfo.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.projectInfo = {
          isFetching: false,
          projectInfoData: payload,
        };
        state.error = null;
      })
      .addCase(fetchMyTeamProjectInfo.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.projectInfo = {
          isFetching: false,
          projectInfoData: null,
        };
        state.success = false;
      })
      .addCase(fetchMyTeamEmployeeProjectList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.employeeProjectList = {
          isFetching: true,
          employeeProjectListData: null,
        };
      })
      .addCase(
        fetchMyTeamEmployeeProjectList.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.employeeProjectList = {
            isFetching: false,
            employeeProjectListData: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        fetchMyTeamEmployeeProjectList.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.employeeProjectList = {
            isFetching: false,
            employeeProjectListData: null,
          };
          state.success = false;
        }
      )
      .addCase(fetchMyTeamEmployeeTimeSheetList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.employeeTimeSheetList = {
          isFetching: true,
          employeeTimeSheetListData: null,
        };
      })
      .addCase(
        fetchMyTeamEmployeeTimeSheetList.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.employeeTimeSheetList = {
            isFetching: false,
            employeeTimeSheetListData: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        fetchMyTeamEmployeeTimeSheetList.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.employeeTimeSheetList = {
            isFetching: false,
            employeeTimeSheetListData: null,
          };
          state.success = false;
        }
      )
      .addCase(fetchAttendanceCheckInCheckOutData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.attendanceCheckInCheckOutList = {
          isFetching: true,
          attendanceCheckInCheckOutData: null,
        };
      })
      .addCase(
        fetchAttendanceCheckInCheckOutData.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.attendanceCheckInCheckOutList = {
            isFetching: false,
            attendanceCheckInCheckOutData: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        fetchAttendanceCheckInCheckOutData.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.attendanceCheckInCheckOutList = {
            isFetching: false,
            attendanceCheckInCheckOutData: null,
          };
          state.success = false;
        }
      );
  },
});

export default MyTeamSlice.reducer;
