import _ from "lodash";
import { employeeSearch } from "../../../../store/slices/masterSlice";
import axios from "axios";
let cancelToken;

export const debouncedSearch = _.debounce(({ page, limit, search, toggleVal, statusVal }, dispatch) => {
  if (cancelToken) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = axios.CancelToken.source();
  dispatch(employeeSearch({ page, limit, search, toggleVal, statusVal, cancelToken }));
}, 300); // Adjust debounce delay as needed
