import React, { useEffect } from "react";
import "../ClientDashboard.css";
import TextFieldComponent from "../../MasterComponent/TextFieldComponent";
import { FormProvider, useForm } from "react-hook-form";
import DatePickerComponent from "../../MasterComponent/DatePickerComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  addEmployeesInProject,
  fetchAllCompanyEmployee,
} from "../../../store/slices/masterSlice";
import AutoCompleteComponent from "../../MasterComponent/AutoCompleteComponent";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import RadioGroupComponent from "../../MasterComponent/RadioGroupComponent";
import { getAllProjectByID } from "../../../store/slices/ProjectSlice";
import { toast } from "react-toastify";

const addTeamMemberSchema = yup?.object({
  employeeName: yup?.object()?.label("Project Name")?.required(),
  role: yup?.string()?.label("Role")?.required(),
  startDate: yup
    ?.string()
    ?.label("Start Date")
    ?.optional()
    ?.required()
    ?.nullable(),
  projectType: yup?.string()?.label("Project Type")?.required(),
});

const AddTeamMember = ({ addTeam, setAddTeam, getProjectsById, setLoad }) => {
  const dispatch = useDispatch();

  const methods = useForm({
    defaultValues: {
      employeeName: null,
      role: null,
      startDate: null,
      projectType: {
        label: null,
        value: null,
      },
    },
    resolver: yupResolver(addTeamMemberSchema),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = methods;

  const submitHandler = async (data) => {
    const submitData = {
      employees_list: [
        {
          employee_id: data?.employeeName?.value,
          employee_role: data?.role,
          project_type: data?.projectType,
          start_date: data?.startDate,
        },
      ],
      project_id: getProjectsById?.data?.project_id,
    };
    try {
      await dispatch(addEmployeesInProject(submitData));
      reset();
      setAddTeam(false);
      await dispatch(getAllProjectByID(getProjectsById?.data?.project_id));
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    dispatch(fetchAllCompanyEmployee());
  }, [dispatch]);

  const employeeList = useSelector(
    (state) => state?.masterList?.allCompanyEmployeesData?.response?.data
  );
  return (
    <>
      <div
        className={
          addTeam
            ? "createProjectDrawer addProject active"
            : "createProjectDrawer addProject"
        }
      >
        <div className="createProjectDrawerHeader">
          <div className="w-full whitespace-nowrap">
            <div className="text-lg mb-2 font-semibold flex items-center justify-between">
              <div>Add Team Member</div>
              <div
                className="headerClose"
                onClick={() => {
                  setAddTeam(false);
                  reset();
                }}
              >
                <i className="fa-light fa-xmark fa-lg"></i>
              </div>
            </div>
            <div className="text-sm text-blue-100">
              Get started by filling the information below to add new Team
              Member.
            </div>
          </div>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submitHandler)}>
            <div className={addTeam ? "active" : ""}>
              <div className="addProjectForm">
                <div className="formContainer whitespace-nowrap pt-4">
                  {/*  Project Detail form */}
                  <div className="row">
                    <div className="col-md-12">
                      <label
                        htmlFor="employeeName"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Employee Name <span className="text-red-500">*</span>:
                      </label>
                      <div className="create_form_input_div autoSelect mb-3">
                        <AutoCompleteComponent
                          name="employeeName"
                          control={control}
                          errors={errors}
                          options={
                            employeeList?.map((v) => ({
                              label: `${v?.EmployeeId} - ${v?.employee_name}`,
                              value: v?.EmployeeId,
                            })) ?? []
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label
                        htmlFor="role"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Role <span className="text-red-500">*</span>:
                      </label>
                      <div className="create_form_input_div mb-3">
                        <TextFieldComponent
                          name="role"
                          // onChangeHandler={() => trigger("role")}
                          control={control}
                          errors={errors}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="startDate"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Start Date <span className="text-red-500">*</span>
                      </label>
                      <DatePickerComponent
                        name="startDate"
                        control={control}
                        errors={errors}
                        backSpaceChange={false}
                        // onHandleChange={() => trigger("billingEndDate")}
                        disableMaskedInput={true}
                      />
                    </div>
                    <div className="col-md-12">
                      <label
                        htmlFor="projectType"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Project type <span className="text-red-500">*</span>
                      </label>
                      <RadioGroupComponent
                        control={control}
                        errors={errors}
                        hidden={false}
                        // handleRadioChange={() => trigger("projectType")}
                        name="projectType"
                        types={[
                          { label: "Primary", value: "Primary" },
                          { label: "Secondary", value: "Secondary" },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* {renderButton()} */}
            </div>
            <div className="text-end p-3  whitespace-nowrap">
              <button
                onClick={() => {
                  setAddTeam(false);
                  reset();
                }}
                type="button"
                className="inline-flex font-sm text-sm border-1 border-gray-600 rounded-md cursor-pointer hover:text-gray-50 hover:bg-gray-500 hover:border-gray-500 py-2 px-4"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="ml-3 inline-flex font-sm text-sm border-1 border-blue-600 rounded-md cursor-pointer bg-blue-600 text-white hover:text-gray-50 hover:bg-blue-500 hover:border-blue-500 py-2 px-4"
              >
                Submit
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
      <div
        className={
          addTeam ? "createProjectOverlay active" : "createProjectOverlay"
        }
        onClick={() => setAddTeam(false)}
      ></div>
    </>
  );
};

export default AddTeamMember;
