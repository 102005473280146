import { Avatar } from "@mui/material";
import React, { useState } from "react";
import stringAvatar from "../../PMS/AvatarFunction";
import Loader from "../../Loader/Loader";
import AddTeamMember from "./AddTeamMember";
import AddTeamApprover from "./AddTeamApprover";
import DeleteTeamMemberDialog from "./DeleteTeamMemberDialog";
import EditTeamMember from "./EditTeamMember";

const ProjectListTeam = ({ getProjectsById, isFetching, allProjectData }) => {
  const [addTeam, setAddTeam] = useState(false);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [Data, setData] = useState({});
  const [updateTeam, setUpdateTeam] = useState({});
  const [addApprover, setAddApprover] = useState(false);

  return (
    <div className="mt-4">
      <div className="row">
        <div className="col-md-6">
          <div className="projectTeams bg-white border-1 border-gray-100 rounded-lg relative overflow-hidden">
            <div className="p-3 flex items-center justify-between">
              {isFetching && <Loader />}
              <h3 className="font-semibold">
                Team Members
                <span className="ml-1">
                  {getProjectsById &&
                    `(${getProjectsById?.data?.team?.length ?? 0})`}
                </span>
              </h3>
              <div
                onClick={() => setAddTeam(true)}
                className="font-sm text-sm border-1 border-blue-300 rounded-md cursor-pointer text-blue-600 hover:text-gray-50 hover:bg-blue-500 hover:border-blue-500 py-1 px-3"
              >
                <i className="fa-light fa-plus pr-1"></i> Add Team Member
              </div>
            </div>
            <div className="teamsContainer px-3">
              <div className="row">
                {getProjectsById &&
                  getProjectsById?.data?.team?.map((list, key) => {
                    return (
                      <div key={key} className="col-12 col-md-6 mb-4">
                        <div className="flex items-center cursor-pointer gap-3 bg-gray-50  p-3 border-1 border-gray-200 rounded-md  text-sm h-100">
                          <div className="">
                            {list?.profileImage ? (
                              <Avatar
                                className="img-fluid"
                                style={{
                                  width: "64px",
                                  height: "64px",
                                  border: "1px solid #00000029",
                                  borderRadius: "50%",
                                  fontSize: "45px",
                                }}
                                src={list.profileImage}
                                alt={list.employee_name}
                              />
                            ) : (
                              list?.employee_name && (
                                <Avatar
                                  className="img-fluid"
                                  style={{
                                    width: "64px",
                                    height: "64px",
                                    border: "1px solid #00000029",
                                    borderRadius: "50%",
                                    fontSize: "45px",
                                  }}
                                  {...stringAvatar(list?.employee_name)}
                                  alt={list.employee_name}
                                />
                              )
                            )}
                          </div>
                          <div>
                            <div className="pt-1 mb-1">
                              {list?.employee_name}
                            </div>
                            <div className=" mb-1">
                              Role:{" "}
                              <span className="font-semibold">
                                {list?.employee_role}
                              </span>
                            </div>
                            <div className="flex items-center gap-2 text-left mb-1">
                              <div>Project Type:</div>
                              <div className="font-semibold">
                                {list?.project_type}
                              </div>
                            </div>
                            <div className="mb-1">
                              <i
                                onClick={() => {
                                  setEdit(true);
                                  setUpdateTeam(list);
                                }}
                                className="fa-light fa-pen-to-square text-blue-500 hover:text-blue-600 cursor-pointer"
                              ></i>

                              <span className="px-2"></span>
                              <i
                                className="fa-light fa-trash text-red-500 hover:text-red-600 cursor-pointer"
                                onClick={() => {
                                  setOpen(true);
                                  setData(list);
                                }}
                              ></i>
                              <span className="px-2"></span>
                              {/* <span
                                  className="border-1 border-blue-400 rounded-md text-blue-600 px-2 text-xs cursor-pointer"
                                  onClick={() => (
                                    setActiveTeamTask(list?.employeeId),
                                    setActiveProjectTab(2),
                                    setTeamData(projectTeamsmInfo[0])
                                  )}
                                >
                                  <i className="fa-light fa-eye text-blue-500 pr-1 cursor-pointer"
                                    ></i>
                                  View Details
                                </span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="projectTeams bg-white border-1 border-gray-100 rounded-lg relative">
            <div className="p-3 flex items-center justify-between">
              {isFetching && <Loader />}
              <h3 className="font-semibold">
                Team Approvers
                <span className="ml-1">
                  {getProjectsById &&
                    `(${getProjectsById?.data?.ProjectApprovers?.length ?? 0})`}
                </span>
              </h3>
              <div
                onClick={() => setAddApprover(true)}
                className="font-sm text-sm border-1 border-blue-300 rounded-md cursor-pointer text-blue-600 hover:text-gray-50 hover:bg-blue-500 hover:border-blue-500 py-1 px-3"
              >
                <i className="fa-light fa-plus pr-1"></i> Add Team Approver
              </div>
            </div>
            <div className="teamsContainer px-3">
              <div className="row">
                {getProjectsById &&
                  getProjectsById?.data?.ProjectApprovers?.map((list, key) => {
                    return (
                      <div
                        key={key}
                        className="col-xl-6 col-sm-12 mb-4"
                        // onClick={() => setTeamData(list)}
                      >
                        <div className="flex items-center gap-3 bg-gray-50 p-3 border-1 border-gray-200 rounded-md  text-sm h-100">
                          <div className="">
                            {list?.ProjectApproverNames?.profile_image ? (
                              <Avatar
                                className="img-fluid"
                                style={{
                                  width: "64px",
                                  height: "64px",
                                  border: "1px solid #00000029",
                                  borderRadius: "50%",
                                  fontSize: "45px",
                                }}
                                src={list?.ProjectApproverNames?.profile_image}
                                alt={list?.ProjectApproverNames?.employee_name}
                              />
                            ) : (
                              list?.ProjectApproverNames?.employee_name && (
                                <Avatar
                                  className="img-fluid"
                                  style={{
                                    width: "64px",
                                    height: "64px",
                                    border: "1px solid #00000029",
                                    borderRadius: "50%",
                                    fontSize: "45px",
                                  }}
                                  {...stringAvatar(
                                    list?.ProjectApproverNames?.employee_name
                                  )}
                                  alt={
                                    list?.ProjectApproverNames?.employee_name
                                  }
                                />
                              )
                            )}
                          </div>
                          <div>
                            <div className="pt-1 mb-1">
                              {list?.ProjectApproverNames?.employee_name}
                            </div>
                            <div className=" mb-1">
                              <span className="font-semibold capitalize">
                                {list?.ApprovalLevel?.approver_post
                                  ?.split("_")
                                  ?.join(" ")}
                              </span>
                            </div>
                            <div className="mb-1">
                              <i
                                className="fa-light fa-pen-to-square text-blue-600 cursor-pointer"
                                onClick={() => setAddApprover(true)}
                              ></i>
                              <span className="px-2"></span>
                              {/* <i className="fa-regular fa-trash text-red-600 cursor-pointer"></i> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {addTeam && (
        <AddTeamMember
          addTeam={addTeam}
          setAddTeam={setAddTeam}
          getProjectsById={getProjectsById}
        />
      )}
      {open && (
        <DeleteTeamMemberDialog
          open={open}
          setOpen={setOpen}
          Data={Data}
          setData={setData}
          projectId={getProjectsById?.data?.project_id}
        />
      )}
      <EditTeamMember
        edit={edit}
        setEdit={setEdit}
        updateTeam={updateTeam}
        getProjectsById={getProjectsById}
      />
      {addApprover && (
        <AddTeamApprover
          getProjectsById={getProjectsById}
          addApprover={addApprover}
          setAddApprover={setAddApprover}
          allProjectData={allProjectData}
        />
      )}
    </div>
  );
};

export default ProjectListTeam;
