import React from "react";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePickerComponent from "../../MasterComponent/DatePickerComponent";
import { useState } from "react";
import AutoCompleteComponent from "../../MasterComponent/AutoCompleteComponent";
import { useDispatch, useSelector } from "react-redux";
import { fetchLeaveAllocationDataV1 } from "../../../store/slices/leaveSlice";
import leaveAllocationSchema from "./reviewAllocationSchema";
import CONSTANTS from "../../../constants/constants";
import dayjs from "dayjs";
import { Avatar, ListItemAvatar } from "@mui/material";
import stringAvatar from "../../PMS/AvatarFunction";
import Loader from "../../Loader/Loader";
import { getEmployeesAccess } from "../../../utils/userPermission";
import { getFiscalYear } from "../../../utils/helper";
const ReviewAllocation = ({
  setVerifyLeaveAllocation,
  employeeList,
  leaveType = [],
  status,
}) => {
  const [back, setBack] = useState(false);
  const [leavetypes, setLeavetypes] = useState();
  leaveType = [{ leave_type_name: "All" }, ...leaveType];
  leaveType = leaveType?.filter(
    (data) => data?.leave_type_name !== "Work From Home"
  );
  let fiscalYears = [];
  let currentMonth = dayjs().month();
  let endYear = dayjs().year();
  if (currentMonth >= CONSTANTS.FISCAL_YEAR_START_MONTH) endYear = endYear + 1;
  for (let i = endYear; i > endYear - 5; i--) {
    fiscalYears.push(`${i - 1}-${i}`);
  }

  // End of  Fiscal Year
  const methods = useForm({
    defaultValues: {
      employee: null,
      from_date: null,
      to_date: null,
      leave_type: null,
      status: { label: "Approved", value: "Approved" },
      fiscal_year: { label: fiscalYears[0], value: fiscalYears[0] },
    },
    resolver: yupResolver(leaveAllocationSchema),
  });
  const employeeArrayList = employeeList?.map((data) => data?.EmployeeId);
  const accessPermission = getEmployeesAccess({
    employees: employeeArrayList,
    component: "Leave_Allocation_V1",
    accessType: "createAccess",
  });
  const employeeListData = employeeList
    ?.filter((data) => {
      return accessPermission?.[data?.EmployeeId];
    })
    ?.map((employeeData) => {
      return {
        EmployeeId: employeeData?.EmployeeId,
        employee_name: employeeData?.employee_name,
      };
    });
  const dispatch = useDispatch();

  const allocationData = useSelector(
    (state) => state?.leave?.leaveAllocationDataV1?.allocationData
  );
  const allocationIsFetching = useSelector(
    (state) => state?.leave?.leaveAllocationDataV1?.isFetching
  );
  let employeeDetails;
  if (allocationData?.data?.length) {
    employeeDetails = allocationData?.data[0];
  }

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    reset,
    formState: { errors, isDirty },
  } = methods;

  const submitHandler = async (data) => {
    dispatch(
      fetchLeaveAllocationDataV1({
        employeeId: data?.employee?.value,
        fromDate: data?.from_date,
        toDate: data?.to_date,
        filterValue: data?.leave_type?.value,
        status: "Approved",
        fiscal_year: data?.fiscal_year?.value,
      })
    );
    setBack(true);
    setLeavetypes(data?.leave_type?.value);
  };
  const handleEmployeeChange = () => {
    setValue("from_date", null);
    setValue("to_date", null);
    setValue("leave_type", null);
    setValue("status", null);
  };
  return (
    <div className="absolute top-0 right-0 z-10 w-100 h-screen">
      <div className="absolute top-0 left-0 z-10 w-100 h-100 bg-gray-950/40"></div>
      <div className="reviewAllocationSidePanel">
        <div
          className="flex p-3 items-center justify-center absolute top-0 right-0 text-xl cursor-pointer"
          onClick={() => {
            setVerifyLeaveAllocation(false);
            setBack(false);
          }}
        >
          <i className="fa-light fa-xmark"></i>
        </div>
        {!back && (
          <div className="">
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(submitHandler)}>
                <div className="verifyAllocationHeader p-3  bg-indigo-50 font-semibold text-lg">
                  Review Allocation Filter
                </div>
                <div className="p-3">
                  <div className="row mt-3">
                    <div className="col-lg-6 mb-3">
                      <label className="text-sm">
                        Search By Employee{" "}
                        <span className="create_form_asterisk">*</span>:
                      </label>
                      {employeeList?.length > 1 ? (
                        <AutoCompleteComponent
                          name="employee"
                          id="employee"
                          control={control}
                          errors={errors}
                          options={employeeListData?.map((v) => ({
                            label: `${v?.EmployeeId} - ${v?.employee_name}`,
                            value: v?.EmployeeId,
                          }))}
                          onChangeHandler={handleEmployeeChange}
                        />
                      ) : null}
                    </div>
                    <div className="col-lg-6 mb-3">
                      <label className="text-sm">Fiscal Year:</label>
                      {fiscalYears?.length > 1 ? (
                        <AutoCompleteComponent
                          name="fiscal_year"
                          id="fiscal_year"
                          control={control}
                          errors={errors}
                          disabled={watch("from_date") ? true : false}
                          options={fiscalYears?.map((v) => ({
                            label: v,
                            value: v,
                          }))}
                        />
                      ) : null}
                    </div>
                    <div className="col-lg-6 mb-3">
                      <label className="text-sm">
                        Leave Allocation Type{" "}
                        <span className="create_form_asterisk">*</span>:
                      </label>
                      {leaveType?.length > 1 ? (
                        <AutoCompleteComponent
                          name="leave_type"
                          id="leave_type"
                          control={control}
                          errors={errors}
                          options={leaveType?.map((v) => ({
                            label: `${v?.leave_type_name}`,
                            value: v?.leave_type_name,
                          }))}
                        />
                      ) : null}
                    </div>
                    {/* <div className="col-lg-6 mb-3">
                      <label className="text-sm">Status:</label>
                      {status?.length > 1 ? (
                        <AutoCompleteComponent
                          name="status"
                          id="status"
                          control={control}
                          errors={errors}
                          disabled={true}
                          options={status?.map((v) => ({
                            label: v,
                            value: v,
                          }))}
                        />
                      ) : null}
                    </div> */}
                    <div className="col-lg-6 mb-3">
                      <label className="text-sm">From Date:</label>
                      <DatePickerComponent
                        errors={errors}
                        control={control}
                        name="from_date"
                        id="from_date"
                        placeHolder="dd-mmm-yyyy"
                        disableMaskedInput={true}
                        backSpaceChange={false}
                        disabled={watch("fiscal_year") ? true : false}
                        onHandleChange={() => {
                          if (!watch("from_date")) setValue("to_date", null);
                        }}
                        maxDate={watch("to_date")}
                      />
                    </div>
                    <div className="col-lg-6 mb-3">
                      <label className="text-sm">To Date:</label>
                      <DatePickerComponent
                        errors={errors}
                        control={control}
                        name="to_date"
                        id="to_date"
                        placeHolder="dd-mmm-yyyy"
                        disableMaskedInput={true}
                        backSpaceChange={false}
                        disabled={watch("from_date") ? false : true}
                        minDate={watch("from_date")}
                      />
                    </div>
                    <div className="col-md-12 mb-3 flex gap-3 justify-end">
                      <button
                        onClick={() => {
                          reset({
                            employee: null,
                            status: null,
                            from_date: null,
                            to_date: null,
                            leave_type: null,
                            fiscal_year: null,
                          });
                        }}
                        className="border-solid border-1 border-gray-400 rounded-full text-gray-500 bg-gray-50 hover:text-red-50 hover:bg-gray-400 py-1 px-4 "
                        id="3"
                        type="button"
                      >
                        Clear
                      </button>
                      <button
                        // onClick={handleVerifySubmit}
                        className="border-solid border-1 border-green-500 rounded-full text-green-50 bg-green-500 hover:text-green-50 hover:bg-green-600 py-1 px-4 "
                        id={!isDirty ? "newDisable" : "submit"}
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        )}

        {back && (
          <div className="">
            {allocationIsFetching && <Loader />}
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(submitHandler)}>
                <div className="verifyAllocationHeader flex items-center gap-3 bg-indigo-50 font-semibold text-lg">
                  <div
                    onClick={() => setBack(false)}
                    className="p-3 hover:bg-white border-r border-r-indigo-200 cursor-pointer"
                  >
                    <i className="fa-light fa-chevron-left"></i> Back
                  </div>
                  Review Request
                </div>
                <div className="p-3 pt-4 leaveApplication">
                  <div className="border-b border-gray-200 pb-4">
                    <div className="flex flex-wrap items-center justify-between gap-3 ">
                      <div className="">
                        <div className="flex items-center px-3 py- text-gray-900 whitespace-nowrap dark:text-white">
                          <ListItemAvatar className="img-fluid">
                            {employeeDetails?.profileImage ? (
                              <Avatar
                                className="img-fluid updateLeaveProfileImg  w-10 h-10 rounded-full object-cover"
                                src={employeeDetails?.profileImage}
                                alt={employeeDetails?.firstName}
                              />
                            ) : (
                              employeeDetails?.firstName && (
                                <Avatar
                                  className="img-fluid updateLeaveProfileImg  w-10 h-10 rounded-full object-cover"
                                  {...stringAvatar(employeeDetails?.firstName)}
                                  alt={employeeDetails?.firstName}
                                />
                              )
                            )}
                          </ListItemAvatar>
                          <div className="pl-3">
                            <div className="inline-flex py-0 rounded-sm px-2 text-sm font-normal border border-gray-500 text-gray-700 bg-gray-50">
                              #{employeeDetails?.EmployeeId}
                            </div>
                            <div className="text-lg font-bold ">
                              {`${employeeDetails?.firstName ?? ""} ${
                                employeeDetails?.middleName ?? ""
                              } ${employeeDetails?.lastName ?? ""}`}
                            </div>
                            <div className="inline-flex  text-sm font-normal text-gray-600 ">
                              {employeeDetails?.designation}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="leaveAppFiscalYearData w-full">
                        <div className="flex justify-between flex-wrap gap-3 bg-white font-semibold border border-solid border-gray-300 rounded-md p-3">
                          <div className="font-semibold text-black">
                            Fiscal Year:{" "}
                            {getValues("fiscal_year")?.value
                              ? getValues("fiscal_year")?.value
                              : getFiscalYear()}
                          </div>
                          <div className="flex justify-between flex-wrap gap-4 bg-white font-semibold">
                            <div>
                              <p className="text-sm pb-2 whitespace-nowrap">
                                Total Leaves Allocated :
                              </p>
                              <p className="font-bold text-xl">
                                {Number(
                                  allocationData?.allocationData
                                    ?.total_leaves_allocated ?? 0
                                ).toFixed(2)}
                              </p>
                            </div>
                            <div>
                              <p className="text-sm pb-2 whitespace-nowrap">
                                Current Leave Balance :
                              </p>
                              <p className="font-bold text-xl text-green-500">
                                {Number(
                                  allocationData?.allocationData
                                    ?.current_leave_balance ?? 0
                                ).toFixed(2)}
                              </p>
                            </div>
                            <div>
                              <p className="text-sm pb-2 whitespace-nowrap">
                                Taken Leaves :
                              </p>
                              <p className="font-bold text-xl text-orange-500">
                                {Number(
                                  allocationData?.approvedLeaveDays ?? 0
                                ).toFixed(2)}
                              </p>
                            </div>
                            <div>
                              <p className="text-sm pb-2 whitespace-nowrap">
                                Open Leave :
                              </p>
                              <p className="font-bold text-xl text-indigo-500">
                                {Number(
                                  allocationData?.openLeaveDays ?? 0
                                ).toFixed(2)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pt-4">
                    <div className="flex items-center justify-between whitespace-nowrap flex-wrap gap-2">
                      <div>
                        <span className="text-gray-700 font-semibold text-sm">
                          {getValues("from_date")
                            ? "Date : "
                            : "Fiscal Year : "}
                        </span>
                        <span className="font-sm text-sm text-gray-500">
                          {getValues("from_date")
                            ? `${dayjs(getValues("from_date"))?.format(
                                "DD-MMM-YYYY"
                              )} to ${dayjs(getValues("to_date"))?.format(
                                "DD-MMM-YYYY"
                              )}`
                            : `${getValues("fiscal_year")?.value}`}
                        </span>
                      </div>
                      <div>
                        <span className="text-gray-700 font-semibold text-sm">
                          {leavetypes === "Earned Leave"
                            ? "Carry Forward Earned Leaves : "
                            : "Total Carry Forward Leaves : "}
                        </span>
                        <span className="font-sm text-sm text-gray-500 ml-1">
                          {Number(
                            allocationData?.allocationData?.carry_forward_leaves
                          )?.toFixed(2)}
                        </span>
                      </div>
                      <div>
                        <span className="text-gray-700 font-semibold text-sm">
                          Total Allocated Leaves :
                        </span>
                        <span className="font-sm text-sm text-gray-500 ml-1">
                          {Number(allocationData?.allocation)?.toFixed(2)}
                        </span>
                      </div>
                    </div>
                    <div className="reviewRequestTableContainer relative overflow-x-auto shadow-sm">
                      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 border border-indigo-200">
                        <thead className="text-xs text-gray-700 uppercase bg-indigo-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            <th scope="col" className="px-6 py-3">
                              Fiscal Year
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Leave Type
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Allocation Days
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Posting Date
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {employeeDetails?.EmployeeAllocation?.map((item) => (
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                              <td className="px-3 py-2">{item.fiscal_year}</td>
                              <td className="px-3 py-2">{item.leave_type}</td>
                              <td className="px-3 py-2">
                                {item.days_requested}
                              </td>
                              <td className="px-3 py-2">
                                {dayjs(item.allocation_date)?.format(
                                  "DD-MMM-YYYY"
                                )}
                              </td>
                              <td className="px-3 py-2">
                                <div className="flex items-center">
                                  <div
                                    className={`flex items-center rounded-sm leaveTableStatus`}
                                  >
                                    <div
                                      className={`h-2.5 w-2.5 rounded-full mr-2 ${item.status}`}
                                    ></div>
                                  </div>
                                  {item.status}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewAllocation;
