import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  fetchMiscellaneousDeductionsData,
  fetchMiscellaneousDeductionsTypes,
} from "../../../store/slices/payrollSlice";
import MUIDataTable from "mui-datatables";
import Loader from "../../Loader/Loader";
import SearchBox from "../../TopHeader/SearchBox/SearchBox";
import DownloadReport from "../DownloadReport";
import dayjs from "dayjs";

import http from "../../../utils/http";
import fileDownload from "js-file-download";
import CreateMiscDeduction from "./CreateMiscDeduction";
import { fetchEmployeeDataStore } from "../../../store/slices/employeeSlice";
import {
  buttonActionHandler,
  editActionHandler,
  rowActionHandler,
} from "./configDeduction";
import DeductionConfirmationPopup from "./DeductionConfirmationPopup";
import EditDeduction from "./EditDeduction";
import {
  componentDisplayAccess,
  getEmployeesAccess,
} from "../../../utils/userPermission";
import { Tooltip } from "@mui/material";
import { getTwoDecimalFloatNumber } from "../../../utils/helper";

export default function Deductions({ startDate, endDate }) {
  const dispatch = useDispatch();

  const [toggelDataUpdate, setToggelDataUpdate] = useState(false);
  const [page, setpage] = useState(0);
  const [limit, setlimit] = useState(15);
  const [sortBy, setsortBy] = useState("creation");
  const [order, setorder] = useState("DESC");
  const [search, setSearch] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [type, setType] = useState(null);
  const [row, setRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [create, setCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const miscDeductionState = useSelector(
    (state) => state?.payRoll?.msicDeductionData?.data ?? []
  );

  const totalItems = useSelector(
    (state) => state?.payRoll?.msicDeductionData?.totalItems ?? []
  );

  const miscDeductionData = miscDeductionState ?? [];
  const loading = useSelector((state) => state.payRoll.isFetching);
  const payrollMonth = dayjs(endDate)?.startOf("month")?.format("YYYY-MM-DD");
  const deductionType = useSelector(
    (state) => state?.payRoll?.msicDeductionTypes
  );
  const employeeData = useSelector((state) => state?.employee?.employeeList);
  let employeeList = miscDeductionData?.map((data) => data?.employee_id);
  const reimbursementAndDeductionAccessById = getEmployeesAccess({
    employees: employeeList,
    component: "Payroll_Reimbursement_Deduction",
  });
  const reimbursementAndDeductionAccess = componentDisplayAccess({
    component: "Payroll_Reimbursement_Deduction",
  });
  useEffect(() => {
    const params = {
      payrollMonth: payrollMonth,
      limit: limit,
      page: page + 1,
      sortBy: sortBy,
      order: order,
      search: search,
    };
    dispatch(fetchMiscellaneousDeductionsData(params));
    dispatch(fetchMiscellaneousDeductionsTypes());
    dispatch(fetchEmployeeDataStore({ module: "EMPLOYEE_MODULE" }));
  }, [
    startDate,
    page,
    dispatch,
    payrollMonth,
    sortBy,
    limit,
    search,
    order,
    toggelDataUpdate,
  ]);

  const columns = [
    {
      label: "Employee Id",
      name: "employee_id",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "Employee Name",
      name: "employee_name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "Manager",
      name: "manager_name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return value ? value : "-";
        },
      },
    },
    {
      label: "Deduction Name",
      name: "deduction_name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return value ? value : "-";
        },
      },
    },
    {
      label: "Deduction Amount",
      name: "deduction_amount",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return value ? getTwoDecimalFloatNumber(value) : "-";
        },
      },
    },
    {
      label: "Deduction Date",
      name: "deduction_date",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return dayjs(value)?.format("DD-MMM-YYYY");
        },
      },
    },
    {
      label: "Description",
      name: "description",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return value ? value : "-";
        },
      },
    },
    {
      label: "Hold Reason",
      name: "hold_reason",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return value ? value : "-";
        },
      },
    },
    {
      label: "Status",
      name: "status",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          let color = "white";
          let textColor = "black";
          if (value === "On Hold") {
            color = "#FFE8E8";
            textColor = "#8A0000";
          } else if (value === "Release") {
            color = "#CDFECD";
            textColor = "#006C00";
          }
          return (
            <span
              style={{
                padding: "5px 10px",
                borderRadius: "20px",
                backgroundColor: color,
                color: textColor,
              }}
            >
              {value ? value : "-"}
            </span>
          );
        },
      },
    },
    {
      label: "   ",
      name: "bonusAction",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowData = miscDeductionData?.[tableMeta?.rowIndex];
          return (
            <>
              <div className="actionIcon" style={{ justifyContent: "start" }}>
                {reimbursementAndDeductionAccessById?.[rowData?.employee_id]
                  ?.updateAccess ? (
                  <Tooltip title="Edit" arrow>
                    <span
                      type="edit"
                      style={{ marginRight: "10px", cursor: "pointer" }}
                      onClick={(e) => {
                        editActionHandler(rowData, setRow, setOpenEdit);
                      }}
                    >
                      <i
                        className="fa-light fa-pen"
                        style={{ pointerEvents: "none" }}
                      ></i>
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip title="Edit" arrow>
                    <span
                      type="edit"
                      style={{
                        marginRight: "10px",
                        pointerEvents: "none",
                        opacity: 0.5,
                      }}
                    >
                      <i className="fa-light fa-pen"></i>
                    </span>
                  </Tooltip>
                )}
                {reimbursementAndDeductionAccessById?.[rowData?.employee_id]
                  ?.updateAccess ? (
                  <Tooltip title="Hold" arrow>
                    <span
                      type="hold"
                      style={
                        rowData?.on_hold
                          ? {
                              marginRight: "10px",
                              // pointerEvents: "none",
                              opacity: 0.5,
                            }
                          : { marginRight: "10px", cursor: "pointer" }
                      }
                      onClick={(e) => {
                        !rowData?.on_hold &&
                          rowActionHandler(
                            e,
                            rowData,
                            setRow,
                            setType,
                            setOpen
                          );
                      }}
                    >
                      <i
                        className="fa-light fa-circle-pause"
                        style={{ pointerEvents: "none" }}
                      ></i>
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip title="Hold" arrow>
                    <span
                      type="hold"
                      style={{
                        marginRight: "10px",
                        // pointerEvents: "none",
                        opacity: 0.5,
                      }}
                    >
                      <i className="fa-light fa-circle-pause"></i>
                    </span>
                  </Tooltip>
                )}
                {reimbursementAndDeductionAccessById?.[rowData?.employee_id]
                  ?.updateAccess ? (
                  <Tooltip title="Release" arrow>
                    <span
                      type="release"
                      style={
                        !rowData?.on_hold
                          ? { opacity: 0.5 }
                          : { cursor: "pointer" }
                      }
                      onClick={(e) => {
                        rowData.on_hold &&
                          rowActionHandler(
                            e,
                            rowData,
                            setRow,
                            setType,
                            setOpen
                          );
                      }}
                    >
                      <i
                        className="fa-light fa-circle-check"
                        style={{ pointerEvents: "none" }}
                      ></i>
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip title="Release" arrow>
                    <span type="release" style={{ opacity: 0.5 }}>
                      <i className="fa-light fa-circle-check"></i>
                    </span>
                  </Tooltip>
                )}
              </div>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    selectToolbarPlacement: "none",
    selectableRows: "multiple",
    filterType: "dropdown",
    responsive: "standard",
    count: totalItems ?? 0,
    rowsPerPage: limit,
    page: page,
    search: false,
    download: false,
    print: false,
    serverSide: true,
    viewColumns: false,
    onChangePage: (currentPage) => {
      setpage(currentPage);
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setlimit(numberOfRows);
    },
    onColumnSortChange: (changedColumn, direction) => {
      setsortBy(changedColumn);
      setorder(direction.toUpperCase());
    },
    onRowSelectionChange: (rowsSelected, allRowsSelected) => {
      let allSelectedRows = [];
      allRowsSelected?.forEach((i) => {
        allSelectedRows.push(miscDeductionData[i?.index]);
      });
      setSelectedRows(allSelectedRows);
    },
    isRowSelectable: (index) => {
      const employeeId = miscDeductionData[index]?.employee_id;
      const hasUpdateAccess =
        reimbursementAndDeductionAccessById[employeeId]?.updateAccess;
      return hasUpdateAccess;
    },
    expandableRows: false,
  };

  const handleBonusDownload = async (e) => {
    try {
      setDownloading(true);

      const response = await http({
        url: `payroll/Misc-Deduction-Excel?payrollMonth=${payrollMonth}`,
        method: "GET",
        responseType: "blob",
      });

      fileDownload(
        response.data,
        `misc_deduction_${dayjs(payrollMonth).format("MMM-YYYY")}.xlsx`
      );
      setDownloading(false);
    } catch (error) {
      setDownloading(false);
    }
  };

  const params = {
    payrollMonth: payrollMonth,
    limit: limit,
    page: page + 1,
    sortBy: sortBy,
    order: order,
    search: search,
  };

  return (
    <>
      {loading && <Loader />}
      <div className="payrollContentDiv">
        <div className="payrollActionsDiv">
          <div className="compAttSearchBtn">
            <SearchBox setSearch={setSearch} />
          </div>
          <button
            type="release"
            className="btn primaryBtnOutline"
            onClick={(e) =>
              buttonActionHandler(e, setRow, setType, setOpen, selectedRows)
            }
            disabled={selectedRows.length <= 0}
          >
            Release
          </button>
          <button
            type="hold"
            className="btn primaryBtnOutline"
            onClick={(e) =>
              buttonActionHandler(e, setRow, setType, setOpen, selectedRows)
            }
            disabled={selectedRows.length <= 0}
          >
            Hold
          </button>
          <button
            type="button"
            className="btn primaryBtnOutline"
            disabled={!reimbursementAndDeductionAccess?.createAccess}
            onClick={() => {
              setCreate(true);
            }}
          >
            New +
          </button>

          <DownloadReport
            handleExcelDownload={handleBonusDownload}
            loading={downloading}
          />
        </div>
        <div className="container-fluid">
          <div className="payrollTableResponsive">
            <div className="payrollTableConteiner">
              <MUIDataTable
                data={miscDeductionData}
                columns={columns}
                options={options}
              />
            </div>
          </div>
        </div>

        {create && (
          <CreateMiscDeduction
            create={create}
            deductionType={deductionType}
            startDate={startDate}
            setCreate={setCreate}
            miscDeductionData={miscDeductionData}
            employeeData={employeeData}
            params={params}
          />
        )}

        {open && (
          <DeductionConfirmationPopup
            open={open}
            setOpen={setOpen}
            selectedRow={row}
            type={type}
            setToggelDataUpdate={setToggelDataUpdate}
            toggelDataUpdate={toggelDataUpdate}
          />
        )}

        {openEdit && (
          <EditDeduction
            openEdit={openEdit}
            setOpenEdit={setOpenEdit}
            deductionType={deductionType}
            startDate={startDate}
            selectedRow={row}
            params={{
              payrollMonth: payrollMonth,
              limit: limit,
              page: page + 1,
              sortBy: sortBy,
              order: order,
              search: search,
            }}
          />
        )}
      </div>
    </>
  );
}
