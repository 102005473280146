import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import AutoCompleteComponent from "../../MasterComponent/AutoCompleteComponent";
import { fetchEmployeeNameList } from "../../../store/slices/employeeSlice";
import { useDispatch, useSelector } from "react-redux";
import DatePickerComponent from "../../MasterComponent/DatePickerComponent";
import dayjs from "dayjs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// import { useState } from "react";

const FilterRegularizationSchema = yup.object().shape({
  search: yup.object().label("Employee Name").optional().nullable(true),
  status: yup.object().label("Status").optional().nullable(true),
  fromDate: yup.string().label("From Date").optional().nullable(true),
  toDate: yup.string().label("To Date").optional().nullable(true),
});

const RegularizationFilter = ({
  setApplyFilter,
  filterValues,
  setFilterValues,
  setPage,
}) => {
  // const initialApply =
  //   filterValues?.search == null &&
  //   filterValues?.status == null &&
  //   filterValues?.leave_type == null &&
  //   filterValues?.date == null &&
  //   filterValues?.date?.from_date == null &&
  //   filterValues?.date?.to_date == null
  //     ? true
  //     : false;
  const methods = useForm({
    defaultValues: {
      search: filterValues?.search ?? null,
      status: filterValues?.status ?? null,
      fromDate: filterValues?.fromDate ?? null,
      toDate: filterValues?.toDate ?? null,
    },
    resolver: yupResolver(FilterRegularizationSchema),
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchEmployeeNameList({ module: "Leave_Module_V1" }));
  }, [dispatch]);
  let employeeList = useSelector((state) => state?.employee?.employeeNameList);
  let statusList = ["Open", "Approved", "Rejected", "Dismissed"];

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isDirty },
    reset,
    watch,
  } = methods;
  const submitHandler = async (data) => {
    localStorage?.setItem("regularizationFilter", JSON.stringify(data));
    setFilterValues(data);
    setPage(1);
    setApplyFilter(false);
  };
  const handleEmployeeChange = () => {
    setValue("fromDate", null);
    setValue("toDate", null);
    setValue("status", null);
  };
  const areAllFieldsNull = Object?.values(watch())?.every(
    (value) => value === null
  );
  return (
    <div className="absolute top-0 right-0 z-10 w-100 h-screen">
      <div className="absolute top-0 left-0 z-10 w-100 h-100 bg-gray-950/40"></div>
      <div className="absolute top-0 right-0 z-20 w-3/6 h-100 bg-white">
        <div
          className="flex p-3 items-center justify-center absolute top-0 right-0 text-xl cursor-pointer"
          onClick={() => setApplyFilter(false)}
        >
          <i className="fa-light fa-xmark"></i>
        </div>
        <div className="">
          <div className="verifyAllocationHeader p-3  bg-indigo-50 font-semibold text-lg">
            Apply Filters
          </div>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(submitHandler)}>
              <div className="p-3">
                <div className="row mt-3">
                  <div className="col-md-12 mb-3">
                    <label>Employee Name:</label>
                    <AutoCompleteComponent
                      name="search"
                      id="search"
                      control={control}
                      errors={errors}
                      options={employeeList?.map((v) => ({
                        label: `${v?.EmployeeId} - ${v?.employee_name}`,
                        value: v?.EmployeeId,
                      }))}
                      onChangeHandler={handleEmployeeChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="text-sm">From Date:</label>
                    <DatePickerComponent
                      errors={errors}
                      control={control}
                      name="fromDate"
                      disableMaskedInput={true}
                      backSpaceChange={false}
                      onHandleChange={(val) => {
                        if (dayjs(val) > dayjs(watch("toDate")))
                          setValue("toDate", val);
                      }}
                      maxDate={new Date()}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="text-sm">To Date:</label>
                    <DatePickerComponent
                      errors={errors}
                      control={control}
                      name="toDate"
                      disableMaskedInput={true}
                      backSpaceChange={false}
                      disabled={watch("fromDate") ? false : true}
                      minDate={watch("fromDate")}
                      maxDate={new Date()}
                    />
                  </div>
                  <div className="col-md-6 mb-3 mt-3">
                    <label>Status:</label>
                    <AutoCompleteComponent
                      name="status"
                      id="status"
                      control={control}
                      errors={errors}
                      options={statusList?.map((v) => {
                        return {
                          label: v,
                          value: v,
                        };
                      })}
                    />
                  </div>
                  <div className="col-md-12 mb-3 flex gap-3 justify-end">
                    <button
                      className="border-solid border-1 border-gray-400 rounded-full text-gray-500 bg-gray-50 hover:text-red-50 hover:bg-gray-400 py-1 px-4 "
                      id="3"
                      type="button"
                      onClick={() => {
                        reset({
                          search: null,
                          status: null,
                          fromDate: null,
                          toDate: null,
                        });
                        localStorage?.setItem("regularizationFilter", null);
                      }}
                    >
                      Clear
                    </button>
                    <button
                      className="border-solid border-1 border-green-500 rounded-full text-green-50 bg-green-500 hover:text-green-50 hover:bg-green-600 py-1 px-4 "
                      disabled={!isDirty || areAllFieldsNull}
                      id={!isDirty || areAllFieldsNull ? "newDisable" : "3"}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

export default RegularizationFilter;
