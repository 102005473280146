import React from "react";
import { Controller } from "react-hook-form";
import { Radio, RadioGroup, ListItem, List } from "@mui/material";
import { makeStyles } from "@mui/styles";
import "./RadioGroupComponent.css";

const useStyles = makeStyles((theme) => ({
  errormsg: {
    fontSize: "12px",
    color: "#f00",
    marginTop: "5px",
    marginBottom: "15px",
  },
  inputClass: {
    pointerEvents: "none",
  },
}));

const RadioGroupComponent = ({
  types,
  control,
  errors,
  name,
  hidden = true,
  required = false,
  disabled = false,
  handleRadioChange,
}) => {
  const classes = useStyles();
  const getErrorMessage = (fieldName) => {
    let nestedErrors = errors;
    const fieldNames = fieldName.split(".");
    for (const field of fieldNames) {
      nestedErrors = nestedErrors?.[field];
      if (!nestedErrors) break;
    }
    return nestedErrors?.message;
  };
  const error = getErrorMessage(name);

  return (
    <>
      <Controller
        name={name}
        control={control}
        value={types[0]}
        render={({ field: { onChange, name, value } }) => (
          <RadioGroup
            className="customRadioBtnMain"
            name={name}
            value={value || ""}
            onChange={(e, val) => {
              onChange(val);
              handleRadioChange && handleRadioChange(val);
            }}
          >
            <List>
              {types.map((item, index) =>
                typeof item === "string" ? (
                  <ListItem
                    variant="outlined"
                    key={item}
                    className="customRadioBtn"
                  >
                    <Radio
                      overlay="true"
                      value={item}
                      required={required}
                      label={item}
                      id={item}
                      disabled={disabled}
                      hidden={hidden}
                    />
                    <label
                      htmlFor={item}
                      className={
                        hidden
                          ? `outlinedSquareButton w-100 ${
                              value === item ? "primarySquareBtn" : ""
                            }`
                          : null
                      }
                    >
                      {item}
                    </label>
                  </ListItem>
                ) : (
                  <ListItem
                    variant="outlined"
                    key={item?.label}
                    className="customRadioBtn"
                  >
                    <Radio
                      overlay="true"
                      value={item?.value}
                      required={required}
                      label={item?.label}
                      id={item?.label}
                      disabled={disabled}
                      hidden={hidden}
                    />
                    <label
                      htmlFor={item.label}
                      className={
                        hidden
                          ? `outlinedSquareButton w-100 ${
                              value === item?.value ? "primarySquareBtn" : ""
                            }`
                          : null
                      }
                    >
                      {item?.label}
                    </label>
                  </ListItem>
                )
              )}
            </List>
          </RadioGroup>
        )}
      />
      {error ? <p className={classes.errormsg}>{error}</p> : null}
    </>
  );
};
export default RadioGroupComponent;
