import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";

const ExportAttendanceTableDataDialog = ({ open, setOpen }) => {
  return (
    <Dialog
      open={open}
      //   TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      className="employee_form_dialog teamDialog"
    >
      <DialogTitle className="add_employee_main_heading text-blue-600">
        <span>Export Daily Attendance Data</span>
      </DialogTitle>
      <DialogContent className="card-content-employee-form">
        <div className="">
          <div className="text-center font-semibold mb-2">Exprt As</div>
          <div className="flex items-center justify-center gap-3 text-sm">
            <div className="bg-gray-100 px-3 border-1 border-gray-200 rounded-md cursor-pointer">
              <div className="form-check flex items-center">
                <input
                  className="form-check-input mr-2 h-4 w-4 cursor-pointer"
                  type="radio"
                  name="export"
                  id="xls"
                  checked
                />
                <label className="form-check-label cursor-pointer" htmlFor="xls">
                  XLS
                </label>
              </div>
            </div>
            <div className="bg-gray-100 px-3 border-1 border-gray-200 rounded-md cursor-pointer">
              <div className="form-check flex items-center">
                <input
                  className="form-check-input mr-2 h-4 w-4 cursor-pointer"
                  type="radio"
                  name="export"
                  id="csv"
                />
                <label className="form-check-label cursor-pointer" htmlFor="csv">
                  CSV
                </label>
              </div>
            </div>
          </div>
          <div className="mt-3 text-sm">
            <div className="form-check inline-flex">
              <input
                className="form-check-input cursor-pointer"
                type="checkbox"
                value=""
                id="flexCheckChecked"
              />
              <label
                className="form-check-label ml-1 cursor-pointer"
                htmlFor="flexCheckChecked"
              >
                Include aditional employee fields
              </label>
            </div>
            <div className="row mt-3 px-4">
              <div className="col-md-4 mb-3">
                <div className="form-check">
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    value=""
                    id="employee"
                  />
                  <label className="form-check-label cursor-pointer" htmlFor="employee">
                    Employee
                  </label>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="form-check">
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    value=""
                    id="firstIn"
                  />
                  <label className="form-check-label cursor-pointer" htmlFor="firstIn">
                    First In
                  </label>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="form-check">
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    value=""
                    id="lastIn"
                  />
                  <label className="form-check-label cursor-pointer" htmlFor="lastIn">
                    Last In
                  </label>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="form-check">
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    value=""
                    id="totalHours"
                  />
                  <label
                    className="form-check-label cursor-pointer"
                    htmlFor="totalHours"
                  >
                    Total Hours
                  </label>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="form-check">
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    value=""
                    id="entry"
                  />
                  <label className="form-check-label cursor-pointer" htmlFor="entry">
                    Entry
                  </label>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="form-check">
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    value=""
                    id="late"
                  />
                  <label className="form-check-label cursor-pointer" htmlFor="late">
                    Late
                  </label>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="form-check">
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    value=""
                    id="workmode"
                  />
                  <label className="form-check-label cursor-pointer" htmlFor="workmode">
                    Work Mode
                  </label>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="form-check">
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    value=""
                    id="shift"
                  />
                  <label className="form-check-label cursor-pointer" htmlFor="shift">
                    Shift(s)
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="employee_form_button_div">
        <button
          type="button"
          onClick={() => setOpen(false)}
          className="border-1 border-gray-300 rounded-lg py-2 px-4 hover:bg-gray-50 text-sm mr-4"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="border-1 border-blue-500 rounded-lg py-2 px-4 bg-blue-600 text-white hover:bg-blue-700 hover:text-white text-sm"
        >
          Save
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportAttendanceTableDataDialog;
