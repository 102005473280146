import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import JoditEditor from "jodit-react";
import { TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { yupResolver } from "@hookform/resolvers/yup";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import * as yup from "yup";
import {
  fetchHandbookSlideById,
  fetchHandbookSlides,
  updateHandbookSlides,
} from "../../../store/slices/handbookSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink, useNavigate } from "react-router-dom";
import Loader from "../../Loader/Loader";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const schema = yup
  .object({
    handbookTitle: yup
      ?.string()
      ?.required()
      ?.label("Handbook Title")
      ?.typeError("Handbook Title can be a Number Only"),
    handbookTitleKey: yup?.string()?.required()?.label("Handbook Title Key")?.trim(),
    handbookHtml: yup?.string()?.label("Description")?.trim()?.optional().nullable(),
  })
  .required();

const HandbookPageEditor = () => {
  const location = useLocation()
  const selectedId = location?.state?.Id;
  const currentSlide = location?.state?.activeSlide;
  const currentOrder = location?.state?.order;

  const dispatch = useDispatch();
  const history = useNavigate();
  const handbookSlides = useSelector((state) => state?.handbook?.handbookData);
  let slideData;
  let idData;
  let handbookFirst;
  if (handbookSlides) {
    slideData = handbookSlides?.find((data) => data?.handbookId === selectedId)?.handbookId ?? handbookSlides[0]?.handbookId;
    idData = selectedId ?? handbookSlides[0]?.handbookId
    handbookFirst = handbookSlides?.find(data => data?.handbookId === selectedId) ?? handbookSlides[0];
  }
  const [activeSlide, setActiveSlide] = useState(slideData);
  const [id, setId] = useState(idData);
  const [fetching, setFetching] = useState(false);
  const [consent, setConsent] = useState(false);

  useEffect(() => {
    dispatch(fetchHandbookSlides());
  }, [dispatch]);

  const handleReset = () => {
    setValue("handbookHtml", "")
  };

  const getHandbookData = async () => {
    await dispatch(fetchHandbookSlideById({ Id: id }));
  };

  const methods = useForm({
    defaultValues: {
      handbookTitle: handbookFirst?.title,
      handbookTitleKey: handbookFirst?.titleName,
      handbookHtml: handbookFirst?.handbookHtml,
      isConsentRequired: handbookFirst?.is_consent_required,
    },
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = methods;

  useEffect(() => {
    const data = {
      handbookTitle: watch("handbookTitle"),
      handbookTitleKey: watch("handbookTitleKey"),
      handbookHtml: watch("handbookHtml"),
      isConsentRequired: watch("isConsentRequired")
    };
    methods.reset(data);
  }, [id]);

  const onSubmit = async (data) => {
    setFetching(true)
    await dispatch(
      updateHandbookSlides({
        Id: id,
        title: data?.handbookTitle,
        titleName: data?.handbookTitleKey,
        handbookHtml: data?.handbookHtml,
        isConsentRequired: data?.isConsentRequired,
      })
    );
    await dispatch(fetchHandbookSlides());
    history("/Handbook-dashboard", { state: { currentSlide, currentOrder } });
    setFetching(false)
  };

  return (
    <>
      {
        fetching ? <Loader />
          :
          <div className="handbookWrapper">
            <div className="handbookLeftAsside">
              <div className="p-3 font-semibold border-b border-gray-300">
                Handbook Page List
              </div>
              <ul className="handbookEditList">
                {handbookSlides &&
                  handbookSlides?.map((list, index) => (
                    <li
                      id={list?.handbookId}
                      key={index}
                      onClick={() => (
                        getHandbookData(list?.handbookId),
                        setId(list?.handbookId),
                        setActiveSlide(list?.handbookId),
                        setValue("handbookTitle", list?.title),
                        setValue("handbookTitleKey", list?.titleName),
                        setValue("handbookHtml", list?.handbookHtml),
                        setValue("isConsentRequired", list?.is_consent_required)
                      )}
                      className={
                        activeSlide === list?.handbookId
                          ? "bg-gray-100 text-sm font-medium p-3 border-b border-gray-200 hover:bg-gray-100 cursor-pointer"
                          : "text-sm font-medium p-3 border-b border-gray-200 hover:bg-gray-100 cursor-pointer"
                      }
                    >
                      <span className="font-semibold">{index + 1}.</span>{" "}
                      {list?.title}
                    </li>
                  ))}
              </ul>
            </div>
            <div className="handbookRightAsside">
              <div className="container-fluid">
                <div className="row mt-3">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-md-10 offset-md-1">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="font-semibold text-lg mb-3 uppercase">
                            Edit Employee Handbook Slides
                          </div>
                        </div>
                      </div>
                      <div className="row items-end">
                        <div className="col-md-6">
                          <label className="text-sm">Handbook Title
                            <span className="create_form_asterisk">*</span>
                          </label>
                          <Controller
                            name="handbookTitle"
                            control={control}
                            rules={{
                              required: true,
                            }}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                onChange={onChange}
                                value={value}
                                variant="outlined"
                                size="small"
                              />
                            )}
                          />
                          <p className="text-red-500 text-sm">
                            {errors?.handbookTitle?.message}
                          </p>
                        </div>
                        {/* <div className="col-md-6">
                          <label className="text-sm"></label>
                          <span>Is Consent Required?</span>
                          <Controller
                            name="isConsentRequired"
                            control={control}
                            errors={errors}
                            render={({ field: { onChange, value, name } }) => (
                              <Checkbox
                                checked={value}
                                icon={<CheckCircleOutlineIcon fontSize="small" />}
                                checkedIcon={<CheckCircleIcon fontSize="small" />}
                                name={name}
                                value={value}
                                onChange={(e, newValue) => {
                                  onChange(newValue);
                                  setConsent(newValue);
                                }}
                              />
                            )}
                          />
                        </div> */}
                      </div>
                      <div className="joditEditor mt-4">
                        <Controller
                          name="handbookHtml"
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field: { onChange, value } }) => (
                            <JoditEditor
                              value={value}
                              onChange={onChange}
                            />
                          )}
                        />
                        <p className="text-red-500 text-sm">
                          {errors?.editorContent?.message}
                        </p>
                      </div>

                      <Dialog
                        open={consent}
                        //   TransitionComponent={Transition}
                        keepMounted
                        aria-describedby="alert-dialog-slide-description"
                        className="employee_form_dialog"
                      >
                        <DialogTitle className="add_employee_main_heading text-red-600">
                          <span>Confirmation for Slide Consent</span>
                        </DialogTitle>
                        <DialogContent className="card-content-employee-form">
                          <div className=""> Do you need concern for this slide? Please confirm.</div>
                        </DialogContent>
                        <DialogActions className="employee_form_button_div">
                          <button
                            type="button"
                            onClick={() => {
                              setConsent(false)
                              setValue("isConsentRequired", false)
                            }
                            }
                            className="border-1 border-gray-300 rounded-lg py-2 px-4 hover:bg-gray-50 text-sm mr-3"
                          >
                            No
                          </button>
                          <button
                            onClick={() => {
                              setValue("isConsentRequired", true)
                              setConsent(false)
                            }

                            }
                            className="border-1 border-blue-500 rounded-lg py-2 px-4 bg-blue-600 text-white hover:bg-blue-700 hover:text-white text-sm"
                          >
                            Yes
                          </button>
                        </DialogActions>
                      </Dialog>

                      <div className="flex justify-end gap-3">
                        <NavLink to='/handbook-dashboard'>
                          <div
                            className="border-1 border-blue-400 text-blue-600 hover:text-gray-500 hover:border-gray-500 py-2 px-3 rounded-md my-4"
                          >
                            Back
                          </div>
                        </NavLink>
                        <button
                          type="button"
                          id={!isDirty ? "newDisable" : ""}
                          onClick={() => handleReset()}
                          className="border-1 border-blue-400 text-blue-600 hover:text-gray-500 hover:border-gray-500 py-2 px-3 rounded-md my-4"
                        >
                          Reset
                        </button>
                        <button
                          id={!isDirty ? "newDisable" : ""}
                          type="submit"
                          className="border-1 border-blue-400 text-white bg-blue-500 hover:bg-blue-600 py-2 px-3 rounded-md my-4"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
      }
    </>
  );
};

export default HandbookPageEditor;
