import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { Avatar, ListItemAvatar } from "@mui/material";
import stringAvatar from "../../PMS/AvatarFunction";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const RegularizationReviewChart = ({ setBack, Year }) => {
  const options = {
    scales: {
      y: {
        min: 0,
        max: 10,
        ticks: {
          stepSize: 1,
        },
        grid: {
          display: true,
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
  };

  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const chartData = useSelector(
    (state) =>
      state?.leave?.monthlyAttendanceRegularization?.monthlyRegularizationData
        ?.data
  );

  const approvedData = {
    labels,
    datasets: [
      {
        label: "Approved Requests",
        data: chartData?.approvedArray,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "#0d6efd",
      },
      {
        label: "Rejected Requests",
        data: chartData?.rejectedArray,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "#dc3545",
      },
    ],
  };
  return (
    <div>
      {" "}
      <div className="reviewRegularizationSidebar">
        <div className="verifyAllocationHeader flex items-center gap-3 bg-indigo-50 font-semibold text-lg">
          <div
            onClick={() => setBack(false)}
            className="p-3 hover:bg-white border-r border-r-indigo-200 cursor-pointer"
          >
            <i className="fa-light fa-chevron-left"></i> Back
          </div>
          Review Regularization
        </div>
        <div className="p-3 pt-4">
          <div className="border-b border-gray-200 pb-4">
            <div className="row">
              <div className="col-md-5">
                <div className="flex items-center px-3 py- text-gray-900 whitespace-nowrap dark:text-white">
                  <ListItemAvatar className="img-fluid">
                    {chartData?.employeeInfo?.profileImage ? (
                      <Avatar
                        className="img-fluid w-28 h-28 rounded-full object-cover"
                        src={chartData?.employeeInfo?.profileImage}
                      />
                    ) : (
                      <Avatar
                        className="img-fluid w-28 h-28 rounded-full object-cover"
                        {...stringAvatar(chartData?.employeeInfo?.firstName)}
                      />
                    )}
                  </ListItemAvatar>
                  <div className="pl-3">
                    <div className="inline-flex py-0 rounded-sm px-2 text-sm font-normal border border-gray-500 text-gray-700 bg-gray-50">
                      #{chartData?.employeeInfo?.EmployeeId}
                    </div>
                    <div className="text-lg font-bold ">
                      {" "}
                      {`${
                        chartData?.employeeInfo?.firstName
                          ? chartData?.employeeInfo?.firstName
                          : ""
                      } ${
                        chartData?.employeeInfo?.middleName
                          ? chartData?.employeeInfo?.middleName
                          : ""
                      } ${
                        chartData?.employeeInfo?.lastName
                          ? chartData?.employeeInfo?.lastName
                          : ""
                      }`}
                    </div>
                    <div className="inline-flex  text-sm font-normal text-gray-600 ">
                      {chartData?.employeeInfo?.designation}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="flex justify-between bg-white font-semibold border border-solid border-gray-300 rounded-md p-3">
                  {chartData?.employeeInfo?.user_id}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 ml-4">
            <label className="text-lg font-semibold	text-gray-400">
              Regularization Requests :
            </label>{" "}
            <label className="text-xl font-bold	ml-2 text-gray-600">
              {Year}
            </label>
          </div>
          <Bar options={options} data={approvedData} height={100} />
        </div>
      </div>
    </div>
  );
};

export default RegularizationReviewChart;
