import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch } from 'react-redux';
import { fetchHandbookSlides, updateManageHandBookOrder } from '../../../store/slices/handbookSlice';

// Define the draggable item component
const DraggableItem = ({ id, index, titleName, moveItem }) => {
  const [, ref] = useDrag({
    type: 'ITEM',
    item: { id, index },
  });

  const [, drop] = useDrop({
    accept: 'ITEM',
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <div ref={(node) => ref(drop(node))} style={{ margin: "5px 10px", padding: '8px', border: '1px solid #ccc', curson: 'pointer', fontWeight: '600' }}>
      {titleName}
    </div>
  );
};

// Define the draggable list component
const DraggableList = ({ handbookSlides, items, setItems }) => {


  const moveItem = (fromIndex, toIndex) => {
    const updatedItems = [...items];
    const [movedItem] = updatedItems.splice(fromIndex, 1);
    updatedItems.splice(toIndex, 0, movedItem);
    setItems(updatedItems);
  };

  return (
    <div>
      {items.map((item, index) => (
        <DraggableItem key={item.handbookId} id={item.handbookId} index={index} titleName={item.title} moveItem={moveItem} />
      ))}
    </div>
  );
};

// Wrap the DraggableList component with DndProvider
const DragDropPopup = ({ open, setOpen, handbookSlides, setActiveSlide, setId }) => {
  const [items, setItems] = useState(handbookSlides);
  const dispatch = useDispatch();

  const onSubmitPageOrder = async () => {
    const data = items.map((v, index) => { return { 'sNo': index, 'titleName': v.titleName } })
    const result = await dispatch(updateManageHandBookOrder({ data: data }));
    if (result?.payload?.status) {
      dispatch(fetchHandbookSlides());
    }
    setActiveSlide(0);
    setId(items[0]?.handbookId)
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      // TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      onClose={() => setOpen(false)}
      className="employee_form_dialog"
    >
      <DialogTitle className="add_employee_main_heading">
        <p style={{ margin: '0px' }}>
          Reorder Slides
        </p>
        <span style={{ fontSize: '16px', color: '#ccc' }}>Drag and Drop to swich page series</span>
      </DialogTitle>
      <DndProvider backend={HTML5Backend}>
        <DraggableList handbookSlides={handbookSlides} setItems={setItems} items={items} />
      </DndProvider>
      <DialogActions className="employee_form_button_div">
        <button type="button" onClick={() => setOpen(false)} className="cancel_button">
          Cancel
        </button>
        <button type="button" onClick={onSubmitPageOrder} className="save_button">
          Submit
        </button>
      </DialogActions>
    </Dialog>

  );
};

export default DragDropPopup;