import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DatePickerComponent from "../../MasterComponent/DatePickerComponent";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { deleteEmployeesFromProject } from "../../../store/slices/masterSlice";
import { getAllProjectByID } from "../../../store/slices/ProjectSlice";
import * as yup from "yup";

const deleteTeamMemberSchema = yup?.object({
  endDate: yup?.string()?.label("End Date")?.required(),
});
const DeleteTeamMemberDialog = ({
  setOpen,
  open,
  Data,
  setData,
  projectId,
}) => {
  const dispatch = useDispatch();
  const methods = useForm({
    defaultValues: {
      employeeName: null,
      role: null,
      startDate: null,
      projectType: {
        label: null,
        value: null,
      },
    },
    resolver: yupResolver(deleteTeamMemberSchema),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = methods;

  const submitHandler = async (data) => {
    let submitData = {
      emp_project_id: Data?.emp_project_id,
      end_date: data?.endDate,
    };
    await dispatch(deleteEmployeesFromProject(submitData));
    dispatch(getAllProjectByID(projectId));
    setOpen(false);
    setData();
  };
  return (
    <Dialog
      open={open}
      //   TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      className="employee_form_dialog"
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <DialogTitle className="add_employee_main_heading">
            <span>Confirm Delete</span>
          </DialogTitle>
          <DialogContent className="card-content-employee-form">
            <div className="flex flex-col">
              <div>Are You Sure You Want To Delete This Record ?</div>
              <div className="col-md-3 mt-2">
                <label className="create_form_labels">
                  End Date<span className="text-red-500">*</span>:
                </label>
              </div>
              <div className="col-md-6">
                <DatePickerComponent
                  name="endDate"
                  control={control}
                  errors={errors}
                  maxDate={new Date()}
                  backSpaceChange={false}
                  // onHandleChange={() => trigger("billingEndDate")}
                  disableMaskedInput={true}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions className="employee_form_button_div">
            <button onClick={() => setOpen(false)} className="cancel_button">
              Cancel
            </button>
            <button type="submit" className="save_button">
              Delete
            </button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
export default DeleteTeamMemberDialog;
