import { ClickAwayListener, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import ExportAttendanceTableDataDialog from "./ExportAttendanceTableDataDialog";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";


import {
  employeeSearch,
  resetEmployeeSearch,
} from "../../../../store/slices/masterSlice";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AttendanceInfo from "./AttendanceInfo";
import AttendanceEmployeeSearch from "./AttendanceEmployeeSearch";
import http from "../../../../utils/http";
import fileDownload from "js-file-download";
import UpdateAttendanceForm from "./UpdateAttendanceForm";
import CustomRightDrawer from "../../../CustomRightDrawer/CustomRightDrawer";
import { getUniqueListBy } from "../../../../utils/helper";
import { debouncedSearch } from "./debouncedSearchActions";
const EmployeePresenceDashboard = () => {
  const [toggle, setToggle] = useState(false);
  const [toggleVal, setToggleVal] = useState("Direct Reporting");
  const [status, setStatus] = useState(false);
  const [statusVal, setStatusVal] = useState("Active");
  const [open, setOpen] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [attendanceData, setAttendanceData] = useState({});
  const [employeeName, setEmployeeName] = useState(null);
  const [openRightDrawer, setOpenRightDrawer] = useState(false);
  const [renderComp, setRenderComp] = useState(false);
  const [search, setSearch] = useState(null);
  const getEmpSearch = useSelector(
    (state) => state?.masterList?.employeeSearchData
  );
  const searchLoading = getEmpSearch?.isFetching;

  const [moreData, setMoreData] = useState([]);

  useEffect(() => {
    if (getEmpSearch?.data?.data?.items?.length && _.isNil(search)) {
      setMoreData(() =>
        getUniqueListBy([...getEmpSearch?.data?.data?.items], "EmployeeId")
      );
    }
    if (getEmpSearch?.data?.data?.items?.length && !_.isNil(search)) {
      setMoreData(getEmpSearch?.data?.data?.items);
    }
  }, [getEmpSearch]);

  const [EmployeeId, setEmployeeId] = useState();

  useEffect(() => {
    if (!EmployeeId && moreData?.length) {
      setEmployeeId(moreData[0]?.EmployeeId);
    }
  }, [moreData]);

  const [fromDate, setFromDate] = useState(
    dayjs()?.startOf("month")?.format("DD-MMM-YYYY")
  );
  const [toDate, setToDate] = useState(
    dayjs()?.endOf("month")?.format("DD-MMM-YYYY")
  );
  const [currDate, setCurrDate] = useState(dayjs()?.format("DD-MMM-YYYY"));

  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("ASC");
  const [monthCompare, setMonthCompare] = useState(dayjs());

  const dispatch = useDispatch();
  useEffect(() => {
    debouncedSearch({ page, limit, search, toggleVal, statusVal }, dispatch);
  }, [page, limit, search, toggleVal, statusVal, dispatch]);

  useEffect(() => {
    return () => dispatch(resetEmployeeSearch());
  }, []);

  const employeeInfo = useSelector(
    (state) => state?.masterList?.employeeAttendanceListData
  );
  const handleAttendanceDownload = async () => {
    try {
      setDownloading(true);
      const response = await http({
        url: `team/v1/attendance-by-employeeId-excel`,
        method: "GET",
        responseType: "blob",
        params: {
          EmployeeId: EmployeeId,
          fromDate: fromDate,
          toDate: toDate,
          order: order,
          status: statusVal,
          reportType: toggleVal
        },
      });
      fileDownload(
        response.data,
        `${employeeInfo?.data?.data?.employeeAttendanceList?.employee_name
        }_Attendance_${dayjs(fromDate).format("MMM-YYYY")}.xlsx`
      );
      setDownloading(false);
    } catch (error) {
      setDownloading(false);
    }
  };
  const isFetching = employeeInfo?.isFetching;
  const selectedEmp = moreData?.filter(
    (list) => list?.EmployeeId == EmployeeId
  );

  const handleDirectIndirect = (val) => {
    toggleVal === val ? setMoreData(moreData) : setMoreData([]);
    setToggle(false);
    setToggleVal(val);
    setPage(1);
    setEmployeeId(null);
  };

  const handleStatus = (val) => {
    statusVal === val ? setMoreData(moreData) : setMoreData([]);
    setStatus(false);
    setStatusVal(val);
    setPage(1);
    setEmployeeId(null);
  };

  return (
    <>
      <div className="dailyAttendanceStatus relative">
        <div className="mt-3">
          <div className="flex items-center justify-between mb-3 px-3">
            <div className="flex items-center text-sm">
              <i className="fa-light fa-users text-gray-700 text-sm p-2 bg-gray-50 border-1 border-gray-200 rounded-md"></i>

              <div className="pl-2 pr-3">Employee Presence</div>
              <div className="pr-3">|</div>
              <div className="font-semibold">
                Total {`(${moreData?.length})`}
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex items-center gap-3">
                <ClickAwayListener onClickAway={() => setToggle(false)}>
                  <div className="relative ml-3">
                    <div
                      onClick={() => setToggle((prev) => !prev)}
                      className="flex items-center justify-between gap-3 w-52 text-gray-500 text-md py-1 px-3 bg-white border-1 border-gray-400 rounded-md cursor-pointer"
                    >
                      <div>
                        {toggleVal == "Direct Reporting"
                          ? "DIRECT"
                          : "INDIRECT"}
                      </div>
                      <i
                        className={
                          toggle
                            ? "fa-light fa-chevron-up"
                            : "fa-light fa-chevron-down"
                        }
                      ></i>
                    </div>
                    {toggle && (
                      <ul className="bg-white w-full border-1 border-gray-200 rounded-md absolute top-9 right-0 z-10">
                        <li
                          onClick={() =>
                            handleDirectIndirect("Direct Reporting")
                          }
                          className="border-b border-gray-200 px-3 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                        >
                          DIRECT
                        </li>
                        <li
                          onClick={() =>
                            handleDirectIndirect("Indirect Reporting")
                          }
                          className="border-b border-gray-200 px-3 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                        >
                          INDIRECT
                        </li>
                      </ul>
                    )}
                  </div>
                </ClickAwayListener>

                <ClickAwayListener onClickAway={() => setStatus(false)}>
                  <div className="relative ml-3">
                    <div
                      onClick={() => setStatus((prev) => !prev)}
                      className="flex items-center justify-between gap-3 w-52 text-gray-500 text-md py-1 px-3 bg-white border-1 border-gray-400 rounded-md cursor-pointer"
                    >
                      <div>{statusVal == "Active" ? "Active" : "Left"}</div>
                      <i
                        className={
                          status
                            ? "fa-light fa-chevron-up"
                            : "fa-light fa-chevron-down"
                        }
                      ></i>
                    </div>
                    {status && (
                      <ul className="bg-white w-full border-1 border-gray-200 rounded-md absolute top-9 right-0 z-10">
                        <li
                          onClick={() => handleStatus("Active")}
                          className="border-b border-gray-200 px-3 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                        >
                          Active
                        </li>
                        <li
                          onClick={() => handleStatus("Left")}
                          className="border-b border-gray-200 px-3 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                        >
                          Left
                        </li>
                      </ul>
                    )}
                  </div>
                </ClickAwayListener>

                <div className="fromToDate">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={fromDate}
                      label="From Date"
                      maxDate={currDate}
                      onChange={(newValue) => {
                        setFromDate(dayjs(newValue)?.format("DD-MMM-YYYY"));
                        setMonthCompare(newValue);
                        (dayjs(newValue) <
                          dayjs(monthCompare)?.startOf("month") ||
                          dayjs(newValue) > dayjs(toDate)) &&
                          setToDate(null);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                        />
                      )}
                      inputFormat={"DD-MMM-YYYY"}
                      disableMaskedInput
                    />
                  </LocalizationProvider>
                </div>
                <div className="fromToDate relative">
                  {toDate == null && (
                    <div className="text-xs text-red-600 absolute bottom-9">
                      To date is mandatory field!
                    </div>
                  )}

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={toDate}
                      label="To Date"
                      className={toDate == null ? "inputLabelCenterAlign" : ""}
                      minDate={fromDate}
                      maxDate={dayjs(fromDate)?.add(30, "day")}
                      onChange={(newValue) => {
                        setToDate(dayjs(newValue)?.format("DD-MMM-YYYY"));
                        setPage(1);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                        />
                      )}
                      inputFormat={"DD-MMM-YYYY"}
                      disableMaskedInput
                    />
                  </LocalizationProvider>
                </div>
                <div
                  onClick={() => {
                    handleAttendanceDownload();
                  }}
                  className="tooltipWrapper text-blue-600 text-md py-1 px-3 bg-inherit border-1 border-blue-400 rounded-md cursor-pointer hover:bg-blue-600 hover:text-white relative"
                >
                  <i className="fa-light fa-download"></i>
                  <div className="tooltipHover edit hidden border-1 border-solid border-blue-400 bg-blue-50 text-blue-500 py-1 px-3 rounded-md text-xs font-semibold">
                    Download in .xlsx
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="empPresenceContainer border-t">
            <div className="empListLeftAsside relative">
              <AttendanceEmployeeSearch
                EmployeeId={EmployeeId}
                setEmployeeId={setEmployeeId}
                getEmpSearch={getEmpSearch}
                page={page}
                setPage={setPage}
                setLimit={setLimit}
                searchLoading={searchLoading}
                search={search}
                setSearch={setSearch}
                moreData={moreData}
                setMoreData={setMoreData}
                toggleVal={toggleVal}
              />
            </div>
            <div className="empListRightAsside">
              <AttendanceInfo
                EmployeeId={EmployeeId}
                fromDate={fromDate}
                toDate={toDate}
                order={order}
                setOrder={setOrder}
                renderComp={renderComp}
                setRenderComp={setRenderComp}
                setAttendanceData={setAttendanceData}
                setOpenRightDrawer={setOpenRightDrawer}
                setEmployeeName={setEmployeeName}
                isFetching={isFetching}
                downloading={downloading}
                selectedEmp={selectedEmp}
                status={statusVal}
                reportType={toggleVal}
              />
            </div>
          </div>
        </div>
      </div>

      {/* <UpdateAttendanceForm
        openRightDrawer={openRightDrawer}
        setOpenRightDrawer={setOpenRightDrawer}
        employeeName={employeeName}
        attendanceData={attendanceData}
        setRenderComp={setRenderComp}
      /> */}
      <CustomRightDrawer
        openRightDrawer={openRightDrawer}
        setOpenRightDrawer={setOpenRightDrawer}
        title="Update Attendance"
        // titleDesc="Role Configuration Desctiption..."
        width="500px"
        InnerContent={
          <UpdateAttendanceForm
            openRightDrawer={openRightDrawer}
            setOpenRightDrawer={setOpenRightDrawer}
            employeeName={employeeName}
            attendanceData={attendanceData}
            setRenderComp={setRenderComp}
          />
        }
      />
      <ExportAttendanceTableDataDialog open={open} setOpen={setOpen} />
    </>
  );
};

export default EmployeePresenceDashboard;
