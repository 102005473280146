import React from "react";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePickerComponent from "../../MasterComponent/DatePickerComponent";
import { useState } from "react";
import AutoCompleteComponent from "../../MasterComponent/AutoCompleteComponent";
import * as yup from "yup";
import dayjs from "dayjs";
import { getEmployeesAccess } from "../../../utils/userPermission";

export const FilterAllocationSchema = yup.object().shape({
  employee: yup.object().label("Employee Name").optional().nullable(true),
  leave_type: yup.object().label("Leave Type").optional().nullable(true),
  status: yup.object().label("Status").optional().nullable(true),
  from_date: yup.string().label("Posting Date").optional().nullable(true),
  to_date: yup
    .string()
    .label("To Date")
    .test(
      "to-date-required",
      "To Date is required when From Date is not null",
      function (toDate) {
        const fromDate = this.parent.from_date;

        if (fromDate !== null && fromDate !== undefined && fromDate !== "") {
          return !!toDate;
        }

        return true;
      }
    )
    .nullable(true)
    .default(null),
});

const FilterAllocation = ({
  employeeList,
  setApplyFilter,
  leaveType,
  status,
  setPage,
  setFilterValues,
  filterValues,
}) => {
  leaveType = leaveType?.filter(
    (data) =>
      data.leave_type_name !== "Earned Leave" &&
      data.leave_type_name !== "Sick Leave" &&
      data.leave_type_name !== "Restricted Holiday" &&
      data.leave_type_name !== "Leave Without Pay" &&
      data.leave_type_name !== "Work From Home"
  );
  const employeeArrayList = employeeList?.map((data) => data?.EmployeeId);
  const accessPermission = getEmployeesAccess({
    employees: employeeArrayList,
    component: "Leave_Allocation_V1",
    accessType: "readAccess",
  });
  const employeeListData = employeeList
    ?.filter((data) => {
      return accessPermission?.[data?.EmployeeId];
    })
    ?.map((employeeData) => {
      return {
        EmployeeId: employeeData?.EmployeeId,
        employee_name: employeeData?.employee_name,
        gender: employeeData?.[`personalInfo.gender`],
      };
    });
  const [filterData, setFilterData] = useState(filterValues);
  const [leaveList, setLeaveList] = useState(leaveType);

  // const initialApply =
  //   filterData?.search == null &&
  //   filterData?.status == null &&
  //   filterData?.leave_type == null &&
  //   filterData?.date?.from_date == null &&
  //   filterData?.date?.to_date == null
  //     ? true
  //     : false;

  // const [disableApply, setDisableApply] = useState(initialApply);

  const methods = useForm({
    defaultValues: {
      employee: filterData?.employee ?? null,
      leave_type: filterData?.leave_type ?? null,
      status: filterData?.status ?? null,
      posting_date: filterData?.date?.posting_date ?? null,
      from_date: filterData?.date?.from_date,
      to_date: filterData?.date?.to_date,
    },
    resolver: yupResolver(FilterAllocationSchema),
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
    watch,
    setValue,
  } = methods;
  const submitHandler = async (data) => {
    data.date = {
      from_date: data.from_date
        ? dayjs(data.from_date).format("MM-DD-YYYY")
        : null,
      to_date: data.to_date ? dayjs(data.to_date).format("MM-DD-YYYY") : null,
    };
    localStorage.setItem("allocationFilter", JSON.stringify(data));
    setFilterValues(data);
    setPage(1);
    setApplyFilter(false);
  };
  const areAllFieldsNull = Object?.values(watch())?.every(
    (value) => value === null
  );

  const handleEmployeeChange = (e) => {
    setValue("from_date", null);
    setValue("to_date", null);
    setValue("leave_type", null);
    setValue("status", null);
    const updatedLeaveList = leaveType.filter((data) => {
      if (e?.gender === "Male") {
        return (
          data.leave_type_name !== "Earned Leave" &&
          data.leave_type_name !== "Sick Leave" &&
          data.leave_type_name !== "Maternity Leave" &&
          data.leave_type_name !== "Restricted Holiday" &&
          data.leave_type_name !== "Leave Without Pay" &&
          data.leave_type_name !== "Work From Home"
        );
      } else if (e?.gender === "Female") {
        return (
          data.leave_type_name !== "Earned Leave" &&
          data.leave_type_name !== "Sick Leave" &&
          data.leave_type_name !== "Restricted Holiday" &&
          data.leave_type_name !== "Paternity Leave" &&
          data.leave_type_name !== "Leave Without Pay" &&
          data.leave_type_name !== "Work From Home"
        );
      } else {
        return (
          data.leave_type_name !== "Earned Leave" &&
          data.leave_type_name !== "Sick Leave" &&
          data.leave_type_name !== "Restricted Holiday" &&
          data.leave_type_name !== "Leave Without Pay" &&
          data.leave_type_name !== "Work From Home"
        );
      }
    });
    setLeaveList(updatedLeaveList);
  };

  return (
    <div className="absolute top-0 right-0 z-10 w-100 h-screen">
      <div className="absolute top-0 left-0 z-10 w-100 h-100 bg-gray-950/40"></div>
      <div className="absolute top-0 right-0 z-20 w-3/6 h-100 bg-white">
        <div
          className="flex p-3 items-center justify-center absolute top-0 right-0 text-xl cursor-pointer"
          onClick={() => setApplyFilter(false)}
        >
          <i className="fa-light fa-xmark"></i>
        </div>
        <div className="">
          <div className="verifyAllocationHeader p-3  bg-indigo-50 font-semibold text-lg">
            Apply Filters
          </div>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(submitHandler)}>
              <div className="p-3">
                <div className="row mt-3">
                  <div className="col-md-12 mb-3">
                    <label className="text-sm">Search By Employee:</label>
                    {employeeList?.length > 1 ? (
                      <AutoCompleteComponent
                        name="employee"
                        id="employee"
                        control={control}
                        errors={errors}
                        options={employeeListData?.map((v) => ({
                          label: `${v?.EmployeeId} - ${v?.employee_name}`,
                          value: v?.EmployeeId,
                          gender: v?.gender,
                        }))}
                        onChangeHandler={handleEmployeeChange}
                      />
                    ) : null}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="text-sm">Leave Type:</label>
                    {leaveType?.length > 1 ? (
                      <AutoCompleteComponent
                        name="leave_type"
                        id="leave_type"
                        control={control}
                        errors={errors}
                        options={leaveList?.map((v) => ({
                          label: `${v?.leave_type_name}`,
                          value: v?.leave_type_name,
                        }))}
                      />
                    ) : null}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="text-sm">Status:</label>
                    {status?.length > 1 ? (
                      <AutoCompleteComponent
                        name="status"
                        id="status"
                        control={control}
                        errors={errors}
                        options={status?.map((v) => ({
                          label: `${v}`,
                          value: v,
                        }))}
                      />
                    ) : null}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="text-sm">From Date:</label>
                    <DatePickerComponent
                      errors={errors}
                      control={control}
                      name="from_date"
                      placeHolder="dd-mmm-yyyy"
                      maxDate={new Date()}
                      backSpaceChange={false}
                      disableMaskedInput={true}
                      onHandleChange={(e) => {
                        setValue("to_date", null);
                      }}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="text-sm">To Date:</label>
                    <DatePickerComponent
                      errors={errors}
                      control={control}
                      name="to_date"
                      placeHolder="dd-mmm-yyyy"
                      minDate={watch("from_date")}
                      backSpaceChange={false}
                      disableMaskedInput={true}
                    />
                  </div>
                  <div className="col-md-12 mb-3 flex gap-3 justify-end">
                    <button
                      className="border-solid border-1 border-gray-400 rounded-full text-gray-500 bg-gray-50 hover:text-red-50 hover:bg-gray-400 py-1 px-4 "
                      id="3"
                      type="button"
                      onClick={() => {
                        reset({
                          employee: null,
                          leave_type: null,
                          status: null,
                          from_date: null,
                          to_date: null,
                        });
                        setFilterData(null);
                      }}
                    >
                      Clear
                    </button>
                    <button
                      disabled={!isDirty || areAllFieldsNull}
                      id={!isDirty || areAllFieldsNull ? "newDisable" : "3"}
                      className="border-solid border-1 border-green-500 rounded-full text-green-50 bg-green-500 hover:text-green-50 hover:bg-green-600 py-1 px-4 "
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

export default FilterAllocation;
