import React, { useEffect } from "react";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePickerComponent from "../../MasterComponent/DatePickerComponent";
import TextAreaComponent from "../../MasterComponent/TextAreaComponent";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import moment from "moment";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  availableLeavesByEmployeeId,
  fetchLeaveApprovers,
  fetchLeaveType,
  fetchTotalAppliedLeave,
  resetAppliedLeave,
  resetLeaveTypeBalance,
} from "../../../store/slices/leaveSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentQuarterStartMidEndDates,
  // getLoggedUserData,
  getPayrollStartEndDates,
} from "../../../utils/helper";
import http from "../../../utils/http";
import dayjs from "dayjs";
import Loader from "../../Loader/Loader";
import {
  fetchEmployeeNameList,
  fetchEmployeesUserIds,
} from "../../../store/slices/employeeSlice";
import AutoCompleteComponent from "../../MasterComponent/AutoCompleteComponent";
import CONSTANTS from "../../../constants/constants";
import AutoCompleteMultiSelect from "../../MasterComponent/AutoCompleteMultiSelect";
import aaplyLeaveSchema from "./applyLeaveSchema";
import { useContext } from "react";
import { PopupContext } from "../../GlobalPopup/GlobalPopup";
import { getEmployeesAccess } from "../../../utils/userPermission";

const ApplyLeaveDialog = ({ open, setOpen, getData, currFiscalYear }) => {
  let currentDate = moment(new Date()).format("YYYY-MM-DD");

  const startOfMonthe = moment(currentDate)
    .startOf("month")
    .format("YYYY-MM-DD");

  const minLeaveAllowDate = moment(startOfMonthe)
    .subtract(1, "month")
    .format("YYYY-MM-DD");

  const { displayPopup } = useContext(PopupContext);

  // const loggedEmployee = getLoggedUserData();
  // const EmployeeId = loggedEmployee?.employeeId ?? null;
  const [employeeId, setEmployeeId] = useState(null);
  const [halfDay, setHalfDay] = useState("");
  const [click, setClick] = useState();
  const [activeHalfDay, setActiveHalfDay] = useState();
  const { payrollStartDate } = getPayrollStartEndDates(currentDate);
  const [isFetching, setIsFetching] = useState(false);
  const [totalLeaveDays, setTotalLeaveDays] = useState(0);
  const startOfMonth = dayjs(payrollStartDate)
    .subtract(1, "month")
    .format("YYYY-MM-DD");
  const [maxToDate, setmaxToDate] = useState(minLeaveAllowDate);
  let employeeList = useSelector((state) => state?.employee?.employeeNameList);

  const employeeArrayList = employeeList?.map((data) => data?.EmployeeId);
  const accessPermission = getEmployeesAccess({
    employees: employeeArrayList,
    component: "Leave_Application_V1",
    accessType: "createAccess",
  });
  const employeeListData = employeeList
    ?.filter((data) => {
      return accessPermission?.[data?.EmployeeId];
    })
    ?.map((employeeData) => {
      return {
        EmployeeId: employeeData?.EmployeeId,
        employee_name: employeeData?.employee_name,
      };
    });
  const dispatch = useDispatch();
  const LeaveApproveList = useSelector(
    (state) => state?.leave?.userLeaveApprover?.approvers
  );
  const LeaveType = useSelector((state) => state?.leave?.leaveType?.types);
  const EmployeeUserIds = useSelector(
    (state) => state?.employee?.employeeUserIds?.userIds
  );
  const selectedLeaveIsFetching = useSelector(
    (state) => state?.leave?.appliedLeave?.isFetching
  );
  const totalSelectedLeave = useSelector(
    (state) => state?.leave?.appliedLeave?.actualDaysApplied
  );
  const leaveTypeBalance = useSelector(
    (state) => state?.leave?.leaveTypeBalance
  );

  useEffect(() => {
    dispatch(resetLeaveTypeBalance());
    if (employeeId) {
      dispatch(fetchLeaveApprovers({ EmployeeId: employeeId }));
    }
    dispatch(fetchEmployeesUserIds());
    dispatch(fetchEmployeeNameList({ module: "Leave_Module_V1" }));
    dispatch(fetchLeaveType());
  }, [employeeId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetAppliedLeave());
    };
  }, [dispatch]);

  const methods = useForm({
    defaultValues: {
      employee: null,
      leave_approver: null,
      leave_type: null,
      half_day_info: "",
      half_day_check: 0,
      from_date: null,
      to_date: null,
      description: "",
      cc: [],
    },
    resolver: yupResolver(aaplyLeaveSchema),
  });
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
    watch,
    setValue,
  } = methods;
  // console.log("isDirty:", isDirty);
  const validateLeave = async () => {
    if (
      watch("from_date") &&
      watch("to_date") &&
      watch("employee") &&
      watch("leave_type")
    ) {
      const result = await dispatch(
        fetchTotalAppliedLeave({
          from_date: dayjs(watch("from_date")).format("YYYY-MM-DD"),
          to_date: dayjs(watch("to_date")).format("YYYY-MM-DD"),
          employeeId: employeeId,
          leaveType: watch("leave_type")?.value,
        })
      );

      if (result?.error?.message === "Rejected") {
        displayPopup(result.payload.message, "alert-danger");
        setValue("from_date", null);
        setValue("to_date", null);
      }
      return result;
    }
  };
  const onSubmit = async (data) => {
    setIsFetching(true);

    // if (
    //   data.leave_type?.value !== "Leave Without Pay" &&
    //   totalLeaveDays > leaveTypeBalance?.leaveAvailable
    // ) {
    //   displayPopup(CONSTANTS.LEAVE_UNAVAILABLE, "alert-danger");
    //   setIsFetching(false);
    // } else {
      const body = {
        cc: data.cc,
        description: data.description,
        employeeId: data.employee?.value,
        from_date: data.from_date,
        half_day: +data.half_day_check,
        half_day_info: activeHalfDay,
        leave_approver: data?.leave_approver?.value?.split("/")[1],
        leave_type: data.leave_type?.value,
        to_date: data.to_date,
        follow_via_email: 1,
        total_leave_days: totalLeaveDays,
      };
      try {
        const res = await http.post(`leave/apply`, body);
        setOpen(false);
        displayPopup(res?.data?.message, "alert-success");
        setIsFetching(false);
        getData();
        return res;
      } catch (e) {
        setOpen(false);
        setIsFetching(false);
        displayPopup(e.response.data.message, "alert-danger");
      }
    // }
  };
  const handleClose = () => {
    setOpen(false);
    reset();
    dispatch(resetAppliedLeave());
  };
  const halfDayData = async () => {
    try {
      let data = {
        from_date: watch("from_date"),
        to_date: watch("to_date"),
        employeeId: employeeId,
        leaveType: watch("leave_type"),
      };
      setTotalLeaveDays(0.5);
      const res = await http.post(`leave/halfDayLeave`, data);
      setHalfDay(res?.data?.response?.half_day_info);
      return res?.data?.response;
    } catch (e) {
      console.log("e:", e);
    }
  };
  const handleHalfDay = (e) => {
    setActiveHalfDay(e.target.value);
    setClick(e.target.id);
  };
  const fromDateChangeHandler = (date) => {
    setValue("from_date", dayjs(date).format("YYYY-MM-DD"));
    dispatch(resetAppliedLeave());

    if (watch("leave_type")?.value === "Maternity Leave") {
      const defaultToDate = dayjs(date).add(179, "days").format("YYYY-MM-DD");
      setValue("to_date", defaultToDate);
      validateLeave();
      const from_date = dayjs(date).format("YYYY-MM-DD");
      const daysDifference = dayjs(defaultToDate).diff(from_date, "days", true);

      // Update the totalLeaveDays state
      if (daysDifference) {
        setTotalLeaveDays(daysDifference + 1);
      }
    } else if (watch("leave_type")?.value === "Paternity Leave") {
      const defaultToDate = dayjs(date).add(1, "days").format("YYYY-MM-DD");
      setValue("to_date", defaultToDate);
      validateLeave();
      const from_date = dayjs(date).format("YYYY-MM-DD");
      const daysDifference = dayjs(defaultToDate).diff(from_date, "days", true);

      // Update the totalLeaveDays state
      if (daysDifference) {
        setTotalLeaveDays(daysDifference + 1);
      }
    } else {
      setValue("to_date", null);
      setTotalLeaveDays(0); // Reset totalLeaveDays for other leave types
    }
  };

  const toDateChangeHandler = async (date) => {
    const result = await validateLeave();
    setValue("to_date", dayjs(date).format("YYYY-MM-DD"));
   // const from_date = watch("from_date");
  //  const to_date = dayjs(date).format("YYYY-MM-DD");
   // const daysDifference = dayjs(to_date).diff(dayjs(from_date), "days", true);
    const calculatedTotalLeaveDays = watch("half_day_check")
      ? 0.5
      : result?.payload?.totalDaysApplied?.actualDaysApplied;

    // Update the totalLeaveDays state
    setTotalLeaveDays(calculatedTotalLeaveDays);
  };
  const LeaveTypeChangeHandler = async (newValue) => {
    try {
      dispatch(resetLeaveTypeBalance());
      dispatch(resetAppliedLeave());
      setValue("from_date", null);
      setValue("to_date", null);
      setValue("half_day_check", false);
      newValue &&
        (await dispatch(
          availableLeavesByEmployeeId({
            EmployeeId: employeeId,
            leaveType: newValue?.value,
          })
        ));
      let mxDate;
      if (newValue.value === "Restricted Holiday") {
        const { quarterEnd } = getCurrentQuarterStartMidEndDates();
        mxDate = quarterEnd;
      } else {
        const leaveTypeKey = newValue.value.split(" ").join("_");
        const daysToAdd = CONSTANTS.LEAVE_MAX_TO_DATE_MONTHS[leaveTypeKey];
        mxDate =
          newValue.value === "Sick Leave"
            ? dayjs(currentDate).add(2, "days").format("YYYY-MM-DD")
            : dayjs(startOfMonth).add(daysToAdd, "month").format("YYYY-MM-DD");
      }
      setmaxToDate(mxDate);
    } catch (e) {
      displayPopup(e?.response?.data?.message, "alert-danger");
    }
  };
  const handleEmployeeChange = (e) => {
    if (e) setEmployeeId(e?.value);
    dispatch(resetLeaveTypeBalance());
    setValue("leave_type", null);
    setValue("leave_approver", null);
    setValue("from_date", null);
    setValue("to_date", null);
    setValue("half_day_info", null);
    setValue("half_day_check", null);
    setValue("description", null);
    dispatch(resetAppliedLeave());
  };
  return (
    <Dialog
      open={open}
      //   TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      className="employee_form_dialog"
    >
      {isFetching && <Loader />}
      <DialogTitle className="add_employee_main_heading">
        <span>Apply Leave</span>
      </DialogTitle>
      <DialogContent className="card-content-employee-form">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="leaveAppFiscalYearData w-full">
              <div className="font-semibold text-black">
                Fiscal Year: {currFiscalYear}
              </div>
              <div className="flex justify-between flex-wrap whitespace-nowrap gap-3 bg-white font-semibold border border-solid border-gray-300 rounded-md p-2 mb-3">
                <div className="flex flex-col justify-between">
                  <p className="text-sm mr-2">Total Leaves Allocated : </p>
                  <p className="font-bold text-base text-gray-950">
                    {Number(leaveTypeBalance?.totalLeave).toFixed(2)}
                  </p>
                </div>
                <div className="flex flex-col justify-between">
                  <p className="text-sm mr-2">Current Leave Balance : </p>
                  <p className="font-bold text-base text-green-500">
                    {Number(leaveTypeBalance?.leaveAvailable).toFixed(2)}
                  </p>
                </div>
                <div className="flex flex-col justify-between">
                  <p className="text-sm mr-2">Open Leaves : </p>
                  <p className="font-bold text-base text-blue-500">
                    {Number(leaveTypeBalance?.leaveOpen).toFixed(2)}
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="text-sm">
                  Employee <span className="create_form_asterisk">*</span>:
                </label>
                {employeeList?.length > 1 ? (
                  <AutoCompleteComponent
                    name="employee"
                    id="employee"
                    control={control}
                    errors={errors}
                    options={employeeListData?.map((v) => ({
                      label: `${v?.EmployeeId} - ${v?.employee_name}`,
                      value: v?.EmployeeId,
                    }))}
                    onChangeHandler={handleEmployeeChange}
                  />
                ) : null}
              </div>
              <div className="col-md-6 mb-3">
                <label className="text-sm">
                  Leave Type <span className="create_form_asterisk">*</span>:
                </label>
                <AutoCompleteComponent
                  name="leave_type"
                  control={control}
                  errors={errors}
                  options={LeaveType.filter(
                    (data) => data?.leave_type_name !== "Work From Home"
                  ).map((v) => ({
                    label: v.leave_type_name,
                    value: v.leave_type_name,
                    maxAllowed: v.max_days_allowed,
                  }))}
                  onChangeHandler={LeaveTypeChangeHandler}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="text-sm">
                  Leave Approver <span className="create_form_asterisk">*</span>
                  :
                </label>
                <AutoCompleteComponent
                  name="leave_approver"
                  control={control}
                  errors={errors}
                  disabled={!watch("employee")}
                  // isFirstValueDefault={true}
                  options={LeaveApproveList?.map((v) => ({
                    label: v.approverName,
                    value: v.approverEmployeeId,
                    userId: v.approver_userId,
                  }))}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="text-sm">CC:</label>
                <AutoCompleteMultiSelect
                  name="cc"
                  control={control}
                  errors={errors}
                  options={EmployeeUserIds}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label className="text-sm">
                  {" "}
                  Employee Comment{" "}
                  <span className="create_form_asterisk">*</span>:
                </label>
                <TextAreaComponent
                  name="description"
                  control={control}
                  errors={errors}
                  rows="2"
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="text-sm">
                  From Date <span className="create_form_asterisk">*</span>:
                </label>
                <DatePickerComponent
                  errors={errors}
                  control={control}
                  name="from_date"
                  id="from_date"
                  disabled={!(watch("employee") && watch("leave_type"))}
                  placeHolder="YYYY-MM-DD"
                  disableMaskedInput={true}
                  backSpaceChange={false}
                  minDate={minLeaveAllowDate}
                  maxDate={maxToDate}
                  onHandleChange={fromDateChangeHandler}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="text-sm">
                  To Date <span className="create_form_asterisk">*</span>:
                </label>
                <DatePickerComponent
                  errors={errors}
                  control={control}
                  name="to_date"
                  id="to_date"
                  placeHolder="YYYY-MM-DD"
                  disabled={
                    !(watch("employee") && watch("leave_type")) ||
                    watch("half_day_check")
                  }
                  disableMaskedInput={true}
                  backSpaceChange={false}
                  minDate={
                    watch("from_date") ? watch("from_date") : minLeaveAllowDate
                  }
                  maxDate={maxToDate}
                  onHandleChange={toDateChangeHandler}
                />
              </div>

              <div className="col-md-12 mb-3">
                <div className="halfDay flex items-center justify-between">
                  <span className="col-5 checkbox-align">
                    <Controller
                      name="half_day_check"
                      control={control}
                      render={({ field: { onChange, name, value } }) => (
                        <Checkbox
                          name={name}
                          onChange={(e, val) => {
                            onChange(val);
                            if (val) {
                              halfDayData();
                            }
                          }}
                          checked={Boolean(value)}
                          className="p-0"
                          disabled={
                            !(watch("from_date") && watch("to_date")) ||
                            (watch("from_date") &&
                              watch("to_date") &&
                              moment(watch("to_date")).diff(
                                moment(watch("from_date")),
                                "days",
                                false
                              ) > 0)
                          }
                        />
                      )}
                    />
                    <span>Half Day</span>
                  </span>
                  {watch("half_day_check") &&
                  !moment(watch("to_date")).diff(
                    moment(watch("from_date")),
                    "days",
                    false
                  ) ? (
                    <>
                      <ul className="attanReguBtn list-unstyled flex items-center whitespace-nowrap gap-3">
                        <li className="w-100 p-0">
                          <button
                            id="firstHalf"
                            type="button"
                            name="half_day_info"
                            className={
                              click === "firstHalf"
                                ? "primarySquareBtn w-100"
                                : "outlinedSquareButton w-100"
                            }
                            onClick={(e) => handleHalfDay(e)}
                            disabled={
                              halfDay && halfDay === "First Half" ? true : false
                            }
                            value="First Half"
                          >
                            First Half
                          </button>
                        </li>
                        <li className="w-100 p-0">
                          <button
                            id="secondHalf"
                            type="button"
                            name="half_day_info"
                            className={
                              click === "secondHalf"
                                ? "primarySquareBtn w-100"
                                : "outlinedSquareButton w-100"
                            }
                            onClick={(e) => handleHalfDay(e)}
                            disabled={
                              halfDay && halfDay === "Second Half"
                                ? true
                                : false
                            }
                            value="Second Half"
                          >
                            Second Half
                          </button>
                        </li>
                      </ul>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <span className="col-7 text-end">
                  Total Leave Days :{" "}
                  {selectedLeaveIsFetching ? (
                    <Loader />
                  ) : (
                    <b>
                      {watch("half_day_check") ? 0.5 : totalSelectedLeave}{" "}
                      {totalSelectedLeave <= 1 ? "day" : "days"}
                    </b>
                  )}
                </span>
              </div>
            </div>
            <DialogActions className="employee_form_button_div">
              <button
                type="button"
                onClick={handleClose}
                className="btn cancelBtnOutline"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={!isDirty}
                id={!isDirty ? "newDisable" : ""}
                className="btn primaryBtnOutline"
              >
                Save
              </button>
            </DialogActions>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default ApplyLeaveDialog;
