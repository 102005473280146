import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import END_POINTS from "../../constants/endpoints";
import { toast } from "react-toastify";
import http from "../../utils/http";

// Core Competencies *********************************

export const fetchCoreCompetencies = createAsyncThunk(
  END_POINTS.GET_CORE_COMPETENCY,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.GET_CORE_COMPETENCY);
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createCoreCompetency = createAsyncThunk(
  END_POINTS.CREATE_CORE_COMPETENCY,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.post(
        `${END_POINTS.CREATE_CORE_COMPETENCY}`,
        params
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateCoreCompetency = createAsyncThunk(
  END_POINTS.UPDATE_CORE_COMPETENCY,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.patch(
        `${END_POINTS.UPDATE_CORE_COMPETENCY}/${params?.coreCompetencyId}`,
        {
          coreCompetencyName: params?.coreCompetencyName,
          coreCompetencyDescription: params?.coreCompetencyDescription,
        }
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const deleteCoreCompetency = createAsyncThunk(
  END_POINTS.DELETE_CORE_COMPETENCY,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.patch(
        `${END_POINTS.DELETE_CORE_COMPETENCY}/${params?.coreCompetencyId}`,
        {
          isActive: params?.isActive,
        }
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Appraisal Cycle *********************************

export const fetchAppraisalCycle = createAsyncThunk(
  END_POINTS.GET_APPRAISAL_CYCLE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.GET_APPRAISAL_CYCLE);
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createAppraisalCycle = createAsyncThunk(
  END_POINTS.CREATE_APPRAISAL_CYCLE,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.post(
        `${END_POINTS.CREATE_APPRAISAL_CYCLE}`,
        params
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// KPI  ********************************************

export const fetchKpiList = createAsyncThunk(
  END_POINTS.GET_KPI_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.GET_KPI_LIST);
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createKpiList = createAsyncThunk(
  END_POINTS.CREATE_KPI_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.post(`${END_POINTS.CREATE_KPI_LIST}`, params);
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateKpiList = createAsyncThunk(
  END_POINTS.UPDATE_KPI_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.patch(
        `${END_POINTS.UPDATE_KPI_LIST}/${params?.kpiId}`,
        {
          kpiName: params?.kpiName,
          kpiDescription: params?.kpiDescription,
        }
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const deleteKpiList = createAsyncThunk(
  END_POINTS.DELETE_KPI_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.patch(
        `${END_POINTS.DELETE_KPI_LIST}/${params?.kpiId}`,
        { isActive: params?.isActive }
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// KRA  ********************************************

export const fetchKraList = createAsyncThunk(
  END_POINTS.GET_KRA_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.GET_KRA_LIST);
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createKraList = createAsyncThunk(
  END_POINTS.CREATE_KRA_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.post(`${END_POINTS.CREATE_KRA_LIST}`, params);
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateKraList = createAsyncThunk(
  END_POINTS.UPDATE_KRA_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.patch(
        `${END_POINTS.UPDATE_KRA_LIST}/${params?.kraId}`,
        {
          kraName: params?.kraName,
          kraDescription: params?.kraDescription,
          kpiId: params?.kpiId,
        }
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const deleteKraList = createAsyncThunk(
  END_POINTS.DELETE_KRA_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.patch(
        `${END_POINTS.DELETE_KRA_LIST}/${params?.kraId}`,
        { isActive: params?.isActive }
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// KRA Association with Designation *****************************************************************

export const fetchKraDesignation = createAsyncThunk(
  END_POINTS.GET_KRA_DESIGNATION,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.GET_KRA_DESIGNATION}`, {
        params: {
          search: params?.search,
        },
      });
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createKraDesignation = createAsyncThunk(
  END_POINTS.CREATE_KRA_DESIGNATION,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.post(
        `${END_POINTS.CREATE_KRA_DESIGNATION}`,
        params
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateKraDesignation = createAsyncThunk(
  END_POINTS.UPDATE_KRA_DESIGNATION,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.patch(
        `${END_POINTS.UPDATE_KRA_DESIGNATION}/${
          params?.designationId?.split("/")[1]
        }`,
        { array: params?.array }
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const deleteKraDesignation = createAsyncThunk(
  END_POINTS.DELETE_KRA_DESIGNATION,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.patch(
        `${END_POINTS.DELETE_KRA_DESIGNATION}/${
          params?.designationId?.split("/")[1]
        }`,
        { isActive: params?.isActive }
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// KRA Association with Employee *****************************************************************

export const fetchEmployeeKra = createAsyncThunk(
  END_POINTS.GET_EMPLOYEE_KRA,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.GET_EMPLOYEE_KRA}`, {
        params: {
          search: params?.search,
        },
      });
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createEmployeeKra = createAsyncThunk(
  END_POINTS.CREATE_EMPLOYEE_KRA,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.post(`${END_POINTS.CREATE_EMPLOYEE_KRA}`, params);
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateEmployeeKra = createAsyncThunk(
  END_POINTS.UPDATE_EMPLOYEE_KRA,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.patch(
        `${END_POINTS.UPDATE_EMPLOYEE_KRA}/${params?.employeeId}`,
        { array: params?.array }
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const deleteEmployeeKra = createAsyncThunk(
  END_POINTS.DELETE_EMPLOYEE_KRA,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.patch(
        `${END_POINTS.DELETE_EMPLOYEE_KRA}/${params?.EmployeeId}`,
        { isActive: params?.isActive }
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

//  Adding Employees to Appraisal Cycle ***************************************

export const fetchEmployeesInAppraisalCycle = createAsyncThunk(
  END_POINTS.GET_EMPLOYEES_IN_APPRAISAL_CYCLE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_EMPLOYEES_IN_APPRAISAL_CYCLE}/${params?.appraisalId}`
      );
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const addEmployeesToAppraisalCycle = createAsyncThunk(
  END_POINTS.ADD_EMPLOYEES_TO_APPRAISAL_CYCLE,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.post(
        `${END_POINTS.ADD_EMPLOYEES_TO_APPRAISAL_CYCLE}`,
        params
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

//  Pms Setup Questionnaire ***************************************************

export const createQuestionnaire = createAsyncThunk(
  END_POINTS.CREATE_QUESTIONNAIRE,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.post(
        `${END_POINTS.CREATE_QUESTIONNAIRE}`,
        params
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const getEmployeesOfDesignation = createAsyncThunk(
  END_POINTS.GET_EMPLOYEES_OF_DESIGNATION,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_EMPLOYEES_OF_DESIGNATION}`,
        { params }
      );
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Continuous Evaluation Cycles *********************************

export const fetchContinuousEvaluation = createAsyncThunk(
  END_POINTS.GET_CONTINUOUS_EVALUATION,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(END_POINTS.GET_CONTINUOUS_EVALUATION);
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createContinuousEvaluation = createAsyncThunk(
  END_POINTS.CREATE_CONTINUOUS_EVALUATION,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.post(
        `${END_POINTS.CREATE_CONTINUOUS_EVALUATION}`,
        params
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateContinuousEvaluation = createAsyncThunk(
  END_POINTS.UPDATE_CONTINUOUS_EVALUATION,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.patch(
        `${END_POINTS.UPDATE_CONTINUOUS_EVALUATION}/${params?.Id}`,
        params
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const deleteContinuousEvaluation = createAsyncThunk(
  END_POINTS.DELETE_CONTINUOUS_EVALUATION,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.patch(
        `${END_POINTS.DELETE_CONTINUOUS_EVALUATION}/${params?.Id}`,
        params
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Adding and Fetching Employees to a Continuous Evaluation Cycle ****************************************************

export const addEmployeesToContinuousEvaluation = createAsyncThunk(
  END_POINTS.ADD_EMPLOYEES_TO_CONTINUOUS_EVALUATION,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.patch(
        `${END_POINTS.ADD_EMPLOYEES_TO_CONTINUOUS_EVALUATION}/${params?.Id}`,
        {
          data: params?.data,
        }
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchEmployeesInContinuousEvaluationCycle = createAsyncThunk(
  END_POINTS.GET_EMPLOYEES_IN_CONTINUOUS_EVALUATION,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_EMPLOYEES_IN_CONTINUOUS_EVALUATION}/${params?.Id}`,
        {
          params: {
            fiscalYear: params?.fiscalYear,
            startDate: params?.startDate,
            endDate: params?.endDate,
            search: params?.search,
            limit: params.limit,
            page: params.page,
          },
        }
      );
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Removing employee from the continuous evaluation cycle

export const removeEmployeeFromContinuousEvaluationCycle = createAsyncThunk(
  END_POINTS.REMOVE_EMPLOYEE_FROM_CONTINUOUS_EVALUATION,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.REMOVE_EMPLOYEE_FROM_CONTINUOUS_EVALUATION}`,
        params
      );
      toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Get Employee Traits for Continuous Evaluation Cycle

export const getEmployeeTraitsForContinuousEvaluationCycle = createAsyncThunk(
  END_POINTS.GET_EMPLOYEE_TRAITS_FOR_CONTINUOUS_EVALUATION,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_EMPLOYEE_TRAITS_FOR_CONTINUOUS_EVALUATION}/${params?.employeeId}`
      );
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Submit Employee Form for Continuous Evaluation

export const submitEmployeeFormContinuousEvaluation = createAsyncThunk(
  END_POINTS.SUBMIT_EMPLOYEE_FORM_CONTINUOUS_EVALUATION,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.patch(
        `${END_POINTS.SUBMIT_EMPLOYEE_FORM_CONTINUOUS_EVALUATION}`,
        params
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Get Employee Form details for Continuous Evaluation Cycle

export const getEmployeeContinuousEvaluationFormDetails = createAsyncThunk(
  END_POINTS.GET_EMPLOYEE_CONTINUOUS_EVALUATION_FORM_DETAILS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_EMPLOYEE_CONTINUOUS_EVALUATION_FORM_DETAILS}/${params?.employeeId}`,
        {
          params: {
            cycleId: params.cycleId,
            approverId: params.approverId,
            subCycleStartDate: params?.subCycleStartDate,
            subCycleEndDate: params?.subCycleEndDate,
            fiscalYear: params?.fiscalYear,
          },
        }
      );
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Get Employee Form Traits for Continuous Evaluation Cycle

export const getEmployeeFormTraitsContinuousEvaluation = createAsyncThunk(
  END_POINTS.GET_EMPLOYEE_FORM_TRAITS_CONTINUOUS_EVALUATION,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_EMPLOYEE_FORM_TRAITS_CONTINUOUS_EVALUATION}`,
        { params: { ...params } }
      );
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Annual Appraisal Cycle Config CRUD

export const getAnnualAppraisalCycleList = createAsyncThunk(
  END_POINTS.GET_ANNUAL_APPRAISAL_CYCLE_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_ANNUAL_APPRAISAL_CYCLE_LIST}`
      );
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createAnnualAppraisalCycleConfig = createAsyncThunk(
  END_POINTS.CREATE_ANNUAL_APPRAISAL_CYCLE,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.post(
        `${END_POINTS.CREATE_ANNUAL_APPRAISAL_CYCLE}`,
        params
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateAnnualAppraisalCycleConfig = createAsyncThunk(
  END_POINTS.UPDATE_ANNUAL_APPRAISAL_CYCLE,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.patch(
        `${END_POINTS.UPDATE_ANNUAL_APPRAISAL_CYCLE}/${params?.Id}`,
        params
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const deleteAnnualAppraisalCycleConfig = createAsyncThunk(
  END_POINTS.DELETE_ANNUAL_APPRAISAL_CYCLE,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.patch(
        `${END_POINTS.DELETE_ANNUAL_APPRAISAL_CYCLE}/${params?.Id}`,
        params
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Annual Appraisal Traits CRUD

export const getAnnualAppraisalTraitList = createAsyncThunk(
  END_POINTS.GET_ANNUAL_APPRAISAL_TRAIT_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_ANNUAL_APPRAISAL_TRAIT_LIST}`
      );
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createAnnualAppraisalTrait = createAsyncThunk(
  END_POINTS.CREATE_ANNUAL_APPRAISAL_TRAIT,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.post(
        `${END_POINTS.CREATE_ANNUAL_APPRAISAL_TRAIT}`,
        params
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateAnnualAppraisalTrait = createAsyncThunk(
  END_POINTS.UPDATE_ANNUAL_APPRAISAL_TRAIT,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.patch(
        `${END_POINTS.UPDATE_ANNUAL_APPRAISAL_TRAIT}/${params?.Id}`,
        params
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const deleteAnnualAppraisalTrait = createAsyncThunk(
  END_POINTS.DELETE_ANNUAL_APPRAISAL_TRAIT,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.patch(
        `${END_POINTS.DELETE_ANNUAL_APPRAISAL_TRAIT}/${params?.Id}`,
        params
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// To fetch the list of filtered employees that are filtered based on the conditions given in the appraisal cycle config like Department , Designation, etc.
export const fetchAnnualAppraisalFilteredEmployees = createAsyncThunk(
  END_POINTS.ANNUL_APPRAISAL_FETCH_FILTERED_EMPLOYEES,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.get(
        `${END_POINTS.ANNUL_APPRAISAL_FETCH_FILTERED_EMPLOYEES}`,
        {
          params: {
            location: params?.location,
            designation: params?.designation,
            department: params?.department,
            date_of_joining_flag: params?.date_of_joining_flag,
            joining_date_until: params?.joining_date_until,
            joining_date_from: params?.joining_date_from,
            joining_date_to: params?.joining_date_to,
            joining_date_months: params?.joining_date_months,
          },
        }
      );
      // toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// To add employees to an appraisal cycle
export const addEmployeesToAnnualAppraisalCycle = createAsyncThunk(
  END_POINTS.ADD_EMPLOYEES_TO_ANNUAL_APPRAISAL_CYCLE,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.post(
        `${END_POINTS.ADD_EMPLOYEES_TO_ANNUAL_APPRAISAL_CYCLE}/${params?.Id}`,
        params
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// To fetch the list of employees that are present in an appraisal cycle
export const fetchEmployeesInAnnualAppraisalCycle = createAsyncThunk(
  END_POINTS.FETCH_EMPLOYEES_IN_ANNUAL_APPRAISAL_CYCLE,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.get(
        `${END_POINTS.FETCH_EMPLOYEES_IN_ANNUAL_APPRAISAL_CYCLE}/${params?.Id}`,
        {
          params: {
            search: params?.search,
          },
        }
      );
      // toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// To fetch the list of reviewers that are present in an appraisal cycle
export const fetchReviewersOfAnnualAppraisalCycle = createAsyncThunk(
  END_POINTS.FETCH_REVIEWERS_OF_ANNUAL_APPRAISAL_CYCLE,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.get(
        `${END_POINTS.FETCH_REVIEWERS_OF_ANNUAL_APPRAISAL_CYCLE}/${params?.Id}`,
        {
          params: {
            employeeId: params?.employeeId,
            fiscalYear: params?.fiscalYear,
          },
        }
      );
      // toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// To remove employees from an appraisal cycle
export const removeEmployeeFromAnnualAppraisalCycle = createAsyncThunk(
  END_POINTS.REMOVE_EMPLOYEE_FROM_ANNUAL_APPRAISAL_CYCLE,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.patch(
        `${END_POINTS.REMOVE_EMPLOYEE_FROM_ANNUAL_APPRAISAL_CYCLE}/${params?.Id}`,
        params
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// To fetch the list of employees that does not belong to any appraisal cycle
export const fetchEmployeeWithNoAppraisalCycle = createAsyncThunk(
  END_POINTS.FETCH_EMPLOYEES_WITH_NO_APPRAISAL_CYCLE,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.get(
        `${END_POINTS.FETCH_EMPLOYEES_WITH_NO_APPRAISAL_CYCLE}`,
        params
      );
      // toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// To allocate traits to annual appraisal cycle
export const allocateTraitsToAppraisalCycle = createAsyncThunk(
  END_POINTS.ALLOCATE_TRAITS_TO_APPRAISAL_CYCLE,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.post(
        `${END_POINTS.ALLOCATE_TRAITS_TO_APPRAISAL_CYCLE}`,
        params
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// To fetch the list of all the traits allocated to all the cycles
export const fetchTraitsAllocatedToAppraisalCycle = createAsyncThunk(
  END_POINTS.FETCH_TRAITS_ALLOCATED_TO_APPRAISAL_CYCLE,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.get(
        `${END_POINTS.FETCH_TRAITS_ALLOCATED_TO_APPRAISAL_CYCLE}`,
        params
      );
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// To fetch the list of all the traits allocated to a cycle by Id
export const fetchTraitsAllocatedToAppraisalCycleById = createAsyncThunk(
  END_POINTS.FETCH_TRAITS_ALLOCATED_TO_APPRAISAL_CYCLE_BY_ID,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.get(
        `${END_POINTS.FETCH_TRAITS_ALLOCATED_TO_APPRAISAL_CYCLE_BY_ID}/${params?.cycleId}`
      );
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Update allocated traits to annual appraisal cycle
export const updateTraitsAllocatedToAppraisalCycle = createAsyncThunk(
  END_POINTS.UPDATE_TRAITS_ALLOCATED_TO_APPRAISAL_CYCLE,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.patch(
        `${END_POINTS.UPDATE_TRAITS_ALLOCATED_TO_APPRAISAL_CYCLE}/${params?.Id}`,
        params
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Delete allocated traits to annual appraisal cycle
export const deleteTraitsAllocatedToAppraisalCycle = createAsyncThunk(
  END_POINTS.DELETE_TRAITS_ALLOCATED_TO_APPRAISAL_CYCLE,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.patch(
        `${END_POINTS.DELETE_TRAITS_ALLOCATED_TO_APPRAISAL_CYCLE}/${params?.Id}`,
        {
          params: {
            data: params?.data,
          },
        }
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// To submit employee annual appraisal form
export const submitEmployeeAnnualAppraisalForm = createAsyncThunk(
  END_POINTS.EMPLOYEE_APPRAISAL_FORM_SUBMIT,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.patch(
        `${END_POINTS.EMPLOYEE_APPRAISAL_FORM_SUBMIT}`,
        params
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Fetch employee appraisal form details
export const getEmployeeAnnualAppraisalFormDetails = createAsyncThunk(
  END_POINTS.GET_EMPLOYEE_ANNUAL_APPRAISAL_FORM_DETAILS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_EMPLOYEE_ANNUAL_APPRAISAL_FORM_DETAILS}/${params?.employeeId}`,
        {
          params: {
            cycleId: params.cycleId,
            reviewerId: params.reviewerId,
            fiscalYear: params?.fiscalYear,
          },
        }
      );
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Pms Badges CRUD

export const getPmsBadgesList = createAsyncThunk(
  END_POINTS.GET_PMS_BADGES_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.GET_PMS_BADGES_LIST}`);
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createPmsBadge = createAsyncThunk(
  END_POINTS.CREATE_PMS_BADGE,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.post(`${END_POINTS.CREATE_PMS_BADGE}`, params);
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updatePmsBadge = createAsyncThunk(
  END_POINTS.UPDATE_PMS_BADGE,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.patch(
        `${END_POINTS.UPDATE_PMS_BADGE}/${params?.Id}`,
        params
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const deletePmsBadge = createAsyncThunk(
  END_POINTS.DELETE_PMS_BADGE,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.patch(
        `${END_POINTS.DELETE_PMS_BADGE}/${params?.Id}`,
        params
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const getPmsBadgeIconsNameList = createAsyncThunk(
  END_POINTS.GET_PMS_BADGE_ICONS_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.GET_PMS_BADGE_ICONS_LIST}`);
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createPmsBadgeIconName = createAsyncThunk(
  END_POINTS.CREATE_PMS_BADGE_ICON,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.post(
        `${END_POINTS.CREATE_PMS_BADGE_ICON}`,
        params
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const getAllocatedBadgesToEmployee = createAsyncThunk(
  END_POINTS.FETCH_ALLOCATED_BADGES,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.FETCH_ALLOCATED_BADGES}/${params?.employeeId}`,
        {
          params: {
            cycleId: params?.cycleId,
            fiscalYear: params?.fiscalYear,
          },
        }
      );
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const allocateBadgeToEmployee = createAsyncThunk(
  END_POINTS.PMS_BADGE_ALLOCATION,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.post(
        `${END_POINTS.PMS_BADGE_ALLOCATION}`,
        params
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Fetch annual appraisal highest rating holders list
export const getAnnualAppraisalHighestRatingsHolders = createAsyncThunk(
  END_POINTS.GET_ANNUAL_APPRAISAL_HIGHEST_RATINGS_HOLDERS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_ANNUAL_APPRAISAL_HIGHEST_RATINGS_HOLDERS}`,
        {
          params: {
            limit: params?.limit,
            fiscalYear: params?.fiscalYear,
          },
        }
      );
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Fetch annual appraisal lowest rating holders list
export const getAnnualAppraisalLowestRatingsHolders = createAsyncThunk(
  END_POINTS.GET_ANNUAL_APPRAISAL_LOWEST_RATINGS_HOLDERS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_ANNUAL_APPRAISAL_LOWEST_RATINGS_HOLDERS}`,
        {
          params: {
            limit: params?.limit,
            fiscalYear: params?.fiscalYear,
          },
        }
      );
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Fetch annual appraisal badge holders list
export const getAnnualAppraisalBadgeHolders = createAsyncThunk(
  END_POINTS.GET_ANNUAL_APPRAISAL_BADGE_HOLDERS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_ANNUAL_APPRAISAL_BADGE_HOLDERS}`,
        {
          params: {
            limit: params?.limit,
            fiscalYear: params?.fiscalYear,
          },
        }
      );
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Fetch list of employees and their cycle wise appraisal status
export const getCycleWiseAppraisalStatus = createAsyncThunk(
  END_POINTS.GET_CYCLE_WISE_APPRAISAL_STATUS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_CYCLE_WISE_APPRAISAL_STATUS}/${params?.cycleId}`,
        {
          params: {
            fiscalYear: params?.fiscalYear,
            status: params?.status,
          },
        }
      );
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Pms Annual Appraisal Self Form Get self appraisal cycles
export const getSelfAppraisalCycles = createAsyncThunk(
  END_POINTS.GET_SELF_APPRAISAL_CYCLES,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_SELF_APPRAISAL_CYCLES}`
      );
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// To fetch the list of reviewers that are present in the Self appraisal cycle
export const fetchReviewersOfSelfAnnualAppraisalCycle = createAsyncThunk(
  END_POINTS.FETCH_REVIEWERS_OF_SELF_ANNUAL_APPRAISAL_CYCLE,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.get(
        `${END_POINTS.FETCH_REVIEWERS_OF_SELF_ANNUAL_APPRAISAL_CYCLE}/${params?.Id}`,
        {
          params: {
            employeeId: params?.employeeId,
            fiscalYear: params?.fiscalYear,
          },
        }
      );
      // toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// Fetch self appraisal form details
export const getSelfAnnualAppraisalFormDetails = createAsyncThunk(
  END_POINTS.GET_SELF_ANNUAL_APPRAISAL_FORM_DETAILS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_SELF_ANNUAL_APPRAISAL_FORM_DETAILS}`,
        {
          params: {
            cycleId: params.cycleId,
            reviewerId: params.reviewerId,
            fiscalYear: params?.fiscalYear,
          },
        }
      );
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed To Perform Operation"
      );
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// To submit self annual appraisal form
export const submitSelfAnnualAppraisalForm = createAsyncThunk(
  END_POINTS.SELF_APPRAISAL_FORM_SUBMIT,
  async (params, { rejectWithValue }) => {
    try {
      const data = await http.patch(
        `${END_POINTS.SELF_APPRAISAL_FORM_SUBMIT}`,
        params
      );
      toast.success(data?.data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

const initialState = {
  getCoreCompetencyData: {
    isFetching: false,
    response: [],
  },
  createCoreCompetencyData: {
    isFetching: false,
    response: null,
  },
  updateCoreCompetencyData: {
    isFetching: false,
    response: null,
  },
  deleteCoreCompetencyData: {
    isFetching: false,
    response: null,
  },
  getAppraisalCycleData: {
    isFetching: false,
    response: [],
  },
  createAppraisalCycleData: {
    isFetching: false,
    response: null,
  },
  getKpiListData: {
    isFetching: false,
    response: [],
  },
  createKpiListData: {
    isFetching: false,
    response: null,
  },
  updateKpiListData: {
    isFetching: false,
    response: null,
  },
  deleteKpiListData: {
    isFetching: false,
    response: null,
  },
  getKraListData: {
    isFetching: false,
    response: [],
  },
  createKraListData: {
    isFetching: false,
    response: null,
  },
  updateKraListData: {
    isFetching: false,
    response: null,
  },
  deleteKraListData: {
    isFetching: false,
    response: null,
  },
  getKraDesignationData: {
    isFetching: false,
    response: [],
  },
  createKraDesignationData: {
    isFetching: false,
    response: null,
  },
  updateKraDesignationData: {
    isFetching: false,
    response: null,
  },
  deleteKraDesignationData: {
    isFetching: false,
    response: null,
  },
  getEmployeeKraData: {
    isFetching: false,
    response: [],
  },
  createEmployeeKraData: {
    isFetching: false,
    response: null,
  },
  updateEmployeeKraData: {
    isFetching: false,
    response: null,
  },
  deleteEmployeeKraData: {
    isFetching: false,
    response: null,
  },
  getEmployeesInAppraisalCycleData: {
    isFetching: false,
    response: [],
  },
  addEmployeesToAppraisalCycleData: {
    isFetching: false,
    response: null,
  },
  createQuestionnaireData: {
    isFetching: false,
    response: null,
  },
  getEmployeesOfDesignationData: {
    isFetching: false,
    response: null,
  },
  getContinuousEvaluationData: {
    isFetching: false,
    response: [],
  },
  createContinuousEvaluationData: {
    isFetching: false,
    response: null,
  },
  updateContinuousEvaluationData: {
    isFetching: false,
    response: null,
  },
  deleteContinuousEvaluationData: {
    isFetching: false,
    response: null,
  },
  addEmployeesToContinuousEvaluationData: {
    isFetching: false,
    response: null,
  },
  fetchEmployeesInContinuousEvaluationData: {
    isFetching: false,
    response: null,
  },
  removeEmployeeFromContinuousEvaluationData: {
    isFetching: false,
    response: null,
  },
  getEmployeeTraitsForContinuousEvaluationCycleData: {
    isFetching: false,
    response: null,
  },
  submitEmployeeFormContinuousEvaluationData: {
    isFetching: false,
    response: null,
  },
  getEmployeeContinuousEvaluationFormDetailsData: {
    isFetching: false,
    response: null,
  },
  getEmployeeFormTraitsContinuousEvaluationData: {
    isFetching: false,
    response: null,
  },
  getAnnualAppraisalCycleListData: {
    isFetching: false,
    response: null,
  },
  createAnnualAppraisalCycleConfigData: {
    isFetching: false,
    response: null,
  },
  updateAnnualAppraisalCycleConfigData: {
    isFetching: false,
    response: null,
  },
  deleteAnnualAppraisalCycleConfigData: {
    isFetching: false,
    response: null,
  },
  getAnnualAppraisalTraitListData: {
    isFetching: false,
    response: null,
  },
  createAnnualAppraisalTraitData: {
    isFetching: false,
    response: null,
  },
  updateAnnualAppraisalTraitData: {
    isFetching: false,
    response: null,
  },
  deleteAnnualAppraisalTraitData: {
    isFetching: false,
    response: null,
  },
  fetchAnnualAppraisalFilteredEmployeesData: {
    isFetching: false,
    response: null,
  },
  addEmployeesToAnnualAppraisalCycleData: {
    isFetching: false,
    response: null,
  },
  fetchEmployeesInAnnualAppraisalCycleData: {
    isFetching: false,
    response: null,
  },
  fetchReviewersOfAnnualAppraisalCycleData: {
    isFetching: false,
    response: null,
  },
  removeEmployeeFromAnnualAppraisalCycleData: {
    isFetching: false,
    response: null,
  },
  fetchEmployeeWithNoAppraisalCycleData: {
    isFetching: false,
    response: null,
  },
  allocateTraitsToAppraisalCycleData: {
    isFetching: false,
    response: null,
  },
  fetchTraitsAllocatedToAppraisalCycleData: {
    isFetching: false,
    response: null,
  },
  fetchTraitsAllocatedToAppraisalCycleDataById: {
    isFetching: false,
    response: null,
  },
  updateTraitsAllocatedToAppraisalCycleData: {
    isFetching: false,
    response: null,
  },
  deleteTraitsAllocatedToAppraisalCycleData: {
    isFetching: false,
    response: null,
  },
  submitEmployeeAnnualAppraisalFormData: {
    isFetching: false,
    response: null,
  },
  getEmployeeAnnualAppraisalFormDetailsData: {
    isFetching: false,
    response: null,
  },
  getPmsBadgesListData: {
    isFetching: false,
    response: null,
  },
  createPmsBadgeData: {
    isFetching: false,
    response: null,
  },
  updatePmsBadgeData: {
    isFetching: false,
    response: null,
  },
  deletePmsBadgeData: {
    isFetching: false,
    response: null,
  },
  getPmsBadgeIconsNameListData: {
    isFetching: false,
    response: null,
  },
  createPmsBadgeIconNameData: {
    isFetching: false,
    response: null,
  },
  getAllocatedBadgesToEmployeeData: {
    isFetching: false,
    response: null,
  },
  allocateBadgeToEmployeeData: {
    isFetching: false,
    response: null,
  },
  getAnnualAppraisalHighestRatingsHoldersData: {
    isFetching: false,
    response: null,
  },
  getAnnualAppraisalLowestRatingsHoldersData: {
    isFetching: false,
    response: null,
  },
  getAnnualAppraisalBadgeHoldersData: {
    isFetching: false,
    response: null,
  },
  getCycleWiseAppraisalStatusData: {
    isFetching: false,
    response: null,
  },
  getSelfAppraisalCyclesData: {
    isFetching: false,
    response: null,
  },
  fetchReviewersOfSelfAnnualAppraisalCycleData: {
    isFetching: false,
    response: null,
  },
  getSelfAnnualAppraisalFormDetailsData: {
    isFetching: false,
    response: null,
  },
  submitSelfAnnualAppraisalFormData: {
    isFetching: false,
    response: null,
  },
  loading: false,
  error: null,
  success: false,
};

export const pmsSlice = createSlice({
  name: "pms",
  initialState,
  reducers: [],
  extraReducers: (builder) => {
    builder
      .addCase(fetchCoreCompetencies.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getCoreCompetencyData = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(fetchCoreCompetencies.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getCoreCompetencyData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(fetchCoreCompetencies.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getCoreCompetencyData = {
          isFetching: false,
          response: [],
        };
        state.success = false;
      })
      .addCase(createCoreCompetency.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createCoreCompetencyData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(createCoreCompetency.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.createCoreCompetencyData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(createCoreCompetency.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createCoreCompetencyData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(updateCoreCompetency.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateCoreCompetencyData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(updateCoreCompetency.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.updateCoreCompetencyData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(updateCoreCompetency.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.updateCoreCompetencyData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(deleteCoreCompetency.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.deleteCoreCompetencyData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(deleteCoreCompetency.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.deleteCoreCompetencyData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(deleteCoreCompetency.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.deleteCoreCompetencyData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(fetchAppraisalCycle.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getAppraisalCycleData = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(fetchAppraisalCycle.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getAppraisalCycleData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(fetchAppraisalCycle.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getAppraisalCycleData = {
          isFetching: false,
          response: [],
        };
        state.success = false;
      })
      .addCase(createAppraisalCycle.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createAppraisalCycleData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(createAppraisalCycle.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.createAppraisalCycleData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(createAppraisalCycle.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createAppraisalCycleData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(fetchKpiList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getKpiListData = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(fetchKpiList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getKpiListData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(fetchKpiList.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getKpiListData = {
          isFetching: false,
          response: [],
        };
        state.success = false;
      })
      .addCase(createKpiList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createKpiListData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(createKpiList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.createKpiListData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(createKpiList.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createKpiListData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(updateKpiList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateKpiListData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(updateKpiList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.updateKpiListData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(updateKpiList.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.updateKpiListData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(deleteKpiList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.deleteKpiListData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(deleteKpiList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.deleteKpiListData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(deleteKpiList.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.deleteKpiListData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(fetchKraList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getKraListData = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(fetchKraList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getKraListData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(fetchKraList.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getKraListData = {
          isFetching: false,
          response: [],
        };
        state.success = false;
      })
      .addCase(createKraList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createKraListData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(createKraList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.createKraListData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(createKraList.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createKraListData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(updateKraList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateKraListData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(updateKraList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.updateKraListData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(updateKraList.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.updateKraListData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(deleteKraList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.deleteKraListData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(deleteKraList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.deleteKraListData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(deleteKraList.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.deleteKraListData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(fetchKraDesignation.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getKraDesignationData = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(fetchKraDesignation.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getKraDesignationData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(fetchKraDesignation.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getKraDesignationData = {
          isFetching: false,
          response: [],
        };
        state.success = false;
      })
      .addCase(createKraDesignation.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createKraDesignationData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(createKraDesignation.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.createKraDesignationData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(createKraDesignation.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createKraDesignationData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(updateKraDesignation.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateKraDesignationData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(updateKraDesignation.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.updateKraDesignationData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(updateKraDesignation.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.updateKraDesignationData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(deleteKraDesignation.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.deleteKraDesignationData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(deleteKraDesignation.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.deleteKraDesignationData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(deleteKraDesignation.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.deleteKraDesignationData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(fetchEmployeeKra.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getEmployeeKraData = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(fetchEmployeeKra.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getEmployeeKraData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(fetchEmployeeKra.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getEmployeeKraData = {
          isFetching: false,
          response: [],
        };
        state.success = false;
      })
      .addCase(createEmployeeKra.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createEmployeeKraData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(createEmployeeKra.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.createEmployeeKraData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(createEmployeeKra.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createEmployeeKraData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(updateEmployeeKra.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateEmployeeKraData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(updateEmployeeKra.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.updateEmployeeKraData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(updateEmployeeKra.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.updateEmployeeKraData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(deleteEmployeeKra.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.deleteEmployeeKraData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(deleteEmployeeKra.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.deleteEmployeeKraData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(deleteEmployeeKra.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.deleteEmployeeKraData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(fetchEmployeesInAppraisalCycle.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getEmployeesInAppraisalCycleData = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(
        fetchEmployeesInAppraisalCycle.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.getEmployeesInAppraisalCycleData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        fetchEmployeesInAppraisalCycle.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.getEmployeesInAppraisalCycleData = {
            isFetching: false,
            response: [],
          };
          state.success = false;
        }
      )
      .addCase(addEmployeesToAppraisalCycle.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.addEmployeesToAppraisalCycleData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(addEmployeesToAppraisalCycle.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.addEmployeesToAppraisalCycleData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(addEmployeesToAppraisalCycle.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.addEmployeesToAppraisalCycleData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(createQuestionnaire.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createQuestionnaireData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(createQuestionnaire.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.createQuestionnaireData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(createQuestionnaire.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createQuestionnaireData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(getEmployeesOfDesignation.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getEmployeesOfDesignationData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(getEmployeesOfDesignation.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getEmployeesOfDesignationData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(getEmployeesOfDesignation.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getEmployeesOfDesignationData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(fetchContinuousEvaluation.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getContinuousEvaluationData = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(fetchContinuousEvaluation.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getContinuousEvaluationData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(fetchContinuousEvaluation.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getContinuousEvaluationData = {
          isFetching: false,
          response: [],
        };
        state.success = false;
      })
      .addCase(createContinuousEvaluation.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createContinuousEvaluationData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(createContinuousEvaluation.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.createContinuousEvaluationData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(createContinuousEvaluation.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createContinuousEvaluationData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(updateContinuousEvaluation.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateContinuousEvaluationData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(updateContinuousEvaluation.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.updateContinuousEvaluationData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(updateContinuousEvaluation.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.updateContinuousEvaluationData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(deleteContinuousEvaluation.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.deleteContinuousEvaluationData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(deleteContinuousEvaluation.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.deleteContinuousEvaluationData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(deleteContinuousEvaluation.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.deleteContinuousEvaluationData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(addEmployeesToContinuousEvaluation.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.addEmployeesToContinuousEvaluationData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(
        addEmployeesToContinuousEvaluation.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.addEmployeesToContinuousEvaluationData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        addEmployeesToContinuousEvaluation.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.addEmployeesToContinuousEvaluationData = {
            isFetching: false,
            response: null,
          };
          state.success = false;
        }
      )
      .addCase(fetchEmployeesInContinuousEvaluationCycle.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.fetchEmployeesInContinuousEvaluationData = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(
        fetchEmployeesInContinuousEvaluationCycle.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.fetchEmployeesInContinuousEvaluationData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        fetchEmployeesInContinuousEvaluationCycle.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.fetchEmployeesInContinuousEvaluationData = {
            isFetching: false,
            response: [],
          };
          state.success = false;
        }
      )
      .addCase(removeEmployeeFromContinuousEvaluationCycle.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.removeEmployeeFromContinuousEvaluationData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(
        removeEmployeeFromContinuousEvaluationCycle.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.removeEmployeeFromContinuousEvaluationData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        removeEmployeeFromContinuousEvaluationCycle.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.removeEmployeeFromContinuousEvaluationData = {
            isFetching: false,
            response: null,
          };
          state.success = false;
        }
      )
      .addCase(
        getEmployeeTraitsForContinuousEvaluationCycle.pending,
        (state) => {
          state.loading = true;
          state.error = null;
          state.getEmployeeTraitsForContinuousEvaluationCycleData = {
            isFetching: true,
            response: [],
          };
        }
      )
      .addCase(
        getEmployeeTraitsForContinuousEvaluationCycle.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.getEmployeeTraitsForContinuousEvaluationCycleData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        getEmployeeTraitsForContinuousEvaluationCycle.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.getEmployeeTraitsForContinuousEvaluationCycleData = {
            isFetching: false,
            response: [],
          };
          state.success = false;
        }
      )
      .addCase(submitEmployeeFormContinuousEvaluation.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.submitEmployeeFormContinuousEvaluationData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(
        submitEmployeeFormContinuousEvaluation.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.submitEmployeeFormContinuousEvaluationData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        submitEmployeeFormContinuousEvaluation.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.submitEmployeeFormContinuousEvaluationData = {
            isFetching: false,
            response: null,
          };
          state.success = false;
        }
      )
      .addCase(getEmployeeContinuousEvaluationFormDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getEmployeeContinuousEvaluationFormDetailsData = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(
        getEmployeeContinuousEvaluationFormDetails.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.getEmployeeContinuousEvaluationFormDetailsData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        getEmployeeContinuousEvaluationFormDetails.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.getEmployeeContinuousEvaluationFormDetailsData = {
            isFetching: false,
            response: [],
          };
          state.success = false;
        }
      )
      .addCase(getEmployeeFormTraitsContinuousEvaluation.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getEmployeeFormTraitsContinuousEvaluationData = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(
        getEmployeeFormTraitsContinuousEvaluation.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.getEmployeeFormTraitsContinuousEvaluationData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        getEmployeeFormTraitsContinuousEvaluation.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.getEmployeeFormTraitsContinuousEvaluationData = {
            isFetching: false,
            response: [],
          };
          state.success = false;
        }
      )
      .addCase(getAnnualAppraisalCycleList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getAnnualAppraisalCycleListData = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(getAnnualAppraisalCycleList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getAnnualAppraisalCycleListData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(getAnnualAppraisalCycleList.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getAnnualAppraisalCycleListData = {
          isFetching: false,
          response: [],
        };
        state.success = false;
      })
      .addCase(createAnnualAppraisalCycleConfig.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createAnnualAppraisalCycleConfigData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(
        createAnnualAppraisalCycleConfig.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.createAnnualAppraisalCycleConfigData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        createAnnualAppraisalCycleConfig.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.createAnnualAppraisalCycleConfigData = {
            isFetching: false,
            response: null,
          };
          state.success = false;
        }
      )
      .addCase(updateAnnualAppraisalCycleConfig.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateAnnualAppraisalCycleConfigData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(
        updateAnnualAppraisalCycleConfig.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.updateAnnualAppraisalCycleConfigData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        updateAnnualAppraisalCycleConfig.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.updateAnnualAppraisalCycleConfigData = {
            isFetching: false,
            response: null,
          };
          state.success = false;
        }
      )
      .addCase(deleteAnnualAppraisalCycleConfig.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.deleteAnnualAppraisalCycleConfigData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(
        deleteAnnualAppraisalCycleConfig.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.deleteAnnualAppraisalCycleConfigData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        deleteAnnualAppraisalCycleConfig.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.deleteAnnualAppraisalCycleConfigData = {
            isFetching: false,
            response: null,
          };
          state.success = false;
        }
      )
      .addCase(getAnnualAppraisalTraitList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getAnnualAppraisalTraitListData = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(getAnnualAppraisalTraitList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getAnnualAppraisalTraitListData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(getAnnualAppraisalTraitList.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getAnnualAppraisalTraitListData = {
          isFetching: false,
          response: [],
        };
        state.success = false;
      })
      .addCase(createAnnualAppraisalTrait.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createAnnualAppraisalTraitData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(createAnnualAppraisalTrait.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.createAnnualAppraisalTraitData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(createAnnualAppraisalTrait.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createAnnualAppraisalTraitData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(updateAnnualAppraisalTrait.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateAnnualAppraisalTraitData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(updateAnnualAppraisalTrait.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.updateAnnualAppraisalTraitData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(updateAnnualAppraisalTrait.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.updateAnnualAppraisalTraitData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(deleteAnnualAppraisalTrait.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.deleteAnnualAppraisalTraitData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(deleteAnnualAppraisalTrait.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.deleteAnnualAppraisalTraitData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(deleteAnnualAppraisalTrait.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.deleteAnnualAppraisalTraitData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(fetchAnnualAppraisalFilteredEmployees.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.fetchAnnualAppraisalFilteredEmployeesData = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(
        fetchAnnualAppraisalFilteredEmployees.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.fetchAnnualAppraisalFilteredEmployeesData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        fetchAnnualAppraisalFilteredEmployees.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.fetchAnnualAppraisalFilteredEmployeesData = {
            isFetching: false,
            response: [],
          };
          state.success = false;
        }
      )
      .addCase(addEmployeesToAnnualAppraisalCycle.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.addEmployeesToAnnualAppraisalCycleData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(
        addEmployeesToAnnualAppraisalCycle.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.addEmployeesToAnnualAppraisalCycleData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        addEmployeesToAnnualAppraisalCycle.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.addEmployeesToAnnualAppraisalCycleData = {
            isFetching: false,
            response: null,
          };
          state.success = false;
        }
      )
      .addCase(fetchEmployeesInAnnualAppraisalCycle.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.fetchEmployeesInAnnualAppraisalCycleData = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(
        fetchEmployeesInAnnualAppraisalCycle.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.fetchEmployeesInAnnualAppraisalCycleData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        fetchEmployeesInAnnualAppraisalCycle.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.fetchEmployeesInAnnualAppraisalCycleData = {
            isFetching: false,
            response: [],
          };
          state.success = false;
        }
      )
      .addCase(fetchReviewersOfAnnualAppraisalCycle.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.fetchReviewersOfAnnualAppraisalCycleData = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(
        fetchReviewersOfAnnualAppraisalCycle.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.fetchReviewersOfAnnualAppraisalCycleData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        fetchReviewersOfAnnualAppraisalCycle.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.fetchReviewersOfAnnualAppraisalCycleData = {
            isFetching: false,
            response: [],
          };
          state.success = false;
        }
      )
      .addCase(removeEmployeeFromAnnualAppraisalCycle.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.removeEmployeeFromAnnualAppraisalCycleData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(
        removeEmployeeFromAnnualAppraisalCycle.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.removeEmployeeFromAnnualAppraisalCycleData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        removeEmployeeFromAnnualAppraisalCycle.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.removeEmployeeFromAnnualAppraisalCycleData = {
            isFetching: false,
            response: null,
          };
          state.success = false;
        }
      )
      .addCase(fetchEmployeeWithNoAppraisalCycle.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.fetchEmployeeWithNoAppraisalCycleData = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(
        fetchEmployeeWithNoAppraisalCycle.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.fetchEmployeeWithNoAppraisalCycleData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        fetchEmployeeWithNoAppraisalCycle.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.fetchEmployeeWithNoAppraisalCycleData = {
            isFetching: false,
            response: [],
          };
          state.success = false;
        }
      )
      .addCase(allocateTraitsToAppraisalCycle.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.allocateTraitsToAppraisalCycleData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(
        allocateTraitsToAppraisalCycle.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.allocateTraitsToAppraisalCycleData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        allocateTraitsToAppraisalCycle.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.allocateTraitsToAppraisalCycleData = {
            isFetching: false,
            response: null,
          };
          state.success = false;
        }
      )
      .addCase(fetchTraitsAllocatedToAppraisalCycle.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.fetchTraitsAllocatedToAppraisalCycleData = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(
        fetchTraitsAllocatedToAppraisalCycle.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.fetchTraitsAllocatedToAppraisalCycleData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        fetchTraitsAllocatedToAppraisalCycle.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.fetchTraitsAllocatedToAppraisalCycleData = {
            isFetching: false,
            response: [],
          };
          state.success = false;
        }
      )
      .addCase(fetchTraitsAllocatedToAppraisalCycleById.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.fetchTraitsAllocatedToAppraisalCycleDataById = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(
        fetchTraitsAllocatedToAppraisalCycleById.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.fetchTraitsAllocatedToAppraisalCycleDataById = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        fetchTraitsAllocatedToAppraisalCycleById.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.fetchTraitsAllocatedToAppraisalCycleDataById = {
            isFetching: false,
            response: [],
          };
          state.success = false;
        }
      )
      .addCase(updateTraitsAllocatedToAppraisalCycle.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateTraitsAllocatedToAppraisalCycleData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(
        updateTraitsAllocatedToAppraisalCycle.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.updateTraitsAllocatedToAppraisalCycleData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        updateTraitsAllocatedToAppraisalCycle.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.updateTraitsAllocatedToAppraisalCycleData = {
            isFetching: false,
            response: null,
          };
          state.success = false;
        }
      )
      .addCase(deleteTraitsAllocatedToAppraisalCycle.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.deleteTraitsAllocatedToAppraisalCycleData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(
        deleteTraitsAllocatedToAppraisalCycle.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.deleteTraitsAllocatedToAppraisalCycleData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        deleteTraitsAllocatedToAppraisalCycle.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.deleteTraitsAllocatedToAppraisalCycleData = {
            isFetching: false,
            response: null,
          };
          state.success = false;
        }
      )
      .addCase(submitEmployeeAnnualAppraisalForm.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.submitEmployeeAnnualAppraisalFormData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(
        submitEmployeeAnnualAppraisalForm.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.submitEmployeeAnnualAppraisalFormData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        submitEmployeeAnnualAppraisalForm.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.submitEmployeeAnnualAppraisalFormData = {
            isFetching: false,
            response: null,
          };
          state.success = false;
        }
      )
      .addCase(getEmployeeAnnualAppraisalFormDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getEmployeeAnnualAppraisalFormDetailsData = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(
        getEmployeeAnnualAppraisalFormDetails.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.getEmployeeAnnualAppraisalFormDetailsData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        getEmployeeAnnualAppraisalFormDetails.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.getEmployeeAnnualAppraisalFormDetailsData = {
            isFetching: false,
            response: [],
          };
          state.success = false;
        }
      )
      .addCase(getPmsBadgesList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getPmsBadgesListData = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(getPmsBadgesList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getPmsBadgesListData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(getPmsBadgesList.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getPmsBadgesListData = {
          isFetching: false,
          response: [],
        };
        state.success = false;
      })
      .addCase(createPmsBadge.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createPmsBadgeData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(createPmsBadge.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.createPmsBadgeData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(createPmsBadge.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createPmsBadgeData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(updatePmsBadge.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updatePmsBadgeData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(updatePmsBadge.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.updatePmsBadgeData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(updatePmsBadge.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.updatePmsBadgeData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(deletePmsBadge.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.deletePmsBadgeData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(deletePmsBadge.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.deletePmsBadgeData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(deletePmsBadge.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.deletePmsBadgeData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(getPmsBadgeIconsNameList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getPmsBadgeIconsNameListData = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(getPmsBadgeIconsNameList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getPmsBadgeIconsNameListData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(getPmsBadgeIconsNameList.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getPmsBadgeIconsNameListData = {
          isFetching: false,
          response: [],
        };
        state.success = false;
      })
      .addCase(createPmsBadgeIconName.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createPmsBadgeIconNameData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(createPmsBadgeIconName.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.createPmsBadgeIconNameData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(createPmsBadgeIconName.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.createPmsBadgeIconNameData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(allocateBadgeToEmployee.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.allocateBadgeToEmployeeData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(allocateBadgeToEmployee.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.allocateBadgeToEmployeeData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(allocateBadgeToEmployee.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.allocateBadgeToEmployeeData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(getAllocatedBadgesToEmployee.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getAllocatedBadgesToEmployeeData = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(getAllocatedBadgesToEmployee.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getAllocatedBadgesToEmployeeData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(getAllocatedBadgesToEmployee.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getAllocatedBadgesToEmployeeData = {
          isFetching: false,
          response: [],
        };
        state.success = false;
      })
      .addCase(getAnnualAppraisalHighestRatingsHolders.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getAnnualAppraisalHighestRatingsHoldersData = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(
        getAnnualAppraisalHighestRatingsHolders.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.getAnnualAppraisalHighestRatingsHoldersData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        getAnnualAppraisalHighestRatingsHolders.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.getAnnualAppraisalLowestRatingsHoldersData = {
            isFetching: false,
            response: [],
          };
          state.success = false;
        }
      )
      .addCase(getAnnualAppraisalLowestRatingsHolders.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getAnnualAppraisalLowestRatingsHoldersData = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(
        getAnnualAppraisalLowestRatingsHolders.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.getAnnualAppraisalLowestRatingsHoldersData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        getAnnualAppraisalLowestRatingsHolders.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.getAnnualAppraisalHighestRatingsHoldersData = {
            isFetching: false,
            response: [],
          };
          state.success = false;
        }
      )
      .addCase(getAnnualAppraisalBadgeHolders.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getAnnualAppraisalBadgeHoldersData = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(
        getAnnualAppraisalBadgeHolders.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.getAnnualAppraisalBadgeHoldersData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        getAnnualAppraisalBadgeHolders.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.getAnnualAppraisalBadgeHoldersData = {
            isFetching: false,
            response: [],
          };
          state.success = false;
        }
      )
      .addCase(getCycleWiseAppraisalStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getCycleWiseAppraisalStatusData = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(
        getCycleWiseAppraisalStatus.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.getCycleWiseAppraisalStatusData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        getCycleWiseAppraisalStatus.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.getCycleWiseAppraisalStatusData = {
            isFetching: false,
            response: [],
          };
          state.success = false;
        }
      )
      .addCase(getSelfAppraisalCycles.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getSelfAppraisalCyclesData = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(getSelfAppraisalCycles.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getSelfAppraisalCyclesData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(getSelfAppraisalCycles.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getSelfAppraisalCyclesData = {
          isFetching: false,
          response: [],
        };
        state.success = false;
      })
      .addCase(fetchReviewersOfSelfAnnualAppraisalCycle.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.fetchReviewersOfSelfAnnualAppraisalCycleData = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(
        fetchReviewersOfSelfAnnualAppraisalCycle.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.fetchReviewersOfSelfAnnualAppraisalCycleData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        fetchReviewersOfSelfAnnualAppraisalCycle.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.fetchReviewersOfSelfAnnualAppraisalCycleData = {
            isFetching: false,
            response: [],
          };
          state.success = false;
        }
      )
      .addCase(getSelfAnnualAppraisalFormDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getSelfAnnualAppraisalFormDetailsData = {
          isFetching: true,
          response: [],
        };
      })
      .addCase(
        getSelfAnnualAppraisalFormDetails.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.getSelfAnnualAppraisalFormDetailsData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        getSelfAnnualAppraisalFormDetails.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.getSelfAnnualAppraisalFormDetailsData = {
            isFetching: false,
            response: [],
          };
          state.success = false;
        }
      )
      .addCase(submitSelfAnnualAppraisalForm.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.submitSelfAnnualAppraisalFormData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(
        submitSelfAnnualAppraisalForm.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.submitSelfAnnualAppraisalFormData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(submitSelfAnnualAppraisalForm.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.submitSelfAnnualAppraisalFormData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      });
  },
});

export default pmsSlice.reducer;
