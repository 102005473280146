import React, { useCallback, useEffect } from "react";
import Leaves from "./Leaves/Leaves";
import { useState } from "react";
import WorkFromHome from "./WorkFromHome/WorkFromHome";
import Regularization from "./Regularization/Regularization";
import Allocation from "./Allocation/Allocation";
import "./LeaveDashboard.css";
import { componentDisplayAccess } from "../../utils/userPermission";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";

const LeaveDashboard = () => {
  const leaveApplicationAccess = componentDisplayAccess({
    component: "Leave_Application_V1",
  });
  const leaveAllocationAccess = componentDisplayAccess({
    component: "Leave_Allocation_V1",
  });
  const workFromHomeAccess = componentDisplayAccess({
    component: "Work_From_Home_V1",
  });
  const regularizationAccess = componentDisplayAccess({
    component: "Regularization_V1",
  });
  const location = useLocation();
  let headerList = [
    {
      item: "Leaves",
      route: "/leave-dashboard",
      access: leaveApplicationAccess,
      component: Leaves,
    },
    {
      item: "Work From Home",
      route: "work-from-home",
      access: workFromHomeAccess,
      component: WorkFromHome,
    },
    {
      item: "Allocation",
      route: "allocation",
      access: leaveAllocationAccess,
      component: Allocation,
    },
    {
      item: "Regularization",
      route: "regularization",
      access: regularizationAccess,
      component: Regularization,
    },
  ];
  let currentPath = location?.pathname?.split("/");
  currentPath = currentPath[currentPath?.length - 1];
  headerList = headerList?.filter((data) => data?.access?.readAccess);
  const history = useNavigate();

  const initialRoute =
    currentPath === "leave-dashboard"
      ? headerList[0]
      : headerList?.filter((i) => i?.route === currentPath)[0];
  const [activeMenuTab, setActiveMenuTab] = useState(initialRoute);
  const updateLocationPathname = useCallback(
    (route) => {
      history(route);
    },
    [history]
  );

  useEffect(() => {
    updateLocationPathname(activeMenuTab?.route);
  }, [activeMenuTab, updateLocationPathname]);
  return (
    <>
      <div className="leaveAppHeader border-b">
        <ul className="flex items-center whitespace-nowrap flex-wrap border-gray-300 font-medium text-gray-600">
          {headerList?.map((data, index) => {
            return data?.access?.readAccess ? (
              <li
                key={index}
                className={
                  activeMenuTab?.item === data?.item
                    ? `py-3 px-4 cursor-pointer active`
                    : `py-3 px-4 cursor-pointer`
                }
              >
                <NavLink
                  to={data?.route}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                  onClick={() => setActiveMenuTab(data)}
                >
                  {data?.item}
                </NavLink>
              </li>
            ) : null;
          })}
        </ul>
      </div>
      <Outlet />
    </>
  );
};

export default LeaveDashboard;
