import React, {
  Component,
  Fragment,
  Suspense,
  useEffect,
  useState,
} from "react";
import "./App.css";
import "./styles/Home.css";
import "./Components/Calendar/Calendar.css";
import "./Components/Calendar/LeaveAllocationRequest/LeaveAllocationRequest.css";
import "./Components/Calendar/UserEventCalendar/UserEventCalendar.css";
import "./Components/Dashboard/Dashboard.css";
import "./Components/HR/OrgClients/OrgClients.css";
import "./Components/ProfileSetting/ProfileSetting.css";
import "./Components/ChangePassword/ChangePassword.css";
import "./Components/EmployeeNew/CreateEmployeeForm/CreateEmployeeForm.css";
import "./Components/EmployeeNew/EmployeesNew.css";
import "./Components/EmployeeNew/EmployeeForm/EmployeeDetails.css";
import "./Components/EmployeeNew/EmployeeForm/EmployeeDocuments/EmployeeDocuments.css";
import "./Components/EmployeeNew/EmployeeForm/EmployeeFinance/EmployeeFinance.css";
import "./Components/EmployeeNew/EmployeeForm/EmployeeProjectInfo/EmployeeProjectInfo.css";
import "./Components/EmployeeNew/EmployeeForm/EmployeeJobProfile/EmployeeJobProfile.css";
import "./Components/EmployeeNew/EmployeeForm/EmployeePersonalInfo/EmployeePersonalInfo.css";
import "./Components/EmployeeNew/EmployeeForm/EmployeeSeparation/EmployeeSeparation.css";
import "./Components/EmployeeNew/EmployeeForm/EmployeeTechnicalBackground/EmployeeTechnicalBackground.css";
import "./Components/Payroll/Payroll.css";

import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import { componentDisplayAccess } from "./utils/userPermission";
import { EmployeeHandbook } from "./Components/EmployeeHandbook/EmployeeHandbook";
import CONSTANTS from "./constants/constants";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedUserData } from "./utils/helper";
import ProtectedRoute from "./utils/ProtectedRoute";
import Layout from "./utils/Layout";
import Unauthorized from "./utils/Unauthorized";
import Missing from "./utils/Missing";
import { userComponentAccess } from "./store/slices/componentSlice";
// import { APP_API_URL } from "./constants";
// import { io } from "socket.io-client";
import { getAccessToken } from "./utils/token";
import { isTeamLead } from "./store/slices/employeeSlice";
import { setUserAuth } from "./store/slices/authSlice";
import Loader from "./Components/Loader/Loader";
import { fetchHelpdeskUser } from "./store/slices/helpdeskSlice";
import ReimbursementAndDeductions from "./Components/Payroll/ReimbursementAndDeductions/ReimbursementAndDeductions";
import MiscDeductionTypeList from "./Components/HR/SalaryStructure/MiscDeductionTypes/MiscDeductionTypeList";
import LeaveDashboard from "./Components/LeaveDashboard/LeaveDashboard";
import { PopupProvider } from "./Components/GlobalPopup/GlobalPopup";
import TeamRoutes from "./Components/Team/TeamRoutes.js";
import ClientDashboard from "./Components/ClientDashboard/ClientDashboard.js";
import ClientRoute from "./Components/ClientDashboard/ClientRoute.js";
import WorkFromHome from "./Components/LeaveDashboard/WorkFromHome/WorkFromHome.js";
import Allocation from "./Components/LeaveDashboard/Allocation/Allocation.js";
import Regularization from "./Components/LeaveDashboard/Regularization/Regularization.js";
import Leaves from "./Components/LeaveDashboard/Leaves/Leaves.js";
import HandbookDashboard from "./Components/Handbook/HandbookDashboard.js";
import HandbookPageEditor from "./Components/Handbook/HandbookEdit/HandbookPageEditor.js";
import HandbookCreateNew from "./Components/Handbook/HandbookEdit/HandbookCreateNew.js";
import Client from "./Components/Client/Client.js";
//import NewJoineeAndExits from "./Components/Payroll/NewJoineeAndExits/NewJoineeAndExits";

const Home = React.lazy(() => import("./Home"));
// const Organization = React.lazy(() =>
//   import("./Components/Organization/Organization")
// );

// const Employee = React.lazy(() => import("./Components/Employee/Employee"));

// const EmployeeForm = React.lazy(() =>
//   import("./Components/Employee/EmployeeForm")
// );
// const Leave = React.lazy(() => import("./Components/Leave/Leave"));
// const Attendance = React.lazy(() =>
//   import("./Components/Attendance/Attendance")
// );
const Salaryslip = React.lazy(() =>
  import("./Components/Salaryslip/Salaryslip")
);
const Policies = React.lazy(() => import("./Components/Policies/Policies"));
// const TermAppraisal = React.lazy(() =>
//   import("./Components/TermAppraisal/TermAppraisal")
// );
const EmployeeTermAppraisal = React.lazy(() =>
  import("./Components/TermAppraisalNew/TermAppraisalNewList")
);
const EmployeeTermAppraisalType = React.lazy(() =>
  import("./Components/TermAppraisalNew/TermAppraisalNewType")
);
const NewTermAppraisalForm = React.lazy(() =>
  import("./Components/TermAppraisalNew/NewTermAppraisalForm")
);
const EditTermAppraisalForm = React.lazy(() =>
  import("./Components/TermAppraisalNew/EditTermAppraisalForm")
);

const Holidays = React.lazy(() => import("./Components/Holidays/Holidays"));
const PolicyForm = React.lazy(() => import("./Components/Policies/Policyform"));
const PolicyNew = React.lazy(() => import("./Components/Policies/PolicyNew"));
const NewTermAppraisal = React.lazy(() =>
  import("./Components/TermAppraisal/NewTermAppraisal")
);
// const NewLeave = React.lazy(() => import("./Components/Leave/NewLeave"));
// const NewAttendance = React.lazy(() =>
//   import("./Components/Attendance/NewAttendance")
// );
const NewSalarySlip = React.lazy(() =>
  import("./Components/HR/SalaryStructure/SalarySlip/NewSalarySlip")
);
const Login = React.lazy(() => import("./Login"));
// const NewUser = React.lazy(() => import("./Components/User/NewUser"));
// const MySettingsForm = React.lazy(() =>
//   import("./Components/User/MySettingsForm")
// );
const Setup = React.lazy(() => import("./Components/Setup/Setup.jsx"));
const RolesList = React.lazy(() => import("./Components/Setup/RolesList"));
const CreateRole = React.lazy(() => import("./Components/Setup/CreateRole"));
const ComponentsList = React.lazy(() =>
  import("./Components/Setup/ComponentsList")
);
const CreateComponent = React.lazy(() =>
  import("./Components/Setup/CreateComponent")
);
const UsersList = React.lazy(() => import("./Components/Setup/UsersList"));
const ApprovalConfig = React.lazy(() =>
  import("./Components/Setup/ApprovalConfig")
);
// const WFH = React.lazy(() => import("./Components/Leave/WFH"));
// const New_WFH = React.lazy(() => import("./Components/Leave/New_WFH"));
const PolicyAmend = React.lazy(() =>
  import("./Components/Policies/PolicyAmend")
);
const HR = React.lazy(() => import("./Components/HR/HR"));
const Deductions = React.lazy(() =>
  import("./Components/HR/SalaryStructure/Deductions")
);
const Earnings = React.lazy(() =>
  import("./Components/HR/SalaryStructure/Earnings")
);
const SalaryStructure = React.lazy(() =>
  import("./Components/HR/SalaryStructure/SalaryStructure")
);
const SalaryBracketList = React.lazy(() =>
  import("./Components/HR/SalaryStructure/SalaryBracketList")
);
const DefineSalaryBracket = React.lazy(() =>
  import("./Components/HR/SalaryStructure/DefineSalaryBracket")
);
const EmployeeSalaryStructure = React.lazy(() =>
  import(
    "./Components/HR/SalaryStructure/EmployeeSalaryStruture/EmployeeSalaryStructure"
  )
);
const NewTermAppraisalType = React.lazy(() =>
  import("./Components/TermAppraisal/NewTermAppraisalType")
);
const SalaryStructureList = React.lazy(() =>
  import("./Components/HR/SalaryStructure/SalaryStructureList")
);
// const LeaveAllocationList = React.lazy(() =>
//   import("./Components/HR/Leave Allocation/LeaveAllocationList")
// );
// const LeaveAllocation = React.lazy(() =>
//   import("./Components/HR/Leave Allocation/LeaveAllocation")
// );
const LeaveTypeList = React.lazy(() =>
  import("./Components/HR/Leave Type/LeaveTypeList")
);
// const ScrollToTop = React.lazy(() =>
//   import("./Components/Employee/ScrollToTop")
// );
const EmployeeShiftList = React.lazy(() =>
  import("./Components/HR/Employee Shift/EmployeeShiftList")
);
const EmployeeShiftForm = React.lazy(() =>
  import("./Components/HR/Employee Shift/EmployeeShiftForm")
);
const ModulesList = React.lazy(() => import("./Components/Setup/ModulesList"));
const NewModule = React.lazy(() => import("./Components/Setup/NewModule"));
const NewHolidayListType = React.lazy(() =>
  import("./Components/Holidays/NewHolidayListType")
);
const HolidayListType = React.lazy(() =>
  import("./Components/Holidays/HolidayListType")
);
const CronSchedulers = React.lazy(() =>
  import("./Components/Setup/CronSchedulers")
);
const MealCharges = React.lazy(() =>
  import("./Components/HR/SalaryStructure/MealCharges")
);
const DepartmentList = React.lazy(() =>
  import("./Components/HR/Department Type/DepartmentList")
);
const DesignationList = React.lazy(() =>
  import("./Components/HR/Designation Types/DesignationList")
);
// const ClientList = React.lazy(() =>
//   import("./Components/HR/Clients/ClientList")
// );
const SkillsList = React.lazy(() =>
  import("./Components/HR/Skills/SkillsList")
);
const BranchList = React.lazy(() =>
  import("./Components/HR/Branches/BranchList")
);
const ReimbursementTypeList = React.lazy(() =>
  import(
    "./Components/HR/SalaryStructure/ReimbursementTypes/ReimbursementTypeList"
  )
);
const DailyMenuList = React.lazy(() =>
  import("./Components/HR/Daily Menu/DailyMenuList")
);
const EmploymentTypeList = React.lazy(() =>
  import("./Components/HR/Employment Type/EmploymentTypeList")
);
const ClientProjectList = React.lazy(() =>
  import("./Components/HR/ClientProjects/ClientProjects")
);
const PayrollDashboard = React.lazy(() =>
  import("./Components/HR/Payroll/PayrollDashboard")
);
const AttendanceSummary = React.lazy(() =>
  import("./Components/HR/Payroll/AttendanceSummary")
);
const ChangePassword = React.lazy(() =>
  import("./Components/ChangePassword/ChangePassword")
);
const SalaryStructuresSummary = React.lazy(() =>
  import("./Components/HR/Payroll/SalaryStructuresSummary")
);
const ArrearsAndLeaveEncashment = React.lazy(() =>
  import("./Components/HR/Payroll/ArrearsAndLeaveEncashment")
);
const PrintSalarySlip = React.lazy(() =>
  import("./Components/HR/SalaryStructure/PrintSalarySlip.jsx")
);
const TemporaryAccessCardList = React.lazy(() =>
  import("./Components/HR/TempAccessCard/TemporaryAccessCardList")
);
// const NewTemporaryAccessCard = React.lazy(() =>
//   import("./Components/HR/TempAccessCard/NewTemporaryAccessCard")
// );
const ItDeclaration = React.lazy(() =>
  import("./Components/Finance/ItDeclaration")
);
const DeclarationSectionList = React.lazy(() =>
  import("./Components/Finance/DeclarationSection/DeclarationList")
);
const DeclarationTypeList = React.lazy(() =>
  import("./Components/Finance/DeclarationType/DeclarationTypeList")
);
const CompareTaxDetails = React.lazy(() =>
  import("./Components/Finance/CompareTaxDetails")
);
const ReceiverGroupList = React.lazy(() =>
  import("./Components/HR/ReceiverGroup/ReceiverList")
);
// const Regularization = React.lazy(() =>
//   import("./Components/Regularization/Regularization.jsx")
// );
const AllEmployees = React.lazy(() =>
  import("./Components/EmployeeNew/AllEmployees")
);
const EmployeeDetails = React.lazy(() =>
  import("./Components/EmployeeNew/EmployeeForm/EmployeeDetails")
);
const LunchRequestList = React.lazy(() =>
  import("./Components/Calendar/Lunch Request/LunchRequestList")
);
const VariablePay = React.lazy(() =>
  import("./Components/HR/SalaryStructure/VariablePay")
);
const OrgClients = React.lazy(() =>
  import("./Components/HR/OrgClients/OrgClients")
);
const Project = React.lazy(() =>
  import("./Components/HR/OrgClients/Projects/Project")
);
const ProjectTypeList = React.lazy(() =>
  import("./Components/HR/Project Type/ProjectTypeList")
);
const ProjectBillingList = React.lazy(() =>
  import("./Components/HR/Project Billing/ProjectBillingList")
);
const ApprovalRequest = React.lazy(() =>
  import("./Components/Approvals/ResignationApproval/ResignationTable")
);
const LeaveAndAttendance = React.lazy(() =>
  import("./Components/Payroll/LeaveAndAttendance/LeaveAndAttendance")
);
const ReimbursementsAndDeduction = React.lazy(() =>
  import("./Components/ReimbursementsAndDeduction/ReimbursementsAndDeduction")
);
const Helpdesk = React.lazy(() => import("./Components/Helpdesk/Helpdesk"));
const Reports = React.lazy(() => import("./Components/Reports/Reports"));
const PayrollLandingPage = React.lazy(() =>
  import("./Components/Payroll/PayrollLandingPage")
);
const Dashboard = React.lazy(() => import("./Components/Dashboard/Dashboard"));
const Calendar = React.lazy(() => import("./Components/Calendar/Calendar"));
const MyTeam = React.lazy(() => import("./Components/MyTeam/MyTeam"));
const Team = React.lazy(() => import("./Components/Team/Team"));
const TimeSheetDashboard = React.lazy(() =>
  import("./Components/TimeSheet/TimeSheetDashboard")
);
const NewJoineeAndExits = React.lazy(() =>
  import("./Components/Payroll/NewJoineeAndExits/NewJoineeAndExits")
);

const HelpdeskDashboard = React.lazy(() =>
  import("./Components/Helpdesk/Dashboard/HelpdeskDashboard")
);

const BonusAndRevisions = React.lazy(() =>
  import("./Components/Payroll/BonusAndRevisions/BonusAndRevisions")
);

const AttendanceDashboard = React.lazy(() =>
  import("./Components/AttendanceNew/AttendanceNewDashboard")
);

const SalaryOnHold = React.lazy(() =>
  import("./Components/Payroll/SalaryOnHold/SalaryOnHoldTab")
);
const PendingStructure = React.lazy(() =>
  import("./Components/Payroll/PendingStructures/PendingStructureTab")
);
const EmailConfig = React.lazy(() =>
  import("./Components/Configurations/EmailConfig/EmailConfig")
);
const Configurations = React.lazy(() =>
  import("./Components/Configurations/Configurations/Configurations")
);

const PayrollPreview = React.lazy(() =>
  import("./Components/Payroll/PayrollPreview/index")
);

const SalarySlipLandingPage = React.lazy(() =>
  import("./Components/Finance/SalarySlip/SalarySlipLandingPage")
);
// const Payslips = React.lazy(() =>
//   import("./Components/Finance/SalarySlip/Payslips")
// );
const HolidayListDashboard = React.lazy(() =>
  import("./Components/HR/HolidayNew/HolidayListDashboard.js")
);
// const HrOrganization = React.lazy(() =>
//   import("./Components/Organization/HR/HR.js")
// );
// const SetupOrganization = React.lazy(() =>
//   import("./Components/Organization/Setup/Setup.js")
// );

const EmployeeAppraisal = React.lazy(() =>
  import(
    "./Components/PerformanceReview/AnnualAppraisal/EmployeeAnnualAppraisal/EmployeeAppraisal.js"
  )
);
const PerformanceReview = React.lazy(() =>
  import("./Components/PerformanceReview/PerformanceReview.js")
);
const ContinuousEvaluationForm = React.lazy(() =>
  import(
    "./Components/PerformanceReview/ContinuousEvaluation/Forms/ContinuousEvaluationForm.js"
  )
);

const App = () => {
  const loggedEmployee = getLoggedUserData();
  const EmployeeId = loggedEmployee?.employeeId ?? null;
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.component?.loading);
  const success = useSelector((state) => state?.component?.success);
  const teamLead = useSelector(
    (state) => state?.employee?.userRole?.isTeamLead
  );
  const [isLoading, setIsLoading] = useState(true);
  const accessData = useSelector((state) => state?.component);
  const token = getAccessToken();
  // const workMode = token ? jwtDecode(token)?.workMode : null;
  // const [socket, setSocket] = useState(null);
  const userData = useSelector((state) => state?.helpdesk?.helpdeskUser?.data);
  useEffect(() => {
    setIsLoading(accessData.loading);
  }, [accessData]);
  useEffect(() => {
    if (EmployeeId && token) {
      dispatch(userComponentAccess());
      dispatch(isTeamLead());
      dispatch(fetchHelpdeskUser());
      dispatch(
        setUserAuth({
          accessToken: token,
          isAuthenticated: true,
        })
      );
    }
  }, [dispatch, token, EmployeeId]);
  // useEffect(() => {
  //   const socket = io(APP_API_URL, {
  //     auth: { EmployeeId: EmployeeId },
  //     transports: ["websocket"],
  //     secure: true,
  //   });
  //   setSocket(socket);
  // }, [EmployeeId]);

  return (
    <PopupProvider>
      <Fragment>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
        </Routes>
        {loading ? (
          <div style={{ width: "100%", height: "100vh" }}>
            <Loader />
          </div>
        ) : (
          <Routes>
            <Route element={<Layout />}>
              <Route
                element={
                  <ProtectedRoute
                    isLoading={isLoading}
                    allowed={loggedEmployee && token && teamLead}
                  />
                }
              >
                <Route
                  path="/MyTeam"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <MyTeam />
                    </Suspense>
                  }
                />
              </Route>
              <Route
                element={
                  <ProtectedRoute
                    isLoading={isLoading}
                    allowed={loggedEmployee && token}
                  />
                }
              >
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.LEAVE_LEAVELIST,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/leave-dashboard"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <LeaveDashboard />
                      </Suspense>
                    }
                  >
                    <Route
                      element={
                        <ProtectedRoute
                          isLoading={isLoading}
                          allowed={
                            componentDisplayAccess({
                              component: CONSTANTS.LEAVE_NEW,
                            })?.readAccess
                          }
                        />
                      }
                    >
                      <Route index element={<Leaves />} />
                    </Route>
                    <Route
                      element={
                        <ProtectedRoute
                          isLoading={isLoading}
                          allowed={
                            componentDisplayAccess({
                              component: CONSTANTS.WORK_FROM_HOME_NEW,
                            })?.readAccess
                          }
                        />
                      }
                    >
                      <Route path="work-from-home" element={<WorkFromHome />} />
                    </Route>
                    <Route
                      element={
                        <ProtectedRoute
                          isLoading={isLoading}
                          allowed={
                            componentDisplayAccess({
                              component: CONSTANTS.LEAVE_ALLOCATION_NEW,
                            })?.readAccess
                          }
                        />
                      }
                    >
                      <Route path="allocation" element={<Allocation />} />
                    </Route>
                    <Route
                      element={
                        <ProtectedRoute
                          isLoading={isLoading}
                          allowed={
                            componentDisplayAccess({
                              component: CONSTANTS.REGULARIZATION_NEW,
                            })?.readAccess
                          }
                        />
                      }
                    >
                      <Route
                        path="regularization"
                        element={<Regularization />}
                      />
                    </Route>
                  </Route>
                </Route>
                <Route
                  path="/ChangePassword"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <ChangePassword />
                    </Suspense>
                  }
                />
                <Route
                  path="/Dashboard"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <Dashboard />
                    </Suspense>
                  }
                />
                <Route
                  path="/Home"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <Home />
                    </Suspense>
                  }
                />
                <Route
                  path="/EmployeeHandbook"
                  element={<EmployeeHandbook />}
                />
                <Route
                  path="/handbook-dashboard"
                  element={<HandbookDashboard />}
                  allowed={
                    componentDisplayAccess({
                      component: CONSTANTS.HANDBOOK,
                    })?.updateAccess
                  }
                />
                <Route
                  path="/handbook-dashboard/handbook-page-editor"
                  element={<HandbookPageEditor />}
                  allowed={
                    componentDisplayAccess({
                      component: CONSTANTS.HANDBOOK,
                    })?.updateAccess
                  }
                />
                <Route
                  path="/handbook-dashboard/handbook-create-new"
                  element={<HandbookCreateNew />}
                  allowed={
                    componentDisplayAccess({
                      component: CONSTANTS.HANDBOOK,
                    })?.createAccess
                  }
                />

                <Route
                  path="/ComponentsList"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <ComponentsList />
                    </Suspense>
                  }
                />
                <Route
                  path="/ComponentsList/New"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <CreateComponent />
                    </Suspense>
                  }
                />
                <Route
                  path="/CronSchedulers"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <CronSchedulers />
                    </Suspense>
                  }
                />
                <Route
                  path="/Calendar"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <Calendar />
                    </Suspense>
                  }
                />

                <Route
                  path="/Helpdesk"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <Helpdesk />
                    </Suspense>
                  }
                />
                {!!userData?.data?.length && (
                  <Route
                    path="/HelpdeskDashboard"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <HelpdeskDashboard />
                      </Suspense>
                    }
                  />
                )}
                <Route
                  path="/ModulesList"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <ModulesList />
                    </Suspense>
                  }
                />
                <Route
                  path="/ModulesList/New"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <NewModule />
                    </Suspense>
                  }
                />
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.HR_Temporary_Access_Card,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/TemporaryAccessCardList"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <TemporaryAccessCardList />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  path="/ItDeclaration"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <ItDeclaration />
                    </Suspense>
                  }
                />
                <Route
                  path="/DeclarationSection"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <DeclarationSectionList />
                    </Suspense>
                  }
                />
                <Route
                  path="/DeclarationType"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <DeclarationTypeList />
                    </Suspense>
                  }
                />

                <Route
                  path="/CompareTaxDetails"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <CompareTaxDetails />
                    </Suspense>
                  }
                />
                <Route
                  path="/ReceiverGroup"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <ReceiverGroupList />
                    </Suspense>
                  }
                />
                <Route
                  path="/LunchRequestList"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <LunchRequestList />
                    </Suspense>
                  }
                />

                <Route
                  path="/AllEmployees"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <AllEmployees />
                    </Suspense>
                  }
                />
                <Route
                  path="/EmployeeDetails/:EmployeeId"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <EmployeeDetails />
                    </Suspense>
                  }
                />
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.HR_Org_Clients,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/OrgClients"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <OrgClients />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/OrgClients/Project"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <Project />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.APPROVAL_REQUEST,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/ApprovalRequest"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <ApprovalRequest />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.ATTENDANCE_NEW,
                        })?.readAccess
                      }
                    />
                  }
                />
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.LEAVE_LEAVELIST,
                        })?.createAccess
                      }
                    />
                  }
                >
                  <Route
                    path="team"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <Team TeamRoutes={TeamRoutes} />
                      </Suspense>
                    }
                  >
                    <Route
                      index
                      element={
                        <Navigate
                          to={TeamRoutes[0]?.path}
                          state={{ parentPath: "team" }}
                        />
                      }
                    />
                    {TeamRoutes.map((route, index) => (
                      <Route
                        key={index}
                        path={`${route.path}`}
                        element={route.element}
                      >
                        {route.subList && (
                          <Route
                            index
                            element={
                              <Navigate
                                state={{ parentPath: "team" }}
                                to={route?.subList[0]?.path}
                              />
                            }
                          />
                        )}
                        {route.subList &&
                          route.subList.map((subRoute, subIndex) => (
                            <Route
                              key={subIndex}
                              path={`${subRoute.path}`}
                              element={subRoute.element}
                            />
                          ))}
                      </Route>
                    ))}
                  </Route>
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.CLIENT,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route path="/home/kick-off" element={<Client />}></Route>
                  <Route
                    path="home/kick-off/ProjectTypeList"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <ProjectTypeList />
                      </Suspense>
                    }
                  />
                  <Route
                    path="home/kick-off/ProjectBillingList"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <ProjectBillingList />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  path="home/kick-off/client-dashboard"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <ClientDashboard ClientRoute={ClientRoute} />
                    </Suspense>
                  }
                >
                  <Route
                    index
                    element={<Navigate to={ClientRoute[0]?.path} />}
                  />
                  {ClientRoute.map((route, index) => (
                    <Route
                      key={index}
                      path={`${route.path}`}
                      element={route.element}
                    />
                  ))}
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.LEAVE_LEAVELIST,
                        })?.createAccess
                      }
                    />
                  }
                ></Route>

                {/* <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.LEAVE_LEAVELIST,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/LeaveApplication/LEAPP/:name"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <NewLeave />
                      </Suspense>
                    }
                  />
                </Route> */}
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.APPROVAL_REQUEST,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/ApprovalRequest"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <ApprovalRequest />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.ATTENDANCE_NEW,
                        })?.readAccess
                      }
                    />
                  }
                >
                  {/* <Route
                path="/Attendance"
                element={
                  <Suspense
                    fallback={
                      <div>
                        <Loader />
                      </div>
                    }
                  >
                    <Attendance />
                  </Suspense>
                }
              /> */}
                  <Route
                    path="/AttendanceDashboard"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <AttendanceDashboard />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/AttendanceDashboard/:EmployeeId"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <AttendanceDashboard />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.REPORT_ACCESS,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/Reports"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <Reports />
                      </Suspense>
                    }
                  />
                </Route>
                {/* <Route
              element={
                <ProtectedRoute
                  isLoading={isLoading}
                  allowed={
                    componentDisplayAccess({
                      component: CONSTANTS.ATTENDANCE_NEW,
                    })?.createAccess
                  }
                />
              }
              >
              <Route
                path="/Attendance/New"
                element={
                  <Suspense
                    fallback={
                      <div>
                        <Loader />
                      </div>
                    }
                  >
                    <NewAttendance />
                  </Suspense>
                }
              />
            </Route> */}
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.EMPLOYEE_SALARY_SLIP_HR,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="Salaryslip"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <Salaryslip />
                      </Suspense>
                    }
                  />

                  <Route
                    path="/Salaryslip/PrintSalarySlip"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <PrintSalarySlip />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/Salaryslip/:salarySlipId"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <NewSalarySlip />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.EMPLOYEE_SALARY_SLIP,
                        })?.createAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/Salaryslip/New"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <NewSalarySlip />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.POLICY_ACCESS,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/Policies"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <Policies />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/Policies/:PolicyId"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <PolicyForm />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.POLICY_ACCESS,
                        })?.createAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/Policies/New"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <PolicyNew />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/Policies/Amend/:PolicyId"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <PolicyAmend />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.HOLIDAY_ACCESS,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/Holidays"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <Holidays />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.SETUP_USERS,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/UsersList"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <UsersList />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.SETUP_USERS,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/ApprovalConfig"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <ApprovalConfig />
                      </Suspense>
                    }
                  />
                </Route>
                {/* <Route
              element={
                <ProtectedRoute
                  isLoading={isLoading}
                  allowed={
                    componentDisplayAccess({
                      component: CONSTANTS.SETUP_USERS,
                    })?.createAccess
                  }
                />
              }
              >
              <Route
                path="/NewUser"
                element={
                  <Suspense
                    fallback={
                      <div>
                        <Loader />
                      </div>
                    }
                  >
                    <NewUser />
                  </Suspense>
                }
              />
              </Route>
              <Route
              element={
                <ProtectedRoute
                  isLoading={isLoading}
                  allowed={
                    componentDisplayAccess({
                      component: CONSTANTS.SETUP_USERS,
                    })?.updateAccess
                  }
                />
              }
              >
              <Route
                path="/User/:name"
                element={
                  <Suspense
                    fallback={
                      <div>
                        <Loader />
                      </div>
                    }
                  >
                    <MySettingsForm />
                  </Suspense>
                }
              />
            </Route> */}
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.SETUP,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/Setup"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <Setup />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.SETUP_ROLES,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/RolesList"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <RolesList />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.SETUP_ROLES,
                        })?.createAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/RolesList/New"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <CreateRole />
                      </Suspense>
                    }
                  />
                </Route>

                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.HUMAN_RESOURCES,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/HR"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <HR />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.SALARY_EARNINGS,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/Earnings"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <Earnings />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.SALARY_DEDUCTIONS,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/Deductions"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <Deductions />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.SALARY_DEDUCTIONS,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/VariablePay"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <VariablePay />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.HR_SALARY_MANAGEMENT,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/SalaryStructure"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <SalaryStructure />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.EMPLOYEE_SALARY_STRUCTURE,
                        })?.createAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/SalaryStructure/New"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <EmployeeSalaryStructure />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.EMPLOYEE_SALARY_STRUCTURE,
                        })?.updateAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/EmployeeSalaryStructure/:SalaryStructureId"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <EmployeeSalaryStructure />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/EmployeeSalaryStructure/:EmployeeId"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <EmployeeSalaryStructure />
                      </Suspense>
                    }
                  />
                </Route>

                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.EMPLOYEE_SALARY_STRUCTURE,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/SalaryStructureList"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <SalaryStructureList />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/SalaryStructureList/:payrollGenerationData"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <SalaryStructureList />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.SALARY_BRACKETS,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/SalaryBracket"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <SalaryBracketList />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component:
                            CONSTANTS.HR_SALARY_MANAGEMENT_MEAL_CHARGES,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/MealCharges"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <MealCharges />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.SALARY_BRACKETS,
                        })?.createAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/SalaryBracket/New"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <DefineSalaryBracket />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.SALARY_BRACKETS,
                        })?.updateAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/SalaryBracket/:BracketId"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <DefineSalaryBracket />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  path="/ReimbursementsAndDeduction"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <ReimbursementsAndDeduction />
                    </Suspense>
                  }
                />
                {/* <Route
                element={
                  <ProtectedRoute
                    isLoading={isLoading}
                    allowed={
                      componentDisplayAccess({
                        component: CONSTANTS.HR_LEAVE_ALLOCATION,
                      })?.readAccess ||
                      componentDisplayAccess({
                        component: CONSTANTS.LEAVE_LEAVE_ALLOCATION_APPROVAL,
                      })?.readAccess
                    }
                  />
                }
              >
                <Route
                  path="/LeaveAllocation"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <LeaveAllocationList />
                    </Suspense>
                  }
                />
              </Route> */}
                {/* <Route
                element={
                  <ProtectedRoute
                    isLoading={isLoading}
                    allowed={
                      componentDisplayAccess({
                        component: CONSTANTS.HR_LEAVE_ALLOCATION,
                      })?.readAccess ||
                      componentDisplayAccess({
                        component: CONSTANTS.LEAVE_LEAVE_ALLOCATION_APPROVAL,
                      })?.readAccess
                    }
                  />
                }
              >
                <Route
                  path="/Regularization"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <Regularization />
                    </Suspense>
                  }
                />
              </Route> */}
                {/* <Route
                element={
                  <ProtectedRoute
                    isLoading={isLoading}
                    allowed={
                      componentDisplayAccess({
                        component: CONSTANTS.HR_LEAVE_ALLOCATION,
                      })?.createAccess
                    }
                  />
                }
              >
                <Route
                  path="/LeaveAllocation/New"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <LeaveAllocation />
                    </Suspense>
                  }
                />
              </Route> */}
                {/* <Route
                element={
                  <ProtectedRoute
                    isLoading={isLoading}
                    allowed={
                      componentDisplayAccess({
                        component: CONSTANTS.HR_LEAVE_ALLOCATION,
                      })?.updateAccess
                    }
                  />
                }
              >
                <Route
                  path="/LeaveAllocation/:name"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <LeaveAllocation />
                    </Suspense>
                  }
                />
              </Route> */}
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.HR_LEAVE_TYPE,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/LeaveTypeList"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <LeaveTypeList />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.HR_SHIFT_TYPES,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/EmployeeShiftList"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <EmployeeShiftList />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.HR_SHIFT_TYPES,
                        })?.createAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/EmployeeShiftList/New"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <EmployeeShiftForm />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.HR_SHIFT_TYPES,
                        })?.updateAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/EmployeeShiftList/:workShiftId"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <EmployeeShiftForm />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.HOLIDAY_ACCESS,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/HolidayListType"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <HolidayListType />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/HolidayListType/New"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <NewHolidayListType />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/HolidayListDashboard"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <HolidayListDashboard />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.HR_DESIGNATION_TYPE,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/DesignationList"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <DesignationList />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.HR_BRANCH,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/BranchList"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <BranchList />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/ReimbursementTypeList"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <ReimbursementTypeList />
                      </Suspense>
                    }
                  />

                  <Route
                    path="/MiscDeductionTypeList"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <MiscDeductionTypeList />
                      </Suspense>
                    }
                  />
                </Route>

                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.HR_DEPARTMENT_TYPE,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/DepartmentList"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <DepartmentList />
                      </Suspense>
                    }
                  />
                </Route>

                {/* <Route
                element={
                  <ProtectedRoute
                    isLoading={isLoading}
                    allowed={
                      componentDisplayAccess({
                        component: CONSTANTS.HR_CLIENT_NAME,
                      })?.readAccess
                    }
                  />
                }
              >
                <Route
                  path="/ClientList"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <ClientList />
                    </Suspense>
                  }
                />
              </Route> */}
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.HR_SKILLS_SET,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/SkillsList"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <SkillsList />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.HR_DAILY_MENU,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/DailyMenuList"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <DailyMenuList />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.HR_PROJECT_NAME,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/ClientProjectList"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <ClientProjectList />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.HR_EMPLOYMENT_TYPE,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/EmploymentTypeList"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <EmploymentTypeList />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.MASTER_PAYROLL,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/PayrollDashboard"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <PayrollDashboard />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/AttendanceSummary/:payrollMonthDates"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <AttendanceSummary />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/SalaryStructuresSummary/:payrollMonthDates"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <SalaryStructuresSummary />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/ArrearsAndLeaveEncashment/:payrollMonth"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <ArrearsAndLeaveEncashment />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/PayrollPreview"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <PayrollPreview />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/PayrollPreview/:payrollMonth"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <PayrollPreview />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/SalaryHold"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <SalaryOnHold />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/PendingSalaryStructures"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <PendingStructure />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.TERM_APPRAISAL_EMPLOYEE,
                        })?.readAccess
                      }
                    />
                  }
                >
                  {/* <Route
                path="/TermAppraisal"
                element={
                  <Suspense
                    fallback={
                      <div>
                        <Loader />
                      </div>
                    }
                  >
                    <TermAppraisal />
                  </Suspense>
                }
              />
              <Route
                path="/TermAppraisal/:appraisal_id"
                element={
                  <Suspense
                    fallback={
                      <div>
                        <Loader />
                      </div>
                    }
                  >
                    <NewTermAppraisal />
                  </Suspense>
                }
              /> */}
                  <Route
                    path="/TermAppraisal/New"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <NewTermAppraisal />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/TermAppraisal/NewTermAppraisalType"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <NewTermAppraisalType />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/EmployeeTermAppraisal"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <EmployeeTermAppraisal />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/EmployeeTermAppraisal/NewTermAppraisalType"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <EmployeeTermAppraisalType />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/EmployeeTermAppraisal/NewTermAppraisalForm"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <NewTermAppraisalForm />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/EmployeeTermAppraisal/:appraisal_id"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <EditTermAppraisalForm />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.MASTER_PAYROLL,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="Payroll"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <PayrollLandingPage />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/LeaveAndAttendance"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <LeaveAndAttendance />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/ReimbursementAndDeductions"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <ReimbursementAndDeductions />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/NewJoineeAndExits"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <NewJoineeAndExits />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/BonusAndRevisions"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <BonusAndRevisions />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isLoading={isLoading}
                      allowed={
                        componentDisplayAccess({
                          component: CONSTANTS.EMPLOYEE_SALARY_SLIP,
                        })?.readAccess
                      }
                    />
                  }
                >
                  <Route
                    path="/Finance"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <SalarySlipLandingPage />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute isLoading={isLoading} allowed={true} />
                  }
                >
                  <Route
                    path="/TimeSheetDashboard"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            <Loader />
                          </div>
                        }
                      >
                        <TimeSheetDashboard />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  path="/EmailConfig"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <EmailConfig />
                    </Suspense>
                  }
                />
                <Route
                  path="/Configurations"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <Configurations />
                    </Suspense>
                  }
                />
              </Route>
              <Route
                path="/EmailConfig"
                element={
                  <Suspense
                    fallback={
                      <div>
                        <Loader />
                      </div>
                    }
                  >
                    <EmailConfig />
                  </Suspense>
                }
              />
              <Route
                path="/Configurations"
                element={
                  <Suspense
                    fallback={
                      <div>
                        <Loader />
                      </div>
                    }
                  >
                    <Configurations />
                  </Suspense>
                }
              />
              <Route
                element={
                  <ProtectedRoute
                    isLoading={isLoading}
                    allowed={
                      componentDisplayAccess({
                        component: CONSTANTS.ORGANIZATION_ACCESS,
                      })?.readAccess
                    }
                  />
                }
              >
                {/* <Route
                  path="/Organization"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <Organization />
                    </Suspense>
                  }
                /> */}
                {/* <Route
                  path="/Organization/HR"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <HrOrganization />
                    </Suspense>
                  }
                />
                <Route
                  path="/Organization/Setup"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <SetupOrganization />
                    </Suspense>
                  }
                /> */}
                <Route
                  path="/EmployeeAppraisal"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <EmployeeAppraisal />
                    </Suspense>
                  }
                />
              </Route>
              <Route
                element={
                  <ProtectedRoute
                    isLoading={isLoading}
                    allowed={
                      componentDisplayAccess({
                        component: CONSTANTS.PMS_DASHBOARD,
                      })?.readAccess
                    }
                  />
                }
              >
                <Route
                  path="/Pms"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <PerformanceReview />
                    </Suspense>
                  }
                />
                <Route
                  path="/Pms/ContinuousEvaluation"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <PerformanceReview />
                    </Suspense>
                  }
                />
                <Route
                  path="/Pms/ContinuousEvaluation/SelfForm"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <ContinuousEvaluationForm />
                    </Suspense>
                  }
                />
                <Route
                  path="/Pms/AnnualAppraisal"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <PerformanceReview />
                    </Suspense>
                  }
                />
                <Route
                  path="/Pms/Setup"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <PerformanceReview />
                    </Suspense>
                  }
                />
                <Route
                  path="/Pms/Annual_Appraisal"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <PerformanceReview />
                    </Suspense>
                  }
                />
                <Route
                  path="/Pms/Annual_Appraisal/Annual_Appraisal_Cycles"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <PerformanceReview />
                    </Suspense>
                  }
                />
                <Route
                  path="/Pms/Annual_Appraisal/Annual_Appraisal_Traits"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <PerformanceReview />
                    </Suspense>
                  }
                />
                <Route
                  path="/Pms/Annual_Appraisal/Allocate_Traits_List"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <PerformanceReview />
                    </Suspense>
                  }
                />
                <Route
                  path="/Pms/Annual_Appraisal/Self_Appraisal_Form"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <PerformanceReview />
                    </Suspense>
                  }
                />
                <Route
                  path="/Pms/Annual_Appraisal/Team_Appraisal_Form"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <PerformanceReview />
                    </Suspense>
                  }
                />
                <Route
                  path="/Pms/Annual_Appraisal/Annual_Appraisal_Dashboard"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <PerformanceReview />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
            <Route>
              <Route path="*" element={<Missing success={success} />} />
            </Route>
            {/* {success ? (
            ) : null} */}
          </Routes>
        )}
      </Fragment>
    </PopupProvider>
  );
};

export default App;
