import React from "react";

const CustomRightDrawer = ({
  title,
  titleDesc,
  InnerContent,
  width,
  CancelBtn,
  SubmitBtn,
  openRightDrawer,
  setOpenRightDrawer,
  data,
}) => {
  return (
    <>
      <div
        className={openRightDrawer ? "rightDrawer active" : "rightDrawer"}
        style={
          openRightDrawer ? { width: width, transform: "translateX(0px)" } : {}
        }
      >
        <div className="drawerHeader">
          <div className="headerContent">
            <div className="headerTitle">{title}</div>
            <div
              className="headerClose"
              onClick={() => {
                setOpenRightDrawer(false);
              }}
            >
              <i className="fa-light fa-xmark fa-lg"></i>
            </div>
          </div>
        </div>
        <div className="drawerContent">{InnerContent}</div>
      </div>
      <div
        className={openRightDrawer ? "drawerOverlay active" : "drawerOverlay"}
        style={
          openRightDrawer ? { width: "100%", transform: "translateX(0px)" } : {}
        }
        onClick={() => setOpenRightDrawer(false)}
      ></div>
    </>
  );
};

export default CustomRightDrawer;
