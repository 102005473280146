import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import componentReducer from "./slices/componentSlice";
import employeeReducer from "./slices/employeeSlice";
import employeeNewReducer from "./slices/employeeNewSlice";
import onlineAttendanceReducer from "./slices/onlineAttendanceSlice";
import masterReducer from "./slices/masterSlice";
import payrollReducer from "./slices/payrollSlice";
import leaveReducer from "./slices/leaveSlice";
import pollReducer from "./slices/pollSlice";
import salaryReducer from "./slices/salarySlice";
import calendarReducer from "./slices/CalendarSlice";
import configData from "./slices/configData";
import lunchRequests from "./slices/lunchRequestSlice";
import teamRequests from "./slices/TeamSlice";
import helpdesk from "./slices/helpdeskSlice";
import reports from "./slices/ReportsSlice";
import MyTeamSlice from "./slices/myTeamSlice";
import handbookSlice from "./slices/handbookSlice";
import ProjectSlice from "./slices/ProjectSlice";
import pms from "./slices/pmsSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    component: componentReducer,
    employee: employeeReducer,
    employeeNew: employeeNewReducer,
    onlineAttendance: onlineAttendanceReducer,
    masterList: masterReducer,
    payRoll: payrollReducer,
    leave: leaveReducer,
    poll: pollReducer,
    salary: salaryReducer,
    calendar: calendarReducer,
    config: configData,
    lunchRequest: lunchRequests,
    teamData: teamRequests,
    helpdesk: helpdesk,
    reports: reports,
    MyTeamSlice: MyTeamSlice,
    handbook: handbookSlice,
    Project: ProjectSlice,
    pms: pms,
  },
});

export default store;
