import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import JoditEditor from "jodit-react";
import { TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
    createHandbookSlides,
    fetchHandbookSlides,
} from "../../../store/slices/handbookSlice";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import Loader from "../../Loader/Loader";

const schema = yup
    .object({
        handbookTitle: yup
            ?.string()
            ?.required()
            ?.trim()
            ?.label("Handbook Title")?.trim(),
        handbookHtml: yup?.string()?.trim()?.label("Description")?.optional().nullable(),
    })
    .required();

const HandbookCreateNew = () => {
    const [fetching, setFetching] = useState(false);
    const dispatch = useDispatch();
    const history = useNavigate();
    let createNew = true;

    useEffect(() => {
        dispatch(fetchHandbookSlides());
    }, [dispatch]);

    const handleReset = () => {
        setValue("handbookTitle", "")
        setValue("handbookHtml", "")
    };

    const methods = useForm({
        defaultValues: {
            handbookTitle: "",
            handbookHtml: "",
        },
        resolver: yupResolver(schema),
    });

    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors, isDirty },
    } = methods;

    const onSubmit = async (data) => {
        let titleName = data?.handbookTitle?.replace(/ /g, "_");
        setFetching(true)
        await dispatch(
            createHandbookSlides({
                title: data?.handbookTitle,
                titleName: titleName,
                handbookHtml: data?.handbookHtml
            })
        );
        await dispatch(fetchHandbookSlides());
        history("/Handbook-dashboard", {state:{createNew}});
        setFetching(false)
    };

    return (
        <>
            {
                fetching ? <Loader />
                    :
                    <div className="handbookWrapper">

                        <div className="handbookRightAsside">
                            <div className="container-fluid">
                                <div className="row mt-3">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="col-md-10 offset-md-1">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="font-semibold text-lg mb-3 uppercase">
                                                        Add Employee Handbook Slides
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="text-sm">Handbook Title
                                                    <span className="create_form_asterisk">*</span>
                                                    </label>
                                                    <Controller
                                                        name="handbookTitle"
                                                        control={control}
                                                        rules={{
                                                            required: true,
                                                        }}
                                                        render={({ field: { onChange, value } }) => (
                                                            <TextField
                                                                onChange={onChange}
                                                                value={value}
                                                                // label="Handbook Title"
                                                                variant="outlined"
                                                                size="small"
                                                            />
                                                        )}
                                                    />
                                                    <p className="text-red-500 text-sm">
                                                        {errors?.handbookTitle?.message}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="joditEditor mt-4">
                                                <Controller
                                                    name="handbookHtml"
                                                    control={control}
                                                    rules={{
                                                        required: true,
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <JoditEditor
                                                            value={value}
                                                            onChange={onChange}
                                                        />
                                                    )}
                                                />
                                                <p className="text-red-500 text-sm">
                                                    {errors?.editorContent?.message}
                                                </p>
                                            </div>
                                            <div className="flex justify-end gap-3">
                                                <NavLink to='/handbook-dashboard'>
                                                    <div
                                                        className="border-1 border-blue-400 text-blue-600 hover:text-gray-500 hover:border-gray-500 py-2 px-3 rounded-md my-4"
                                                    >
                                                        Back
                                                    </div>
                                                </NavLink>
                                                <button
                                                    type="button"
                                                    onClick={() => handleReset()}
                                                    className="border-1 border-blue-400 text-blue-600 hover:text-gray-500 hover:border-gray-500 py-2 px-3 rounded-md my-4"
                                                >
                                                    Reset
                                                </button>
                                                <button
                                                    id={!isDirty ? "newDisable" : ""}
                                                    type="submit"
                                                    className="border-1 border-blue-400 text-white bg-blue-500 hover:bg-blue-600 py-2 px-3 rounded-md my-4"
                                                >
                                                    Create
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    );
};

export default HandbookCreateNew;
