import { componentDisplayAccess } from "../../utils/userPermission";
import Client from "./Client/Client";
import ProjectDashboard from "./Project/ProjectDashboard";
const clientAccess = componentDisplayAccess({
  component: "Client_Details",
});
const projectAccess = componentDisplayAccess({
  component: "Client_Details",
});
const ClientRoute = [
  {
    path: "client",
    element: <Client />,
    title: "Client",
    index: true,
    access: componentDisplayAccess({
      component: "Client_Details",
    }),
  },
  {
    path: "project",
    element: <ProjectDashboard />,
    title: "Project",
    index: true,
    access: projectAccess,
  },
];

export default ClientRoute;
