import React, { useEffect, useMemo } from "react";
import "../ClientDashboard.css";
import TextFieldComponent from "../../MasterComponent/TextFieldComponent";
import { FormProvider, useForm } from "react-hook-form";
import DatePickerComponent from "../../MasterComponent/DatePickerComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  addEmployeesInProject,
  fetchAllCompanyEmployee,
  updateEmployeesInProject,
} from "../../../store/slices/masterSlice";
import AutoCompleteComponent from "../../MasterComponent/AutoCompleteComponent";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import RadioGroupComponent from "../../MasterComponent/RadioGroupComponent";
import { getAllProjectByID } from "../../../store/slices/ProjectSlice";
import { toast } from "react-toastify";

const addTeamMemberSchema = yup?.object({
  employeeName: yup?.object()?.label("Project Name")?.required(),
  role: yup?.string()?.label("Role")?.required(),
  startDate: yup?.string()?.label("Start Date")?.required(),
  newStartDate: yup?.string()?.label("New Start Date")?.required(),
  projectType: yup?.string()?.label("Project Type")?.required(),
});

const EditTeamMember = ({ edit, setEdit, getProjectsById, updateTeam }) => {
  const dispatch = useDispatch();

  const employeeList = useSelector(
    (state) => state?.masterList?.allCompanyEmployeesData?.response?.data
  );
  let defaultEmployee = useMemo(
    () => ({
      label: `${updateTeam?.employee_id} - ${updateTeam?.employee_name}`,
      value: updateTeam?.employee_id,
    }),
    [updateTeam]
  );
  const methods = useForm({
    defaultValues: {
      employeeName: defaultEmployee,
      role: updateTeam?.employee_role,
      startDate: updateTeam?.start_date,
      projectType: updateTeam?.project_type,
    },
    resolver: yupResolver(addTeamMemberSchema),
  });
  const {
    handleSubmit,
    control,
    formState: { errors, dirtyFields },
    reset,
    setValue,
  } = methods;

  useEffect(() => {
    const data = {
      employeeName: defaultEmployee,
      role: updateTeam?.employee_role,
      startDate: updateTeam?.start_date,
      projectType: updateTeam?.project_type,
    };
    methods.reset(data);
  }, [defaultEmployee, methods, updateTeam]);

  const submitHandler = async (data) => {
    const submitData = {
      employees_list: {
        employee_id: data?.employeeName?.value,
        employee_role: data?.role,
        project_type: data?.projectType,
        start_date: data?.startDate,
        newStartDate: data?.newStartDate,
      },
      project_id: getProjectsById?.data?.project_id,
    };
    try {
      await dispatch(updateEmployeesInProject(submitData));
      reset();
      setEdit(false);
      await dispatch(getAllProjectByID(getProjectsById?.data?.project_id));
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    dispatch(fetchAllCompanyEmployee());
  }, []);

  return (
    <>
      <div
        className={
          edit
            ? "createProjectDrawer addProject active"
            : "createProjectDrawer addProject"
        }
      >
        <div className="createProjectDrawerHeader">
          <div className="w-full whitespace-nowrap">
            <div className="text-lg mb-2 font-semibold flex items-center justify-between">
              <div>Update Team Member</div>
              <div
                className="headerClose"
                onClick={() => {
                  setEdit(false);
                  reset();
                }}
              >
                <i className="fa-light fa-xmark fa-lg"></i>
              </div>
            </div>
          </div>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submitHandler)}>
            <div className={edit ? "active" : ""}>
              <div className="addProjectForm">
                <div className="formContainer whitespace-nowrap pt-4">
                  {/*  Project Detail form */}
                  <div className="row">
                    <div className="col-md-12">
                      <label
                        htmlFor="employeeName"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Employee Name <span className="text-red-500">*</span>:
                      </label>
                      <div className="create_form_input_div autoSelect mb-3">
                        <AutoCompleteComponent
                          name="employeeName"
                          control={control}
                          disabled="true"
                          errors={errors}
                          options={
                            employeeList?.map((v) => ({
                              label: `${v?.EmployeeId} - ${v?.employee_name}`,
                              value: v?.EmployeeId,
                            })) ?? []
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="startDate"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Previous Start Date{" "}
                        <span className="text-red-500">*</span>
                      </label>
                      <DatePickerComponent
                        name="startDate"
                        control={control}
                        errors={errors}
                        disabled={true}
                        backSpaceChange={false}
                        // onHandleChange={() => trigger("billingEndDate")}
                        disableMaskedInput={true}
                      />
                    </div>
                    <div className="col-md-12">
                      <label
                        htmlFor="role"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Role <span className="text-red-500">*</span>:
                      </label>
                      <div className="create_form_input_div mb-3">
                        <TextFieldComponent
                          name="role"
                          onChangeHandler={() => setValue("newStartDate", null)}
                          control={control}
                          errors={errors}
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <label
                        htmlFor="projectType"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Project type <span className="text-red-500">*</span>
                      </label>
                      <RadioGroupComponent
                        control={control}
                        errors={errors}
                        hidden={false}
                        handleRadioChange={() => setValue("newStartDate", null)}
                        name="projectType"
                        types={[
                          { label: "Primary", value: "Primary" },
                          { label: "Secondary", value: "Secondary" },
                        ]}
                      />
                    </div>
                    {dirtyFields["role"] || dirtyFields["projectType"] ? (
                      <div className="col-md-12 mb-3 mt-3">
                        <label
                          htmlFor="newStartDate"
                          className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          New Start Date
                          <span className="text-red-500">*</span>
                        </label>
                        <DatePickerComponent
                          name="newStartDate"
                          control={control}
                          errors={errors}
                          backSpaceChange={false}
                          maxDate={new Date()}
                          // onHandleChange={() => trigger("billingEndDate")}
                          disableMaskedInput={true}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              {/* {renderButton()} */}
            </div>
            <div className="text-end p-3  whitespace-nowrap">
              <button
                onClick={() => {
                  setEdit(false);
                  reset();
                }}
                type="button"
                className="inline-flex font-sm text-sm border-1 border-gray-600 rounded-md cursor-pointer hover:text-gray-50 hover:bg-gray-500 hover:border-gray-500 py-2 px-4"
              >
                Cancel
              </button>
              {dirtyFields["role"] || dirtyFields["projectType"] ? (
                <button
                  type="submit"
                  className="ml-3 inline-flex font-sm text-sm border-1 border-blue-600 rounded-md cursor-pointer bg-blue-600 text-white hover:text-gray-50 hover:bg-blue-500 hover:border-blue-500 py-2 px-4"
                >
                  Submit
                </button>
              ) : (
                <button
                  type="button"
                  className="ml-3 inline-flex font-sm text-sm border-1 border-blue-500 rounded-md cursor-pointer bg-blue-500 text-white hover:text-gray-50 hover:bg-blue-500 hover:border-blue-500 py-2 px-4"
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </FormProvider>
      </div>
      <div
        className={
          edit ? "createProjectOverlay active" : "createProjectOverlay"
        }
        onClick={() => setEdit(false)}
      ></div>
    </>
  );
};

export default EditTeamMember;
