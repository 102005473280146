import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import React from "react";
import { deleteHandbookSlide, fetchHandbookSlides } from "../../../store/slices/handbookSlice";
import { useDispatch } from "react-redux";

const ConfirmDeleteDialog = ({ deletePage, setDeletePage, Id, setIsLoading, setId, setActiveSlide, handbookSlides }) => {
    const dispatch = useDispatch();

    const handleDelete = async () => {
        setIsLoading(true)
        await dispatch(deleteHandbookSlide({ Id: Id }))
        await dispatch(fetchHandbookSlides());
        setId(handbookSlides[0]?.handbookId === Id ? handbookSlides[1]?.handbookId : handbookSlides[0]?.handbookId);
        setActiveSlide(0);
        setDeletePage(false)
        setIsLoading(false)
    }

    return (
        <Dialog
            open={deletePage}
            //   TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
            className="employee_form_dialog"
        >
            <DialogTitle className="add_employee_main_heading text-red-600">
                <span>Confirm Delete</span>
            </DialogTitle>
            <DialogContent className="card-content-employee-form">
                <div className="">Are you sure you want to Delete this slide?</div>
            </DialogContent>
            <DialogActions className="employee_form_button_div">
                <button
                    type="button"
                    onClick={() => setDeletePage(false)}
                    className="border-1 border-gray-300 rounded-lg py-2 px-4 hover:bg-gray-50 text-sm mr-3"
                >
                    No
                </button>
                <button
                    onClick={() => handleDelete()}
                    className="border-1 border-blue-500 rounded-lg py-2 px-4 bg-blue-600 text-white hover:bg-blue-700 hover:text-white text-sm"
                >
                    Yes
                </button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDeleteDialog;
