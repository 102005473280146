import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Loader from "../../Loader/Loader";

const AllocationConfirmDialog = ({
  confirmStatus,
  setConfirmStatus,
  setSelectedStatus,
  handleUpdateStatus,
  selectedStatus,
  setSelectedEmpData,
  updateIsFetching,
}) => {
  return (
    <Dialog
      open={confirmStatus}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      className="employee_form_dialog"
    >
      {updateIsFetching && <Loader />}
      <DialogTitle className="add_employee_main_heading">
        <div className="commentsHeader">
          <div>Allocation Confirmation</div>
        </div>
      </DialogTitle>
      <DialogContent className="card-content-employee-form">
        <div className="leaveComments bg-white ">
          Are you sure you want to{" "}
          {selectedStatus === "Approved" ? "approve" : "reject"} this allocation
          request.
        </div>
      </DialogContent>
      <DialogActions className="employee_form_button_div">
        <button
          type="button"
          onClick={() => {
            setConfirmStatus(false);
            setSelectedStatus();
            setSelectedEmpData([]);
          }}
          className="btn cancelBtnOutline"
        >
          Cancel
        </button>
        <button
          type="submit"
          // disabled={!isDirty}
          className="btn primaryBtnOutline"
          onClick={() => handleUpdateStatus(selectedStatus)}
        >
          Save
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default AllocationConfirmDialog;
