import React, { useEffect, useRef, useState } from "react";
import { Avatar, ListItemAvatar, debounce } from "@mui/material";
import stringAvatar from "../../../PMS/AvatarFunction";
import Loader from "../../../Loader/Loader";
import { useDispatch } from "react-redux";
import { clearAttendanceList } from "../../../../store/slices/masterSlice";
import { getUniqueListBy } from "../../../../utils/helper";
import axios from "axios";
const AttendanceEmployeeSearch = ({
  EmployeeId,
  setEmployeeId,
  page,
  setPage,
  searchLoading,
  setLimit,
  setSearch,
  moreData,
  setMoreData,
  getEmpSearch,
}) => {
  const [loadingMore, setLoadingMore] = useState(false);
  const containerRef = useRef(null);
  const ref = useRef(null);
  const cancelTokenSourceRef = useRef(null);
  const dispatch = useDispatch();

  const cancelSourceToken = () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokenSourceRef.current = axios.CancelToken.source();
  };

  useEffect(() => {
    const container = containerRef.current;
    const debounceScroll = debounce(handleScroll, 200);
    container.addEventListener("scroll", debounceScroll);
    return () => {
      container.removeEventListener("scroll", debounceScroll);
    };
  }, [loadingMore, page]);

  const handleScroll = () => {
    const container = containerRef.current;
    if (
      container.scrollTop + container.clientHeight + 5 >=
      container.scrollHeight
    ) {
      if (!loadingMore) {
        setLoadingMore(true);
        setPage(page + 1);
        setLimit(25);
      }
    }
  };

  // useEffect(() => {
  //   if (getEmpSearch?.data?.data?.items?.length) {
  //     setMoreData(getUniqueListBy([...moreData, ...getEmpSearch?.data?.data?.items], "EmployeeId"));
  //   }
  // }, [getEmpSearch]);

  const handleSearchChange = (event) => {
    // const delayInput = setTimeout(() => {
    setMoreData([]);
    dispatch(clearAttendanceList());
    setEmployeeId();
    setSearch(event?.target?.value);
    setPage(1);
    // }, 2000);

    // return () => clearTimeout(delayInput);
  };

  useEffect(() => {
    if (loadingMore) {
      setLoadingMore(false);
    }
  }, [getEmpSearch]);

  return (
    <>
      <div className="text-gray-500 py-2 px-3 relative border-b">
        <span
          className="text-gray-500 cursor-text"
          onClick={() => ref.current.focus()}
        >
          <i className="fa-light fa-magnifying-glass"></i>
        </span>
        <input
          ref={ref}
          onChange={handleSearchChange}
          type="text"
          name="search input"
          placeholder="Search By Employee"
          className="text-sm border-none pl-1"
        />
      </div>
      <ul className="teamProjectLists relative" ref={containerRef}>
        {(loadingMore || searchLoading) && <Loader />}
        {moreData?.length
          ? moreData?.map((item, key) => (
            <li key={key}>
              <div
                id={item?.EmployeeId}
                onClick={() => setEmployeeId(item?.EmployeeId)}
                className={
                  EmployeeId === item?.EmployeeId
                    ? `bg-blue-50 flex items-center cursor-pointer py-1 px-2 border-b border-gray-200  text-sm h-100`
                    : `flex items-center cursor-pointer py-1 px-2 border-b border-gray-200  text-sm h-100`
                }
              >
                <div>
                  <ListItemAvatar className="userProfileImg">
                    {item?.profileImage ? (
                      <Avatar
                        className="my-2"
                        src={item?.profileImage}
                        style={{
                          width: "35px",
                          height: "35px",
                          fontSize: "18px",
                        }}
                        alt="Employee Image"
                      />
                    ) : (
                      item?.name && (
                        <Avatar
                          className="my-2"
                          {...stringAvatar(item?.name)}
                          style={{
                            width: "35px",
                            height: "35px",
                            fontSize: "18px",
                          }}
                          alt="Employee Image"
                        />
                      )
                    )}
                  </ListItemAvatar>
                </div>
                <div>
                  <div className="flex items-center gap-2 text-left mb-1">
                    <div className="font-semibold">{item?.name}</div>
                  </div>
                  <div className="flex items-center gap-2 text-left mb-1 text-xs">
                    <div className="">#{item?.EmployeeId}</div>
                  </div>
                </div>
              </div>
            </li>
          ))
          : null}
      </ul>
    </>
  );
};

export default AttendanceEmployeeSearch;
