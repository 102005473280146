import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { getLoggedUserData } from "../../utils/helper";
import { componentDisplayAccess } from "../../utils/userPermission";

import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userLogout } from "../../store/slices/authSlice";
import "../ProfileSetting/ProfileSetting.css";
import stringAvatar from "../PMS/AvatarFunction";
import Avatar from "@mui/material/Avatar";
import { fetchEmployeeCard } from "../../store/slices/employeeSlice";
import { useSelector } from "react-redux";
import { fetchOrgClientsApproverConfig } from "../../store/slices/masterSlice";

const NavbarMobile = ({ setActive, active }) => {
  const [activeSubmenu, setActiveSubmenu] = useState(false);

  const loggedEmployee = getLoggedUserData();
  const AttendanceListAccess = componentDisplayAccess({
    component: "Attendance_New",
  });
  const PolicyAccess = componentDisplayAccess({
    component: "Policy_Access",
  });
  const LunchAccess = componentDisplayAccess({
    component: "Lunch_Access",
  });
  const slipDisplay = componentDisplayAccess({
    component: "Salary_Slip",
  });
  const slipDisplayHR = componentDisplayAccess({
    component: "Salary_Slip_HR",
  });
  const leaveListAccess = componentDisplayAccess({
    component: "Leave_LeaveList",
  });
  const salaryPayrollAccess = componentDisplayAccess({
    component: "Master_Payroll",
  });
  const termAppraisal = componentDisplayAccess({
    component: "Term_Appraisal_Employee",
  });
  const SetupAccess = componentDisplayAccess({
    component: "Setup",
  });
  const HRAccess = componentDisplayAccess({
    component: "Human_Resources",
  });
  const teamLead = useSelector(
    (state) => state?.employee?.userRole?.isTeamLead
  );

  const employeeAccess = componentDisplayAccess({
    component: "Employee_EmployeeList",
  });
  const EmployeeWorkModeReportAccess = componentDisplayAccess({
    component: "Employee_Work_Mode_Report",
  });
  const LeaveBalanceReportAccess = componentDisplayAccess({
    component: "Leave_Balance_Report",
  });
  const WorkFromHomeReportAccess = componentDisplayAccess({
    component: "Work_From_Home_Report",
  });
  const LeavesReportAccess = componentDisplayAccess({
    component: "Leaves_Report",
  });
  const AttendanceRegularizationReportAccess = componentDisplayAccess({
    component: "Attendance_Regularization_Report",
  });
  const TodayAttendanceReportAccess = componentDisplayAccess({
    component: "Today_Attendance_Report",
  });
  const EmployeeReportAccess = componentDisplayAccess({
    component: "Employee_Report",
  });
  const ApprovalRequest = componentDisplayAccess({
    component: "Seperation_Approval",
  });

  const dispatch = useDispatch();
  const success = useSelector((state) => state?.component?.success);
  const EmployeeId = loggedEmployee?.employeeId ?? null;
  useEffect(() => {
    if (!!success) {
      dispatch(fetchEmployeeCard(EmployeeId));
    }
  }, [EmployeeId, dispatch, success]);
  const employeeCardData = useSelector(
    (state) => state?.employee?.employeeCardData?.employeeCard
  );

  const onSignoutSuccess = () => {
    // setTimeout(() => history("/login"), 0.1)
    dispatch(userLogout());
  };
  const handleCloseMenu = () => {
    setActive(false);
  };
  const handleClick = (element) => {
    if (element === "organization") setActiveSubmenu(true);
    else {
      setActiveSubmenu(false);
      setActive(false);
    }
  };
  useEffect(() => {
    if (!!success) {
      dispatch(fetchOrgClientsApproverConfig({ project_id: null }));
    }
  }, [dispatch, success]);
  const approversDetails = useSelector(
    (state) => state?.masterList?.OrgClientsApproverConfigData?.response
  );
  return (
    <>
      <div className={active ? "mobMenu active" : "mobMenu"}>
        <div className="closeMenuMobile" onClick={handleCloseMenu}>
          <i className="fa-light fa-xmark"></i>
        </div>
        <div className="userProfileMobile">
          <ul>
            <div className="userDetails">
              <div className="userProfileIcon">
                {employeeCardData?.profileImage ? (
                  <Avatar
                    src={employeeCardData?.profileImage}
                    className="newJoineeProfile"
                  />
                ) : (
                  employeeCardData?.firstName && (
                    <Avatar
                      {...stringAvatar(employeeCardData?.firstName)}
                      className="newJoineeProfile"
                    />
                  )
                )}
              </div>
              <div className="userProfileText">
                <div className="userProfileName">
                  {employeeCardData && (
                    <>
                      {employeeCardData?.firstName && (
                        <>{employeeCardData.firstName} </>
                      )}
                      {employeeCardData?.middleName && (
                        <>{employeeCardData.middleName} </>
                      )}
                      {employeeCardData?.lastName && (
                        <>{employeeCardData.lastName}</>
                      )}
                    </>
                  )}
                </div>
                <div className="userProfileDesig">
                  {employeeCardData?.designation}
                </div>
              </div>
            </div>
          </ul>
        </div>

        <ul className="mobileMenu">
          <li>
            <NavLink
              to="/Dashboard"
              activeclassname="active"
              onClick={handleClick}
              className={({ isActive }) =>
                isActive && !activeSubmenu ? "mobMenues active" : "inactive"
              }
            >
              <i className="fa-light fa-objects-column mobMenu-icon"></i>
              <span className="link_name">Dashboard</span>
            </NavLink>
          </li>
          {/* <ClickAwayListener onClickAway={() => setOrg(false)}>
            <li
              className={show ? "navlist showMenu" : "navlist"}
              id="leaveApp"
              // onClick={empMenuToggle}
            >
              <div onClick={() => setOrg(!org)}>
                <NavLink
                  to={"#"}
                  activeclassname="active"
                  className={({ isActive }) =>
                    activeSubmenu == "organization" ? "mobMenues active" : "inactive"
                  }
                  onClick={(e) => (
                    e?.preventDefault(), setActiveSubmenu("organization")
                  )}
                >
                  <div className="leaveApplication">
                    <div>
                      <i className="fa-light fa-sitemap"></i>
                      <span className="link_name">Organization</span>
                    </div>
                    {org ? (
                      <i className="fa-light fa-chevron-up"></i>
                    ) : (
                      <i className="fa-light fa-chevron-down"></i>
                    )}
                  </div>
                </NavLink>
                {org && (
                  <div className="leaveApplicationSubmenu">
                      <div className="px-4 py-2">
                        <i className="fa-light fa-screwdriver-wrench me-1 fs-6"></i>
                        Settings
                        <ul className="mobSubmenuList">
                          <li>
                            <NavLink
                              to="#"
                              onClick={handleCloseMenu}
                              className="submenuInnerLinks"
                            >
                              General Settings
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="#"
                              onClick={handleCloseMenu}
                              className="submenuInnerLinks"
                            >
                              Notifications
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                      <div className="px-4 py-2">
                        <i className="fa-light fa-envelope me-1 fs-6"></i>
                        Emails
                        <ul className="mobSubmenuList">
                          <li>
                            <NavLink
                              to="#"
                              onClick={handleCloseMenu}
                              className="submenuInnerLinks"
                            >
                              General Settings
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="#"
                              onClick={handleCloseMenu}
                              className="submenuInnerLinks"
                            >
                              Notifications
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                  </div>
                )}
              </div>
            </li>
          </ClickAwayListener> */}
          {!!SetupAccess?.readAccess || HRAccess?.readAccess ? (
            <li>
              <NavLink
                to="/Home"
                activeclassname="active"
                onClick={handleClick}
                className={({ isActive }) =>
                  isActive && !activeSubmenu ? "mobMenues active" : "inactive"
                }
              >
                <i className="fa-light fa-house mobMenu-icon"></i>
                <span className="link_name">Home</span>
              </NavLink>
            </li>
          ) : null}
          {!!employeeAccess?.readAccess ? (
            <li>
              <NavLink
                to={`/EmployeeDetails/${EmployeeId}`}
                activeclassname="active"
                onClick={handleClick}
                className={({ isActive }) =>
                  isActive && !activeSubmenu ? "mobMenues active" : "inactive"
                }
              >
                <i className="fa-light fa-user-vneck sidenav-icon"></i>
                <span className="link_name"> My Profile</span>
              </NavLink>
            </li>
          ) : null}
          {teamLead && (
            <li>
              <NavLink
                to={"/MyTeam"}
                activeclassname="active"
                onClick={handleClick}
                className={({ isActive }) =>
                  isActive && !activeSubmenu ? "mobMenues active" : "inactive"
                }
              >
                <i className="fa-light fa-user-group sidenav-icon"></i>
                <span className="link_name"> My Team</span>
              </NavLink>
            </li>
          )}
          <li>
            <NavLink
              to={"/AllEmployees"}
              activeclassname="active"
              onClick={handleClick}
              className={({ isActive }) =>
                isActive && !activeSubmenu ? "mobMenues active" : "inactive"
              }
            >
              <i className="fa-light fa-building-user sidebar-icon"></i>
              <span className="link_name"> Employees</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Calendar"
              activeclassname="active"
              onClick={handleClick}
              className={({ isActive }) =>
                isActive && !activeSubmenu ? "mobMenues active" : "inactive"
              }
            >
              <i className="fa-light fa-calendar-day mobMenu-icon"></i>
              <span className="link_name"> Calendar</span>
            </NavLink>
          </li>
          {/* {!!leaveListAccess?.updateAccess && leaveListAccess?.readAccess ? (
            <ClickAwayListener onClickAway={() => setLeave(false)}>
              <li id="leaveApp">
                <div onClick={() => setLeave(!leave)}>
                  <NavLink
                    to={"#"}
                    activeclassname="active"
                    className={({ isActive }) =>
                      activeSubmenu === "leaves"
                        ? "mobMenues active"
                        : "inactive"
                    }
                    onClick={(e) => {
                      e?.preventDefault();
                      setActiveSubmenu("leaves");
                    }}
                  >
                    <div className="leaveApplication">
                      <div>
                <i className="fa-light fa-person-to-door"></i>
                        <span className="link_name">Leaves</span>
                      </div>
                      {leave ? (
                        <i className="fa-light fa-chevron-up"></i>
                      ) : (
                        <i className="fa-light fa-chevron-down"></i>
                      )}
                    </div>
                  </NavLink>
                  {leave && (
                    <ul className="leaveApplicationSubmenu">
                      {!!leaveListAccess?.readAccess ? (
                        <li>
                          <NavLink
                            to="/LeaveApplication"
                            onClick={handleCloseMenu}
                          >
                            Apply Leave
                          </NavLink>
                        </li>
                      ) : null}
                      {!!linkAccess?.readAccess || workMode === "Office" ? (
                        <li>
                          <NavLink to="/WFH" onClick={handleCloseMenu}>
                            Apply Work From Home
                          </NavLink>
                        </li>
                      ) : null}
                      {!!leaveListAccess?.readAccess ? (
                        <li>
                          <NavLink
                            to="/LeaveAllocation"
                            onClick={handleCloseMenu}
                          >
                            Leave Allocation
                          </NavLink>
                        </li>
                      ) : null}
                      {!!leaveListAccess?.updateAccess &&
                      leaveListAccess?.readAccess ? (
                        <li>
                          <NavLink
                            to="/Regularization"
                            onClick={handleCloseMenu}
                          >
                            Attendance Regularization
                          </NavLink>
                        </li>
                      ) : null}
                    </ul>
                  )}
                </div>
              </li>
            </ClickAwayListener>
          ) : null} */}
          {leaveListAccess?.readAccess ? (
            <li>
              <NavLink
                to="/leave-dashboard"
                id="Leave"
                onClick={handleClick}
                className={({ isActive }) =>
                  isActive && !activeSubmenu ? "mobMenues active" : "inactive"
                }
              >
                <i className="fa-light fa-person-to-door sidenav-icon"></i>
                <span className="link_name">Leave</span>
              </NavLink>
            </li>
          ) : null}
          {!!AttendanceListAccess?.readAccess ? (
            <li>
              <NavLink
                to="/AttendanceDashboard"
                activeclassname="active"
                onClick={handleClick}
                className={({ isActive }) =>
                  isActive && !activeSubmenu ? "mobMenues active" : "inactive"
                }
              >
                <i className="fa-light fa-user-check mobMenu-icon"></i>
                <span className="link_name">Attendance</span>
              </NavLink>
            </li>
          ) : null}
          {!!slipDisplayHR?.readAccess ? (
            <li>
              <NavLink
                to="/Salaryslip"
                activeclassname="active"
                onClick={handleClick}
                className={({ isActive }) =>
                  isActive && !activeSubmenu ? "mobMenues active" : "inactive"
                }
              >
                <i className="fa-light fa-file mobMenu-icon"></i>
                <span className="link_name">Salary Slip</span>
              </NavLink>
            </li>
          ) : null}
          {!!slipDisplay?.readAccess ? (
            <li>
              <NavLink
                to="/Finance"
                id="Finance"
                activeclassname="active"
                onClick={handleClick}
                className={({ isActive }) =>
                  isActive && !activeSubmenu ? "mobMenues active" : "inactive"
                }
              >
                <i className="fa-light fa-sack-dollar sidenav-icon"></i>
                <span className="link_name">Finance</span>
              </NavLink>
            </li>
          ) : null}
          {!!EmployeeWorkModeReportAccess?.readAccess ||
          !!LeaveBalanceReportAccess?.readAccess ||
          !!WorkFromHomeReportAccess?.readAccess ||
          !!LeavesReportAccess?.readAccess ||
          !!AttendanceRegularizationReportAccess?.readAccess ||
          !!TodayAttendanceReportAccess?.readAccess ||
          !!EmployeeReportAccess?.readAccess ? (
            <li>
              <NavLink
                to="/Reports"
                activeclassname="active"
                onClick={handleClick}
                className={({ isActive }) =>
                  isActive && !activeSubmenu ? "mobMenues active" : "inactive"
                }
              >
                <i className="fa-light fa-clipboard-list-check mobMenu-icon"></i>
                <span className="link_name">Reports</span>
              </NavLink>
            </li>
          ) : null}
          {!!salaryPayrollAccess?.readAccess ||
          !!salaryPayrollAccess?.updateAccess ? (
            <li>
              <NavLink
                to="/Payroll"
                activeclassname="active"
                onClick={handleClick}
                className={({ isActive }) =>
                  isActive && !activeSubmenu ? "mobMenues active" : "inactive"
                }
              >
                <i className="fa-light fa-money-check-dollar-pen mobMenu-icon"></i>
                <span className="link_name">Payroll</span>
              </NavLink>
            </li>
          ) : null}
          {!!PolicyAccess?.readAccess ? (
            <li>
              <NavLink
                to="/Policies"
                activeclassname="active"
                onClick={handleClick}
                className={({ isActive }) =>
                  isActive && !activeSubmenu ? "mobMenues active" : "inactive"
                }
              >
                <i className="fa-light fa-user-shield mobMenu-icon"></i>
                <span className="link_name">Policies</span>
              </NavLink>
            </li>
          ) : null}
          {!!LunchAccess?.readAccess ? (
            <li>
              <NavLink
                to="/LunchRequestList"
                activeclassname="active"
                onClick={handleClick}
                className={({ isActive }) =>
                  isActive && !activeSubmenu ? "mobMenues active" : "inactive"
                }
              >
                <i className="fa-light fa-pot-food mobMenu-icon"></i>
                <span className="link_name">Lunch</span>
              </NavLink>
            </li>
          ) : null}
          {/* {!!termAppraisal?.readAccess ? (
            <li>
              <NavLink
                to="/TermAppraisal"
                activeclassname="active"
                onClick={handleClick}
                className={({ isActive }) =>
                  isActive && !activeSubmenu ? "mobMenues active" : "inactive"
                }
              >
                <i className="fa-light fa-thumbs-up mobMenu-icon"></i>
                <span className="link_name">Appraisal</span>
              </NavLink>
            </li>
          ) : null} */}
          {!!ApprovalRequest?.readAccess ? (
            <li>
              <NavLink
                to="/ApprovalRequest"
                activeclassname="active"
                onClick={handleClick}
                className={({ isActive }) =>
                  isActive && !activeSubmenu ? "mobMenues active" : "inactive"
                }
              >
                <i className="fa-light fa-thumbs-up mobMenu-icon"></i>
                <span className="link_name">Approval Request</span>
              </NavLink>
            </li>
          ) : null}
          {!!termAppraisal?.readAccess ? (
            <li>
              <NavLink
                to="/EmployeeTermAppraisal"
                activeclassname="active"
                onClick={handleClick}
                className={({ isActive }) =>
                  isActive && !activeSubmenu ? "mobMenues active" : "inactive"
                }
              >
                <i className="fa-light fa-thumbs-up mobMenu-icon"></i>
                <span className="link_name">Appraisal</span>
              </NavLink>
            </li>
          ) : null}
          <li>
            <NavLink
              to="/EmployeeHandbook"
              activeclassname="active"
              onClick={handleClick}
              className={({ isActive }) =>
                isActive && !activeSubmenu ? "mobMenues active" : "inactive"
              }
            >
              <i className="fa-light fa-book-open-reader mobMenu-icon"></i>
              <span className="link_name">Handbook</span>
            </NavLink>
          </li>
          {approversDetails?.length ? (
            <li>
              <NavLink
                to="/TimeSheetDashboard"
                activeclassname="active"
                onClick={handleClick}
                className={({ isActive }) =>
                  isActive && !activeSubmenu ? "mobMenues active" : "inactive"
                }
              >
                <i className="fa-light fa-timer mobMenu-icon"></i>
                <span className="link_name">Time Sheet</span>
              </NavLink>
            </li>
          ) : null}
        </ul>
        <div className="userProfileSettingMobile">
          <ul className="list-unstyled">
            <li>
              <Link
                to={`/EmployeeDetails/${EmployeeId}`}
                onClick={handleCloseMenu}
              >
                <i className="fa-light fa-user-tie userSettingIcons"></i>
              </Link>
            </li>
            <li>
              <Link to={`/ChangePassword`} onClick={handleCloseMenu}>
                <div className="uSettingPassIcon">
                  <i className="fa-light fa-lock-keyhole"></i>
                </div>
              </Link>
            </li>
            <li>
              <Link to="#" onClick={onSignoutSuccess}>
                <i className="fa-light fa-arrow-right-from-bracket userSettingIcons"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default NavbarMobile;
