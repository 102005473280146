import React, { useEffect } from "react";
import "../ClientDashboard.css";
import TextFieldComponent from "../../MasterComponent/TextFieldComponent";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import DatePickerComponent from "../../MasterComponent/DatePickerComponent";
import TextAreaComponent from "../../MasterComponent/TextAreaComponent";
import { Checkbox } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  createClientSlice,
  fetchAllHods,
  fetchAllReportingManagers,
  fetchDepartmentTypeSliceDataStore,
  getAllClientsData,
} from "../../../store/slices/masterSlice";
import AutoCompleteComponent from "../../MasterComponent/AutoCompleteComponent";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { fetchProjectType } from "../../../store/slices/ProjectSlice";
import TextFieldNumberComponent from "../../MasterComponent/TextFieldNumberComponent";

let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;
const commonStringValidatorOptional = yup
  .number()
  .test("is-decimal", "Please enter upto two decimal only", (val) => {
    if (val !== undefined) {
      return patternTwoDigisAfterComma.test(val);
    }
    return true;
  })
  .label("Cost")
  .optional()
  .nullable()
  .typeError("Please Enter a Valid Cost");
const commonStringValidatorRequired = yup
  .number()
  .test("is-decimal", "Please enter upto two decimal only", (val) => {
    if (val !== undefined) {
      return patternTwoDigisAfterComma.test(val);
    }
    return true;
  })
  .label("Cost")
  .required()
  .typeError("Please Enter a Valid Cost");

const addClientSchema = yup?.object({
  clientName: yup?.string()?.label("Client Name")?.required().trim(),
  salesPerson: yup
    ?.string()
    ?.label("Sales Person")
    ?.optional()
    .nullable()
    .trim(),
  ndaDocument: yup?.mixed()?.label("NDA Signed Attachment")?.required(),
  ndaDate: yup
    .string()
    .label("NDA Signed Attachment & Signed Date")
    ?.trim()
    ?.optional()
    .nullable(),
  ndaCheckbox: yup?.boolean()?.label("NDA checkbox")?.optional().nullable(),
  clientAddress: yup
    ?.string()
    ?.label("Address")
    ?.optional()
    ?.nullable()
    ?.trim(),
  projectName: yup?.string()?.label("Project name")?.required()?.trim(),
  department: yup?.object()?.label("Department")?.optional().nullable(),
  hod: yup
    ?.array()
    ?.label("Head of Project")
    ?.required()
    .min(1, "Head of Project is a required field")
    .nonNullable(),
  projectManager: yup
    ?.object()
    ?.label("Project Manager")
    ?.optional()
    .nullable(),
  projectType: yup?.object()?.label("Project Type")?.optional().nullable(),
  domain: yup?.object()?.label("Domain")?.optional().nullable(),
  otherDomain: yup?.string()?.label("Other Domain")?.optional().nullable(),
  cost: commonStringValidatorRequired,
  projectBilling: yup
    ?.object()
    ?.label("Project Billing")
    ?.optional()
    .nullable(),
  sowDocument: yup?.mixed()?.label("SOW Signed Attachment")?.required(),
  sowDate: yup
    .string()
    .label("SOW Signed Attachment & Signed Date")
    ?.optional()
    .nullable(),
  sowCheckbox: yup?.boolean()?.label("SOW checkbox")?.optional()?.nullable(),
  billingTerms: yup.string().label("Billing Terms")?.required().trim(),
  billingStartDate: yup.string().label("Billing Start Date")?.required().trim(),
  followUp: yup.string().label("Follow Up")?.required().trim(),
  clientContact: yup
    .array()
    .label("client Contact")
    .of(
      yup?.object({
        contactPerson: yup.string().label("Contact Person Name").required(),
        contactPersonEmail: yup
          .string()
          .label("Contact Person Email")
          .required()
          ?.matches(
            /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
            "Please Enter a Valid User Id"
          ),
        contactPersonNumber: yup
          .string()
          .label("Contact Number")
          .optional()
          .nullable(),
        contactPersonDepartment: yup
          .string()
          .label("Department")
          ?.optional()
          .nullable(),
      })
    ),
  projectContact: yup
    .array()
    .label("Project Contact")
    .of(
      yup?.object({
        contactPerson: yup.string().label("Name").required(),
        contactPersonEmail: yup
          .string()
          .label("Contact Person Email")
          .required()
          ?.matches(
            /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
            "Please Enter a Valid User Id"
          ),
        contactPersonNumber: yup
          .string()
          .label("Contact Number")
          .optional()
          .nullable(),
      })
    ),
  billingTermsData: yup
    .array()
    .label("Billing Terms Data")
    .of(
      yup?.object({
        cost: commonStringValidatorOptional,
        billingDueDate: yup
          .string()
          .label("Billing Due Date")
          .optional()
          .nullable(),
        paidDate: yup.string().label("Paid Date").optional().nullable(),
      })
    ),
});

const AddClientForm = ({ addClient, setAddClient, setLoad }) => {
  // const [stepForm, setStepForm] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllHods());
    dispatch(fetchAllReportingManagers());
    dispatch(fetchDepartmentTypeSliceDataStore());
    dispatch(fetchProjectType());
  }, [dispatch]);

  let getAllHodList = useSelector(
    (state) => state?.masterList?.allHodsList?.response?.data
  );
  getAllHodList = getAllHodList?.map((v) => ({
    label: `${v?.empNameByUserId?.EmployeeId} - ${
      v?.empNameByUserId?.firstName ?? ""
    } ${v?.empNameByUserId?.middleName ?? ""} ${
      v?.empNameByUserId?.lastName ?? ""
    }`,
    value: v?.empNameByUserId?.EmployeeId,
  }));

  let getAllRMList = useSelector(
    (state) => state?.masterList?.allReportingManagersList?.response?.data
  );
  getAllRMList = getAllRMList?.map((v) => ({
    label: `${v?.EmployeeId} - ${v?.firstName ?? ""} ${v?.middleName ?? ""} ${
      v?.lastName ?? ""
    }`,
    value: v?.EmployeeId,
  }));

  let getDepartmentType = useSelector(
    (state) => state?.masterList?.departmentTypeData?.departmentTypeList
  );
  getDepartmentType = getDepartmentType?.map((v) => ({
    label: `${v?.department_name ?? ""}`,
    value: v?.department_id,
  }));
  let domainList = [
    { label: "Insurance", value: "Insurance" },
    { label: "Healthcare", value: "Healthcare" },
    { label: "Sports", value: "Sports" },
    { label: "Internal Product", value: "Internal Product" },
    { label: "Finance", value: "Finance" },
    { label: "Technology", value: "Technology" },
    { label: "Education", value: "Education" },
    { label: "Retail", value: "Retail" },
    { label: "Hospitality", value: "Hospitality" },
    { label: "Real Estate", value: "Real Estate" },
    { label: "Automotive", value: "Automotive" },
    { label: "Fashion", value: "Fashion" },
    { label: "Food & Beverage", value: "Food & Beverage" },
    { label: "Entertainment", value: "Entertainment" },
    { label: "Travel & Tourism", value: "Travel & Tourism" },
    { label: "Legal", value: "Legal" },
    { label: "Marketing & Advertising", value: "Marketing & Advertising" },
    { label: "Construction", value: "Construction" },
    { label: "Non-profit/Charity", value: "Non-profit/Charity" },
    { label: "Others", value: "Others" },
  ];

  const methods = useForm({
    defaultValues: {
      clientName: null,
      ndaDocument: null,
      ndaDate: null,
      salesPerson: null,
      otherDomain: null,
      ndaCheckbox: false,
      clientAddress: null,
      projectName: null,
      department: null,
      hod: null,
      projectManager: null,
      projectType: null,
      cost: 0,
      projectBilling: null,
      sowCheckbox: false,
      sowDocument: null,
      followUp: null,
      sowDate: null,
      billingTerms: null,
      billingStartDate: null,
      clientContact: [
        {
          contactPerson: "",
          contactPersonEmail: "",
          contactPersonNumber: null,
          contactPersonDepartment: "",
        },
      ],
      domain: null,
      projectContact: [
        {
          contactPerson: "",
          contactPersonEmail: "",
          contactPersonNumber: null,
        },
      ],
      billingTermsData: [
        {
          cost: 0,
          billingDueDate: "",
          paidDate: "",
        },
      ],
    },
    resolver: yupResolver(addClientSchema),
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    watch,
    reset,
    trigger,
    setValue,
  } = methods;

  const ProjectState = useSelector((state) => state?.Project);
  let projectTypeNames = ProjectState?.projectType?.rows?.map((i) => {
    return {
      label: i?.name,
      value: i?.project_type_id,
    };
  });
  let projectBilling = ProjectState?.projectType?.rows?.filter(
    (i) => i?.project_type_id === watch("projectType")?.value
  )[0]?.projectTypeBilling;
  let projectBillingTypes = projectBilling?.map((i) => {
    return {
      label: i?.name,
      value: i?.project_billing_id,
    };
  });

  const submitHandler = async (data) => {
    setLoad(true);
    data.hod = data?.hod?.map((data) => data?.value);
    const submitData = {
      client_name: data?.clientName,
      nda_date: data?.ndaDate,
      is_visible_nda_details: data?.ndaCheckbox,
      address: data?.clientAddress,
      project_name: data?.projectName,
      department: data?.department?.value,
      head_of_department: data?.hod,
      project_manager: data?.projectManager?.value,
      project_type: data?.projectType?.value,
      project_billing: data?.projectBilling?.value,
      sow_date: data?.sowDate,
      is_visible_billing_type: data?.sowCheckbox,
      cost: data?.cost ? data?.cost : 0,
      billing_terms: data?.billingTerms,
      billing_start_date: data?.billingStartDate,
      follow_up: data?.followUp,
      domain: data?.domain?.value,
      other_domain: data?.otherDomain,
      billing_due_date: JSON.stringify(
        data?.billingTermsData?.map((data) => {
          return {
            cost: String(data?.cost),
            billing_due_date: data?.billingDueDate,
            paid_date: data?.paidDate,
          };
        })
      ),
      sale_person: data?.salesPerson,
      client_contact_person: JSON.stringify(
        data?.clientContact?.map((data) => {
          return {
            contact_person_name: data?.contactPerson,
            contact_email: data?.contactPersonEmail,
            contact_number: data?.contactPersonNumber,
            department: data?.contactPersonDepartment,
          };
        })
      ),
      project_contact_person: JSON.stringify(
        data?.projectContact?.map((data) => {
          return {
            contact_person_name: data?.contactPerson,
            contact_email: data?.contactPersonEmail,
            contact_number: data?.contactPersonNumber,
          };
        })
      ),
    };
    const formData = new FormData();
    if (data?.sowDocument && data?.sowDocument?.length > 0) {
      for (let i = 0; i < data?.sowDocument?.length; i++) {
        formData.append(`sow_signed`, data.sowDocument[i]);
      }
    }
    if (data?.ndaDocument && data?.ndaDocument?.length > 0) {
      for (let i = 0; i < data?.ndaDocument?.length; i++) {
        formData.append(`nda_Document`, data.ndaDocument[i]);
      }
    }
    for (let key in submitData) {
      formData.append(key, submitData[key]);
    }
    await dispatch(createClientSlice(formData));
    reset();
    setAddClient(false);
    setValue("ndaDocument", null);
    setValue("sowDocument", null);
    await dispatch(getAllClientsData());
    setLoad(false);
  };
  const inputRefNdaDocument = React.useRef(null);
  const inputRefSowDocument = React.useRef(null);

  const {
    fields: clientContactFields,
    append: clientContactAppend,
    remove: clientContactRemove,
  } = useFieldArray({
    name: `clientContact`,
    control,
  });
  const {
    fields: projectContactFields,
    append: projectContactAppend,
    remove: projectContactRemove,
  } = useFieldArray({
    name: `projectContact`,
    control,
  });
  const {
    fields: billingTermsDataFields,
    append: billingTermsDataAppend,
    remove: billingTermsDataRemove,
  } = useFieldArray({
    name: `billingTermsData`,
    control,
  });

  return (
    <>
      <div
        className={
          addClient ? "createProjectDrawer active" : "createProjectDrawer"
        }
      >
        <div className="createProjectDrawerHeader">
          <div className="w-full whitespace-nowrap">
            <div className="text-lg mb-2 font-semibold flex items-center justify-between">
              <div>Add Client</div>
              <div
                className="headerClose"
                onClick={() => {
                  setAddClient(false);
                  reset();
                }}
              >
                <i className="fa-light fa-xmark fa-lg"></i>
              </div>
            </div>
            <div className="text-sm text-blue-100">
              Get started by filling the information below to add new client.
            </div>
          </div>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submitHandler)}>
            <div className={addClient ? "active" : ""}>
              <div className="addClientForm bg-white rounded-md border-1 border-gray-200 mt-2">
                <div className="font-semibold flex items-center justify-between text-lg px-3">
                  Client Details:
                </div>
                <div className="formContainer whitespace-nowrap pt-4 text-sm">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="clientName"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Client name <span className="text-red-500">*</span>
                      </label>
                      <TextFieldComponent
                        name="clientName"
                        onChangeHandler={() => trigger("clientName")}
                        control={control}
                        errors={errors}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="ndaDocument"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        NDA Signed Attachment & Signed Date
                        <span className="text-red-500">*</span>
                        <label
                          htmlFor="nda-file-upload"
                          className="cursor-pointer inline-flex items-center rounded-md text-sm font-medium ml-1 text-black"
                        >
                          <i className="fa-light fa-paperclip mr-2 justify-center align-middle"></i>
                        </label>
                      </label>

                      <div className="flex flex-column items-center">
                        <div className="col-md-12">
                          <Controller
                            name="ndaDocument"
                            control={control}
                            errors={errors}
                            render={({ field: { value, onChange, name } }) => (
                              <>
                                <div className="flex items-center gap-3">
                                  <div>
                                    <input
                                      ref={inputRefNdaDocument}
                                      name={name}
                                      className="w-0 h-0 opacity-0 overflow-hidden absolute z-0"
                                      accept="image/*, .pdf, .doc, .docx"
                                      type="file"
                                      id="nda-file-upload"
                                      multiple={true}
                                      onChange={(e) => {
                                        e.preventDefault();
                                        onChange(e?.target?.files);
                                        trigger("ndaDate");
                                      }}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          />
                        </div>
                        <div className="col-md-12">
                          <DatePickerComponent
                            name="ndaDate"
                            errors={errors}
                            control={control}
                            backSpaceChange={false}
                            onHandleChange={() => trigger("ndaDate")}
                            disableMaskedInput={true}
                          />
                        </div>
                        {watch("ndaDocument")?.length ? (
                          <div className="col-md-12 text-sm ml-0 mt-1">
                            {watch("ndaDocument")?.length === 1
                              ? `${
                                  watch("ndaDocument")?.length
                                } file Uploaded Successfully !`
                              : `${
                                  watch("ndaDocument")?.length
                                } file(s) Uploaded Successfully !`}
                          </div>
                        ) : null}
                        {!watch("ndaDocument")?.length && errors.ndaDocument ? (
                          <div className="custom-error w-100 text-left mt-1">
                            {errors?.ndaDocument?.message}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="mb-3">
                        <label
                          htmlFor="salesPerson"
                          className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Sales Person
                        </label>
                        <TextFieldComponent
                          name="salesPerson"
                          id="salesPerson"
                          onChangeHandler={() => trigger("salesPerson")}
                          control={control}
                          errors={errors}
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="domain"
                          className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Domain
                        </label>
                        <div className="create_form_input_div autoSelect">
                          <AutoCompleteComponent
                            // size="small"
                            name="domain"
                            control={control}
                            errors={errors}
                            onChangeHandler={() => {
                              trigger("domain");
                              setValue("otherDomain", null);
                            }}
                            options={domainList?.length ? domainList : []}
                          />
                        </div>
                      </div>
                      {watch("domain")?.value === "Others" && (
                        <div>
                          <label
                            htmlFor="otherDomain"
                            className="block mb-1 text-sm font-medium text-gray-900 dark:text-white mt-3"
                          >
                            Other Domain
                          </label>
                          <div className="create_form_input_div autoSelect">
                            <TextFieldComponent
                              name="otherDomain"
                              id="otherDomain"
                              onChangeHandler={() => trigger("otherDomain")}
                              control={control}
                              errors={errors}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="clientAddress"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Address
                      </label>
                      <TextAreaComponent
                        name="clientAddress"
                        placeholder="Write your address here..."
                        control={control}
                        handleChange={() => trigger("clientAddress")}
                        errors={errors}
                        rows={watch("domain")?.value === "Others" ? 7 : 4}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8 mb-3">
                      <label
                        htmlFor="ndaCheckbox"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        <Controller
                          name="ndaCheckbox"
                          control={control}
                          errors={errors}
                          value={false}
                          render={({ field: { onChange, value } }) => (
                            <>
                              <Checkbox
                                color="primary"
                                onChange={(e, val) => {
                                  onChange(val);
                                }}
                                checked={value || false}
                              />
                            </>
                          )}
                        />
                        Show NDA Details to HOD.
                      </label>
                    </div>
                    <div
                      className="col-md-4 mb-3 relative"
                      onClick={() => {
                        clientContactAppend({
                          contactPerson: "",
                          contactPersonEmail: "",
                          contactPersonNumber: null,
                          contactPersonDepartment: "",
                        });
                      }}
                    >
                      <div className="border-1 border-blue-600 rounded-md cursor-pointer text-blue-600 hover:text-gray-50 hover:bg-blue-500 hover:border-blue-500  d-flex justify-content-center align-items-center gap-1 px-3 text-sm py-0.5 absolute right-3 bottom-1">
                        <i className="fa-light fa-plus primary"></i>
                        Add Client Contact
                      </div>
                    </div>
                  </div>
                  <hr className="border-1 border-gray-300" />
                  {clientContactFields?.map((item, index) => {
                    return (
                      <>
                        <div
                          className="flex-col py-2 mb-2 justify-content-center "
                          key={item?.id}
                        >
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <label
                                htmlFor={`clientContact.${index}.contactPerson`}
                                className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                              >
                                Client Contact Person Name
                                <span className="text-red-500">*</span>
                              </label>
                              <TextFieldComponent
                                name={`clientContact.${index}.contactPerson`}
                                id={`clientContact.${index}.contactPerson`}
                                control={control}
                                errors={errors}
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <label
                                htmlFor="contactPersonEmail"
                                className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                              >
                                Client Contact Person Email
                                <span className="text-red-500">*</span>
                              </label>
                              <TextFieldComponent
                                name={`clientContact.${index}.contactPersonEmail`}
                                id={`clientContact.${index}.contactPersonEmail`}
                                control={control}
                                errors={errors}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <label
                                htmlFor="contactPersonNumber"
                                className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                              >
                                Client Contact Person No.
                              </label>
                              <TextFieldComponent
                                name={`clientContact.${index}.contactPersonNumber`}
                                id={`clientContact.${index}.contactPersonNumber`}
                                type="number"
                                control={control}
                                errors={errors}
                              />
                            </div>
                            <div className="col-md-6 d-flex gap-4">
                              <div className="col-md-9">
                                <label
                                  htmlFor="contactPersonDepartment"
                                  className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                  Client Contact Person Department
                                </label>
                                <TextFieldComponent
                                  name={`clientContact.${index}.contactPersonDepartment`}
                                  id={`clientContact.${index}.contactPersonDepartment`}
                                  control={control}
                                  errors={errors}
                                />
                              </div>
                              <div
                                className="col-md-2 mt-4 relative border-1 h-fit border-gray-400 d-flex justify-content-center px-0 py-2 rounded cursor-pointer"
                                onClick={() => {
                                  clientContactRemove(index);
                                }}
                              >
                                <i className="fa-solid fa-trash-can danger"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="border-1 border-gray-300" />
                      </>
                    );
                  })}
                  {/*  Project Detail form */}
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <div className="font-semibold flex items-center justify-between py-2 text-lg">
                        Project Details:
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="projectName"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Project Name <span className="text-red-500">*</span>
                      </label>
                      <TextFieldComponent
                        name="projectName"
                        onChangeHandler={() => trigger("projectName")}
                        control={control}
                        errors={errors}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="department"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Department
                      </label>
                      <div className="create_form_input_div autoSelect">
                        <AutoCompleteComponent
                          // size="small"
                          name="department"
                          control={control}
                          errors={errors}
                          onChangeHandler={() => trigger("department")}
                          options={
                            getDepartmentType?.length ? getDepartmentType : []
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="hod"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Head of Project
                        <span className="text-red-500">*</span>
                      </label>
                      <div className="create_form_input_div autoSelect">
                        <AutoCompleteComponent
                          size="small"
                          name="hod"
                          control={control}
                          errors={errors}
                          multiple={true}
                          onChangeHandler={() => trigger("hod")}
                          options={getAllHodList?.length ? getAllHodList : []}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="projectManager"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Project Manager
                      </label>
                      <div className="create_form_input_div autoSelect">
                        <AutoCompleteComponent
                          name="projectManager"
                          control={control}
                          errors={errors}
                          options={getAllRMList?.length ? getAllRMList : []}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="projectType"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Project Type
                      </label>
                      <div className="create_form_input_div autoSelect">
                        <AutoCompleteComponent
                          size="small"
                          name="projectType"
                          control={control}
                          errors={errors}
                          onChangeHandler={() =>
                            setValue("projectBilling", null)
                          }
                          options={
                            projectTypeNames?.length ? projectTypeNames : []
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="projectBilling"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Project Billing
                      </label>
                      <div className="create_form_input_div autoSelect">
                        <AutoCompleteComponent
                          name="projectBilling"
                          control={control}
                          errors={errors}
                          disabled={!watch("projectType")}
                          options={
                            projectBillingTypes?.length
                              ? projectBillingTypes
                              : []
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="sowDocument"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        SOW Signed Attachment & Signed Date
                        <span className="text-red-500">*</span>
                        <label
                          htmlFor="sow-file-upload"
                          className="cursor-pointer inline-flex items-center rounded-md text-sm font-medium ml-1 text-black"
                        >
                          <i className="fa-light fa-paperclip mr-2 justify-center align-middle"></i>
                          {/* Upload file */}
                        </label>
                      </label>
                      <div className="flex flex-column items-center">
                        <div className="col-md-12">
                          <Controller
                            name="sowDocument"
                            control={control}
                            errors={errors}
                            render={({ field: { value, onChange, name } }) => (
                              <div className="flex items-center gap-3">
                                <div>
                                  <input
                                    ref={inputRefSowDocument}
                                    name={name}
                                    className="w-0 h-0 opacity-0 overflow-hidden absolute z-0"
                                    accept="image/*, .pdf, .doc, .docx"
                                    type="file"
                                    id="sow-file-upload"
                                    multiple={true}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      onChange(e?.target?.files);
                                      trigger("sowDate");
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          />
                        </div>
                        <div className="col-md-12">
                          <DatePickerComponent
                            name="sowDate"
                            control={control}
                            errors={errors}
                            backSpaceChange={false}
                            onHandleChange={() => trigger("sowDate")}
                            disableMaskedInput={true}
                          />
                        </div>
                        {watch("sowDocument")?.length ? (
                          <div className="col-md-12 text-sm ml-0 mt-1">
                            {watch("sowDocument")?.length === 1
                              ? `${
                                  watch("sowDocument")?.length
                                } file Uploaded Successfully !`
                              : `${
                                  watch("sowDocument")?.length
                                } file(s) Uploaded Successfully !`}
                          </div>
                        ) : null}
                        {!watch("sowDocument")?.length && errors.sowDocument ? (
                          <div className="custom-error w-100 text-left mt-1">
                            {errors?.sowDocument?.message}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="cost"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Cost/ Rate <span className="text-red-500">*</span>
                      </label>
                      <TextFieldNumberComponent
                        name="cost"
                        control={control}
                        errors={errors}
                        type={"number"}
                        disabled={false}
                        defaultValue={watch("cost")}
                        InputProps={{
                          inputProps: { min: 0, step: "any" },
                        }}
                        onChangeHandler={() => trigger("cost")}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8 mb-3">
                      <label
                        htmlFor="sowCheckbox"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white mt-3"
                      >
                        <Controller
                          name="sowCheckbox"
                          control={control}
                          errors={errors}
                          value={false}
                          render={({ field: { onChange, value } }) => (
                            <>
                              <Checkbox
                                color="primary"
                                onChange={(e, val) => {
                                  onChange(val);
                                }}
                                checked={value || false}
                              />
                            </>
                          )}
                        />
                        Show Billing And Project Type Of HOD
                      </label>
                    </div>
                    <div
                      className="col-md-4 mb-3 relative"
                      onClick={() => {
                        projectContactAppend({
                          contactPerson: "",
                          contactPersonEmail: "",
                          contactPersonNumber: null,
                        });
                      }}
                    >
                      <div className="border-1 border-blue-600 rounded-md cursor-pointer text-blue-600 hover:text-gray-50 hover:bg-blue-500 hover:border-blue-500  d-flex justify-content-center align-items-center gap-1 px-3 py-0.5 text-sm absolute right-3 bottom-1">
                        <i className="fa-light fa-plus primary"></i>
                        Add Project Contact
                      </div>
                    </div>
                  </div>
                  <hr className="border-1 border-gray-300" />
                  {projectContactFields?.map((item, index) => {
                    return (
                      <>
                        <div
                          className="d-flex justify-content-center"
                          key={item?.id}
                        >
                          <div className="row py-2">
                            <div className="col-md-3 mb-2 mr-2">
                              <label
                                htmlFor={`projectContact.${index}.contactPerson`}
                                className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                              >
                                Contact Person Name
                                <span className="text-red-500">*</span>
                              </label>
                              <TextFieldComponent
                                name={`projectContact.${index}.contactPerson`}
                                id={`projectContact.${index}.contactPerson`}
                                control={control}
                                errors={errors}
                              />
                            </div>
                            <div className="col-md-4 mb-2 mr-2">
                              <label
                                htmlFor="contactPersonEmail"
                                className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                              >
                                Contact Person Email
                                <span className="text-red-500">*</span>
                              </label>
                              <TextFieldComponent
                                name={`projectContact.${index}.contactPersonEmail`}
                                id={`projectContact.${index}.contactPersonEmail`}
                                control={control}
                                errors={errors}
                              />
                            </div>
                            <div className="col-md-3 mb-2 mr-2">
                              <label
                                htmlFor="contactPersonNumber"
                                className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                              >
                                Contact Person No.
                              </label>
                              <TextFieldComponent
                                name={`projectContact.${index}.contactPersonNumber`}
                                id={`projectContact.${index}.contactPersonNumber`}
                                type="number"
                                control={control}
                                errors={errors}
                              />
                            </div>
                            <div
                              className="col-md-1 mt-4 ml-3 mb-2 h-fit relative border-1 border-gray-400 d-flex justify-content-center px-0 py-2 rounded cursor-pointer"
                              onClick={() => {
                                projectContactRemove(index);
                              }}
                            >
                              <i className="fa-solid fa-trash-can danger"></i>
                            </div>
                          </div>
                        </div>
                        <hr className="border-1 border-gray-300" />
                      </>
                    );
                  })}
                  {/*  Follow UP And Billing Terms Form */}
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <div className="font-semibold flex items-center justify-between py-2 text-lg">
                        Follow Up & Billing Terms:
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="billingTerms"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Billing Terms
                        <span className="text-red-500">*</span>
                      </label>
                      <TextFieldComponent
                        name="billingTerms"
                        onChangeHandler={() => trigger("billingTerms")}
                        control={control}
                        errors={errors}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="followUp"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Follow Up <span className="text-red-500">*</span>
                      </label>
                      <DatePickerComponent
                        name="followUp"
                        control={control}
                        errors={errors}
                        backSpaceChange={false}
                        onHandleChange={() => trigger("billingEndDate")}
                        disableMaskedInput={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="billingStartDate"
                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Billing Start Date
                        <span className="text-red-500">*</span>
                      </label>
                      <DatePickerComponent
                        name="billingStartDate"
                        control={control}
                        errors={errors}
                        backSpaceChange={false}
                        onHandleChange={() => trigger("billingStartDate")}
                        disableMaskedInput={true}
                      />
                    </div>
                    <div
                      className="col-md-6 mb-3 relative"
                      onClick={() => {
                        billingTermsDataAppend({
                          cost: 0,
                          billingDueDate: "",
                          paidDate: "",
                        });
                      }}
                    >
                      <div className="border-1 border-blue-600 rounded-md cursor-pointer text-blue-600 hover:text-gray-50 hover:bg-blue-500 hover:border-blue-500  d-flex justify-content-center align-items-center gap-1 px-3 py-0.5 text-sm absolute right-3 bottom-1">
                        <i className="fa-light fa-plus primary"></i>
                        Add Cost
                      </div>
                    </div>
                  </div>
                  <hr className="border-1 border-gray-300" />
                  {billingTermsDataFields?.map((item, index) => {
                    return (
                      <>
                        <div
                          className="d-flex justify-content-center"
                          key={item?.id}
                        >
                          <div className="row py-2">
                            <div className="col-md-3 mr-2">
                              <label
                                htmlFor={`billingTermsData.${index}.cost`}
                                className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                              >
                                Cost / Rate
                              </label>
                              <TextFieldNumberComponent
                                name={`billingTermsData.${index}.cost`}
                                control={control}
                                errors={errors}
                                type={"number"}
                                disabled={false}
                                defaultValue={watch(
                                  `billingTermsData.${index}.cost`
                                )}
                                InputProps={{
                                  inputProps: { min: 0, step: "any" },
                                }}
                                onChangeHandler={() =>
                                  trigger(`billingTermsData.${index}.cost`)
                                }
                              />
                            </div>
                            <div className="col-md-4 mr-2">
                              <label
                                htmlFor={`billingTermsData.${index}.billingDueDate`}
                                className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                              >
                                Billing Due Date
                              </label>
                              <DatePickerComponent
                                name={`billingTermsData.${index}.billingDueDate`}
                                control={control}
                                errors={errors}
                                backSpaceChange={false}
                                disableMaskedInput={true}
                              />
                            </div>
                            <div className="col-md-3 mr-4">
                              <label
                                htmlFor={`billingTermsData.${index}.paidDate`}
                                className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                              >
                                Billing Paid Date
                              </label>
                              <DatePickerComponent
                                name={`billingTermsData.${index}.paidDate`}
                                control={control}
                                errors={errors}
                                backSpaceChange={false}
                                disableMaskedInput={true}
                              />
                            </div>
                            <div
                              onClick={() => {
                                billingTermsDataRemove(index);
                              }}
                              className="col-md-1 mt-4 border-1 h-fit border-gray-400 d-flex justify-content-center px-3 py-2 rounded cursor-pointer"
                            >
                              <i className="fa-solid fa-trash-can danger"></i>
                            </div>
                          </div>
                        </div>
                        <hr className="border-1 border-gray-300" />
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="text-end p-3  whitespace-nowrap">
              <button
                onClick={() => {
                  setAddClient(false);
                  reset();
                }}
                type="button"
                className="inline-flex font-sm text-sm border-1 border-gray-600 rounded-md cursor-pointer hover:text-gray-50 hover:bg-gray-500 hover:border-gray-500 py-2 px-4"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={!isDirty}
                id={!isDirty ? "newDisable" : ""}
                className="ml-3 inline-flex font-sm text-sm border-1 border-blue-600 rounded-md cursor-pointer bg-blue-600 text-white hover:text-gray-50 hover:bg-blue-500 hover:border-blue-500 py-2 px-4"
              >
                Submit
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
      <div
        className={
          addClient ? "createProjectOverlay active" : "createProjectOverlay"
        }
        onClick={() => setAddClient(false)}
      ></div>
    </>
  );
};

export default AddClientForm;
