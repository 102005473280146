import dayjs from "dayjs";
import React, { useState } from "react";
import pdf from "./images/pdf-icon.png";
import doc from "./images/doc-icon.png";
import UpdateClient from "./UpdateClient";
import { componentDisplayAccess } from "../../../utils/userPermission";
const ClientInformation = (clientDataById) => {
  console.log('clientDataById:', clientDataById)
  const [openEdit, setOpenEdit] = useState(false);
  const clientAccess = componentDisplayAccess({
    component: "Client_Details",
  });
  return (
    <>
      <div className="mt-4">
        <div className="row">
          <div className="col-md-6">
            <div className="h-full bg-white border-1 p-3 border-gray-100 rounded-lg relative overflow-hidden">
              <div className="mb-4">
                <div className="flex items-center justify-between text-md mb-2 font-semibold">
                  <div>NDA Signed Attachment:</div>
                  {clientAccess?.updateAccess ? (
                    <button
                      onClick={() => setOpenEdit(true)}
                      className="inline-flex text-blue-600 border-1 border-blue-500 hover:bg-blue-500 hover:text-gray-50 hover:border-blue-500 rounded-md py-1 px-3 text-sm cursor-pointer"
                    >
                      Edit
                    </button>
                  ) : null}
                </div>
                <div className="updateFormImg flex gap-4">
                  {clientDataById?.clientData?.kickDetails?.nda_Document?.map(
                    (item, key) => {
                      let itemExtension = item?.name?.split(".");
                      let imageExtension = [
                        "jpg",
                        "jpeg",
                        "png",
                        "gif",
                        "bmp",
                        "tiff",
                        "tif",
                        "webp",
                        "svg",
                      ];
                      let documentExtension = ["doc", "docx"];
                      itemExtension = itemExtension[itemExtension?.length - 1];
                      return (
                        <div key={key} id={key} className="">
                          {imageExtension?.includes(itemExtension) ? (
                            <div className="flex flex-col align-items-center justify-center">
                              <img
                                src={item?.url}
                                alt={item?.name}
                                onClick={() => {
                                  const link = document.createElement("a");
                                  link.href = item?.url;
                                  link.download = item?.name;
                                  link.click();
                                }}
                                className="uploadImgIcon img-fluid"
                              />
                              <div className="updateImgName">{item?.name}</div>
                            </div>
                          ) : documentExtension?.includes(itemExtension) ? (
                            <div className="flex flex-col align-items-center justify-center">
                              <img
                                src={doc}
                                alt={item?.name}
                                onClick={() => {
                                  const link = document.createElement("a");
                                  link.href = item?.url;
                                  link.download = item?.name;
                                  link.click();
                                }}
                                className="uploadImgIcon img-fluid"
                              />{" "}
                              <div className="updateImgName">{item?.name}</div>
                            </div>
                          ) : (
                            <div className="flex flex-col align-items-center justify-center">
                              <img
                                src={pdf}
                                alt={item?.name}
                                onClick={() => {
                                  const link = document.createElement("a");
                                  link.href = item?.url;
                                  link.download = item?.name;
                                  link.click();
                                }}
                                className="uploadImgIcon img-fluid"
                              />
                              <div className="updateImgName">{item?.name}</div>
                            </div>
                          )}
                          {/* <p className="text-sm mt-1">{item?.name}</p> */}
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
              <div className="mb-4">
                <div className="text-md mb-2 font-semibold">Address:</div>
                <div className="text-sm">
                  {clientDataById?.clientData?.address ?? "-"}
                </div>
              </div>
              <div className="mb-4">
                <div className="text-md mb-2 font-semibold">
                  NDA Signed Date:
                </div>
                <div className="text-sm">
                  {dayjs(clientDataById?.clientData?.kickDetails?.nda_date)?.format(
                    "DD-MMM-YYYY"
                  ) ?? "-"}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="h-full bg-white border-1 p-3 border-gray-100 rounded-lg relative overflow-hidden">
              <div className="mb-4">
                <div className="text-lg mb-2 font-semibold">
                  Contact Person Details:
                </div>
              </div>
              <div className="d-flex gap-2">
                {clientDataById?.clientData?.contactDetails?.map(
                  (data, index) => {
                    return (
                      <div className="col-md-4 px-2 py-1">
                        <div className="row">
                          <div className="col-md-12 px-2 mb-4">
                            <div className="text-md mb-2 font-semibold">
                              Contact Person {index + 1}:
                            </div>
                            <div className="text-sm capitalize">
                              {data?.contact_person_name}
                            </div>
                          </div>
                          <div className="col-md-12 px-2 mb-4">
                            <div className="text-md mb-2 font-semibold">
                              Contact Email:
                            </div>
                            <div className="text-sm">{data?.contact_email}</div>
                          </div>
                          <div className="col-md-12 px-2 mb-4">
                            <div className="text-md mb-2 font-semibold">
                              Contact Number:
                            </div>
                            <div className="text-sm">
                              {data?.contact_number}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>
        {openEdit && (
          <UpdateClient
            setOpenEdit={setOpenEdit}
            openEdit={openEdit}
            clientData={clientDataById?.clientData}
          />
        )}
      </div>
    </>
  );
};

export default ClientInformation;
