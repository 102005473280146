import React from "react";
import { NavLink } from "react-router-dom";
import ProjectDashboard from "./Project/ProjectDashboard";
import Client from "./Client/Client";
import { componentDisplayAccess } from "../../utils/userPermission";

const ClientHeader = () => {
  const clientAccess = componentDisplayAccess({
    component: "Client_Details",
  });
  const projectAccess = componentDisplayAccess({
    component: "Project",
  });
  const ClientRoute = [
    {
      path: "client",
      element: <Client />,
      title: "Client",
      index: true,
      access: clientAccess,
    },
    {
      path: "project",
      element: <ProjectDashboard />,
      title: "Project",
      index: true,
      access: projectAccess,
    },
  ];
  return (
    <ul className="teamTopHeader flex items-center border-b border-gray-300 bg-white text-sm">
      {ClientRoute?.map((item, index) => {
        return item?.access?.readAccess ? (
          <li className="py-2 px-3 relative" key={index}>
            <NavLink
              to={`${item.path}`}
              className={({ isActive, isPending }) =>
                isPending
                  ? "pending py-2"
                  : isActive
                  ? `active border-b border-blue-500 py-2 ${item?.path}`
                  : "py-2"
              }
            >
              {item.title}
            </NavLink>
          </li>
        ) : null;
      })}
    </ul>
  );
};

export default ClientHeader;
