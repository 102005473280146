import React, { createContext, useContext, useState } from "react";
import "./GlobalPopup.css";

// Create a context for managing the popup state
export const PopupContext = createContext();

// Create a component that will render the popup globally
const GlobalPopup = () => {
  const { message, closePopup, alertClass } = useContext(PopupContext);
  return (
    message && (
      <div className="alertMessageMain">
        <div
          className={`alert ${alertClass} alert-dismissible fade show`}
          role="alert"
        >
          <div className="alertMessageContent">
            <div className="alretMessageIcon">
              {alertClass === "alert-danger" ? (
                <i className="fa-light fa-circle-xmark"></i>
              ) : null || alertClass === "alert-success" ? (
                <i className="fa-light fa-circle-check"></i>
              ) : null || alertClass === "alert-warning" ? (
                <i className="fa-light fa-circle-exclamation"></i>
              ) : null || alertClass === "alert-info" ? (
                <i className="fa-light fa-circle-info"></i>
              ) : null}
            </div>
            <div>
              {/* <h5>Welcome back <strong> Name! </strong></h5>  */}
              <p>{message}</p>
            </div>
            <button
              type="button"
              className="alertMessageClose"
              onClick={() => closePopup()}
            >
              {" "}
              <i className="fa-light fa-xmark"></i>{" "}
            </button>
          </div>
        </div>
      </div>
    )
  );
};

// Create a provider for the PopupContext
export const PopupProvider = ({ children }) => {
  const [message, setMessage] = useState("");
  const [alertClass, setAlertClass] = useState("");
  const manualClose = false;
  const displayPopup = (msg, alertClass, manualClose) => {
    setMessage(msg);
    setAlertClass(alertClass);
    if (!manualClose) {
      setTimeout(() => {
        closePopup();
      }, 5000);
    }
  };

  const closePopup = () => {
    setMessage("");
  };

  return (
    <PopupContext.Provider
      value={{ message, displayPopup, closePopup, alertClass, manualClose }}
    >
      {children}
      <GlobalPopup />
      {/* <AlertsMessage alertClass={"text-danger"} responseData={{message: message}}></AlertsMessage> */}
    </PopupContext.Provider>
  );
};
