import React from "react";
import "../SearchBox/SearchBox.css";

const SearchBox = ({ setSearch, placeholder="Search" }) => {
  const handleSearchChange = (event) => {
    const delayInput = setTimeout(() => {
      setSearch(event?.target?.value);
    }, 800);
    return () => clearTimeout(delayInput);
  };

  return (
    <>
      <div className="searchBox">
        <input
          type="text"
          placeholder={placeholder}
          onChange={handleSearchChange}
          className="searchBoxInput"
        />
        <i className="fa-light fa-magnifying-glass searchBoxIcon"></i>
      </div>
    </>
  );
};
export default SearchBox;
