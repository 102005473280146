import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "../../utils/http";
import END_POINTS from "../../constants/endpoints";
import { toast } from "react-toastify";


export const fetchEmployeePermanentWorkStatus = createAsyncThunk(
  "employee-workMode",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.EMPLOYEE_PERMANENT_WORK_STATUS}/${params}`
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);


export const fetchEmployeeAttendance = createAsyncThunk(
  "employee/attendance",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `employee/attendance/${params.EmployeeId}`,
        { params: { startDate: params.startDate, endDate: params.endDate } }
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const postRegularizationRequest = createAsyncThunk(
  END_POINTS.REQUEST_REGULARIZATION,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        `${END_POINTS.REQUEST_REGULARIZATION}`,
        params
      );
      toast.success(data.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
const initialState = {
  EmployeeOnlineData: {},
  currentDateAttendanceLog: {},
  employeePermanentWorkStatus: "",
  employeeMonthlyAttendance: { isFetching: false, attendanceList: [] },
  regularizationRes: {
    isFetching: false,
  },
  loading: false,
  error: null,
  success: false,
};

export const onlineAttendanceDataSlice = createSlice({
  name: "onlineAttendance",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployeePermanentWorkStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchEmployeePermanentWorkStatus.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.employeePermanentWorkStatus = payload;
          state.error = null;
        }
      )
      .addCase(
        fetchEmployeePermanentWorkStatus.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.employeePermanentWorkStatus = "";
          state.success = false;
        }
      )

      //Employee Monthly Attendance
      .addCase(fetchEmployeeAttendance.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEmployeeAttendance.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.employeeMonthlyAttendance = {
          status: false,
          attendanceList: payload,
        };
        state.error = null;
      })
      .addCase(fetchEmployeeAttendance.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.employeeMonthlyAttendance = { status: false, attendanceList: [] };
        state.success = false;
      })
      // REQUEST REGULARIZATION
      .addCase(postRegularizationRequest.pending, (state) => {
        state.regularizationRes.isFetching = true;
      })
      .addCase(postRegularizationRequest.fulfilled, (state, { payload }) => {
        state.regularizationRes.isFetching = false;
      })
      .addCase(postRegularizationRequest.rejected, (state, { payload }) => {
        state.regularizationRes.isFetching = false;
      });
  },
});

export default onlineAttendanceDataSlice.reducer;
