import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "../../utils/http";
import END_POINTS from "../../constants/endpoints";
import { toast } from "react-toastify";

export const fetchPolicyReportData = createAsyncThunk(
  END_POINTS.GET_POLICY_CONSENT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.GET_POLICY_CONSENT}`, {
        params: {
          status: params?.status,
          page: params?.page,
          limit: params.limit,
          sortField: params?.sortField,
          sortOrder: params?.sortOrder,
          search: params?.search,
        },
      });
      // toast.success(data?.message);
      return data;
    } catch (error) {
      // toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchWfhReportData = createAsyncThunk(
  END_POINTS.FETCH_WFH_REPORT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.FETCH_WFH_REPORT}`, {
        params: {
          from_date: params?.fromDate,
          to_date: params?.toDate,
          status: params?.status,
          page: params?.page,
          limit: params.limit,
          sortField: params?.sortField,
          sortOrder: params?.sortOrder,
          search: params?.search,
        },
      });
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const fetchWfhRequestData = createAsyncThunk(
  END_POINTS.GET_WFH_APPLICATION_V1,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.GET_WFH_APPLICATION_V1}`, {
        params: {
          from_date: params?.fromDate,
          to_date: params?.toDate,
          status: params?.status,
          page: params?.page,
          limit: params.limit,
          sort: params?.sort,
          order: params?.order,
          search: params?.search,
        },
      });
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const fetchLeaveReportData = createAsyncThunk(
  END_POINTS.FETCH_LEAVE_REPORT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.FETCH_LEAVE_REPORT}`, {
        params: {
          from_date: params?.fromDate,
          to_date: params?.toDate,
          status: params?.status,
          page: params?.page,
          limit: params.limit,
          sortField: params?.sortField,
          sortOrder: params?.sortOrder,
          search: params?.search,
        },
      });
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchRegularizationReportData = createAsyncThunk(
  END_POINTS.REGULARIZATION_REPORT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.REGULARIZATION_REPORT}`, {
        params: {
          start_date: params?.fromDate,
          end_date: params?.toDate,
          status: params?.status,
          page: params?.page,
          limit: params.limit,
          sortField: params?.sortField,
          sortOrder: params?.sortOrder,
          search: params?.search,
        },
      });
      // toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchTodayAttendanceReportData = createAsyncThunk(
  END_POINTS.TODAY_ATTENDANCE_REPORT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.TODAY_ATTENDANCE_REPORT}`, {
        params: {
          status: params?.status,
          page: params?.page,
          limit: params.limit,
          sortField: params?.sortField,
          sortOrder: params?.sortOrder,
          search: params?.search,
        },
      });
      // toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchWorkModeData = createAsyncThunk(
  END_POINTS.WORK_MODE_DATA,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.WORK_MODE_DATA}`, {
        params: {
          status: params?.status,
          page: params?.page,
          limit: params.limit,
          sortField: params?.sortField,
          sortOrder: params?.sortOrder,
          search: params?.search,
        },
      });
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchWorkModeRecords = createAsyncThunk(
  END_POINTS.WORK_MODE_RECORDS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.WORK_MODE_RECORDS}/${params?.employeeId}`
      );
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchLeaveBalanceReportData = createAsyncThunk(
  END_POINTS.LEAVE_BALANCE_REPORT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.LEAVE_BALANCE_REPORT}`, {
        params: {
          leave_type: params?.leave_type,
          page: params?.page,
          limit: params.limit,
          sortField: params?.sortField,
          sortOrder: params?.sortOrder,
          search: params?.search,
          fiscal_year: params?.fiscal_year,
        },
      });
      // toast.success(data?.message);
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateLeaveBalance = createAsyncThunk(
  END_POINTS.UPDATE_LEAVE_BALANCE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        END_POINTS.UPDATE_LEAVE_BALANCE,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const allocateLeaveBalance = createAsyncThunk(
  END_POINTS.ALLOCATE_LEAVE_BALANCE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        END_POINTS.ALLOCATE_LEAVE_BALANCE,
        params
      );
      toast.success(data?.message);
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchEmployeeReportData = createAsyncThunk(
  END_POINTS.FETCH_EMPLOYEE_REPORT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.FETCH_EMPLOYEE_REPORT}`, {
        params: {
          status: params?.status,
          page: params?.page,
          limit: params.limit,
          sortField: params?.sortField,
          sortOrder: params?.sortOrder,
          search: params?.search,
        },
      });
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchBirthdayList = createAsyncThunk(
  END_POINTS.FETCH_BIRTHDAY_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.FETCH_BIRTHDAY_LIST}`, {
        params: {
          page: params?.page,
          limit: params.limit,
          sortField: params?.sortField,
          sortOrder: params?.sortOrder,
          search: params?.search,
          fromDate: params?.fromDate,
          toDate: params?.toDate,
        },
      });
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchAnniversaryList = createAsyncThunk(
  END_POINTS.FETCH_ANNIVERSARY_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.FETCH_ANNIVERSARY_LIST}`, {
        params: {
          page: params?.page,
          limit: params.limit,
          sortField: params?.sortField,
          sortOrder: params?.sortOrder,
          search: params?.search,
          fromDate: params?.fromDate,
          toDate: params?.toDate,
        },
      });
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

const initialState = {
  getWfhReportData: {
    isFetching: false,
    Data: null,
  },
  getWfhRequestData: {
    isFetching: false,
    Data: null,
  },
  getLeaveReportData: {
    isFetching: false,
    Data: null,
  },
  getRegularizationReportData: {
    isFetching: false,
    Data: null,
  },
  getTodayAttendanceData: {
    isFetching: false,
    Data: null,
  },
  policyConsentData: {
    isFetching: false,
    Data: null,
  },
  getWorkModeData: {
    isFetching: false,
    Data: null,
  },
  getLeaveBalanceData: {
    isFetching: false,
    Data: null,
  },
  updateLeaveBalanceResponse: {
    isFetching: false,
    response: null,
  },
  allocateLeaveBalanceResponse: {
    isFetching: false,
    response: null,
  },
  getEmployeeReportData: {
    isFetching: false,
    Data: null,
  },
  loading: false,
  error: null,
  success: false,
};

export const ReportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWfhReportData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getWfhReportData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(fetchWfhReportData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getWfhReportData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(fetchWfhReportData.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getWfhReportData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(fetchWfhRequestData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getWfhRequestData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(fetchWfhRequestData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getWfhRequestData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(fetchWfhRequestData.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getWfhRequestData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(fetchLeaveReportData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getLeaveReportData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(fetchLeaveReportData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getLeaveReportData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(fetchLeaveReportData.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getLeaveReportData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(fetchRegularizationReportData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getRegularizationReportData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(
        fetchRegularizationReportData.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.getRegularizationReportData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(fetchRegularizationReportData.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getRegularizationReportData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(fetchTodayAttendanceReportData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getTodayAttendanceData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(
        fetchTodayAttendanceReportData.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.getTodayAttendanceData = {
            isFetching: false,
            response: payload,
          };
          state.error = null;
        }
      )
      .addCase(
        fetchTodayAttendanceReportData.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.getTodayAttendanceData = {
            isFetching: false,
            response: null,
          };
          state.success = false;
        }
      )

      .addCase(fetchWorkModeData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getWorkModeData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(fetchWorkModeData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getWorkModeData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(fetchWorkModeData.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getWorkModeData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })

      .addCase(fetchWorkModeRecords.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getWorkModeRecords = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(fetchWorkModeRecords.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getWorkModeRecords = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(fetchWorkModeRecords.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getWorkModeRecords = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })

      .addCase(fetchLeaveBalanceReportData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getLeaveBalanceData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(fetchLeaveBalanceReportData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getLeaveBalanceData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(fetchLeaveBalanceReportData.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getLeaveBalanceData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(updateLeaveBalance.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateLeaveBalanceResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(updateLeaveBalance.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.updateLeaveBalanceResponse = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(updateLeaveBalance.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.updateLeaveBalanceResponse = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(allocateLeaveBalance.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.allocateLeaveBalanceResponse = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(allocateLeaveBalance.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.allocateLeaveBalanceResponse = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(allocateLeaveBalance.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.allocateLeaveBalanceResponse = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(fetchPolicyReportData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.policyConsentData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(fetchPolicyReportData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.policyConsentData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(fetchPolicyReportData.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.policyConsentData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(fetchEmployeeReportData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getEmployeeReportData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(fetchEmployeeReportData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getEmployeeReportData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(fetchEmployeeReportData.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getEmployeeReportData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })

      .addCase(fetchBirthdayList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getBirthdayList = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(fetchBirthdayList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getBirthdayList = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(fetchBirthdayList.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getBirthdayList = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })

      .addCase(fetchAnniversaryList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getAnniversaryList = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(fetchAnniversaryList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getAnniversaryList = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(fetchAnniversaryList.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getAnniversaryList = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
  },
});

export default ReportsSlice.reducer;
