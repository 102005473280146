import React from "react";

import { Dialog, DialogContent, DialogTitle } from "@mui/material";

const RegularizationCommentDialog = ({
  commentDialog,
  setCommentDialog,
  comment,
}) => {
  return (
    <Dialog
      open={commentDialog}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      className="employee_form_dialog"
    >
      <DialogTitle className="add_employee_main_heading">
        <div className="commentsHeader">
          <div>Comments</div>
          <i
            className="fa-light fa-xmark closeIcon"
            onClick={() => setCommentDialog(false)}
          ></i>
        </div>
      </DialogTitle>
      <DialogContent className="card-content-employee-form">
        <div className="leaveComments bg-white ">
          <div className="empComment p-3 border-1 border-gray-300 rounded-lg">
            <div className="text-blue-600 font-medium whitespace-nowrap mb-2">
              {" "}
              <i className="fa-light fa-comment-lines"></i> Employee Comment
            </div>
            <div className="text-sm">{comment}</div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default RegularizationCommentDialog;
