import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchParticularEmployeeAttendanceList } from "../../../../store/slices/masterSlice";
import { componentDisplayAccess } from "../../../../utils/userPermission";
import Loader from "../../../Loader/Loader";

const AttendanceInfo = ({
  EmployeeId,
  fromDate,
  toDate,
  order,
  setOrder,
  renderComp,
  setRenderComp,
  setAttendanceData,
  setOpenRightDrawer,
  setEmployeeName,
  isFetching,
  downloading,
  selectedEmp,
  status,
  reportType
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    toDate &&
      dispatch(
        fetchParticularEmployeeAttendanceList({
          EmployeeId: EmployeeId,
          fromDate: fromDate,
          toDate: toDate,
          order: order,
          status: status,
          reportType: reportType,
        })
      );
    setRenderComp(false);
  }, [EmployeeId, fromDate, toDate, order, renderComp]);

  const attendanceList = useSelector(
    (state) =>
      state?.masterList?.employeeAttendanceListData?.data?.data
        ?.employeeAttendanceList
  );

  const presentArray = [];
  const absentArray = [];
  const halfdayArray = [];
  attendanceList &&
    attendanceList?.dailyAttendance?.filter((item) =>
      item?.status === "Present"
        ? presentArray?.push(item)
        : item?.status === "Absent"
          ? absentArray?.push(item)
          : halfdayArray?.push(item)
    );

  const teamAttendanceDisplayAccess = componentDisplayAccess({
    component: "Team_Attendance",
  });
  return (
    <>
      <div className="employeePresenceTable relative bg-white w-full rounded-lg">
        {(isFetching || downloading) && <Loader />}
        <table className="w-full text-sm dark:text-gray-400 whitespace-nowrap">
          <thead className="bg-white text-gray-700">
            <tr className="border-b border-gray-200">
              <th colSpan={9}>
                <div className="flex items-center gap-2 py-2">
                  <div className="font-normal">
                    #{selectedEmp[0]?.EmployeeId}
                  </div>
                  <div className="font-semibold">{selectedEmp[0]?.name}</div>
                </div>
              </th>
            </tr>
            <tr className="border-b">
              <th scope="col" className="text-left px-3 py-3">
                <div
                  onClick={() => setOrder(order == "ASC" ? "DESC" : "ASC")}
                  className="flex items-center justify-between cursor-pointer"
                >
                  <p>Date</p>
                  <i
                    className={
                      order === "ASC"
                        ? "fa-light fa-arrow-down-a-z text-md"
                        : "fa-light fa-arrow-up-z-a text-md"
                    }
                  />
                </div>
              </th>
              <th scope="col" className="px-3 py-3">
                First In
              </th>
              <th scope="col" className="px-3 py-3">
                Last Out
              </th>
              <th scope="col" className="px-3 py-3">
                Total Hours
              </th>
              <th scope="col" className="px-3 py-3">
                Work Mode
              </th>
              <th scope="col" className="px-3 py-3">
                Status
              </th>
              <th rowSpan={2} scope="col" className="px-3 py-3">
                Shift
              </th>
              <th scope="col" className="px-3 py-3">
                Regularized
              </th>
              <th rowSpan={2} scope="col" className="px-3 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {EmployeeId && attendanceList?.dailyAttendance?.length ? (
              attendanceList?.dailyAttendance?.map((item) => (
                <tr className="bg-white border-b" key={item?.attendanceId}>
                  <td className="py-2 px-3">{`${dayjs(
                    item?.attendanceDate
                  )?.format("ddd")}, ${dayjs(item?.attendanceDate)?.format(
                    "DD-MMM-YYYY"
                  )}`}</td>
                  <td className="py-2 px-3">
                    {item?.InTime ? dayjs(item?.InTime)?.format("hh:mm A") : "-"}
                  </td>
                  <td className="py-2 px-3">
                    {item?.OutTime ? dayjs(item?.OutTime)?.format("hh:mm A") : "-"}
                  </td>
                  <td className="py-2 px-3">
                    {item?.TotalHours ? `${Number(item?.TotalHours)?.toFixed(2)} hrs` : `0.00 hrs`}
                  </td>
                  <td className="py-2 px-3">{item?.workMode ?? "-"}</td>
                  <td className="py-2 px-3">
                    <span
                      className={`${item?.status === "Present" &&
                        "inline-block bg-green-400 h-3 w-3 rounded-full mr-1"
                        }
                            ${item?.status === "Half Day" &&
                        "inline-block bg-yellow-400 h-3 w-3 rounded-full mr-1"
                        }
                            ${item?.status === "Absent" &&
                        "inline-block bg-red-400 h-3 w-3 rounded-full mr-1"
                        }`}
                    ></span>
                    {item?.status}
                  </td>
                  <td className="py-2 px-3">
                    {`${item?.masterWorkShift
                      ?.shift_name
                      }
                    (${dayjs(
                        item?.masterWorkShift
                          ?.start_time,
                        "hh:mm:ss"
                      )?.format("hh:mm A")} -
                    ${dayjs(
                        item?.masterWorkShift?.end_time,
                        "hh:mm:ss"
                      )?.format("hh:mm A")})`}
                  </td>
                  <td className="py-2 px-3">
                    {item?.is_regularized == 1 ? "Regularized" : "-"}
                  </td>
                  <td className="py-2 px-3">
                    {teamAttendanceDisplayAccess?.updateAccess ? (
                      <i
                        onClick={() => (
                          setOpenRightDrawer(true),
                          setEmployeeName(attendanceList?.employee_name),
                          setAttendanceData(item)
                        )}
                        className="fa-light fa-pencil text-blue-500 cursor-pointer"
                      ></i>
                    ) : (
                      <i
                        aria-disabled
                        className="fa-light fa-pencil text-blue-300 cursor-not-allowed"
                      ></i>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <div className="absolute inset-y-1/2 inset-x-1/2">
                <div className="flex items-center flex-col gap-2">
                  <i className="fa-light fa-clipboard-user fa-4x text-gray-300"></i>
                  <div className="text-md">No Data Found</div>
                </div>
              </div>
            )}
          </tbody>
        </table>
      </div>
      <div className="flex items-center justify-end">
        <div className="py-2 px-3 flex items-center gap-3 text-xs font-semibold">
          <div className="border-l-4 border-green-500 mr-2">
            <div className="ml-2">
              <div>Present</div>
              <div>{presentArray?.length} Day(s)</div>
            </div>
          </div>
          <div className="border-l-4 border-red-500 mr-2">
            <div className="ml-2">
              <div>Absent</div>
              <div>{absentArray?.length} Day(s)</div>
            </div>
          </div>
          <div className="border-l-4 border-yellow-500 mr-2">
            <div className="ml-2">
              <div>Half Day</div>
              <div>{halfdayArray?.length} Day(s)</div>
            </div>
          </div>
          {/* <div className="border-l-4 border-gray-400 mr-2">
            <div className="ml-2">
              <div>Week Off</div>
              <div>0 Day(s)</div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default AttendanceInfo;
