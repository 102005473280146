const CONSTANTS = Object.freeze({
  FISCAL_YEAR_START_MONTH: 3,
  DD_MMM_YYYY: "DD-MMM-YYYY", // Format using for UI
  DATE_FORMAT: "DD-MM-YYYY",
  YYYY_MM_DD: "YYYY-MM-DD", //Format saved in backend DB
  HH_MM_A: "hh:mm a",
  NO_DATA_TO_UPDATE: "No Data To Update",
  CURRENCY: "&#x20B9;",
  REQUIRED: "This field is required.",
  MAXLENGTH: " cannot exceed ",
  MINLENGTH: " should be at least ",
  PASSPORT: "Please enter a valid passport number i.e. U7000000 ",
  PATTERN_ALPHABET: "Only Alphabetical characters Allowed.",
  PATTERN_ALPHABET_SPACE: "Only Alphabetical characters and Space Allowed.",
  PATTERN_NUMBERS: "Only Digits are Allowed.",
  PATTERN_NUMBERS_UAN: "Please enter a valid UAN Number of 12 digits.",
  PATTERN_PHONE_NUMBER: "Please enter a valid phone number.",
  PATTERN_ALPHABET_NUMBER: "Only Alphabetical characters and Number Allowed.",
  MANAGER_ALPHABET_NUMBER:
    "Please Enter A Valid Project Manager Id i.e EMP/12345.",
  PATTERN_ALPHABET_NUMBER_SPECIAL:
    "Only Alphabetical characters, Number And Special Characters(,.-_) Allowed.",
  PATTERN_EMAIL: "Please enter valid email type.",
  PATTERN_PATTERN: "Please enter a valid ",
  PATTERN_EPF: "Please enter a valid EPF Number example MHBAN0000000123",
  PATTERN_PAN:
    "Please Enter a Valid PAN. Format : 1-5 [Alphabet], 6-9[Number], 10[Alphabet]",
  ADDING_ERROR: "Can not add empty data.",
  ADDING_ERROR_DOSAGE: "Dosage Date can not be greater then current date.",
  ADDING_ERROR_AGE: "Person Should be at least 18 years old.",
  ADDING_ERROR_FROM_TO: "Please enter a valid From date or To date.",
  ADDING_ERROR_CERTIFICATE:
    "Certification Date can not be greater then Valid till date.",
  PATTERN_ACCOUNT_NUMBER: "Account number should be 9-18 digits long.",
  AADHAAR_NUMBER: "Please enter a valid Aadhaar number",
  LEAVE_APPROVER: "There should be at least one leave approver.",
  DATE_OF_BIRTH: "Minimum age required is 18 years old.",
  DESIGNATION_EMPTY: "Designation Name Can Not Be Empty Or Space in Beginning",
  BRANCH_EMPTY: "Branch Can Not Be Empty Or Space in Beginning",
  SKILL_EMPTY: "Skill Can Not Be Empty Or Space in Beginning",
  DESIGNATION_EXISTS: "Designation Already Exists.",
  DEPARTMENT_EXISTS: "Department Already Exists.",
  BRANCH_EXISTS: "Branch Already Exists.",
  SKILL_EXISTS: "Skill Already Exists.",
  CLIENT_EXISTS: "Client Already Exists",
  CLIENT_EMPTY: "Client Name Cannot Be Empty",
  DATE_OF_JOINING: "Date of joining should not be greater then present date.",
  DATE_OF_ISSUE:
    "Passport Valid Upto date should be greater than Date of issue.",
  _FORM: "Please enter a valid date.",
  DISPLAY_DATE_FORMAT: "Not a valid date format - use format 12-Jan-2022",
  CONTRACT_DATE: "Contract Date should be greater than Offer Date",
  ADDRESS: "Please enter a valid address.",
  OFFER_DATE: "Confirmation Date should be greater than Offer Date",
  WORK_MODE_FROM_DATE: "From Date can not be less than Current Date",
  PAYROLL_CYCLE_DAY: 26,
  ATTENDANCE_PUNCH: ["InTime", "OutTime"],
  LEAVE_UNAVAILABLE:
    "Leaves Available Are Insufficient. Kindly Apply As Leave Without Pay",
  NOTHING_TO_UPDATE: "Nothing to Update",
  WORKSHIFT_START_DATE_MIN:
    "Workshift Start Date can not be Less than Current Date",
  WORKSHIFT_END_DATE_MIN: "Workshift End Date can not be Less than Start Date",
  WORKSHIFT_START_DATE_MAX:
    "Workshift Start Date can not be Greater than Current Date of next month",
  INVALID_WORKSHIFT_DATE: "Invalid Pattern of Woskshift Start Date",
  INVALID_WORKSHIFT_END_DATE: "Invalid Pattern of Woskshift End Date",
  POST_MESSAGE_1: "Data Size Too Long",
  POST_MESSAGE_2: "Kindly Upload Data With Size Less Than 80 KB.",
  TIME_DIFFERENCE:
    "Difference between Start Time and End Time can not be Greater than 12 hours",
  TIME_DIFFERENCE_LESS_THAN_REQ: "Invalid Value of Minimum Required Hours",
  START_TIME_REQUIRED: "Start Time is Required",
  END_TIME_REQUIRED: "End Time is Required",
  SHIFT_TYPE_REQUIRED: "Shift Type is Required",
  SHIFT_NAME_REQUIRED: "Shift Name is Required",
  MIN_HOURS_REQUIRED: "Minimum Hours is Required",
  STARTTIME_MORETHAN_ENDTIME: "Start Time can not be Greater than End Time",
  DEPARTMENT_CANNOT_BE_EMPTY: "Department Name can not be Empty",
  EMPLOYMENT_TYPE_CANNOT_BE_EMPTY: "Employment Type Name can not be Empty",
  EMPLOYMENT_EXISTS: "Employment Type Already Exists",
  MIN_FILE_SIZE: "File Size Is Less Than 10KB",
  MIN_FILE_SIZE_LOGO: "File Size Is Less Than 5KB",
  CHOOSE_FILE: "Please Choose A Valid File Format",
  MAX_IMAGE_SIZE_NEW: "File Size Sholud Be Less Than 500KB",
  MAX_IMAGE_SIZE: "File Size Sholud Be Less Than 80KB",
  MAX_RESUME_SIZE: "File Size Sholud Be Less Than 5MB",
  VALID_DATE_FORM: "Field Must Have A Valid Date.",
  VALID_NAME: "Please Enter A Valid ",
  APPLY_WFH: "Apply_WFH",
  ASSIGN_WORK_MODE: "Assign_Work_Mode",
  ATTENDANCE_NEW: "Attendance_New",
  EMPLOYEE_BASIC_INFORMATION: "Employee_Basic_Information",
  EMPLOYEE_EMPLOYEELIST: "Employee_EmployeeList",
  EMPLOYEE_EXIT: "Employee_Exit",
  EMPLOYEE_FINANCE: "Employee_Finance",
  EMPLOYEE_JOB_PROFILE_DETAILS: "Employee_Job_Profile_Details",
  EMPLOYEE_PERSONAL_DETAILS: "Employee_Personal_Details",
  EMPLOYEE_PROJECT_INFORMATION: "Employee_Project_Information",
  EMPLOYEE_TECHNICAL_BACKGROUND: "Employee_Technical_Background",
  EMPLOYEE_WORK_SHIFT: "Employee_Work_Shift",
  HOLIDAY_ACCESS: "Holiday_Access",
  HR_BRANCH: "HR_Branch",
  HR_CLIENT_NAME: "HR_Client_Name",
  HR_DAILY_MENU: "HR_Daily_Menu",
  HR_DEPARTMENT_TYPE: "HR_Department_Type",
  HR_DESIGNATION_TYPE: "HR_Designation_Type",
  HR_EMPLOYMENT_TYPE: "HR_Employment_Type",
  HR_LEAVE_ALLOCATION: "HR_Leave_Allocation",
  HR_LEAVE_TYPE: "HR_Leave_Type",
  HR_PROJECT_NAME: "HR_Project_Name",
  HR_SALARY_MANAGEMENT: "HR_Salary_Management",
  SALARY_DEDUCTIONS: "Salary_Deductions",
  SALARY_EARNINGS: "Salary_Earnings",
  EMPLOYEE_SALARY_STRUCTURE: "Salary_Structure",
  HR_SALARY_MANAGEMENT_MEAL_CHARGES: "HR_Salary_Management_Meal_Charges",
  SALARY_BRACKETS: "Salary_Brackets",
  HR_SHIFT_TYPES: "HR_Shift_Types",
  HR_SKILLS_SET: "HR_Skills_Set",
  HUMAN_RESOURCES: "Human_Resources",
  LEAVE_LEAVE_ALLOCATION_APPROVAL: "Leave_Allocation",
  LEAVE_LEAVELIST: "Leave_LeaveList",
  CLIENT: "Client_Details",
  APPROVAL_REQUEST:"Seperation_Approval",
  MY_SETTING: "My_Setting",
  POLICY_ACCESS: "Policy_Access",
  EMPLOYEE_SALARY_SLIP: "Salary_Slip",
  EMPLOYEE_SALARY_SLIP_HR: "Salary_Slip_HR",
  SETUP: "Setup",
  SETUP_COMPONENTS: "Setup_Components",
  SETUP_CRON: "Setup_Cron",
  SETUP_DATAACCESS: "Setup_DataAccess",
  SETUP_ROLES: "Setup_Roles",
  SETUP_USERS: "Setup_Users",
  TERM_APPRAISAL_EMPLOYEE: "Term_Appraisal_Employee",
  TERM_APPRAISAL_MANAGEMENT: "Term_Appraisal_Management",
  TERM_APPRAISAL_MANAGER: "Term_Appraisal_Manager",
  MASTER_PAYROLL: "Master_Payroll",
  QUARTER_END: "RH Can Be Applied Only In Current Quarter",
  DATE_OUT_OF_RANGE:
    "Selected Dates Are Beyond Permitted Dates For Applying Leave",
  DECLARATION_EMPTY: "Declaration Can Not Be Empty",
  DECLARATION_EXIST: "Declaration Already Exist",
  GROUP_CANNOT_BE_EMPTY: "Group Name Cannot be empty",
  WFH_LEAVE: "Successfully Applied for Work from Home",

  LEAVE_MAX_TO_DATE_MONTHS: {
    Bereavement_Leave: 7,
    Compensatory_Off: 7,
    Earned_Leave: 7,
    Sick_Leave: 1,
    Maternity_Leave: 10,
    Paternity_Leave: 7,
    Leave_Without_Pay: 7,
    Work_From_Home: 7,
  },
  NOTIFICATION_TYPE: {
    ATTENDANCE: "Attendance",
    SALARY_SLIP: "Salary_Slip",
    PROBATION_PERIOD: "Probation_Period",
    INVESTMENT_DECLARATION: "Investment_Declaration",
  },
  MASTER_MODULES: {
    LEAVE_APPLICATION_MODULE: "LEAVE_APPLICATION_MODULE",
  },
  REPORT_ACCESS: "Report_Access",
  HR_Temporary_Access_Card: "HR_Temporary_Access_Card",
  LEAVE_NEW: "Leave_Application_V1",
  LEAVE_ALLOCATION_NEW: "Leave_Allocation_V1",
  WORK_FROM_HOME_NEW: "Work_From_Home_V1",
  REGULARIZATION_NEW: "Regularization_V1",
  HR_Org_Clients: "HR_Org_Clients",
  TEAM_TIME_SHEET_ACCESS: "Team_Time_Sheet_Access",
  HANDBOOK: "Handbook",
  ORGANIZATION_ACCESS: "Organization",
  PMS_DASHBOARD: "PMS_DASHBOARD",
});

export const datePlaceholder = { placeholder: "12-Jan-2022" };
export const datePlaceholderClient = { placeholder: "12-Jan-2022" };

export default CONSTANTS;
