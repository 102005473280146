import React, { useEffect, useState } from "react";
import { SliderData } from "./Slider";
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from "react-icons/fa";
import styles from "./EmployeeHandbook.module.css";
import "./Handbook.css";
import Loader from "../Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { fetchEmployeeConsent } from "../../store/slices/handbookSlice";
const ImageSlider = ({
  slides,
  policyVersion,
  companyName,
  setEnable,
  setSlideConsent,
  setId,
  current,
  setCurrent
}) => {
  const [handBookSlides, setHandbookSlides] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const pageWiseConsent = useSelector(
    (state) => state?.handbook?.employeeConsent?.data?.slideConsent
  );

  useEffect(() => {
    setHandbookSlides([
      { handbookImage: SliderData[0].image },
      { handbookImage: SliderData[1].image },
      ...slides,
      { handbookImage: SliderData[SliderData.length - 1].image },
    ]);
    pageWiseConsent === false ? setSlideConsent(true) : setSlideConsent(false);
  }, [slides, pageWiseConsent]);
  const nextSlide = () => {
    setEnable(handBookSlides?.length - 2 === current ? false : true);

    setCurrent(
      current === handBookSlides.length - 1 ? FaArrowAltCircleLeft : current + 1
    );
    setIsLoading(true);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? current : current - 1);
    setIsLoading(true);
    setEnable(true);
  };

  if (!Array.isArray(handBookSlides) || handBookSlides.length <= 0) {
    return null;
  }

  const handleImageLoad = async (slide) => {
    setId(slide?.handbookId);
    await dispatch(fetchEmployeeConsent({ title: slide?.title }));
    setIsLoading(false);
  };
  return (
    <section className={styles.slider}>
      {current === 0 ? (
        <></>
      ) : (
        <FaArrowAltCircleLeft
          className={styles.left_arrow}
          onClick={prevSlide}
        />
      )}
      {current === handBookSlides.length - 1 ? (
        <></>
      ) : (
        <FaArrowAltCircleRight
          className={styles.right_arrow}
          onClick={nextSlide}
        />
      )}
      {handBookSlides.map((slide, index) => {
        return (
          <div
            className={
              index === current
                ? `${styles.slideScroll} ${styles.active}`
                : styles.slideScroll
            }
            key={index}
          >
            {index === current && (
              <>
                {current > 1 && current < handBookSlides.length - 1 && (
                  <div className="handbookSlideHeader bg-white flex items-center justify-between py-2 px-3 font-semibold border-b border-gray-300">
                    <div>Employee Handbook</div>
                    <div>{companyName?.value}</div>
                  </div>
                )}

                <div className="handbookImageSlides">
                  {isLoading && <Loader />}
                  <img
                    src={slide.handbookImage}
                    alt="EmployeeHandbook"
                    className={styles.scrollFix}
                    onLoad={() => handleImageLoad(slide)}
                  />
                </div>

                {current > 1 && current < handBookSlides.length - 1 && (
                  <div className="handbookSlideBottom bg-red-700 text-white flex items-center justify-between text-xs py-1 px-3">
                    <div>Policy Version {policyVersion?.value}</div>
                    <div>
                      Page {index + 1}/{handBookSlides?.length}{" "}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        );
      })}
    </section>
  );
};

export default ImageSlider;
