import React from "react";
import styles from "../Setup/SetUp.module.css";
import { Link } from "react-router-dom";

import { componentDisplayAccess } from "../../utils/userPermission";
const Client = () => {
  const projectTypeAccess = componentDisplayAccess({
    component: "Project_Type",
  });
  const projectBillingAccess = componentDisplayAccess({
    component: "Project_Billing",
  });
  const clientDetailsAccess = componentDisplayAccess({
    component: "Client_Details",
  });
  return (
    <>
      <div className="container-fluid">
        {/* <div className="head">
          <div className={styles.leaveheading}>
            <h1 className={styles.newuserhead}>Setup</h1>
          </div>
        </div> */}

        <div className={`${styles.div1} mt-5`}>
          <div className={styles.firstdiv}>
            <div className={styles.setupmain}>
              <div className="row">
                {!!projectTypeAccess?.readAccess ? (
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                    <Link className="hrTabes" id="head11" to="ProjectTypeList">
                      <div className={styles.roles}>
                        <div className="hrTabLeftAsside">
                          <i className="fa-regular fa-sitemap hrTabIcons"></i>
                        </div>
                        <h3>Project Types</h3>
                      </div>
                    </Link>
                  </div>
                ) : (
                  <></>
                )}
                {!!projectBillingAccess?.readAccess ? (
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                    <Link
                      className="hrTabes"
                      id="head11"
                      to="ProjectBillingList"
                    >
                      <div className={styles.roles}>
                        <div className="hrTabLeftAsside">
                          <i className="fa-regular fa-sitemap hrTabIcons"></i>
                        </div>
                        <h3>Project Billings</h3>
                      </div>
                    </Link>
                  </div>
                ) : (
                  <></>
                )}
                {!!clientDetailsAccess?.readAccess ? (
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                    <Link className="hrTabes" id="head11" to="client-dashboard">
                      <div className={styles.roles}>
                        <div className="hrTabLeftAsside">
                          <i className="fa-solid fa-face-hand-yawn hrTabIcons"></i>
                        </div>
                        <h3>Client</h3>
                      </div>
                    </Link>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Client;
