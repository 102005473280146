import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import // fetchLeaveApplicationV1,
// leaveUpdateStatusV1,
"../../../store/slices/leaveSlice";
import { useSelector } from "react-redux";
import Loader from "../../Loader/Loader";

const WFHConfirmationDialog = ({
  confirmStatus,
  setConfirmStatus,
  handleUpdate,
  // selectedStatus,
  setSelectedStatus,
  status,
  setSelectedEmp,
}) => {
  // const dispatch = useDispatch();
  // const getData = (params) => {
  //   dispatch(fetchLeaveApplicationV1({ ...params }));
  // };
  const updateIsFetching = useSelector(
    (state) => state?.leave?.leaveUpdateStatusDataV1?.isFetching
  );
  return (
    <Dialog
      open={confirmStatus}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      className="employee_form_dialog"
    >
      {updateIsFetching && <Loader />}
      <DialogTitle className="add_employee_main_heading">
        <div className="commentsHeader">
          <div>WFH Confirmation</div>
        </div>
      </DialogTitle>
      <DialogContent className="card-content-employee-form">
        <div className="leaveComments bg-white ">
          Are you sure you want to{" "}
          {status === "Approved" ? "approve" : "reject"} this WFH request.
        </div>
      </DialogContent>
      <DialogActions className="employee_form_button_div">
        <button
          type="button"
          onClick={() => {
            setConfirmStatus(false);
            setSelectedStatus(null);
            setSelectedEmp([]);
          }}
          className="btn cancelBtnOutline"
        >
          Cancel
        </button>
        <button
          type="submit"
          // disabled={!isDirty}
          className="btn primaryBtnOutline"
          onClick={() => handleUpdate(status)}
        >
          Save
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default WFHConfirmationDialog;
