import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "../../utils/http";
import { toast } from "react-toastify";

import END_POINTS from "../../constants/endpoints";

export const fetchProjectType = createAsyncThunk(
  "projectType/get-project-type-billing",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`projectType/get-project-type-billing`);
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchAllProjects = createAsyncThunk(
  "v2/client/getAllProjects",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`v2/client/getAllProjects`, {
        params: {
          // isActive: params?.isActive ?? "active",
          filterValue: params?.filterValue ?? "Active",
          clientId: params?.clientId ?? "",
          search: params?.search ?? "",
          sort: params?.sort ?? "ASC",
          limit: params?.limit ?? 25,
          page: params?.page ?? 1,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchProjectTypeOnly = createAsyncThunk(
  "projectType/get",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`projectType/get`, {
        params: {
          sortBy: params?.sortBy ?? "name",
          sort: params?.sort ?? "ASC",
          limit: params?.limit ?? 25,
          page: params?.page ?? 1,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchBillingType = createAsyncThunk(
  "projectType/project-billing",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`projectType/project-billing`, {
        params: {
          sortBy: params?.sortBy ?? "name",
          sort: params?.sort ?? "ASC",
          limit: params?.limit ?? 25,
          page: params?.page ?? 1,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const getAllProjectByID = createAsyncThunk(
  END_POINTS.GET_ALL_PROJECT_BY_ID,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_ALL_PROJECT_BY_ID}/${params}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createProjectType = createAsyncThunk(
  "projectType/create",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(`projectType/create`, {
        name: params.name,
        description: params.description,
      });
      toast.success(data?.message);
      return data;
    } catch (error) {
      console.log(error);
      toast.error(error.response.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const createProjectBilling = createAsyncThunk(
  "projectType/create-project-billing",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(`projectType/create-project-billing`, {
        name: params.name,
        project_type_id: params?.project_type_id,
        description: params.description,
      });
      toast.success(data?.message);
      return data;
    } catch (error) {
      console.log(error);
      toast.error(error.response.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateProjectType = createAsyncThunk(
  "projectType/update",
  async (params, { rejectWithValue }) => {
    try {
      const Id = params?.project_type_id;
      const { data } = await http.patch(
        `projectType/update/${Id}`,

        params?.is_deleted
          ? {
              is_deleted: params?.is_deleted ?? null,
            }
          : {
              name: params.name,
              description: params.description,
            }
      );
      toast.success(data?.message);
      return data;
    } catch (error) {
      console.log(error);
      toast.error(error.response.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateProjectBilling = createAsyncThunk(
  "projectType/update-project-billing",
  async (params, { rejectWithValue }) => {
    try {
      const Id = params?.project_billing_id;
      const { data } = await http.patch(
        `projectType/update-project-billing/${Id}`,
        params?.is_deleted
          ? {
              is_deleted: params?.is_deleted ?? null,
            }
          : {
              name: params?.name,
              project_type_id: params?.project_type_id,
              description: params?.description,
            }
      );
      toast.success(data?.message);
      return data;
    } catch (error) {
      console.log(error);
      toast.error(error.response.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

const initialState = {
  projectType: [],
  projectTypeList: [],
  projectBilling: [],
  getProjectDataById: { status: false, projectData: [] },
  loading: false,
  error: null,
  success: false,
};

export const ProjectSlice = createSlice({
  name: "ProjectSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProjectType.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.projectType = payload?.data;
        state.error = null;
      })
      .addCase(fetchProjectType.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.projectType = [];
        state.success = false;
      })

      .addCase(fetchProjectTypeOnly.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProjectTypeOnly.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.projectTypeList = payload?.data;
        state.error = null;
      })
      .addCase(fetchProjectTypeOnly.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.projectTypeList = [];
        state.success = false;
      })

      .addCase(fetchBillingType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBillingType.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.projectBilling = payload?.data;
        state.error = null;
      })
      .addCase(fetchBillingType.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.projectBilling = [];
        state.success = false;
      })
      //
      .addCase(fetchAllProjects.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.allProjects = {
          isFetching: true,
          data: null,
        };
      })
      .addCase(fetchAllProjects.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        // state.allProjects = payload?.data;
        state.error = null;
        state.allProjects = {
          isFetching: false,
          data: payload,
        };
      })
      .addCase(fetchAllProjects.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        // state.allProjects = [];
        state.success = false;
        state.allProjects = {
          isFetching: true,
          data: null,
        };
      })
      .addCase(getAllProjectByID.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.getProjectDataById = { isFetching: true, projectData: [] };
      })
      .addCase(getAllProjectByID.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.getProjectDataById = { isFetching: false, projectData: payload };
        state.error = null;
      })
      .addCase(getAllProjectByID.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.getProjectDataById = { isFetching: false, projectData: [] };
        state.success = false;
      });
  },
});

export default ProjectSlice.reducer;
