import { Avatar, ClickAwayListener, Pagination, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { fetchAttendanceCheckInCheckOutData } from "../../../../store/slices/myTeamSlice";
import stringAvatar from "../../../PMS/AvatarFunction";
import Loader from "../../../Loader/Loader";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import http from "../../../../utils/http";
import fileDownload from "js-file-download";

const EarlyLateCheckinCheckout = () => {
  const [toggle, setToggle] = useState(false);
  const [status, setStatus] = useState(false);
  const [statusVal, setStatusVal] = useState("Active");
  const [pageLimit, setPageLimit] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [order, setOrder] = useState("ASC");
  const [sortBy, setSortBy] = useState("firstName");
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState();
  const [attendanceDate, setAttendanceDate] = useState(
    dayjs(new Date())?.subtract(1, "day")?.format("DD-MMM-YYYY")
  );
  const [reportingType, setreportingType] = useState("Direct Reporting");
  const [downloading, setDownloading] = useState(false);
  const [currDate, setCurrDate] = useState(dayjs()?.format("DD-MMM-YYYY"));

  const ref = useRef(null);
  const empCheckInCheckoutData = useSelector(
    (state) =>
      state?.MyTeamSlice?.attendanceCheckInCheckOutList
        ?.attendanceCheckInCheckOutData?.data
  );
  const isFetching = useSelector(
    (state) => state?.MyTeamSlice?.attendanceCheckInCheckOutList?.isFetching
  );

  useEffect(() => {
    if (empCheckInCheckoutData) {
      setPageCount(empCheckInCheckoutData?.totalPages);
      setCount(empCheckInCheckoutData?.count ?? 0);
    }
  }, [empCheckInCheckoutData]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const add = (attendanceDate) => {
    return dayjs(attendanceDate)?.add(1, "days")?.format("DD-MMM-YYYY");
  };
  const subtract = (attendanceDate) => {
    return dayjs(attendanceDate)?.subtract(1, "days")?.format("DD-MMM-YYYY");
  };

  const handlePrevDate = () => {
    setAttendanceDate(subtract(attendanceDate));
    setPage(1);
  };
  const handleNextDate = () => {
    if (dayjs(attendanceDate)?.isBefore(dayjs()?.subtract(2, "day"))) {
      setAttendanceDate(add(attendanceDate));
    }
    setPage(1);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      fetchAttendanceCheckInCheckOutData({
        attendanceDate: attendanceDate,
        page: page,
        limit: limit,
        type: reportingType,
        sortBy: sortBy,
        order: order,
        search: search,
        attendanceStatus: statusVal
      })
    );
  }, [attendanceDate, page, limit, reportingType, sortBy, order, search, statusVal]);

  const handleAttendanceDownload = async () => {
    try {
      setDownloading(true);
      const response = await http({
        url: `team/v1/download-attendance-by-date`,
        method: "GET",
        responseType: "blob",
        params: {
          // EmployeeId: EmployeeId,
          attendanceDate: attendanceDate,
          sort: order,
          type: reportingType,
          status: statusVal,
        },
      });
      fileDownload(
        response.data,
        `Attendance_${dayjs(attendanceDate).format("DD-MMM-YYYY")}.xlsx`
      );
      setDownloading(false);
    } catch (error) {
      setDownloading(false);
    }
  };
  const handleSearchChange = (event) => {
    const delayInput = setTimeout(() => {
      setSearch(event?.target?.value);
    }, 800);
    setPage(1);
    return () => clearTimeout(delayInput);
  };

  return (
    <>
      <div className="dailyAttendanceStatus">
        <div className="border-1 rounded-md mt-3 relative">
          {(isFetching || downloading) && <Loader />}
          <div className="flex items-center justify-between bg-white px-3">
            <div className="flex items-center text-sm">
              {/* <FontAwesomeIcon
                className="text-gray-700 text-sm px-2 bg-gray-50 rounded-md"
                icon={faClipboardUser}
              />
              <div className="pl-2 pr-3">Daily Attendance</div>
              <div className="pr-3">|</div> */}

              <div className="text-gray-500 py-2 relative border-r border-gray-200">
                <span
                  className="text-gray-500 cursor-text"
                  onClick={() => ref.current.focus()}
                >
                  <i className="fa-light fa-magnifying-glass"></i>
                </span>
                <input
                  ref={ref}
                  onChange={handleSearchChange}
                  type="text"
                  name="search input"
                  placeholder="Search By Employee"
                  className="text-sm border-none pl-1"
                />
              </div>
              <div className="font-semibold w-24 pl-3">
                Total {`(${empCheckInCheckoutData?.count ?? 0})`}
              </div>
            </div>
            <div className="flex items-center gap-3">
              <ClickAwayListener onClickAway={() => setToggle(false)}>
                <div className="relative ml-3">
                  <div
                    onClick={() => setToggle((prev) => !prev)}
                    className="flex items-center justify-between gap-3 w-52 text-gray-500 text-md py-1 px-3 bg-white border-1 border-gray-400 rounded-md cursor-pointer"
                  >
                    <div>
                      {reportingType == "Direct Reporting"
                        ? "DIRECT"
                        : "INDIRECT"}
                    </div>
                    <i
                      className={
                        toggle
                          ? "fa-light fa-chevron-up"
                          : "fa-light fa-chevron-down"
                      }
                    ></i>
                  </div>
                  {toggle && (
                    <ul className="bg-white w-full border-1 border-gray-200 rounded-md absolute top-9 right-0 z-10">
                      <li
                        onClick={() => {
                          setToggle(false);
                          setreportingType("Direct Reporting");
                          setPage(1);
                        }}
                        className="border-b border-gray-200 px-3 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                      >
                        DIRECT
                      </li>
                      <li
                        onClick={() => {
                          setToggle(false);
                          setreportingType("Indirect Reporting");
                          setPage(1)
                        }}
                        className="border-b border-gray-200 px-3 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                      >
                        INDIRECT
                      </li>
                    </ul>
                  )}
                </div>
              </ClickAwayListener>
              <ClickAwayListener onClickAway={() => setStatus(false)}>
                <div className="relative ml-3">
                  <div
                    onClick={() => setStatus((prev) => !prev)}
                    className="flex items-center justify-between gap-3 w-52 text-gray-500 text-md py-1 px-3 bg-white border-1 border-gray-400 rounded-md cursor-pointer"
                  >
                    <div>
                      {statusVal == "Active"
                        ? "Active"
                        : "Left"}
                    </div>
                    <i
                      className={
                        status
                          ? "fa-light fa-chevron-up"
                          : "fa-light fa-chevron-down"
                      }
                    ></i>
                  </div>
                  {status && (
                    <ul className="bg-white w-full border-1 border-gray-200 rounded-md absolute top-9 right-0 z-10">
                      <li
                        onClick={() => {
                          setStatus(false);
                          setStatusVal("Active");
                        }}
                        className="border-b border-gray-200 px-3 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                      >
                        Active
                      </li>
                      <li
                        onClick={() => {
                          setStatus(false);
                          setStatusVal("Left");
                        }}
                        className="border-b border-gray-200 px-3 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                      >
                        Left
                      </li>
                    </ul>
                  )}
                </div>
              </ClickAwayListener>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ minWidth: 200, maxWidth: 150 }}>
                  <DatePicker
                    value={attendanceDate}
                    label="Daily Attendance"
                    maxDate={dayjs(currDate)?.subtract(1, "days")}
                    onChange={(newValue) => {
                      setAttendanceDate(dayjs(newValue)?.format("DD-MMM-YYYY"));
                      setPage(1);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                      />
                    )}
                    inputFormat={"DD-MMM-YYYY"}
                    disableMaskedInput
                  />
                </Box>
              </LocalizationProvider>
              <div
                onClick={() => {
                  handleAttendanceDownload();
                }}
                className="tooltipWrapper relative text-blue-600 text-md py-1 px-3 bg-inherit border-1 border-blue-400 rounded-md cursor-pointer hover:bg-blue-600 hover:text-white"
              >
                <i className="fa-light fa-download"></i>
                <div className="tooltipHover edit hidden border-1 border-solid border-blue-400 bg-blue-50 text-blue-500 py-1 px-3 rounded-md text-xs font-semibold">
                  Download in .xlsx
                </div>
              </div>
            </div>
          </div>
          <div className="attendanceTable relative bg-white w-full rounded-lg">
            <table className="w-full text-sm text-center dark:text-gray-400 whitespace-nowrap">
              <thead className="bg-white text-gray-700">
                <tr>
                  <th
                    rowSpan={2}
                    scope="col"
                    className="text-left px-6 py-2 border-1 border-gray-200"
                  >
                    <div
                      onClick={() => setOrder(order == "ASC" ? "DESC" : "ASC")}
                      className="flex items-center justify-between cursor-pointer"
                    >
                      <p>Employee</p>
                      <i
                        className={
                          order === "ASC"
                            ? "fa-light fa-arrow-down-a-z text-md"
                            : "fa-light fa-arrow-up-z-a text-md"
                        }
                      />
                    </div>
                  </th>
                  <th
                    rowSpan={2}
                    scope="col"
                    className="px-6 py-2 border-1 border-gray-200"
                  >
                    First In
                  </th>
                  <th
                    rowSpan={2}
                    scope="col"
                    className="px-6 py-2 border-1 border-gray-200"
                  >
                    Last Out
                  </th>
                  <th
                    rowSpan={2}
                    scope="col"
                    className="px-6 py-2 border-1 border-gray-200"
                  >
                    Total Hours
                  </th>
                  <th
                    scope="col"
                    colSpan={2}
                    className="px-6 py-2 border-1 border-gray-200"
                  >
                    Entry
                  </th>
                  <th
                    scope="col"
                    colSpan={2}
                    className="px-6 py-2 border-1 border-gray-200"
                  >
                    Exit
                  </th>
                  <th
                    rowSpan={2}
                    scope="col"
                    className="px-6 py-2 border-1 border-gray-200"
                  >
                    Effective Hours
                  </th>
                  <th
                    rowSpan={2}
                    scope="col"
                    className="px-6 py-2 border-1 border-gray-200"
                  >
                    Work Mode
                  </th>
                  <th
                    rowSpan={2}
                    scope="col"
                    className="px-6 py-2 border-1 border-gray-200"
                  >
                    Status
                  </th>
                  <th
                    rowSpan={2}
                    scope="col"
                    className="px-6 py-2 border-1 border-gray-200"
                  >
                    Shift
                  </th>
                </tr>
                <tr>
                  <th
                    scope="col"
                    rowSpan={2}
                    className="px-6 py-2 border-1 border-gray-200"
                  >
                    Early
                  </th>
                  <th
                    scope="col"
                    rowSpan={2}
                    className="px-6 py-2 border-1 border-gray-200"
                  >
                    Late
                  </th>
                  <th
                    scope="col"
                    rowSpan={2}
                    className="px-6 py-2 border-1 border-gray-200"
                  >
                    Early
                  </th>
                  <th
                    scope="col"
                    rowSpan={2}
                    className="px-6 py-2 border-1 border-gray-200"
                  >
                    Late
                  </th>
                </tr>
              </thead>
              <tbody>
                {empCheckInCheckoutData?.rows?.length ? (
                  empCheckInCheckoutData?.rows?.map((list, key) => {
                    return (
                      <tr key={key} className="bg-white border-b">
                        <td className="py-2 px-3 text-left">
                          <div className="flex items-center">
                            {list?.profileImage ? (
                              <Avatar
                                className="img-fluid"
                                style={{
                                  width: "28px",
                                  height: "28px",
                                  border: "1px solid #00000029",
                                  borderRadius: "50%",
                                  fontSize: "18px",
                                }}
                                src={list?.profileImage}
                                alt={list?.employeeName}
                              />
                            ) : (
                              list?.employeeName && (
                                <Avatar
                                  className="img-fluid"
                                  style={{
                                    width: "28px",
                                    height: "28px",
                                    border: "1px solid #00000029",
                                    borderRadius: "50%",
                                    fontSize: "18px",
                                  }}
                                  {...stringAvatar(list?.employeeName)}
                                  alt={list.employeeName}
                                />
                              )
                            )}
                            <div className="pl-3 pr-2 text-gray-500">
                              #{list?.employeeId}
                            </div>
                            <div>{list?.employeeName}</div>
                          </div>
                        </td>
                        <td className="py-2 px-3">
                          {list?.inTime === "Invalid date"
                            ? "-"
                            : list?.inTime
                              ? dayjs(list?.inTime, "hh:mm a")?.format("hh:mm A")
                              : "-"}
                        </td>
                        <td className="py-2 px-3">
                          {list?.outTime === "Invalid date"
                            ? "-"
                            : list?.outTime
                              ? dayjs(list?.outTime, "hh:mm a")?.format("hh:mm A")
                              : "-"}
                        </td>
                        <td className="py-2 px-3">
                          {list?.totalHours
                            ? `${Number(list?.totalHours)?.toFixed(2)} hrs`
                            : `0.00 hrs`}
                        </td>
                        <td className="py-2 px-3">
                          <span className="text-green-500 font-semibold">
                            {list?.inTimeDiff > 0
                              ? `+${list?.inTimeDiff}`
                              : "-"}
                          </span>
                        </td>
                        <td className="py-2 px-3">
                          <span className="text-red-500 font-semibold">
                            {list?.inTimeDiff < 0 ? `${list?.inTimeDiff}` : "-"}
                          </span>
                        </td>
                        <td className="py-2 px-3">
                          <span className="text-red-500 font-semibold">
                            {list?.outTimeDiff < 0
                              ? `${list?.outTimeDiff}`
                              : "-"}
                          </span>
                        </td>
                        <td className="py-2 px-3">
                          <span className="text-green-500 font-semibold">
                            {list?.outTimeDiff > 0
                              ? `+${list?.outTimeDiff}`
                              : "-"}
                          </span>
                        </td>
                        <td className="py-2 px-3 text-green-500 font-semibold">
                          {Number(list?.totalHours)?.toFixed(2)}
                        </td>
                        <td className="py-2 px-3">{list?.workMode ?? "-"}</td>
                        <td className="py-2 px-3">
                          <span
                            className={`${list?.status === "Present"
                              ? "inline-block bg-green-400 h-3 w-3 rounded-full mr-1"
                              :
                              list?.status === "Half Day"

                                ? "inline-block bg-yellow-400 h-3 w-3 rounded-full mr-1"
                                : "inline-block bg-red-400 h-3 w-3 rounded-full mr-1"
                              }`}
                          ></span>
                          {list?.status}
                        </td>
                        <td className="py-2 px-3">{`${list?.shiftName} (${dayjs(
                          list?.startTime,
                          "hh:mm:ss"
                        )?.format("hh:mm A")} - ${dayjs(
                          list?.endTime,
                          "hh:mm:ss"
                        )?.format("hh:mm A")})`}</td>
                      </tr>
                    );
                  })
                ) : (
                  <div className="absolute inset-y-1/2 inset-x-1/2">
                    <div className="flex items-center flex-col gap-2">
                      <i className="fa-light fa-clipboard-user fa-4x text-gray-300"></i>
                      <div className="text-md">No data Found</div>
                    </div>
                  </div>
                )}
              </tbody>
            </table>
          </div>
          <div className="px-3 bg-white border-t">
            <div className="timeSheetPagination bg-transparent">
              <Stack spacing={2}>
                <Pagination
                  count={pageCount}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                  className="paginationCount"
                />
              </Stack>
              <div className="totalNoOfPages ms-3">
                On Page:
                <span className="totalCount">
                  {page} of {pageCount}
                </span>
              </div>
              <label className="PaginationLabelWhere">|</label>
              <div className="totalNoOfPages">
                No. of Rows: <span className="totalCount">{count}</span>
              </div>
              <label className="PaginationLabelWhere">|</label>
              <div className="text-sm font-medium text-gray-600">Limit : </div>
              <ClickAwayListener onClickAway={() => setPageLimit(false)}>
                <div
                  className="paginationLimitBox"
                  onClick={() => setPageLimit((prev) => !prev)}
                >
                  {limit}
                  {pageLimit ? (
                    <i className={"fa-light fa-chevron-up ms-2"}></i>
                  ) : (
                    <i className={"fa-light fa-chevron-down ms-2"}></i>
                  )}
                  {pageLimit && (
                    <ul className="paginationLimitBoxDropdown">
                      {[25, 50, 75, 100]?.map((list, index) => {
                        return (
                          <li
                            key={index}
                            value={list}
                            onClick={(e) => (
                              setLimit(e?.target?.value), setPage(1)
                            )}
                          >
                            {list}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </ClickAwayListener>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EarlyLateCheckinCheckout;
