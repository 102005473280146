import React from "react";
import { Outlet } from "react-router";
import ClientHeader from "./ClientHeader";

const ClientDashboard = ({ClientRoute}) => {
  return (
    <div className="projectContainer">
      <ClientHeader ClientRoute={ClientRoute} />
      <Outlet />
    </div>
  );
};

export default ClientDashboard;
