import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export default function DownloadReport({
  handleExcelDownload,
  loading = false,
  heading="Report",
}) {
  return (
    <button
      className="btn primaryBtnOutline"
      onClick={handleExcelDownload}
      disabled={loading}
    >
      <i className="fa-light fa-download me-2"></i>
      {heading}
      {loading && <CircularProgress className="downloadReportLoader" />}
    </button>
  );
}
