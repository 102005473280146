import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import ClientInformation from "./ClientInformation";
import Loader from "../../Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getAllClientsDataById } from "../../../store/slices/masterSlice";

const ClientDetails = ({ setShowClientDetail, clientData }) => {
  const dispatch = useDispatch();
  const clientId = clientData?.client_id;
  const [activeClientTab, setActiveClientTab] = useState(1);
  const [activeTeamTask, setActiveTeamTask] = useState();
  useEffect(() => {
    dispatch(getAllClientsDataById(clientId));
  }, [dispatch, clientId]);

  const isFetching = useSelector(
    (state) => state?.masterList?.getClientDataById?.isFetching
  );
  const clientDataById = useSelector(
    (state) => state?.masterList?.getClientDataById?.clientData?.data
  );
  const projectListTab = [
    {
      id: 1,
      tabname: "Information",
    },
    // {
    //   id: 2,
    //   tabname: "Settings",
    // },
  ];

  return (
    <>
      <div className="clientInformationContainer">
        <div
          onClick={() => setShowClientDetail(false)}
          className="flex items-center gap-2 mb-2 text-blue-600 cursor-pointer text-md"
        >
          <i className="fa-light fa-arrow-left"></i>{" "}
          <span className="text-sm">Back to Client</span>
        </div>

        <div className="rounded-md border-1 border-gray-100 relative">
          {isFetching && <Loader />}
          <div className="flex items-center justify-between py-3 px-3 bg-white border-b border-gray-200 pb-4">
            <div className="w-full flex items-center gap-3">
              <div className="flex items-center">
                <div className="border-r border-gray-300 pr-3 font-semibold uppercase">
                  {clientDataById?.client_icon ? (
                    <img
                      src={clientDataById.client_icon}
                      alt={clientDataById?.client_name}
                      className="img-fluid w-32"
                    />
                  ) : (
                    clientDataById?.client_name
                  )}
                </div>
                {/* <div className="pl-3">
                  <div className="text-gray-950">
                    {clientDataById?.project_name}
                  </div>
                  <div className="text-xs text-gray-500">
                    {clientDataById?.project_area}
                  </div>
                </div> */}
              </div>
            </div>
            <div className="w-full flex items-center justify-end gap-5 text-sm">
              <div className="">
                <div className="text-gray-500 mb-2 font-semibold">
                  Client Name
                </div>
                <div className="capitalize">{clientDataById?.client_name}</div>
              </div>
              <div className="">
                <div className="text-gray-500 mb-2 font-semibold">Domain</div>
                <div className="capitalize">
                  {clientDataById?.domain === "Others"
                    ? clientDataById?.other_domain
                    : clientDataById?.domain ?? "-"}
                </div>
              </div>
              <div className="">
                <div className="text-gray-500 mb-2 font-semibold">Status</div>
                <div className="text-green-600 cursor-pointer">
                  {clientDataById?.is_active ? "Active" : "Left"}
                </div>
              </div>
              <div className="">
                <div className="text-gray-500 mb-2 font-semibold">
                  Creation Date
                </div>
                <div className="text-gray-700">
                  {dayjs(clientDataById?.creation)?.format("DD-MMM-YYYY") ??
                    "-"}
                </div>
              </div>
            </div>
          </div>
          <div className="projectListDetailsTab bg-white">
            <ul className="flex items-center text-sm">
              {projectListTab &&
                projectListTab?.map((list) => (
                  <li
                    id={list?.id}
                    onClick={
                      () => setActiveClientTab(list?.id)
                      //   setActiveTeamTask(
                      //     projectTeamInfo[0]?.teams[0]?.employeeId
                      //   )
                    }
                    className={
                      activeClientTab === list?.id
                        ? `active border-b border-blue-500 py-2 px-3 cursor-pointer`
                        : `py-2 px-3 cursor-pointer border-b border-transparent hover:text-blue-600 active:border-b`
                    }
                  >
                    {list?.tabname}
                  </li>
                ))}
            </ul>
          </div>
          {activeClientTab === 1 && (
            <ClientInformation
              activeClientTab={activeClientTab}
              setActiveClientTab={setActiveClientTab}
              activeTeamTask={activeTeamTask}
              setActiveTeamTask={setActiveTeamTask}
              clientData={clientDataById}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ClientDetails;
