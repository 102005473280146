import React, { useCallback, useEffect, useState } from "react";
import "../ClientDashboard.css";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  createClientProject,
  fetchAllCompanyEmployee,
  fetchAllHods,
  fetchAllReportingManagers,
  fetchDepartmentTypeSliceDataStore,
} from "../../../store/slices/masterSlice";
import AutoCompleteComponent from "../../MasterComponent/AutoCompleteComponent";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextFieldComponent from "../../MasterComponent/TextFieldComponent";
import {
  fetchAllProjects,
  getAllProjectByID,
} from "../../../store/slices/ProjectSlice";

const addProjectSchema = yup?.object({
  project_id: yup?.number()?.integer()?.label("Project Id")?.required(),
  hod: yup?.object()?.label("Head of Project")?.required(),
  project_manager: yup?.object()?.label("Project Manager")?.required(),
  approver_1: yup?.object()?.label("Approver_1")?.optional().nullable(),
  approver_2: yup?.object()?.label("Approver_2")?.optional().nullable(),
  timesheet_viewer: yup
    ?.object()
    ?.label("TimeSheet Viewer")
    ?.optional()
    .nullable(),
});

const AddTeamApprover = ({
  addApprover,
  setAddApprover,
  getProjectsById,
  allProjectData,
}) => {
  const [addApprover2, setAddApprover2] = useState(false);
  let hod = getProjectsById?.data?.ProjectApprovers?.find(
    (data) => data.ApprovalLevel?.approver_post === "hod"
  );
  if (hod) {
    hod = {
      label: `${hod?.approver_emp_id} - ${hod?.ProjectApproverNames?.employee_name}`,
      value: hod?.approver_emp_id,
    };
  }
  let project_manager = getProjectsById?.data?.ProjectApprovers?.find(
    (data) => data.ApprovalLevel?.approver_post === "project_manager"
  );
  if (project_manager) {
    project_manager = {
      label: `${project_manager?.approver_emp_id} - ${project_manager?.ProjectApproverNames?.employee_name}`,
      value: project_manager?.approver_emp_id,
    };
  }
  let approver_2 = getProjectsById?.data?.ProjectApprovers?.find(
    (data) => data.ApprovalLevel?.approver_post === "approver_2"
  );
  if (approver_2) {
    approver_2 = {
      label: `${approver_2?.approver_emp_id} - ${approver_2?.ProjectApproverNames?.employee_name}`,
      value: approver_2?.approver_emp_id,
    };
  }

  let approver_1 = getProjectsById?.data?.ProjectApprovers?.find(
    (data) => data.ApprovalLevel?.approver_post === "approver_1"
  );
  if (approver_1) {
    approver_1 = {
      label: `${approver_1?.approver_emp_id} - ${approver_1?.ProjectApproverNames?.employee_name}`,
      value: approver_1?.approver_emp_id,
    };
  }
  let timesheet_viewer = getProjectsById?.data?.ProjectApprovers?.find(
    (data) => data.ApprovalLevel?.approver_post === "timesheet_viewer"
  );
  if (timesheet_viewer) {
    timesheet_viewer = {
      label: `${timesheet_viewer?.approver_emp_id} - ${timesheet_viewer?.ProjectApproverNames?.employee_name}`,
      value: timesheet_viewer?.approver_emp_id,
    };
  }

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllHods());
    dispatch(fetchAllReportingManagers());
    dispatch(fetchDepartmentTypeSliceDataStore());
    dispatch(fetchAllCompanyEmployee());
  }, [dispatch]);

  let getAllHodList = useSelector(
    (state) => state?.masterList?.allHodsList?.response?.data
  );
  getAllHodList = getAllHodList?.map((v) => ({
    label: `${v?.empNameByUserId?.EmployeeId} - ${
      v?.empNameByUserId?.firstName ?? ""
    } ${v?.empNameByUserId?.middleName ?? ""} ${
      v?.empNameByUserId?.lastName ?? ""
    }`,
    value: v?.empNameByUserId?.EmployeeId,
  }));

  let getAllRMList = useSelector(
    (state) => state?.masterList?.allReportingManagersList?.response?.data
  );
  getAllRMList = getAllRMList?.map((v) => ({
    label: `${v?.EmployeeId} - ${v?.firstName ?? ""} ${v?.middleName ?? ""} ${
      v?.lastName ?? ""
    }`,
    value: v?.EmployeeId,
  }));
  let getAllimesheetViewerList = useSelector(
    (state) => state?.masterList?.allReportingManagersList?.response?.data
  );
  getAllimesheetViewerList = getAllimesheetViewerList?.map((v) => ({
    label: `${v?.EmployeeId} - ${v?.firstName ?? ""} ${v?.middleName ?? ""} ${
      v?.lastName ?? ""
    }`,
    value: v?.EmployeeId,
  }));

  const defaultValue = {
    project_id: getProjectsById?.data?.project_id,
    hod: hod ?? null,
    project_manager: project_manager ?? null,
    approver_1: approver_1 ?? null,
    approver_2: approver_2 ?? null,
    timesheet_viewer: timesheet_viewer ?? null,
  };
  const methods = useForm({
    defaultValues: defaultValue,
    resolver: yupResolver(addProjectSchema),
  });
  useEffect(() => {
    if (
      getProjectsById?.data?.ProjectApprovers?.filter(
        (item) => item?.ApprovalLevel?.approver_post === "approver_2"
      )?.length
    ) {
      setAddApprover2(true);
    }
  }, [getProjectsById?.data?.ProjectApprovers]);

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
    watch,
    trigger,
    setValue,
  } = methods;
  const fetchInitialData = useCallback(() => {
    const submitData = {
      project_id: getProjectsById?.data?.project_id,
      hod: hod ?? null,
      project_manager: project_manager ?? null,
      approver_1: approver_1 ?? null,
      approver_2: approver_2 ?? null,
      timesheet_viewer: timesheet_viewer ?? null,
    };
    methods.reset(submitData);
  }, [getProjectsById?.data?.project_id, methods]);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  let employeeList = useSelector(
    (state) => state?.masterList?.allCompanyEmployeesData?.response?.data
  );
  employeeList = employeeList?.map((v) => ({
    label: `${v?.EmployeeId} - ${v?.employee_name}`,
    value: v?.EmployeeId,
  }));
  let teamArray =
    getProjectsById?.data &&
    getProjectsById?.data?.team?.map((i) => i?.employee_id);
  const submitHandler = async (data) => {
    const dirtyFields = Object.keys(methods.formState.dirtyFields);
    const dirtyData = {};
    dirtyFields.forEach((key) => {
      dirtyData[key] =
        String(data[key]?.value) !== "undefined"
          ? String(data[key]?.value)
          : null;
    });
    const submitData = {
      ...dirtyData,
      project_id: data?.project_id,
    };
    await dispatch(createClientProject(submitData));
    await dispatch(fetchAllProjects(allProjectData));
    await dispatch(getAllProjectByID(getProjectsById?.data?.project_id));
    reset();
    setAddApprover(false);
  };
  getAllHodList = getAllHodList?.filter(
    (item) =>
      item?.value !== watch("approver_1")?.value &&
      item?.value !== watch("approver_2")?.value &&
      item?.value !== watch("timesheet_viewer")?.value &&
      !teamArray?.includes(item?.value)
  );
  getAllRMList = getAllRMList?.filter(
    (item) =>
      item?.value !== watch("approver_1")?.value &&
      item?.value !== watch("approver_2")?.value &&
      item?.value !== watch("timesheet_viewer")?.value &&
      !teamArray?.includes(item?.value)
  );
  getAllimesheetViewerList = getAllimesheetViewerList?.filter(
    (item) =>
      item?.value !== watch("approver_1")?.value &&
      item?.value !== watch("approver_2")?.value &&
      item?.value !== watch("hod")?.value &&
      item?.value !== watch("project_manager")?.value &&
      !teamArray?.includes(item?.value)
  );
  employeeList = employeeList?.filter(
    (item) =>
      item?.value !== watch("hod")?.value &&
      item?.value !== watch("project_manager")?.value &&
      item?.value !== watch("approver_1")?.value &&
      item?.value !== watch("approver_2")?.value &&
      item?.value !== watch("timesheet_viewer")?.value &&
      !teamArray?.includes(item?.value)
  );
  return (
    <>
      <div
        className={
          addApprover
            ? "createProjectDrawer addProject active"
            : "createProjectDrawer addProject"
        }
      >
        <div className="createProjectDrawerHeader">
          <div className="w-full whitespace-nowrap">
            <div className="text-lg mb-2 font-semibold flex items-center justify-between">
              <div>{getProjectsById ? "Update " : "Add "} Approver</div>
              <div
                className="headerClose"
                onClick={() => {
                  setAddApprover(false);
                  reset();
                }}
              >
                <i className="fa-light fa-xmark fa-lg"></i>
              </div>
            </div>
            <div className="text-sm text-blue-100">
              Get started by filling the information below to
              {getProjectsById ? "update " : "add "} Approver.
            </div>
          </div>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submitHandler)}>
            <div className={addApprover ? "active" : ""}>
              <div className="formContainer whitespace-nowrap pt-4">
                {/*  Project Detail form */}
                <div className="row">
                  <div className="col-md-12">
                    <label
                      htmlFor="project_id"
                      className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Project Id <span className="text-red-500">*</span>:
                    </label>
                    <div className="create_form_input_div autoSelect mb-3">
                      <TextFieldComponent
                        name="project_id"
                        control={control}
                        errors={errors}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label
                      htmlFor="hod"
                      className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Head Of Department <span className="text-red-500">*</span>
                      :
                    </label>
                    <div className="create_form_input_div autoSelect mb-3">
                      <AutoCompleteComponent
                        name="hod"
                        control={control}
                        errors={errors}
                        // onChangeHandler={() => trigger("hod")}
                        options={getAllHodList?.length ? getAllHodList : []}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label
                      htmlFor="project_manager"
                      className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Project Manager <span className="text-red-500">*</span>:
                    </label>
                    <div className="create_form_input_div autoSelect mb-3">
                      <AutoCompleteComponent
                        name="project_manager"
                        control={control}
                        errors={errors}
                        options={getAllRMList?.length ? getAllRMList : []}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label
                      htmlFor="timesheet_viewer"
                      className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Time Sheet Viewer:
                    </label>
                    <div className="create_form_input_div autoSelect mb-3">
                      <AutoCompleteComponent
                        name="timesheet_viewer"
                        control={control}
                        errors={errors}
                        options={getAllimesheetViewerList ?? []}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label
                      htmlFor="approver_1"
                      className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Approver 1:
                    </label>
                    <div className="create_form_input_div autoSelect mb-3">
                      <AutoCompleteComponent
                        name="approver_1"
                        control={control}
                        errors={errors}
                        options={employeeList ?? []}
                      />
                    </div>
                  </div>
                  {addApprover2 && watch("approver_1") ? (
                    <div className="col-md-12">
                      <label
                        htmlFor="approver_2"
                        className="mb-1 text-sm flex font-medium text-gray-900 dark:text-white"
                      >
                        Approver 2:
                        <div className="approverPriorityInfoIcon relative">
                          <i className="fa-light fa-circle-info"></i>
                          <div className="approverPriority1InfoDiv">
                            <label className="approverPriority1InfoLabel">
                              Approver 2 Has More Priority Than Approver 1
                            </label>
                          </div>
                        </div>
                      </label>
                      <div className="create_form_input_div autoSelect mb-3">
                        <AutoCompleteComponent
                          name="approver_2"
                          control={control}
                          errors={errors}
                          options={employeeList ?? []}
                        />
                      </div>
                    </div>
                  ) : null}
                  {addApprover2 &&
                  !getProjectsById?.data?.ProjectApprovers?.filter(
                    (item) =>
                      item?.ApprovalLevel?.approver_post === "approver_2"
                  )?.length ? (
                    <button
                      className="addButtonInitial d-flex justify-content-end"
                      type="button"
                      onClick={() => {
                        setAddApprover2(false);
                        setValue("approver_2", null);
                        trigger();
                      }}
                    >
                      Remove
                    </button>
                  ) : watch("approver_1") &&
                    !getProjectsById?.data?.ProjectApprovers?.filter(
                      (item) =>
                        item?.ApprovalLevel?.approver_post === "approver_2"
                    )?.length ? (
                    <button
                      className="addButtonInitial d-flex justify-content-end"
                      type="button"
                      onClick={() => setAddApprover2(true)}
                    >
                      + Add More
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="text-end p-3  whitespace-nowrap">
              <button
                onClick={() => {
                  setAddApprover(false);
                  reset();
                }}
                type="button"
                className="inline-flex font-sm text-sm border-1 border-gray-600 rounded-md cursor-pointer hover:text-gray-50 hover:bg-gray-500 hover:border-gray-500 py-2 px-4"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={!isDirty}
                id={!isDirty ? "newDisable" : ""}
                className="ml-3 inline-flex font-sm text-sm border-1 border-blue-600 rounded-md cursor-pointer bg-blue-600 text-white hover:text-gray-50 hover:bg-blue-500 hover:border-blue-500 py-2 px-4"
              >
                Submit
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
      <div
        className={
          addApprover ? "createProjectOverlay active" : "createProjectOverlay"
        }
        onClick={() => setAddApprover(false)}
      ></div>
    </>
  );
};

export default AddTeamApprover;
